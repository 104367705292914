/**
 * @description:Utility info
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-05-2024
 */
const isNotNull = function (data) {
  if (data !== null
    && data !== undefined
    && data !== 'null'
    && data !== 'undefined'
    && data !== '') {
    return true;
  } else {
    return false;
  }
}

/**
 * Check success oresponse
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-05-2024
 */
const isSuccessResponse = function (status) {
  if (isNotNull(status) && status === 200) {
    return true;
  } else {
    return false;
  }

}

/**
 * Array is empty or not
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-05-2024
 */
const isArrayNotEmpty = function (arr) {
  if (isNotNull(arr) && arr.length > 0) {
    return true;
  } else {
    return false;
  }

}

/**
 * Group by data
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-05-2024
 */
const groupBy = function (array, key) {
  if (!isArrayNotEmpty(array) || !isNotNull(key)) {
    return null;
  }
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const getNumberFormat = function async (num, digit) {
  try {
    if(num === undefined || num === null || num === ""){
      return "";
    }
    var nf = new Intl.NumberFormat();
    return nf.format(num);
  } catch (error) {
    return num;
  }
}


module.exports = {
  isNotNull: isNotNull,
  isSuccessResponse: isSuccessResponse,
  isArrayNotEmpty: isArrayNotEmpty,
  groupBy: groupBy,
  getNumberFormat: getNumberFormat
};
