import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {
    showFilters: false,
    airlineFilter: '',
    sortBy: 'Cheapest',
    stops: [],
    direct: false,
    searchFlight: '',
    sortlist: 'Select',
    boardTime: [
      { earlymorning: false, morning: false, afternoon: false, evening: false },
      { earlymorning: false, morning: false, afternoon: false, evening: false }
    ]
  }
}
const FlightFilter = createSlice({
  name: 'flightfilter',
  initialState,
  reducers: {
    setShowFilter: (state, action) => {
      state.value.showFilters = action.payload;
    },
    setAirlineFilter: (state, action) => {
      state.value.airlineFilter = action.payload;
    },
    setSortByFilter: (state, action) => {
      state.value.sortBy = action.payload;
    },
    setPriceFilter: (state, action) => {
      state.value.sortBy = action.payload;
    },
    setStopsFilter: (state, action) => {
      state.value.stops = action.payload;
    },
    setSearchFlight: (state, action) => {
      state.value.searchFlight = action.payload;
    },
    setDirectFlight: (state, action) => {
      state.value.direct = action.payload;
    },
    setSortList: (state, action) => {
      state.value.sortlist = action.payload
    },
    setOnboardTime: (state, action) => {
      const { text, index, value } = action.payload;
      state.value.boardTime[index][text] = value;
      console.log('toolkit', text, index, value)
    }
  }
})
export const {
  setShowFilter,
  setAirlineFilter,
  setSortByFilter,
  setPriceFilter,
  setStopsFilter,
  setSearchFlight,
  setDirectFlight,
  setSortList,
  setOnboardTime
} = FlightFilter.actions;
export default FlightFilter.reducer;