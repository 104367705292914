import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Timer from './Timer';
import CardDetails from './ChildComp/CardDetails';
import { Context } from '../../../../App';
import PriceDetails from '../../Hotels/review/PriceDetails';

/**
 * @author: Praveen Varma
 * @param {*}
 * @description Hotel payment data
 * @date : 29-07-2024
 */
function HotelPaymentDetails(props) {
    const nav = useNavigate();
    const [context, setContext] = useContext(Context);
    const location = useLocation();
    const [payDetails, setPayDetails] = useState("");
    const [displayTripDetails, setDisplayTripDetails] = useState(true);
    const [timer, setTimer] = useState(true)
    const [request, setRequest] = useState(location?.state?.req);
    const [cur, setUserCur] = useState("");
    const [itinerary, setitinerary] = useState(props?.itinerary_info);

    useEffect(() => {
        if (props?.itinerary_info === undefined || props?.itinerary_info === null || props?.itinerary_info === "") {
            nav("/")
        }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem("SEL_CUR") !== null) {
            setUserCur(sessionStorage.getItem("SEL_CUR"));
            setContext({ ...context, userCur: sessionStorage.getItem("SEL_CUR") });
        } else {
            setUserCur(context.userCur);
            sessionStorage.setItem("SEL_CUR", context.userCur)
        }
    }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

    useEffect(() => {
        setPayDetails({
            "flow": "Hotel",
            "book_id": props.bookingId,
            "kft_id": itinerary?.kft_id,
            "sup": itinerary?.req?.sup,
            "amount": itinerary?.req?.final_amt,
            "transId": props.transId,
            "currency": itinerary?.req?.usr_cur,
            "itinerary_id": itinerary?.itinerary_id
        });
    }, [cur, request])

    const pay_otp_enable = (data) => {
        if (data && data?.otp_enable) {
            setDisplayTripDetails(false);
            setTimer(false);
        }
    }

    return (
        <>
            <section className="statusBarWrap">
                <div className="mainContainer">
                    <Row>
                        <Col>
                            <div className="d-flex align-items-center">
                                <div className="statusBarItem">
                                    <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                                    <span>Review Hotel</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                                    <span>Traveller Details</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='atPresent smallCircle'>3</span>
                                    <span>Payment</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='pending smallCircle'>4</span>
                                    <span>Confirmation</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <div className="paymentPage">
                <section>
                    <div className="mainContainer">
                        <Row>
                            <Col>
                                <div className="d-flex mt-20">
                                    <div className="paymentSideWrap">
                                        {request !== undefined && (
                                            <>
                                                {timer && (
                                                    <Timer />
                                                )}
                                                <CardDetails
                                                    pay_data={payDetails}
                                                    base_usr_exrate={request?.base_usr_exrate}
                                                    pay_otp_enable={pay_otp_enable}
                                                />
                                            </>
                                        )}
                                    </div>

                                    {itinerary && displayTripDetails &&
                                        <PriceDetails
                                            itinerary={itinerary}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </>
    )
}
export default HotelPaymentDetails