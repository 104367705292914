/**
 * @ Author: Mohammed Ubedullah
 * @ Description: Flight Details Popup
 */

import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { handleTimeChange } from '../../../../common/services/DateValidations';
import Table from 'react-bootstrap/Table';

function FlightDetailsPopup({modalShow, closeModal, data, cur}) {
  const currency = cur;
  const [detailData, setDetailData] = useState("")

  useEffect(() => {
    if(Array.isArray(data)) {
      setDetailData(data)
    } else {
      setDetailData([data])
    }
  }, [data])

  const closeFlightDetails = () => {
    closeModal(false)
  }

  // Airlines logo...
  const handleLogo = (data) => {
    let logo;
    try {
      logo = require(`../../../../../assets/airlines-logo/${data}.png`);
    } catch (error) {
      logo = null;
    }
    return logo
  }

  return (
    <Modal
      show={modalShow}
      onHide={closeFlightDetails}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='flightDetailsPopup'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="flightDetailTitle">
            Flight Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="">
            <Tabs
                  defaultActiveKey="flightItinerary"
                  id="uncontrolled-tab-example"
                  className="flightDetailsTab"
                >
                  <Tab eventKey="flightItinerary" title="Flight Itinerary">
                    <div className="tabshow">
                      <div className="flightmain-info">
                        {detailData &&
                          detailData[0]?.OutboundInboundlist?.map((outbounddata, index) => {
                            const lengths = outbounddata?.flightlist?.length;
                            const depertureDatas = outbounddata?.flightlist[0]?.Departure;
                            const arrivalDatas = outbounddata?.flightlist[lengths - 1]?.Arrival;
                            return (
                              <React.Fragment key={index}>
                                <div className='mt-15'>
                                  <div className="flightDepWrap">
                                    <div className="flightDep">
                                      <i class={index === 0 ? "icon icon-Flight-Circle" : "icon icon-Flight-Circle return"}></i> {index === 0 ? "Departure" : "Return"}
                                    </div>
                                  </div>
                                  <div className="routeTimeWrap">
                                    <div className="routeTime">
                                      <span className='route'>{depertureDatas?.city}</span>
                                      <i class="icon icon-Arrow"></i>
                                      <span className='route'>{arrivalDatas?.city}</span>
                                      <span className='time leftSeparatorLine'>{depertureDatas?.Date}</span>
                                    </div>
                                  </div>
                                  <i class="icon icon-Pin"></i>
                                </div>
                                          

                                <div className="rbBot">
                                  {outbounddata?.flightlist?.map((flightListData, idx) => (
                                    <>
                                  <div className="rbInnerBot" key={idx}>
                                  <div className='planeInfo'>
                                    <div className="planeName">                                      
                                    {handleLogo(flightListData?.MarketingAirline?.code) && (
                                      <img src={handleLogo(flightListData?.MarketingAirline?.code)} alt="AirlineLogo" className='img-fluid planeImage' />
                                    )}
                                    {flightListData?.MarketingAirline?.name}</div>
                                    <div className="planeCodeClassWrap"><span className='planeCode'>{flightListData?.MarketingAirline?.code}-{flightListData?.MarketingAirline?.number}</span><span className='planeClass leftSeparatorLine'>{flightListData?.CabinClassText}</span></div>
                                    <div className="operatedBy">Operated by : {flightListData?.MarketingAirline?.name}</div>
                                    <div className="flightDetailsPiecesWrap"><span className='pieces'><i class="icon icon-Bag"></i> {flightListData?.CheckinBaggage[0]?.Value}</span></div>
                                  </div>
                                  <div className='airlineInfo'>
                                    <div className='deparrInfo'>
                                        <div className='deparrTime'>{flightListData?.Departure.time}</div>
                                        <div className='deparrLocation'>{flightListData?.Departure.city}</div>
                                        <div className='airportName'>{flightListData?.Departure.name}</div>
                                        {/* <div className="terminal">Terminal 1</div> */}
                                    </div>
                                    <div className='oneSideDuration text-center'>
                                      <span><i class="icon icon-flight"></i></span>
                                      <span>Duration : {handleTimeChange(flightListData?.FlightMinutes)}</span>
                                      <span><i class="icon icon-Pointer"></i></span>
                                    </div>
                                    <div className='deparrInfo'>
                                      <div className='deparrTime'>{flightListData?.Arrival?.time}</div>
                                      <div className='deparrLocation'>{flightListData?.Arrival?.city}</div>
                                      <div className='airportName'>{flightListData?.Arrival?.name}</div>
                                      {/* <div className="terminal">Terminal 1</div> */}
                                    </div>
                                    </div>
                                  </div>
                                  {handleTimeChange(outbounddata?.flightlist[0]?.connectiontime ? outbounddata?.flightlist[0]?.connectiontime : outbounddata?.flightlist[1]?.connectiontime) && (
                                    <>                                   
                                    {(idx === 0) && (
                                      <div className="totalDuationTimeWrap">
                                        <div className='totalDuationTime'>
                                          <span className='separatorLine'>Layover : {handleTimeChange(outbounddata?.flightlist[0]?.connectiontime ? outbounddata?.flightlist[0]?.connectiontime : outbounddata?.flightlist[1]?.connectiontime)}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    </>
                                  )}
                                </>
                                  ))}
                                  </div>

                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                    </div>                  
                  </Tab>


                  <Tab eventKey="baggage" title="Baggage">
                    
                {detailData &&
                  detailData[0]?.OutboundInboundlist?.map((outbound, index) => {
                    const lengths = outbound?.flightlist?.length;
                    const depertureDatas = outbound?.flightlist[0]?.Departure;
                    const arrivalDatas = outbound?.flightlist[lengths - 1]?.Arrival;
                    // console.log(outbound.flightlist)
                    return (
                      <React.Fragment key={index}>
                      <div className='mt-15'>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class={index === 0 ? "icon icon-Flight-Circle" : "icon icon-Flight-Circle return"}></i> {index === 0 ? "Departure" : "Return"}
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'>{depertureDatas?.city}</span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'>{arrivalDatas?.city}</span>
                            <span className='time leftSeparatorLine'>{depertureDatas?.Date}</span>
                          </div>
                        </div>
                        <i class="icon icon-Pin"></i>
                      </div>
                      {/* <div className='mt-15'>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle"></i> Departure
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'>Dubai</span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'>London</span>
                            <span className='time leftSeparatorLine'>Sat, 20 Apr,2024</span>
                          </div>
                        </div>
                        <i class="icon icon-Pin"></i>
                      </div>
                      <div className='d-flex justify-content-between pb-5 mt-15'>
                        <div className='boxesWrap'>
                          <div className='boxesHeader'>
                            <span className='boxOne commonBox'>
                              <span>DXB</span>
                              <i class="icon icon-Arrow"></i>
                              <span>RUH</span>
                            </span>
                            <span className='boxTwo commonBox'>Check In</span>
                            <span className='boxThree commonBox'>Cabin</span>
                          </div>
                          <div className='boxesData'>
                            <span className='boxFour commonBox'>Adult</span>
                            <span className='boxFive commonBox'>2 Piece</span>
                            <span className='boxSix commonBox'>7 KG</span>
                          </div>
                        </div>
                        <div className='boxesWrap'>
                          <div className='boxesHeader'>
                            <span className='boxOne commonBox'>
                              <span>DXB</span>
                              <i class="icon icon-Arrow"></i>
                              <span>RUH</span>
                            </span>
                            <span className='boxTwo commonBox'>Check In</span>
                            <span className='boxThree commonBox'>Cabin</span>
                          </div>
                          <div className='boxesData'>
                            <span className='boxFour commonBox'>Adult</span>
                            <span className='boxFive commonBox'>2 Piece</span>
                            <span className='boxSix commonBox'>7 KG</span>
                          </div>
                        </div>
                      </div> */}


                        
                        <div className='d-flex justify-content-between pb-5 mt-15'>
                          {
                            outbound?.flightlist?.map((listItem) => {
                              return (
                                <>
                                  
                                    <div className='boxesWrap'>
                                      <div className='boxesHeader'>
                                        <span className='boxOne commonBox'>
                                          <span>{listItem?.Departure?.Iata}</span>
                                          <i class="icon icon-Arrow"></i>
                                          <span>{listItem?.Arrival?.Iata}</span>
                                        </span>
                                        <span className='boxTwo commonBox'>Check In</span>
                                        <span className='boxThree commonBox'>Cabin</span>
                                      </div>
                                      
                                    {
                                      listItem?.CheckinBaggage?.map((baggage) => {
                                        return (
                                          <>
                                          <div className='boxesData'>
                                            <span className='boxFour commonBox'>{baggage?.Type}</span>
                                            <span className='boxFive commonBox'>{listItem?.CabinClassText}</span>
                                            <span className='boxSix commonBox'>{baggage?.Value}</span>
                                          </div>
                                          </>
                                        )
                                      })
                                    }
                                    </div>
                                    {/* <div className='boxesWrap'>
                                      <div className='boxesHeader'>
                                        <span className='boxOne commonBox'>
                                          <span>DXB</span>
                                          <i class="icon icon-Arrow"></i>
                                          <span>RUH</span>
                                        </span>
                                        <span className='boxTwo commonBox'>Check In</span>
                                        <span className='boxThree commonBox'>Cabin</span>
                                      </div>
                                      <div className='boxesData'>
                                        <span className='boxFour commonBox'>Adult</span>
                                        <span className='boxFive commonBox'>2 Piece</span>
                                        <span className='boxSix commonBox'>7 KG</span>
                                      </div>
                                    </div> */}
                                </>
                              )
                            })
                          }
                        </div>                          
                      {/* <div className="checkInWrap mt-20">
                        <span>Check-in Baggage : </span>
                        <span>Economy Class : 1 piece, up to 23kg</span>
                        <span className='leftSeparatorLine'>Business Class : 1 piece, up to 32kg.</span>
                      </div> */}
                      </React.Fragment>
                    )
                  })
                }
                  </Tab>
                  <Tab eventKey="fareBreakup" title="Fare Breakup">  
                    {
                      detailData &&
                      detailData[0]?.OutboundInboundlist?.map((outbound, index) => {
                        const lengths = outbound?.flightlist?.length;
                        const depertureDatas = outbound?.flightlist[0]?.Departure;
                        const arrivalDatas = outbound?.flightlist[lengths - 1]?.Arrival;
                        return (
                          <>                          
                            <div className='mt-15'>
                              <div className="flightDepWrap">
                                <div className="flightDep">
                                  <i class={index === 0 ? "icon icon-Flight-Circle" : "icon icon-Flight-Circle return"}></i> {index === 0 ? "Departure" : "Return"}
                                </div>
                              </div>
                              <div className="routeTimeWrap">
                                <div className="routeTime">
                                  <span className='route'>{depertureDatas?.city}</span>
                                  <i class="icon icon-Arrow"></i>
                                  <span className='route'>{arrivalDatas?.city}</span>
                                  <span className='time leftSeparatorLine'>{depertureDatas?.Date}</span>
                                </div>
                              </div>
                              <i class="icon icon-Pin"></i>
                            </div>
                            <div className='fareBreakupTableWrap mb-0 mt-15'>
                              {
                                outbound?.flightlist?.map((list, index) => {
                                  return (
                                    <>
                                    {index === 0 && (
                                      <div className="fareBreakupTable">
                                        <div className="fareBreakupTableHeader">
                                            <span className='paxtypeSpan'>Pax Type</span>
                                            <span className='basefareSpan'>Base Fare</span>
                                            <span className='feetaxSpan'>Fee & Tax</span>
                                            <span className='nopaxSpan'>No. Of Pax</span>
                                            <span className='totalpaxSpan'>Total Per Pax</span>
                                            <span className='totalSpan'>Total</span>
                                        </div>
                                        <div className='fareBreakupTableDataWrap'>
                                          

                                        {
                                          list?.CheckinBaggage?.map((baggage, id) => {
                                            return (
                                              <>                                              
                                                <div className="fareBreakupTableData">
                                                  <span className='paxtypeSpan'>{baggage?.Type}</span>
                                                  <span className='basefareSpan'>{currency} {detailData[0]?.price.net_base_price}</span>
                                                  <span className='feetaxSpan'>{currency} {detailData[0]?.price.tax_price}</span>
                                                  <span className='nopaxSpan'>{detailData[0]?.price?.tarriffinfo[id]?.quantity}</span>
                                                  <span className='totalpaxSpan'>{currency} {detailData[0]?.price.tarriffinfo[id]?.per_pax_total_price}</span>
                                                  <span className='totalSpan'>{currency} {detailData[0]?.price.tarriffinfo[id]?.totalprice}</span>
                                                </div>
                                              </>
                                            )
                                          })
                                        }
                                        </div>
                                      </div>
                                    )}
                                    </>
                                  )
                                })
                              }
                            </div>
                          </>
                        )
                      })
                    }

                    
                  </Tab>
                </Tabs>
          </div>
      </Modal.Body>
    </Modal>
  )
}

export default FlightDetailsPopup
