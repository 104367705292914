import React from 'react'

function TripSummary() {
  return (
    <>
      <div className="summary-info-box">
        <div className="tripummary-box">

          <div className="titletext d-flex">
            <span>Trip Summary</span>
            <div className="summary-bag">
              <img src="assets/images/flight-result/copy.svg" alt="" />Flight
              Details
            </div>
          </div>

          <div className="dubaitourinfo d-flex">
            <div className="d-flex">
              <img src="assets/images/flight-result/upicon.svg" alt="" />
              <img src="assets/images/flight-result/pl.svg" alt="" />
            </div>

            <div className="d-flex">
              <span>Dubai</span>
              <img src="assets/images/flight-result/rightarow.svg" alt="" />
              <span>London</span>
            </div>
            <i>|</i>
            <p>Sat, 20 Apr,2024</p>

          </div>

          <div className="d-flex eco ms-air">
            <span>Egypt Air</span> <small>MS-911</small> | <small>Economy - K</small>
          </div>

          <div className="flt-roots summary-flt">
            <div className="left">
              <strong>23:00</strong>
              <span>DXB</span>

            </div>
            <div className="flt-middle-info">
              <p>1 Stop</p>
              <div className="bordericons">
                <img src="assets/images/flight-result/fly-right.svg" alt="" />
                <img src="assets/images/flight-result/pin.svg" alt="" />
              </div>
              <p>Duration : 11h 15m</p>
            </div>
            <div className="right">
              <strong>05:40</strong>
              <span>LHR</span>
            </div>
          </div>

          <div className="d-flex eco ms-air">
            <span>Egypt Air</span> <small>MS-911</small> | <small>Economy - K</small>
          </div>

          <div className="flt-roots summary-flt">
            <div className="left">
              <strong>23:00</strong>
              <span>DXB</span>

            </div>
            <div className="flt-middle-info">
              <p>1 Stop</p>
              <div className="bordericons">
                <img src="assets/images/flight-result/fly-right.svg" alt="" />
                <img src="assets/images/flight-result/pin.svg" alt="" />
              </div>
              <p>Duration : 11h 15m</p>
            </div>
            <div className="right">
              <strong>05:40</strong>
              <span>LHR</span>
            </div>
          </div>

          <div className="fare-info-row">
            <div className="faretext d-flex">
              <span>Fare Details</span>
              {/* <small>Fare Rules</small> */}
            </div>
            <div className="aed-adult d-flex">
              <strong> <img src="assets/images/flight-result/up.svg" alt="" /> Traveller 1 (Adult)</strong>
              <strong>AED 4,038.00</strong>
            </div>

            <div className="base-fare-box">
              <p>
                <span>Base Fare</span>
                <small>AED 3,500.00</small>
              </p>

              <p>
                <span>Taxes $ Fees <img src="assets/images/flight-result/i.svg" alt="" /></span>
                <small>AED 538.00</small>
              </p>
            </div>
          </div>

          <div className="pay-amount d-flex">
            <span>Total Payable Amount</span>
            <p>
              <strong>AED 4,038.00</strong>
              {/* <small><img src="assets/images/flight-result/tik.svg" alt="" />Installments</small> */}
            </p>
          </div>

        </div>

        <div className="promo-code-row">
          <div className="title">Promo Code</div>
          <div className="promo-inner-box">
            <div className="enter-code">
              <input type="text" placeholder="Select or Enter Promo Code" />
              <button>Apply</button>
            </div>

            <ul className="flycheckbox">
              <li>
                <input type="radio" id="test1" name="radio-group" checked />
                <label for="test1"> FLY100 <span>Use this promo code & save SAR 50.00</span></label>
              </li>
              <li>
                <input type="radio" id="test2" name="radio-group" checked />
                <label for="test2"> FLY100 <span>Use this promo code & save SAR 50.00</span></label>
              </li>
              <li>
                <input type="radio" id="test3" name="radio-group" checked />
                <label for="test3"> FLY100 <span>Use this promo code & save SAR 50.00</span></label>
              </li>
            </ul>

          </div>


        </div>

      </div>
    </>
  )
}

export default TripSummary
