import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { handlePostApi } from '../../../../utilities/Axios';
import { useLocation } from "react-router-dom";
import FlightSummary from "./FlightSummary";
import PassengersDetails from "./PassengersDetails";
import FareDetails from "./FareDetails";
import { useNavigate } from 'react-router-dom';
import PaymentSummary from './PaymentSummary';
const B2C_COMMON_URL = process.env.REACT_APP_COMMON_URL;

function FlightConfirmation() {
    const nav = useNavigate();
    const location = useLocation();
    const [book_resp, setBookResp] = useState("");

    useEffect(() => {
      window.scrollTo(0, 0);  
    }, []);
  
    useEffect(() => {
      window.addEventListener('popstate', () => {
        return window.location="/";
      })
    }, [])

    useEffect(() => {
        const params_req = new URLSearchParams(location?.search);
        let payload = {};
        for (const [key, value] of params_req.entries()) {
            payload[key] = value;
        }
        fireFlightBook(payload?.itinerary_id);
    }, [])

    const fireFlightBook = async (itinerary_id) => {
        try {
            let payload = {
                bid: itinerary_id
            };       
            let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/book/postbook`, payload);
            if (response && response.data && response.data?.suc) {
                let bookResp = response.data?.res;
                setBookResp(bookResp);
            } else {
                setBookResp("");
            }
        } catch (error) {
            console.log("ERROR ::::: ", error);            
        }
    }

  return (
    <>
     <div className="fixedHeader">
      <Header page="fixedHeader" />
    </div>
    <div className="completedStatus"></div>
    <div className="confirmationPage">
    <section>
        <div className="mainContainer">
            <Row>
                <Col>
                {book_resp && book_resp !== "" ? (
                    <div className="d-flex topPage">
                        <div className="confirmationSideWrap">                         
                            {(book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase() == "CONFIRMED" || book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase().split(" ").join("") == "PARTIALLYCONFIRMED" ? (
                                <div className="bookingSuccessfulWrap">
                                    <div className="bookingSuccessfulTitle">Booking Confirmed <i class="icon icon-tick"></i></div>
                                    <div className="bookingSuccessfulInfo">Confirmation and E-Tickets sent to <span>{book_resp.bkng_info?.email}</span> 
                                    {/* and <span>{book_resp.bkng_info?.phone_no}</span> */}
                                    </div>
                                </div>
                            ) :                             
                            <div className="bookingSuccessfulWrap">
                                <div className="bookingSuccessfulTitle" style={{color:"#ff3010"}}>Booking Failed </div>
                            </div>
                            )}                      
                            {/* {(book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase() == "CONFIRMED" || book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase().split(" ").join("") == "PARTIALLYCONFIRMED") && (
                                <div className="bookingSuccessfulWrap">
                                    <div className="bookingSuccessfulTitle">Booking Confirmed <i class="icon icon-tick"></i></div>
                                    <div className="bookingSuccessfulInfo">Confirmation and E-Tickets sent to <span>{book_resp.bkng_info?.booking_status_str}</span> 
                                    and <span>+971-507000826</span>
                                    </div>
                                </div>
                            )} */}
                            {(book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase().split(" ").join("") == "TICKETINGFAILED" || book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase() == "FAILED") && (
                                <div className="bookingSuccessfulWrap">
                                    <div className="bookingSuccessfulTitle">Booking Failed <i class="icon icon-tick"></i></div>
                                    {/* <div className="bookingSuccessfulInfo">Confirmation and E-Tickets sent to <span>khamisanidaniyal193@gmail.com</span> and <span>+971-507000826</span></div>  */}
                                </div>
                            )}
                            
                            <div className="flightSummary marginTop30">
                            {book_resp && (
                                <>
                                {Number(book_resp.mongo_db_data[0].tt) !== 3 && (
                                    <FlightSummary
                                        data={book_resp.mongo_db_data[0]}
                                        booking_id={book_resp.bkng_info?.booking_id}
                                        pnr={book_resp.bkng_info?.pnr}
                                    />
                                )}
                                {/* MULTI CITY */}
                                {/* {book_resp &&
                                    book_resp.origns.length > 0 &&
                                    Number(book_resp.tt) === 3 &&
                                    book_resp.origns[0].srcorigns.map((org, idx) => (
                                    <div className="newlayoutDesign" key={idx}>
                                        <FlightSummary
                                        mapping={book_resp.mappings}
                                        tripType={book_resp.tt}
                                        sector={org}
                                        headerInfo={{
                                            dd: org.seg[0].dd,
                                            ad: org.seg[0].ad,
                                            src_code: org.seg[0].da,
                                            dest_code: org.seg[org.seg.length-1].ar,
                                        }}
                                        seg={org.seg[0]}
                                        />
                                    </div>
                                    ))} */}
                                <div className="horizontalDivider"></div>
                                <div className="travellerDetails">
                                    <PassengersDetails
                                    pax_info={book_resp.onword_info?.pax_info}
                                    email={book_resp.bkng_info?.email}
                                    phone_no={book_resp.bkng_info?.phone_no}
                                    isd_code={book_resp.bkng_info?.isd_code}
                                    />
                                </div>
                                {book_resp?.pay_info && book_resp?.pay_info.length > 0 && (
                                    <div className="travellerDetails">
                                        <PaymentSummary
                                        payment={book_resp?.pay_info}
                                        />
                                    </div>
                                )}
                                </>
                            )}
                            {/* <br /> */}
                            {/* FOR FLEXI FLOW  */}
                            {/* {book_resp && book_resp.flexi_flow && (
                                <>
                                {book_resp.origns.map((org, idx) => (
                                    <React.Fragment key={idx}>
                                        {org["srcorigns"][0].ref === 1 && (
                                        <div className="newlayoutDesign">
                                            {org["srcorigns"] && (
                                            <FlightSummary
                                                origin={[org["srcorigns"][0], "Confirm"]}
                                                mapping={book_resp.mappings}
                                                tripType={book_resp.tt}
                                                bktype={book_resp.bktype}
                                                sector={org.srcorigns[0]}
                                                headerInfo={{
                                                ad: org.srcorigns[0].seg[0].ad,
                                                dd: org.srcorigns[0].seg[0].dd,
                                                src_code: book_resp.src_code,
                                                dest_code: book_resp.dest_code,
                                                }}
                                                seg={org.srcorigns[0].seg[0]}
                                            />
                                            )}
                                            <div className="horizontalDivider"></div>
                                            <div className="travellerDetails">
                                            <PassengersDetails
                                                sector={"O"}
                                                sup={org.srcorigns[0].sup}
                                                response={org.srcorigns[0]}
                                            />
                                            </div>
                                        </div>
                                        )}

                                        {org["destorigins"][0].ref === 2 && (
                                        <>
                                        <div className="horizontalDivider"></div>
                                        <div className="newlayoutDesign">
                                            <br/>
                                            {org["destorigins"] && (
                                            <FlightSummary
                                                origin={[org["destorigins"][0], "Confirm"]}
                                                mapping={book_resp.mappings}
                                                tripType={book_resp.tt}
                                                bktype={book_resp.bktype}
                                                sector={org.destorigins[0]}
                                                headerInfo={{
                                                ad: org.destorigins[0].seg[0].ad,
                                                dd: org.destorigins[0].seg[0].dd,
                                                src_code: book_resp.dest_code,
                                                dest_code: book_resp.src_code,
                                                }}
                                                seg={org.destorigins[0].seg[0]}
                                            />
                                            )}
                                            <div className="horizontalDivider"></div>
                                            <div className="travellerDetails">
                                            <PassengersDetails
                                                sector={"R"}
                                                sup={org.destorigins[0].sup}
                                                response={org.destorigins[0]}
                                            />
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </React.Fragment>
                                ))}
                                </>
                            )} */}
                            </div>
                            {/* <div className="disclaimerWrap">
                                <div className="disclaimerTitle">Disclaimer</div>
                                <div className="disclaimerInfo">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </div>
                            </div> */}
                        </div>
                        <div className="ticketSideWrap">
                            {book_resp && book_resp.bkng_info?.booking_status_str.toUpperCase() == "CONFIRMED" && (
                                <div className="eticketsWrap">
                                    <div className="ticketTitle">Ticket (s)</div>
                                    <div className="downloadETicket"><i class="icon icon-d-e-ticket"></i> <span>Download E-ticket(s)</span></div>
                                    <div className="emailETicket"><i class="icon icon-e-w-ticket"></i> <span>Email/Watsapp E-Ticket(S)</span></div>
                                </div>                            
                            )}
                            <FareDetails 
                                fare_info={book_resp?.onword_info?.fare_info[0]}
                                cur={book_resp?.bkng_info?.cur}
                                base_usr_exrate={book_resp?.bkng_info?.base_usr_exrate}
                            />
                            <div className="manageYourTripWrap">
                                <div className="manageYourTripTitle">Manage Your Trip</div>
                                <div className="yourListWrap">
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Web-Checkin"></i> Web Check In</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Seat"></i> Add Seat or Meal</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-calendar"></i> Modify Dates</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Cancel-ticket"></i> Cancel All Tickets</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle' onClick={(e) => nav("/mytrips")}>Go to my Trips</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="congrulationsWrap">
                                <div className="congrulationsTitle"><i class="icon icon-tick"></i> Congratulations!</div>
                                <div className="congrulationsInfo">
                                 Cashback of AED 20.0 has been processed to your Wallet and shall reflect in a few days.
                                </div>
                            </div> */}
                        </div>
                    </div>                    
                ) :                 
                <div className="disclaimerWrap">
                    <div className="disclaimerTitle mb-0 text-center">No Data Found</div>
                </div>
                }
                </Col>
            </Row>
        </div>
    </section>
    </div>
    <Footer />
    </>
  )
}

export default FlightConfirmation
