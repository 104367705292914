import React from 'react'

function Static() {

  return (
    <>
      <div class="loader-container">
        {/* <div class="animated-background profile-image"></div> */}


        <div class="animated-background" >

          <div className="flight-result-right" >
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="animated-background">
              <div className="flightmain-info">

                <div className="pricetop-root d-flex">
                  <article className="left">


                  </article>
                  <article className="right d-flex">
                    <div className="ptext d-flex">
                      <b><img src="assets/images/flight-result/tick.svg" alt="" /> </b>
                      <div className="">

                      </div>
                    </div>
                    <btn className="animated-background" > </btn>

                  </article>
                </div>

                <div className="animated-background">
                  <div className=" flyinnerd-flex">
                    <div className="animated-background"></div>
                    <div className="animated-background">
                      <span></span>
                      <i> </i>
                      <span></span>

                      <span></span>
                    </div>
                    <picture></picture>
                  </div>

                  <em> </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>

                <div className="flight-to">
                  <div className="flyinner d-flex">
                    <div className="animated-background"></div>


                  </div>

                  <em>  </em>

                </div>

                <div className="animated-background">

                  <div className="egypt-air">
                    <strong></strong>
                    <div className="d-flex eco">
                      <span></span>  <span></span>
                    </div>
                    <p></p>
                    <div className="flt-bag">
                      <div className="fl-dels inbg" >
                      </div>
                      <div className="fl-dels">
                      </div>
                    </div>
                  </div>

                  <div className="flt-roots">
                    <div className="left">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                    <div className="flt-middle-info">
                      <p></p>
                      <div className="bordericons">

                      </div>
                      <p></p>
                    </div>
                    <div className="right">
                      <strong></strong>
                      <span></span>
                      <p></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div >
    </>
  )
}

export default Static
