import React, { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Context } from '../App.js'
// import Home from '../Components/homepage/Home'
import FlightResult from '../components/bookingflow/Flights/result/FlightResult'
import FlightReview from '../components/bookingflow/Flights/Review/FlightReview'
import FlightPayment from '../components/bookingflow/Flights/Payment/FlightPayment'
import Home from '../components/homepage/Home'
import HotelResult from '../components/bookingflow/Hotels/result/HotelResult'
import RoomSelection from '../components/bookingflow/Hotels/details/RoomSelection'
import HotelReview from '../components/bookingflow/Hotels/review/HotelReview'
import HotelConfirmation from '../components/bookingflow/Hotels/confirm/HotelConfirmation'
import FlightConfirmation from '../components/bookingflow/Flights/confirmation/FlightConfirmation'
import MyProfile from '../components/myaccount/MyProfile.jsx'
import ErrorPage from '../components/common/ErrorPage.jsx'
import MyTrips from '../components/myaccount/MyTrips.jsx'
import { handlePostApi } from '../utilities/Axios.js'
import PageNotFound from '../components/common/PageNotFound.jsx'
import HotelPayment from '../components/bookingflow/Flights/Payment/HotelPayment.jsx'
import About from '../components/common/About.jsx'
const B2C_COMMOM_URL  = process.env.REACT_APP_COMMON_URL;

function Routing() {
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if(localStorage.getItem("KF_EX_RATES") === null) {
      getExratesContext();
    }
  }, [])

  const getExratesContext = async () => {
    try {
      if(localStorage.getItem("KF_EX_RATES")===null){
      const payload = {}
      let response = await handlePostApi(`${B2C_COMMOM_URL}/b2c/common/exchange/rates`, payload);
      if (response?.data?.suc) { 
        if(response.data!==undefined && response.data.res!==null && response.data.res!==undefined){
          setContext({ ...context, ex_rates: response.data.res});
          localStorage.setItem("KF_EX_RATES",JSON.stringify(response.data.res));
        }
      } else {
      }
    }
    } catch (err) {
    console.error("Error in getExratesContext" + err);
  }
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/flight/results' element={<FlightResult />} />
        <Route path='/flight/review' element={<FlightReview />} />
        <Route path='/flight/payment' element={<FlightPayment />} />
        <Route path='/flight/confirm' element={<FlightConfirmation />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/mytrips' element={<MyTrips />} />
        <Route path='/errorPage' element={<ErrorPage />} />
        <Route path='/hotel/result' element={<HotelResult />} />
        <Route path='/hotel/detail' element={<RoomSelection />} />
        <Route path='/hotel/review' element={<HotelReview />} />
        <Route path='/hotel/payment' element={<HotelPayment />} />
        <Route path='/hotel/confirm' element={<HotelConfirmation />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default Routing
