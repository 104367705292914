import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
const GOOGLE_CLEINT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

function SocialMediaGoogle(props) {

    const loginWithGmail = async (code) => {
        props.loginWithGmail(code);
    }
    return (
        <>
            <span className="google">
                <GoogleOAuthProvider clientId={GOOGLE_CLEINT_ID}>
                    <GoogleLogin
                        shape="circle"
                        text="Signin"
                        size="small"
                        width="250px"
                        type="standard"
                        onSuccess={credentialResponse => {
                            console.log("GOOGLE RESP : " + JSON.stringify(credentialResponse));
                            if (credentialResponse?.credential) {
                                loginWithGmail(credentialResponse?.credential);
                            } else {
                                console.log(":::: GOOGLE Login Failed ::::");
                            }
                        }}
                        onError={(e) => {
                            console.log('GoogleOAuthProvider Failed', e);
                        }}
                    />
                </GoogleOAuthProvider>
            </span>
        </>
    )

}
export default SocialMediaGoogle