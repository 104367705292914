import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer'
import '../../assets/css/home.css';
import '../../assets/fonts/icons/style.css';
import Search from '../bookingflow/Flights/search/Search'
import BestOffers from './BestOffers';
import MostPopularRoutes from "./MostPopularRoutes";
import TrendingDestination from "./TrendingDestination";
import SpecialHotelDeals from "./SpecialHotelDeals";
import DownloadApp from "./DownloadApp";
import AboutKwickfly from "./AboutKwickfly";
import Newsletter from "./Newsletter";
import SearchBox from './ChildComp/SearchBox';

function Home() {

  return (
    <>
      <Header flow="RESULT" />
      {/* <Search /> */}
      <SearchBox />
      <BestOffers />
      <MostPopularRoutes />
      <TrendingDestination />
      <SpecialHotelDeals />
      <DownloadApp />
      <AboutKwickfly />
      <Newsletter />
      <Footer />
    </>
  )
}

export default Home
