import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import Loader from '../../../../../assets/Gifs/Double Ring-1s-200px.gif';
import {handleGetApi, handlePostApi} from '../../../../../utilities/PaymentAxios';
import { isArrayNotEmpty, isNotNull } from '../../../../utils/validators';
import master_card_url from "../../../../../assets/images/home/footer/card.png";
import visa_card_url from "../../../../../assets/images/home/footer/visa.png";
import express_card_url from "../../../../../assets/images/home/footer/express.png";
import Select from 'react-select';
import axios from "axios"
const B2C_COMMON_URL  =process.env.REACT_APP_COMMON_URL;

function CardDetails(props) {
  let exrate = props?.base_usr_exrate ? props?.base_usr_exrate : 1;
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [loader, setLoader] = useState(false);
  const [ip_address, setIPaddress] = useState("");
  const [card_type, setCardType] = useState("");
  const [card_type_url, setCardTypeUrl] = useState("");
  const [payment_info, setPaymentInfo] = useState({
    card_num: "",
    expiry_month: "",
    expiry_year: "",
    cvv: "",
    holder_name: "",
    currency: "",
    book_id: "",
    browser_ip: "",
    amount: ""
  });

  const [month_dropdown, setMonth_dropdown] = useState([]);
  const [year_dropdown, setYear_dropdown] = useState([]);

  const [pay_otp_enable, setPayOTPEnable] = useState(false);
  const [pay_otp, setPayOTPData] = useState("");
  const [fieldError, setFieldError] = useState([]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    const pasteBox_num = document.getElementById("no-paste-num");
    const postBox_name = document.getElementById("no-paste-name");
    const postBox_cvv = document.getElementById("no-paste-cvv");
    pasteBox_num.onpaste = e => {
      e.preventDefault();
      return false;
    };
    postBox_name.onpaste = e => {
      e.preventDefault();
      return false;
    };
    postBox_cvv.onpaste = e => {
      e.preventDefault();
      return false;
    };
    getIPAddress();
    let month_array = [];
    for (var i = 1; i < 12; i++) {
      let month = {
        label: i,
        value: i < 9 ? 0+""+i+"" : i+""
      }
      month_array.push(month)
    }
    setMonth_dropdown(month_array);

    let current_yr = Number(moment().format("YYYY"));
    let year_arry =[];
    for(var i= current_yr; i<=2050; i++){
      let year ={
        label: i+"",
        value: i+""
      }
      year_arry.push(year);
    }
    setYear_dropdown(year_arry)
  }, []);

  const getIPAddress = async() =>{
   let ip_info =  await handleGetApi("https://api.ipify.org/?format=json");
   if(ip_info?.data?.ip){
      setIPaddress(ip_info?.data?.ip)
   }
  }

  /**
   * @author Praveen Varma
   * @description Getting card type from supplier/db
   * @date 17-07-2024
   * @param {} cardNum 
   */
  const get_card_type = async (cardNum) => {
    if (cardNum && cardNum.length >= 6) {
      let card_resp = await handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/payment/master/card/details/fetch`, {
        card_num: cardNum
      })
      if (card_resp?.data?.res?.card_type) {
        setCardType(card_resp?.data?.res?.card_type);
        let url = await getCardIconUrl(card_resp?.data?.res?.card_type);
        setCardTypeUrl(url);
      } else {
        setCardType("");
        setCardTypeUrl("");
      }
    } else {
      setCardType("");
      setCardTypeUrl("");
    }
  }

  const isPayValidate = () => {
    let errors = [];
    if(payment_info.card_num === "" || payment_info.card_num === null) errors.push("card_num");
    if(payment_info.holder_name === "" || payment_info.holder_name === null) errors.push("holder_name");
    if(payment_info.expiry_month === "" || payment_info.expiry_month === null) errors.push("expiry_month");
    if(payment_info.expiry_year === "" || payment_info.expiry_year === null) errors.push("expiry_year");
    if(payment_info.cvv === "" || payment_info.cvv === null) errors.push("cvv");
    if(payment_info.expiry_month !== "" && payment_info.expiry_month !== null && payment_info.expiry_year !== "" && payment_info.expiry_year !== null) {
      let date = new Date();
      let currentYear = moment(date).format("YYYY");
      let currentMonth = moment(date).format("MM");
      if(Number(currentYear) === Number(payment_info.expiry_year)) {
        if(Number(currentMonth) > Number(payment_info.expiry_month)) {
          errors.push("date_issue")
        }
      }
    }
    setFieldError(errors);
    if(isArrayNotEmpty(errors)) {
      return false
    } else {      
      return true
    }
  }

  const handlePayment = async () => {
    try {
      setLoader(true);
      if(!isPayValidate()) {
        setLoader(false);
        return;
      }
      setFieldError([]);
      let exp_month = payment_info.expiry_month.length ===1 ? `0${payment_info.expiry_month}` : payment_info.expiry_month
      let payload = {
        "currency": props?.pay_data?.currency,
        "amount": (props?.pay_data?.amount * exrate).toFixed(2),
        "card_num": payment_info.card_num,
        "expiry": payment_info.expiry_year+"-"+exp_month,
        "cvv": payment_info.cvv,
        "holder_name": payment_info.holder_name,
        "browser_ip": ip_address,
        "card_type": card_type,
        "browserScreenHeight":windowDimensions?.width,
        "browserScreenWidth":windowDimensions?.height,
        "browserUserAgent": window?.navigator?.userAgent,
      }
      if(props?.pay_data?.flow==="Flight") {
        payload.book_id = props?.pay_data?.book_id;
        payload.prd = "f";
        payload.supplier = props?.pay_data?.sup?.toString();
        payload.kft_id = props?.pay_data?.kft_id;
        payload.trans_id = props?.pay_data?.transId;
      } else if(props?.pay_data?.flow==="Hotel") {
        payload.book_id = props?.pay_data?.book_id;
        payload.prd = "h";
        payload.supplier=1;
        payload.itinerary_id = sessionStorage.getItem("h_itinerary");
        payload.kft_id = sessionStorage.getItem("h_kft_id");
        payload.trans_id = props?.pay_data?.transId;
      }
      let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/payment/initaite`, payload);
      if (response && response.data && response.data?.suc && response.data?.res) {
        setPayOTPEnable(true);
        setPayOTPData(response?.data?.res);
        props.pay_otp_enable({otp_enable:true})
        setLoader(false);
      } else {
        setPayOTPEnable(false);
        setPayOTPData("")
        setLoader(false);
        let error =["invalid-card-details"];
        setFieldError(error);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("ERROR ::::: ", error);
    }
  }

  const getCardIconUrl = async (type) =>{
    if(type && type !== null && type !== ""){
        if(type.toUpperCase() === "MASTERCARD"){
          return master_card_url;
        }else if(type.toUpperCase() === "VISA"){
          return visa_card_url;
        }else if(type.toUpperCase() === "AMERICAN EXPRESS"){
          return express_card_url;
        }
    }
    return "";
  }

  return (
    <>
    <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
                <img src={Loader} alt='' />
            </div>
      {pay_otp_enable && pay_otp !=="" && pay_otp !== null ?
        <>
          <div dangerouslySetInnerHTML={{ __html: pay_otp }} />
        </> :
        <>
          <div className="like-to-pay">
            <div className="heading">How would you like to pay?</div>

            <div className="pay-credit">
              <div className="d-flex paymentTabsWrap">
              <Tabs
                defaultActiveKey="card"
                id="uncontrolled-tab-example"
                className="d-flex flex-column paymentTabs"
              >
                <Tab eventKey="card" title="Credit Card/Debit Card">
                <div className="pay-formbox">
                <div className="inputflex-box">
                  <label>Card Number {card_type ? <img src={card_type_url} alt="pay-method-image" className='img-fluid payMethodImage' /> : ""}</label>
                  <input type="number" id="no-paste-num" name="card_num" placeholder="Enter Your Card Number Here" onChange={(e) => {
                    let info = payment_info;
                    info[e.target.name] = e.target.value;
                    setPaymentInfo(info);
                    if(e.target.value.length >= 6 && e.target.value.length <=8 ){
                        get_card_type(e.target.value);
                    }else if(e.target.value.length <=5){
                        setCardType("");
                    }
                    let errors = fieldError;
                    if(e.target.value !== null && e.target.value !== "") {
                      errors = errors.filter((it) => it !== "card_num");
                    } else {
                      errors.push("card_num")
                    }
                    setFieldError(errors);
                  }} />
                  {isArrayNotEmpty(fieldError) && fieldError?.includes("card_num") && (
                    <div className="error">Card number must be provided</div>
                  )}
                </div>
                <div className="inputflex-box">
                  <label>Name on Card</label>
                  <input type="text" maxlength="60" id="no-paste-name" name="holder_name" placeholder="Enter Your Name On Card" onChange={(e) => {
                    let info = payment_info;
                    info[e.target.name] = e.target.value
                    setPaymentInfo(info);
                    let errors = fieldError;
                    if(e.target.value !== null && e.target.value !== "") {
                      errors = errors.filter((it) => it !== "holder_name");
                    } else {
                      errors.push("holder_name")
                    }
                    setFieldError(errors);
                  }} />
                  {isArrayNotEmpty(fieldError) && fieldError?.includes("holder_name") && (
                    <div className="error">Card holder name must be provided</div>
                  )}
                </div>
                <div className="inputexpiry d-flex">
                  <div className="box">
                    <label>Expiry Month & Year</label>
                    <div className="selectboxinner">
                    <Select
                      name="expiry_month"
                      onChange={(e) => {
                        let info = payment_info;
                        info[`expiry_month`] = e.value
                        setPaymentInfo(info);
                        let errors = fieldError;
                        if (e.value !== null && e.value !== "") {
                          errors = errors.filter((it) => it !== "expiry_month");
                        } else {
                          errors.push("expiry_month")
                        }
                        setFieldError(errors);
                      }}
                      options={month_dropdown}
                      placeholder="Month"
                      className='formSelect daySelect'
                    />
                     {/*  <select name="expiry_month"  onChange={(e) => {
                        let info = payment_info;
                        info[e.target.name] = e.target.value
                        setPaymentInfo(info);
                        let errors = fieldError;
                        if(e.target.value !== null && e.target.value !== "") {
                          errors = errors.filter((it) => it !== "expiry_month");
                        } else {
                          errors.push("expiry_month")
                        }
                        setFieldError(errors);
                      }}>
                        <option value="">Select</option>
                        {month_dropdown && month_dropdown.length > 0 &&
                          month_dropdown.map((month) => {
                            return (
                              <>
                                <option value={month.value}>{month.label}</option>
                              </>
                            )
                          })
                        }
                      </select> */}
                      {isArrayNotEmpty(fieldError) && fieldError?.includes("expiry_month") && (
                        <div className="error">Expiry month must be provided</div>
                      )}
                      <Select
                        name="expiry_year"
                        onChange={(e) => {
                          let info = payment_info;
                          info[`expiry_year`] = e.value
                          setPaymentInfo(info);
                          let errors = fieldError;
                          if (e.value !== null && e.value !== "") {
                            errors = errors.filter((it) => it !== "expiry_year");
                          } else {
                            errors.push("expiry_year")
                          }
                          setFieldError(errors);
                        }}
                        options={year_dropdown}
                        placeholder="Year"
                        className='formSelect daySelect'
                      />
                    {/*   <select name="expiry_year"  onChange={(e) => {
                        let info = payment_info;
                        info[e.target.name] = e.target.value
                        setPaymentInfo(info);
                        let errors = fieldError;
                        if(e.target.value !== null && e.target.value !== "") {
                          errors = errors.filter((it) => it !== "expiry_year");
                        } else {
                          errors.push("expiry_year")
                        }
                        setFieldError(errors);
                      }}>
                        <option value="">Select</option>
                        {year_dropdown && year_dropdown.length > 0 &&
                          year_dropdown.map((year) => {
                            return (
                              <>
                                <option value={year.value}>{year.label}</option>
                              </>
                            )
                          })
                        }
                      </select> */}
                      {isArrayNotEmpty(fieldError) && fieldError?.includes("expiry_year") && (
                        <div className="error">Expiry year must be provided</div>
                      )}
                      {isArrayNotEmpty(fieldError) && fieldError?.includes("date_issue") && (
                        <div className="error">Please select future month</div>
                      )}
                    </div>
                  </div>
                  <div className="box">
                    <label>Card CVV</label>
                    <input type="password" maxlength="3" id="no-paste-cvv" name="cvv"  placeholder="Enter Card CVV" onChange={(e) => {
                      let info = payment_info;
                      info[e.target.name] = e.target.value
                      setPaymentInfo(info);
                      let errors = fieldError;
                      if(e.target.value !== null && e.target.value !== "") {
                        errors = errors.filter((it) => it !== "cvv");
                      } else {
                        errors.push("cvv")
                      }
                      setFieldError(errors);
                    }} />
                    {isArrayNotEmpty(fieldError) && fieldError?.includes("cvv") && (
                      <div className="error">Cvv must be provided</div>
                    )}
                  </div>
                </div>

                <div className="aedpricebox">
                  <div className="payNowBtnWrap d-flex justify-content-between align-items-center">
                    <span className='totalPay'> {props?.pay_data?.currency} {(props?.pay_data?.amount * exrate).toFixed(2)}</span>
                    {!loader ?                         
                      <button onClick={(e) => handlePayment()} className='payNowBtn'>PAY NOW <i class="icon icon-down-arrow"></i></button>
                      :                                            
                      <button className='payNowBtn'>Processing...<i class="icon icon-down-arrow"></i></button>
                    }
                  </div>
                  <>
                  {isArrayNotEmpty(fieldError) && fieldError?.includes("invalid-card-details") && (
                    <div className="error"><h5>Invalid card details</h5></div>
                  )}
                  </>
                  <p>By clicking ‘PAY NOW’ button, I understand and agree to the <span>Terms & Conditions</span> of
                    this website.</p>
                </div>
              </div>
                </Tab>
                <Tab eventKey="upi" title="UPI">
                  UPI goes here
                </Tab>
                <Tab eventKey="applePay" title="Apple Pay">
                  Apple pay goes here
                </Tab>
                <Tab eventKey="easyPay" title="Easy Pay">
                  Easy pay goes here
                </Tab>
              </Tabs>
              </div>
            
              {/* <ul>
                <li className="active"><img src="assets/images/flight-result/Card.svg" alt="" /> Credit Card/Debit
                  Card 2303779999000408</li>
                <li><img src="assets/images/flight-result/Card.svg" alt="" /> UPI</li>
              </ul> */}
             
            </div>

          </div>
        </>
      }

    </>
  )
}

export default CardDetails