import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addRooms, decreaseAdult, decreaseChildren, decreaseInfant, increaseAdult, increaseChildren, increaseInfant, removeRooms, setChildAges } from '../../../toolkit/Slices/SearchHotelSlice';
import { Ages } from '../../common/Lists/ChildAges';

function HotelBox() {
  const dispatch = useDispatch();
  const sliceData = useSelector((state) => state.searchhotel.value)
  const [showTravel, setShowTravel] = useState(false)
  const handleShowTravel = () => {
    setShowTravel(!showTravel)
  }
  const handleChildAges = (roomid, event, id) => {
    const aages = event.target.value;
    dispatch(setChildAges({ roomid, id, aages }))
  }
  let totalPassengers = 0;
  sliceData.rooms.forEach((i, index) => {
    totalPassengers = sliceData.rooms[index].children + sliceData.rooms[index].adult + sliceData.rooms[index].infant + totalPassengers;
  });
  return (
    <>
      <div className="tab-inner-box">
        <div className="search-inputs spacingbottom d-flex">

          <div className="form-to hotel-formto">
            <div className="input-box">
              <label> <img src="assets/images/home/picon.svg" alt="" /> Destination</label>
              <input type="text" placeholder="Dubai(DBI), United Arab Emirates" />
            </div>
          </div>

          <div className="date-option hotel-date">
            <div className="input-box">
              <label><img src="assets/images/home/date.svg" alt="" />Check-In</label>
              <input type="text" placeholder="DD/MM/YY" />
            </div>
            <div className="nightbox">
              <label><img src="assets/images/home/night.svg" alt="" />Night</label>
              <input type="text" placeholder="01" />
            </div>
            <div className="input-box">
              <label><img src="assets/images/home/date.svg" alt="" />Check-Out</label>
              <input type="text" placeholder="DD/MM/YY" />
            </div>
          </div>

          <div className="travel-class">
            <div className="input-box">
              <label onClick={handleShowTravel}><img src="assets/images/home/date.svg" alt="" />Rooms & Guests</label>
              <strong>{sliceData.rooms.length} Room, {totalPassengers} Travellers</strong>
              <div className="adultslist" style={{ display: showTravel ? 'block' : 'none' }}>
                {

                  sliceData?.rooms?.map((data, index) => {
                    return (
                      <>
                        <div>
                          <h3>Room no: {index + 1}</h3>
                          <div className="selecttravel">
                            <h3>Select Travellers</h3>
                            <div className="adultfind">
                              <span>Adult (12+ years)</span>
                              <div className="counter">
                                <div className="minussign" onClick={() => { dispatch(decreaseAdult(index)) }} >-</div>
                                <div className="countno">{sliceData.rooms[index].adult}</div>
                                <div className="plussign" onClick={() => { dispatch(increaseAdult(index)) }}>+</div>
                              </div>
                            </div>
                            <div className="adultfind">
                              <span>Child (2 -11 years)</span>
                              <div className="counter">
                                <div className="minussign" onClick={() => { dispatch(decreaseChildren(index)) }}>-</div>
                                <div className="countno">{sliceData.rooms[index].children}</div>
                                <div className="plussign" onClick={() => { dispatch(increaseChildren(index)) }}>+</div>
                              </div>
                            </div>
                            <ul className='child-ages' style={{ display: "flex" }}>
                              {
                                Array.from(Array(sliceData.rooms[index].children)).map((c, ageIndex) => {
                                  return (
                                    <>
                                      <li className='select-age' >
                                        <label>Child {ageIndex + 1} Age</label>
                                        <select onChange={(event) => { { handleChildAges(index, event, ageIndex) }; }} >
                                          {
                                            Ages.map((items) => {
                                              return (
                                                <>
                                                  <option key={items.id} value={items.value}>{items.value}</option>
                                                </>
                                              )
                                            })
                                          }
                                        </select>
                                      </li>
                                    </>
                                  )
                                })
                              }
                            </ul>
                            <div className="adultfind">
                              <span>Infant (Under 2 years)</span>
                              <div className="counter">
                                <div className="minussign" onClick={() => { dispatch(decreaseInfant(index)) }} >-</div>
                                <div className="countno">{sliceData.rooms[index].infant}</div>
                                <div className="plussign" onClick={() => { dispatch(increaseInfant(index)) }} >+</div>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: "space-around" }}>
                            <button style={{ display: (sliceData.rooms.length === index + 1) ? 'block' : 'none' }} onClick={() => { { dispatch(addRooms(index)) } }}>Add Room</button>
                            <button style={{ display: (sliceData.rooms.length > 1) ? 'flex' : 'none' }} onClick={() => { dispatch(removeRooms(index)) }}>Remove Room</button>
                          </div>
                        </div>
                      </>
                    )
                  })
                }

                <div className="applybutton">
                  <button onClick={() => { handleShowTravel() }}>Apply</button>
                </div>
              </div>
            </div>
          </div>

          <button className="searchbutton"><img src="assets/images/home/k.svg" alt="" /></button>

        </div>
      </div>

    </>
  )
}

export default HotelBox
