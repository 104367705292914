import React from 'react'
import Header from './Header'
import Footer from './Footer'

function PageNotFound() {
  return (
    <>
    <Header />
    <div>
      Page not found
    </div>
    <Footer />
    </>
  )
}

export default PageNotFound
