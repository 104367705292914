import React, { useState, useEffect, useContext } from 'react';
import TripSummary from '../Review/TripSummary';
import CardDetails from './ChildComp/CardDetails';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import AirlineLogo from '../../../../assets/images/review/QR.png';
import { Context } from '../../../../App';
import Timer from './Timer';


function FlightPaymentDetails(props) {
  const [context, setContext] = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const location = useLocation();
  const nav = useNavigate();
  const [cur, setUserCur] = useState("");
  const [payDetails, setPayDetails] = useState("")
  const [displayTripDetails, setDisplayTripDetails] = useState(true)
  const [timer, setTimer] = useState(true)
  const [request, setRequest] = useState(location?.state?.req);

  useEffect(() => {
    if(props?.tid === undefined) {
      nav("/")
    }
  }, []);

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    let totalAmount = Number(request?.price?.total_price);
    let extras = request?.adtBagPrice + request?.adtMealPrice + request?.chdBagPrice + request?.chdMealPrice ;
    let promo_code = request?.promoCode ? request?.promoCode : 0;
    let total_price = totalAmount + extras - promo_code;
    setPayDetails({
      "flow":"Flight", 
      "book_id":props.bookingId, 
      "kft_id":props.tid, 
      "sup":props.sup, 
      "amount":total_price, 
      "transId":props.transId,
      "currency":cur
    });
  }, [cur, request])

  const pay_otp_enable = (data) => {
    if(data && data?.otp_enable) {
      setDisplayTripDetails(false);
      setTimer(false);
    }
  }

  return (
    <>
    <section className="statusBarWrap">
        <div className="mainContainer">
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div className="statusBarItem">
                  <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                  <span>Review Flight</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                  <span>Traveller Details</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='atPresent smallCircle'>3</span>
                  <span>Payment</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='pending smallCircle'>4</span>
                  <span>Confirmation</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="paymentPage">
        <section>
          <div className="mainContainer">
            <Row>
              <Col>
                <div className="d-flex mt-20">
                  <div className="paymentSideWrap">
                    {request !== undefined && (
                    <>
                      {timer && (
                        <Timer />
                      )}
                      <CardDetails 
                        pay_data={payDetails}
                        base_usr_exrate={request?.base_usr_exrate}
                        pay_otp_enable={pay_otp_enable}
                      />
                    </>
                    )}
                  </div>
                  {displayTripDetails && (
                  <div className="summarySideWrap">
                    {request !== undefined && (
                    <TripSummary 
                      data={request}
                      promoDisc={request.promoCode}
                    />
                    )}
                  </div>
                  // <div className="summarySideWrap">
                  //   <div className="tripSummaryWrap">
                  //     <div class="leftSemicircle"></div>
                  //     <div className="tripSummaryTitle">
                  //       <span>Trip Summary</span>
                  //       <span className='fDet' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> Flight Details</span>
                  //     </div>
                  //     <Accordion defaultActiveKey="0" className='tripSumCollapse'>
                  //     <Accordion.Item eventKey="0">
                  //       <Accordion.Header className='tripSumHeader'>
                  //         <i class="icon icon-down-arrow"></i>
                  //         <i class="icon icon-Flight-Circle"></i>
                  //         <div class="routeTime">
                  //           <span class="route">Dubai</span>
                  //           <i class="icon icon-Arrow"></i>
                  //           <span class="route">London</span>
                  //           <span class="time leftSeparatorLine">Sat, 20 Apr,2024</span>
                  //         </div>
                  //       </Accordion.Header>
                  //       <Accordion.Body>
                  //         <div className="d-flex align-items-center">
                  //           <div class="planeName"><img src={AirlineLogo} alt="AirlineLogo" class="img-fluid planeImage" /> Egypt Air</div>
                  //           <div class="planeCodeClassWrap"><span class="planeCode">MS-911</span><span class="planeClass leftSeparatorLine">Economy - K</span></div>
                  //         </div>
                  //         <div className='airlineInfo'>
                  //           <div className='deparrInfo'>
                  //               <div className='deparrTime'>23:00</div>
                  //               <div className='deparrLocation'>DXB</div>
                  //           </div>
                  //           <div class="layoverInfo text-center">
                  //             <div class="layoverDuration">1 Stop</div>
                  //             <div class="layoverSeparator"><span></span></div>
                  //             <div class="totalDuration">Duration : 11h 15m</div>
                  //           </div>
                  //           <div className='deparrInfo'>
                  //             <div className='deparrTime'>05:40</div>
                  //             <div className='deparrLocation'>LHR</div>
                  //           </div>
                  //         </div>
                  //       </Accordion.Body>
                  //     </Accordion.Item>
                  //     <Accordion.Item eventKey="1">
                  //       <Accordion.Header className='tripSumHeader'>
                  //         <i class="icon icon-down-arrow"></i>
                  //         <i class="icon icon-Flight-Circle return"></i>
                  //         <div class="routeTime">
                  //           <span class="route">London</span>
                  //           <i class="icon icon-Arrow"></i>
                  //           <span class="route">Dubai</span>
                  //           <span class="time leftSeparatorLine">Sat, 20 Apr,2024</span>
                  //         </div>
                  //       </Accordion.Header>
                  //       <Accordion.Body>
                  //         <div className="d-flex align-items-center">
                  //           <div class="planeName"><img src={AirlineLogo} alt="AirlineLogo" class="img-fluid planeImage" /> Egypt Air</div>
                  //           <div class="planeCodeClassWrap"><span class="planeCode">MS-911</span><span class="planeClass leftSeparatorLine">Economy - K</span></div>
                  //         </div>
                  //         <div className='airlineInfo'>
                  //           <div className='deparrInfo'>
                  //               <div className='deparrTime'>23:00</div>
                  //               <div className='deparrLocation'>LHR</div>
                  //           </div>
                  //           <div class="layoverInfo text-center">
                  //             <div class="layoverDuration">1 Stop</div>
                  //             <div class="layoverSeparator"><span></span></div>
                  //             <div class="totalDuration">Duration : 11h 15m</div>
                  //           </div>
                  //           <div className='deparrInfo'>
                  //             <div className='deparrTime'>05:40</div>
                  //             <div className='deparrLocation'>DXB</div>
                  //           </div>
                  //         </div>
                  //       </Accordion.Body>
                  //     </Accordion.Item>
                  //     <div className="fareDetailsWrap">
                  //       <div className='fareDetailTitle'>Fare Details</div>
                  //       <div className='fareRuleTitle'>Fare Rules</div>
                  //     </div>
                  //     <Accordion.Item eventKey="2" className='travellerItem'>
                  //       <Accordion.Header className='tripSumHeader travellerHeader'>
                  //         <div className="d-flex justify-content-between align-items-center">
                  //           <div className="travellerType"><i class="icon icon-down-arrow"></i> Traveller 1 (Adult)</div>
                  //           <div className="currency">AED 4,038.00</div>
                  //         </div>
                  //       </Accordion.Header>
                  //       <Accordion.Body className='fareDetailBody'>
                  //         <div className="d-flex justify-content-between align-items-center mb-12">
                  //           <div className="fareDetailBodyTitle">Base Fare</div>
                  //           <div className="currAmnt">AED 4,038.00</div>
                  //         </div>
                  //         <div className="d-flex justify-content-between align-items-center">
                  //           <div className="fareDetailBodyTitle">Taxes $ Fees <i class="icon icon-Info"></i></div>
                  //           <div className="currAmnt">AED 4,038.00</div>
                  //         </div>
                  //       </Accordion.Body>
                  //     </Accordion.Item>
                  //   </Accordion>
                  //   <div className="payableAmntWrap">
                  //     <div className="payableAmntTitle">Total Payable Amount</div>
                  //     <div className="currAmnt">AED 4,038.00</div>
                  //   </div>
                  //   <div className="installmentTitle"><i class="icon icon-tick"></i> Installments</div>
                  //   <div class="rightSemicircle"></div>
                  //   </div>
                  // </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='flightDetailsPopup'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="flightDetailTitle">
            Flight Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="">
            <Tabs
                  defaultActiveKey="flightItinerary"
                  id="uncontrolled-tab-example"
                  className="flightDetailsTab"
                >
                  <Tab eventKey="flightItinerary" title="Flight Itinerary">
                  <div className='mt-15'>
                      <div className="flightDepWrap">
                        <div className="flightDep">
                          <i class="icon icon-Flight-Circle"></i> Departure
                        </div>
                      </div>
                      <div className="routeTimeWrap">
                        <div className="routeTime">
                          <span className='route'>Dubai</span>
                          <i class="icon icon-Arrow"></i>
                          <span className='route'>London</span>
                          <span className='time leftSeparatorLine'>Sat, 20 Apr,2024</span>
                        </div>
                      </div>
                    </div>
                  <div className="rbBot">
                    <div className="rbInnerBot">
                    <div className='planeInfo'>
                      <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> Egypt Air</div>
                      <div className="planeCodeClassWrap"><span className='planeCode'>MS-911</span><span className='planeClass leftSeparatorLine'>Economy - K</span></div>
                      <div className="operatedBy">Operated by : Emirates</div>
                      <div className="flightDetailsPiecesWrap"><span className='pieces'><i class="icon icon-Bag"></i> 2 Piece</span></div>
                    </div>
                    <div className='airlineInfo'>
                      <div className='deparrInfo'>
                          <div className='deparrTime'>23:00</div>
                          <div className='deparrLocation'>Dubai (DXB)</div>
                          <div className='airportName'>Dubai International Airport, Dubai</div>
                          <div className="terminal">Terminal 1</div>
                      </div>
                      <div className='oneSideDuration text-center'>
                        <span><i class="icon icon-flight"></i></span>
                        <span>Duration : 2h 0m</span>
                        <span><i class="icon icon-Pointer"></i></span>
                      </div>
                      <div className='deparrInfo'>
                        <div className='deparrTime'>05:40</div>
                        <div className='deparrLocation'>Riyadh (RUH)</div>
                        <div className='airportName'>Heathrow Airport, London</div>
                        <div className="terminal">Terminal 1</div>
                      </div>
                      </div>
                    </div>
                    <div className="totalDuationTimeWrap"><div className='totalDuationTime'><span className='separatorLine'>Layover : 2h 20m</span></div></div>
                    <div className="rbInnerBot">
                    <div className='planeInfo'>
                      <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> Egypt Air</div>
                      <div className="planeCodeClassWrap"><span className='planeCode'>MS-911</span><span className='planeClass leftSeparatorLine'>Economy - K</span></div>
                      <div className="operatedBy">Operated by : Emirates</div>
                      <div className="flightDetailsPiecesWrap"><span className='pieces'><i class="icon icon-Bag"></i> 2 Piece</span></div>
                    </div>
                    <div className='airlineInfo'>
                      <div className='deparrInfo'>
                          <div className='deparrTime'>23:00</div>
                          <div className='deparrLocation'>Dubai (DXB)</div>
                          <div className='airportName'>Dubai International Airport, Dubai</div>
                          <div className="terminal">Terminal 1</div>
                      </div>
                      <div className='oneSideDuration text-center'>
                        <span><i class="icon icon-flight"></i></span>
                        <span>Duration : 2h 0m</span>
                        <span><i class="icon icon-Pointer"></i></span>
                      </div>
                      <div className='deparrInfo'>
                        <div className='deparrTime'>05:40</div>
                        <div className='deparrLocation'>Riyadh (RUH)</div>
                        <div className='airportName'>Heathrow Airport, London</div>
                        <div className="terminal">Terminal 1</div>
                      </div>
                      </div>
                    </div>
                  </div>
                  </Tab>
                  <Tab eventKey="return" title="Baggage">
                    <div className='mt-15'>
                      <div className="flightDepWrap">
                        <div className="flightDep">
                          <i class="icon icon-Flight-Circle"></i> Departure
                        </div>
                      </div>
                      <div className="routeTimeWrap">
                        <div className="routeTime">
                          <span className='route'>Dubai</span>
                          <i class="icon icon-Arrow"></i>
                          <span className='route'>London</span>
                          <span className='time leftSeparatorLine'>Sat, 20 Apr,2024</span>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between pb-5 mt-15'>
                      <div className='boxesWrap'>
                        <div className='boxesHeader'>
                          <span className='boxOne commonBox'>
                            <span>DXB</span>
                            <i class="icon icon-Arrow"></i>
                            <span>RUH</span>
                          </span>
                          <span className='boxTwo commonBox'>Check In</span>
                          <span className='boxThree commonBox'>Cabin</span>
                        </div>
                        <div className='boxesData'>
                          <span className='boxFour commonBox'>Adult</span>
                          <span className='boxFive commonBox'>2 Piece</span>
                          <span className='boxSix commonBox'>7 KG</span>
                        </div>
                      </div>
                      <div className='boxesWrap'>
                        <div className='boxesHeader'>
                          <span className='boxOne commonBox'>
                            <span>DXB</span>
                            <i class="icon icon-Arrow"></i>
                            <span>RUH</span>
                          </span>
                          <span className='boxTwo commonBox'>Check In</span>
                          <span className='boxThree commonBox'>Cabin</span>
                        </div>
                        <div className='boxesData'>
                          <span className='boxFour commonBox'>Adult</span>
                          <span className='boxFive commonBox'>2 Piece</span>
                          <span className='boxSix commonBox'>7 KG</span>
                        </div>
                      </div>
                    </div>
                    <div className='mt-15'>
                      <div className="flightDepWrap">
                        <div className="flightDep">
                          <i class="icon icon-Flight-Circle"></i> Departure
                        </div>
                      </div>
                      <div className="routeTimeWrap">
                        <div className="routeTime">
                          <span className='route'>Dubai</span>
                          <i class="icon icon-Arrow"></i>
                          <span className='route'>London</span>
                          <span className='time leftSeparatorLine'>Sat, 20 Apr,2024</span>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between pb-5 mt-15'>
                      <div className='boxesWrap'>
                        <div className='boxesHeader'>
                          <span className='boxOne commonBox'>
                            <span>DXB</span>
                            <i class="icon icon-Arrow"></i>
                            <span>RUH</span>
                          </span>
                          <span className='boxTwo commonBox'>Check In</span>
                          <span className='boxThree commonBox'>Cabin</span>
                        </div>
                        <div className='boxesData'>
                          <span className='boxFour commonBox'>Adult</span>
                          <span className='boxFive commonBox'>2 Piece</span>
                          <span className='boxSix commonBox'>7 KG</span>
                        </div>
                      </div>
                      <div className='boxesWrap'>
                        <div className='boxesHeader'>
                          <span className='boxOne commonBox'>
                            <span>DXB</span>
                            <i class="icon icon-Arrow"></i>
                            <span>RUH</span>
                          </span>
                          <span className='boxTwo commonBox'>Check In</span>
                          <span className='boxThree commonBox'>Cabin</span>
                        </div>
                        <div className='boxesData'>
                          <span className='boxFour commonBox'>Adult</span>
                          <span className='boxFive commonBox'>2 Piece</span>
                          <span className='boxSix commonBox'>7 KG</span>
                        </div>
                      </div>
                    </div>
                    <div className="checkInWrap mt-20">
                      <span>Check-in Baggage : </span>
                      <span>Economy Class : 1 piece, up to 23kg</span>
                      <span className='leftSeparatorLine'>Business Class : 1 piece, up to 32kg.</span>
                    </div>
                  </Tab>
                  <Tab eventKey="fareBreakup" title="Fare Breakup">
                    <Table className='mb-0 mt-15'>
                      <thead>
                        <tr>
                          <th>Pax Type</th>
                          <th>Base Fare</th>
                          <th>Fee & Tax</th>
                          <th>No. Of Pax</th>
                          <th>Total Per Pax</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Adult</td>
                          <td>AED 2,038.54</td>
                          <td>AED 981.46</td>
                          <td>1</td>
                          <td>AED 3,020.00</td>
                          <td>AED 3,020.00</td>
                        </tr>
                        <tr>
                          <td>Adult</td>
                          <td>AED 2,038.54</td>
                          <td>AED 981.46</td>
                          <td>1</td>
                          <td>AED 3,020.00</td>
                          <td>AED 3,020.00</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
          </div>
      </Modal.Body>
      </Modal>
    </>
  )
}

export default FlightPaymentDetails
