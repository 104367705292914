import React from "react";
import { convertDate, handleFormatTime, handleTimeChange } from '../../../common/services/DateValidations';

const _ = require("lodash");
function FlightSummary(props) {
  const data = props?.data;  

  // Airlines logo...
  const handleLogo = (data) => {
    let logo;
    try {
      logo = require(`../../../../assets/airlines-logo/${data}.png`);
    } catch (error) {
      logo = null;
    }
    return logo
  }

  return (
    <>      
    <div className="confirmBookingSummaryWrap"> 
    <div className="confirmBookingSummaryTitleWrap">
        <div className="confirmBookingSummaryTitle"><i class="icon icon-flight"></i> Booking Summary</div>
        <div className="confirmBookingTrip">
            <span>Trip Id : {props.booking_id ? props.booking_id : "N/A"}</span>
            <span className='leftSeparatorLine'>PNR : {props.pnr ? props.pnr : "N/A"}</span>
        </div>
    </div>   
    {
      data.OutboundInboundlist?.map((outbounddata, index) => {
        const lengths = outbounddata.flightlist.length;
        const flightListData = outbounddata.flightlist[0];
        const depertureDatas = outbounddata.flightlist[0].Departure;
        const arrivalDatas = outbounddata.flightlist[lengths - 1].Arrival;
        const layover = outbounddata?.flightlist[lengths - 1]?.connectiontime;
        const layovername = outbounddata?.flightlist[0]?.Arrival?.Iata;
        const trip = outbounddata.flightlist[0].flightindex
        return (
          <>
          <div className="confirmBookingSummaryInnerWrap">
            <div className="rbMid">
              <div className='d-flex align-items-center'>
                <div className="flightDepWrap">
                  <div className="flightDep">
                    <i class={index === 0 ? "icon icon-Flight-Circle" : "icon icon-Flight-Circle return"}></i> {index === 0 ? "Departure" : "Return"}
                  </div>
                </div>
                <div className="routeTimeWrap">
                  <div className="routeTime">
                    <span className='route'>{depertureDatas.city}</span>
                    <i class="icon icon-Arrow"></i>
                    <span className='route'>{arrivalDatas.city}</span>
                    <span className='time leftSeparatorLine'>{convertDate(depertureDatas.Date)}</span>
                  </div>
                </div>
              </div>
                {/* <div className='suplier'>Sup : {flightListData?.supplier}</div> */}
              </div>
              <div className="rbBot">
                <div className='planeInfo'>
                  <div className="planeName">
                    {handleLogo(flightListData?.MarketingAirline?.code) && (
                      <img src={handleLogo(flightListData?.MarketingAirline?.code)} alt="AirlineLogo" className='img-fluid planeImage' /> 
                    )}
                    {flightListData?.MarketingAirline?.name}
                  </div>
                  <div className="planeCodeClassWrap"><span className='planeCode'>{flightListData?.MarketingAirline?.code}-{flightListData?.MarketingAirline?.number}</span><span className='planeClass leftSeparatorLine'>{flightListData.CabinClassText}</span></div>
                  <div className="operatedBy">Operated by : {flightListData?.OperatingAirline?.name}</div>
                  <div className="flightDetailsPiecesWrap">
                    {/* <span className='flightDetails' onClick={() => { { handleFlightDetails() }; { filterDetails(data.tid) } }}><i class="icon icon-Pop"></i> Flight Details</span> */}
                    {/* <span className='pieces'><i class="icon icon-Bag"></i> {flightListData?.CheckinBaggage[0]?.Value}</span> */}
                    </div>
                  
                </div>
                <div className='airlineInfo'>
                  <div className='deparrInfo'>
                    <div className='deparrTime'>{depertureDatas.time}</div>
                    <div className='deparrLocation'>{depertureDatas.city}</div>
                    <div className='airportName'>{depertureDatas.name}</div>
                  </div>
                  <div className='layoverInfo text-center'>
                    <div className='layoverDuration'>{lengths - 1 === 0 ? 'Direct' : `${lengths - 1} Stop`}{lengths > 1 ? `, Layover : ${handleTimeChange(layover)} (${layovername}) ` : ''}</div>
                    <div className='layoverSeparator'><span></span></div>
                    <div className='totalDuration'>Duration : {handleTimeChange(outbounddata.totaltime)}</div>
                  </div>
                  <div className='deparrInfo'>
                    <div className='deparrTime'>{arrivalDatas.time}</div>
                    <div className='deparrLocation'>{arrivalDatas.city}</div>
                    <div className='airportName'>{arrivalDatas.name}</div>
                  </div>
                </div>
              </div>
          </div>
          </>
        )
      })
    }
    </div> 
    </>
  );
}

export default FlightSummary;
