/**
 * @description: Email Validation
 * @author: Ubedullah
 * @param {*}
 * @function emailValidation
 * @date : 16-06-2024
 * Uppercase (A-Z) and lowercase (a-z) letters
 * Digits (0-9)
 * Characters such as ! # $ % & ‘ * + – / = ? ^ _ ` { | } ~
 * Character . ( period, dot or fullstop) but it should not be the first or last character 
 * and should not come one after the other
 */
export const emailValidation = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return true;
    }
    return false
}

export const validateEmail = (email) => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return true;
    }
    return false
}

/**
 * @description: Password Validation
 * @author: Ubedullah
 * @param {*}
 * @function passwordValidation
 * @date : 22-06-2024
 */

export const passwordValidation = (password) => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password)) {
        return true;
    }
    return false
}


/**
* @description: mobileNumber Validation
* @author: Ubedulah
* @param {*}
* @function: mobileNumberValidation
* @date : 16-06-2024
* Below Formats it will work
* +XX-XXXX-XXXX
* +XX.XXXX.XXXX
* +XX XXXX XXXX (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)
*/

export const mobileNumberValidation = (ph) => {
if (/^[0-9]+$/.test(ph)) {
    return true;
}
else {
    return false;
}
}

/**
* @description: to check if the value has only characters
* @author : Ubedullah
* @date : 16-06-2024
*/
export const characterValidation = (chr) => {
if(/^\s|\s$/.test(chr)) {
    return false
} else {
if (/^[a-zA-Z0-9&% ]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}
} 
export const comapanyCharacterValidation = (chr) => {
if(/^\s|\s$/.test(chr)) {
    return false
} else {
if (/^[a-zA-Z0-9&_. ]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}
}


/**
* @description: to check if the value has only characters
* @author : Ubedulah
* @date : 16-06-2024
*/
export const characternameValidation = (chr) => {
if(/^\s|\s$/.test(chr)) {
    return false
} else {
if (/^[a-zA-Z ]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}
}
/**
* @description: to check if the value has  characters and numbers
* @author : Ubedullah
* @date : 16-06-2024
*/
export const characterNumberValidation = (chr) => {
if (/^[0-9a-zA-Z]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}

export const ticketNumberValidation = (tkt) => {
if (/^[0-12]+$/.test(tkt)) {
    return false;
}
else {
    return true;
}
}

export const companyCharacterValidation = (chr) => {
if(/^\s|\s$/.test(chr)) {
    return false
} else {
if (/^[a-zA-Z0-9&_. ]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}
}


//Alphanumeric validation
export const alpahnumericvalidate = (chr) => {

if (/^[a-zA-Z0-9.]+$/.test(chr)) { 
    return true;
}
else {
    return false;
}
}

export const charSpaceValidation = (chr) => {
if (/^[0-9a-zA-Z ]+$/.test(chr)) {
    return true;
}
else {
    return false;
}
}

