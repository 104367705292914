/**
 * @ Author: Aman Goyal
 * @ Description: Search Box
 */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HotelBox from './HotelBox';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../assets/Gifs/Double Ring-1s-200px.gif'
import { decreaseAdult, decreaseChildren, decreaseInfant, handleMulticity, handleRemoveMulticity, increaseAdult, increaseChildren, increaseInfant, setArrivalDatas, setChildAges, setClassType, setDepartureDatas, setDeparturedate, setMultiDeparture, setMultiDeparturedate, setReturnDate, setTripWays, setNs, setRefundable, setBaggage, setPrefAirlines } from '../../../toolkit/Slices/SearchFlightSlice';
import { Ages } from '../../common/Lists/ChildAges';
import { handleAirportSearch } from '../../bookingflow/Flights/common/AirportValid';
import { handleDateFormat, handleCompleteDate, handleSingledate } from '../../common/services/DateValidations';
import ReactDatePicker from 'react-datepicker';
import AutoCompleteDep from '../../common/services/AutoCompleteDep';
import AutoCompleteReturn from '../../common/services/AutoCompleteReturn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Accordion from 'react-bootstrap/Accordion';
import HotelSearch from './HotelSearch';

let airportsData = require("../../maasterdata/AirlinesMasterData.json");

function SearchBox() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const sliceData = useSelector((state) => state.searchflight.value);
  const [origin, setOrigin] = useState([])
  const [originSearch, setOriginSearch] = useState('')
  const [showOrigin, setShowOrigin] = useState(false)
  const [showCalendars, setShowCalendars] = useState(false);
  const [sourceClearEnable, setSourceClearEnable] = useState(false);
  const [destinationClearEnable, setDestinationClearEnable] = useState(false);
  const [modifySearch, setModifySearch] = useState(false);
  const [moreOption, setMoreOptions] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [validFrom, setValidFrom] = useState(false);
  const [validTo, setValidTo] = useState(false);
  const [rtDate, setRtDate] = useState(false);
  const [onewayDate, setOnewayDate] = useState(false);
  const [prefAirlinesSelected, setPrefAirlinesSelected] = useState([]);
  

  const dateRangePickerRef = useRef();
  useEffect(() => {
      let tabs = document.querySelector(".searchPanelTabs");
        let tabHeader = tabs.querySelector(".tabHeader");
        let tabBody = tabs.querySelector(".tabBody");
        let tabIndicator = tabs.querySelector(".tabIndicator");
        let tabHeaderNodes = tabs.querySelectorAll(".tabHeader > div");
        let tabBodyNodes = tabs.querySelectorAll(".tabBody .innerBodyWrap > div");
    
        for(let i=0;i<tabHeaderNodes.length;i++){
            tabHeaderNodes[i].addEventListener("click",function(){
                tabHeader.querySelector(".active").classList.remove("active");
                tabHeaderNodes[i].classList.add("active");
                tabBody.querySelector(".active").classList.remove("active");
                tabBodyNodes[i].classList.add("active");
                tabIndicator.style.left = `calc(calc(calc(50% - 0px) * ${i}) + 0px)`;
            });
        }
    }, [])
  // handles origin search....
  const handleShowOrigin = () => {
    setShowOrigin(!showOrigin)
  }
  const handleOrigin = (e) => {
    const search = e.target.value;
    if (search.length >= 3) {
      const response = handleAirportSearch(search)
      response.then(function (result) {
        if (result?.data?.suc) {
          setOrigin(result.data?.res?.data);
          setShowOrigin(true)
        } else {
          setOrigin([]);
        }
      }).catch(error => {
        console.log("Error occurred in Predective search", error)
      });
    } else if (search.length === 0){
        setOrigin([]);
        setShowOrigin(false);
        setSourceClearEnable(false);
    }
  }
  const handleOriginSearch = (code, country) => {
    const value = origin.find(item => item.selected_data.cityname == code.cityname)
    setOriginSearch(code?.cityname + "(" + code?.iata + ")" + ", " + code?.name)
    handleShowOrigin(false)
    // dispatch(setDepartureDatas(value))
    dispatch(setDepartureDatas({...code, countryname: country ? country : code.countryname}))
    setValidFrom(false);
  }
  // handles destination searches...
  const [showDestination, setShowDestination] = useState(false)
  const [destination, setDestination] = useState([])
  const [destinationSearch, setDestinationSearch] = useState('')
  const handleShowDestination = () => {
    setShowDestination(!showDestination)
  }
  const handleDestination = (e) => {
    const search = e.target.value;
    if (search.length >= 3) {
      const response = handleAirportSearch(search)
      response.then(function (result) {
        if (result?.data?.suc) {
          setDestination(result.data?.res?.data);
          setShowDestination(true)
        } else {
          setDestination([]);
        }
      }).catch(error => {
        console.log("Error occurred in Predective search", error)
      });
    } else if (search.length === 0){
      setDestination([]);
      setShowDestination(false);
      setDestinationClearEnable(false);
    }
  }
  const handleDestinationSearch = (code, country) => {
    handleShowDestination(false)
    const items = destination.find(item => item.selected_data.cityname == code.cityname)
    setDestinationSearch(code?.cityname + "(" + code?.iata + ")" + ", " + code?.name)
    // dispatch(setArrivalDatas(items))
    dispatch(setArrivalDatas({...code, countryname: country ? country : code.countryname}))
    setValidTo(false);
  }
  useEffect(() => {
  }, [originSearch, destinationSearch])

  // handle departure date and return date....
  useEffect(() => {
    window.addEventListener('dateRangeSelected', handleDateRangeSelected);

    return () => {
      window.removeEventListener('dateRangeSelected', handleDateRangeSelected);
    };
  }, []);
  const handleDateRangeSelected = (event) => {
    const { departureDate, returnDate } = event.detail;
    dispatch(setDeparturedate(departureDate))
    dispatch(setReturnDate(returnDate))
  };

  const handleDateRangeChange = (event, picker) => {
    let startDate = picker.startDate.format('YYYY-MM-DD')
    let endDate = picker.endDate.format('YYYY-MM-DD')
    dispatch(setDeparturedate(startDate))
    dispatch(setReturnDate(endDate))
    setRtDate(false);
    setOnewayDate(false);
  };

  const handleDateChange = (e) => {
    // setSelectedDate(e.target.value);
    dispatch(setDeparturedate(e.target.value))
  };


  const handleStartDateFocus = () => {
    setShowCalendars(true);
  };

  const handleEndDateFocus = () => {
    setShowCalendars(true);
  };


  const handleChildAges = (event, index) => {
    const newData = event.target.value;
    dispatch(setChildAges({ index, newData }))
  }
  const [showTravel, setShowTravel] = useState(false)
  const handleShowTravel = () => {
    setShowTravel(!showTravel)
  }


  const handleSearchButton = () => {
    if (sliceData?.tripWay === 'multi') {
      sliceData?.multicity.forEach((data, index) => {
        const { departure, arrival, departureDate } = data;
        if (departure && arrival && departureDate) {
          sessionStorage.removeItem('flightSlice');
          setLoader(true);
          const jsonData = JSON.stringify(sliceData);
          sessionStorage.setItem('flightSlice', jsonData);
          constructURLForMultiSearch();
          // nav('/flight/results')
        } else {
          if (!departure && !arrival && !departureDate) {
            setValidFrom(true);
            setValidTo(true);
            setRtDate(true);
            setOnewayDate(true);
          } else if (!departure) {
            setValidFrom(true);
          } else if (!arrival) {
            setValidTo(true);
          } else {
            setRtDate(true);
            setOnewayDate(true);
          }
        }
      });
    } else {
      if (destinationSearch && originSearch && sliceData?.departureDate) {
        sessionStorage.removeItem('flightSlice');
        setLoader(true);
        const jsonData = JSON.stringify(sliceData);
        sessionStorage.setItem('flightSlice', jsonData);
        constructURL(); 
        // nav('/flight/results')
      } else {
        if (!originSearch && !destinationSearch && !sliceData?.departureDate) {
          setValidFrom(true);
          setValidTo(true);
          setRtDate(true);
          setOnewayDate(true);
        } else if (!originSearch) {
          setValidFrom(true);
        } else if (!destinationSearch) {
          setValidTo(true);
        } else {
          setRtDate(true);
          setOnewayDate(true);
        }
      }
    }
  }

  const constructURL = () => {
    const state = sliceData;
    try {
      const params = new URLSearchParams({
        srcCode: state.departure.iata,
        destCode: state.arrival.iata,
        dd: state.departureDate,
        ad: state.returnDate,
        tt: state.tripWay === 'round' ? 2 : 1,
        adt: state.adult,
        chd: state.children,
        inf: state.infant,
        ns: state.ns,
        cls: state.ClassType,
        modify: modifySearch,
        usr_cur: state.currency,
      });
      const urlParams = params.toString();
      // Construct the URL with the parameters
      const url = `/flight/results?${urlParams}`;
      var h = window.location.host.includes('localhost')?'http':'https';
      window.location.href = `${h}://${window.location.host}`+url;
      //navigate(url);
    } catch (e) {
      console.error("Exception in flight search request" + e);
    }
  }

  const constructURLForMultiSearch = () => {
    const state = sliceData;
    try {
      const params = new URLSearchParams({
        dep1: state.multicity[0]?.departure.iata,
        dep2: state.multicity[1]?.departure.iata,
        dep3: state.multicity[2]?.departure.iata,
        dep4: state.multicity[3]?.departure.iata,
        dep5: state.multicity[4]?.departure.iata,
        dep6: state.multicity[5]?.departure.iata,
        arr1: state.multicity[0]?.arrival.iata,
        arr2: state.multicity[1]?.arrival.iata,
        arr3: state.multicity[2]?.arrival.iata,
        arr4: state.multicity[3]?.arrival.iata,
        arr5: state.multicity[4]?.arrival.iata,
        arr6: state.multicity[5]?.arrival.iata,
        depDate1: state.multicity[0]?.departureDate,
        depDate2: state.multicity[1]?.departureDate,
        depDate3: state.multicity[2]?.departureDate,
        depDate4: state.multicity[3]?.departureDate,
        depDate5: state.multicity[4]?.departureDate,
        depDate6: state.multicity[5]?.departureDate,
        tt: 3,
        adt: state.adult,
        chd: state.children,
        inf: state.infant,
        ns: state.ns,
        cls: state.ClassType,
        modify: modifySearch,
        usr_cur: state.currency,
      });
      const urlParams = params.toString();
      // Construct the URL with the parameters
      const url = `/flight/results?${urlParams}`;
      var h = window.location.host.includes('localhost')?'http':'https';
      window.location.href = `${h}://${window.location.host}`+url;
    } catch (e) {
      console.error("Exception in constructURLForMultiSearch:" + e);
    }
  }


  const handleSearchBoxes = () => {
    setDestinationSearch(originSearch);
    setOriginSearch(destinationSearch);
  }

  // multicity....
  const handleMultiDate = (value, index) => {
    const date = handleCompleteDate(value);
    dispatch(setMultiDeparturedate({ index, date }))
  }

  //For clearing the source field
  const clearSource = () => {
    setOrigin([]);
    setOriginSearch('');
    handleShowOrigin(false)
    setSourceClearEnable(false);
  };

  //For clearing the destination field
  const clearDestination = () => {
    setDestination([]);
    setDestinationSearch('');
    handleShowDestination(false)
    setDestinationClearEnable(false);
  };

  const handleDirectFlights = () => {
    dispatch(setNs(!sliceData?.ns));
  }

  const moreOptions = (type) => {
    if(type === "refundable") {
      dispatch(setRefundable(!sliceData?.refundable));
    } else if (type === "baggage") {
      dispatch(setBaggage(!sliceData?.baggage));
    }
  }

  const handleMoreOptions = () => {
    setMoreOptions(!moreOption)
  }

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handleTripType = (type) => {
    if(type === 'round') {
      dispatch(setTripWays(type))
    } else if(type === 'one') {
      dispatch(setTripWays(type))
    } else if(type === 'multi') {
      dispatch(setTripWays(type))
    }
    setShowOrigin(false);
    setShowDestination(false);
    setSourceClearEnable(false);
    setDestinationClearEnable(false);
    // setOriginSearch('');
    // setDestinationSearch('');
  }
  // const handleEvent = (event, picker) => {
  //   let startDate = picker.startDate.format('YYYY-MM-DD')
  //   let endDate = picker.endDate.format('YYYY-MM-DD')
  //   dispatch(setDeparturedate(startDate))
  //   dispatch(setReturnDate(endDate))
  // }

  const handlePrefAirlines = (airline) => {
    setPrefAirlinesSelected(airline);    
    dispatch(setPrefAirlines(airline));
  }

  return (
    <>
    
    <section className='bannerWrap'>
      <div className="bgBanner"></div>

      <div className="mainContainer">
        
        <Row>
          <Col>
          <div className="searchPanelTabs">
          <div className="msg">Let us show you the world!</div>
                <div className="tabHeaderWrap">
                <div className="tabHeader">
                    <div className='active'><i class="icon icon-flight"></i>Flights</div>
                    <div><i class="icon icon-hotel"></i>Hotels</div>
                </div>
                <div className="tabIndicator"></div>
                </div>
                <div className="tabBody">
                <div class="topSemicircle"></div>
                <div className="leftSideTriangle"></div>
                <div className="rightSideTriangle"></div>
                <div className="innerBodyWrap">
                  <div className='active flightWrap'>
                  <div id="flightnav" className="tab-content current topRelative22">
                    <div className="tab-inner-box">
                      <ul className='tripTabWrap mb-15'>
                        <li className={sliceData.tripWay === 'round' ? 'active' : ""} onClick={() => {handleTripType("round") }}>Roundtrip</li>
                        <li className={sliceData.tripWay === 'one' ? 'active' : ""} onClick={() => { handleTripType("one") }}>One-Way</li>
                        <li className={sliceData.tripWay === 'multi' ? 'active' : ""} onClick={() => { handleTripType("multi") }}>Multi-City</li>
                      </ul>
                      { sliceData?.tripWay !== 'multi' && (
                      <div className="inputFieldsWrap">
                        <Form.Group className="destFrom position-relative">
                          <Form.Label><i class="icon icon-flight"></i>From</Form.Label>
                          <Form.Control className={validFrom ? "redBorder" : "" } type="text" placeholder="Origin" value={originSearch}  onClick={() => { setShowDestination(false) }}
                            onChange={(e) => { { setOriginSearch(e.target.value); setSourceClearEnable(true) }; handleOrigin(e) }} />
                            <div className="autolist predictiveWrap " style={{ display: showOrigin ? "block" : 'none' }}>
                              <ul className="borderrow verticalScroll">
                                {origin?.length > 0 &&
                                  origin?.map((items, index) => {
                                    return (
                                      <>
                                      <li key={index}>
                                      <Accordion defaultActiveKey="0" className='predictveSearchCollapse'>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            <div className="d-flex justify-content-between align-items-start">
                                              <div>
                                                <div className="cityNameWrap"><i class="icon icon-flight"></i>{items.selected_data.cityname} <span className='allAirportsTitle'>(All airports)</span> <span className='countryCode'>{items.selected_data.citycode}</span></div>
                                                <div className='countryName'>{items.selected_data.countryname}</div>
                                              </div>
                                              <div className='d-flex'>
                                                <i class="icon icon-down-arrow"></i>
                                              </div>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                          {items.selected_data.airports.map((air, idx) => (
                                            <div className='airportName' key={idx} onClick={() => { handleOriginSearch(air, items.selected_data.countryname) }}><i class="icon icon-flight"></i>{air.name} <span className='countryCode'>{air.iata}</span></div>
                                          ))}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                        
                                          
                                          
                                          {items.nearby_routes.map((near, idx) => (
                                            <div className='nearByWrap' key={idx} onClick={() => { handleOriginSearch(near) }}>
                                              <div className='airportName'><i class="icon icon-flight"></i>{near.name} <span className='countryCode'>{near.iata}</span></div>
                                              <div className='nearbyCity'><span className='nearByTitle'>Near by</span> <span className='cityName'>{near.cityname}</span> <span className='distance'>{near.distance}</span></div>
                                            </div>
                                          ))}
                                        </li>
                                      </>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                            {sourceClearEnable && (
                              <span className='removePrediSearch' onClick={clearSource}>
                                <i class="icon icon-Close"></i>
                              </span>
                            )}
                        </Form.Group>
                        
                        <div onClick={handleSearchBoxes} className="interchangeWrap position-relative"><i class="icon icon-flip-arrow"></i></div>
                        <Form.Group className="destTo position-relative">
                          <Form.Label><i class="icon icon-flight"></i>To</Form.Label>
                          <Form.Control className={validTo  ? "redBorder" : "" } type="text" placeholder="Destination" value={destinationSearch} onClick={() => { setShowOrigin(false) }} onChange={(e) => { { setDestinationSearch(e.target.value); setDestinationClearEnable(true) }; { handleDestination(e) } }}/>
                            <div className="autolist predictiveWrap" style={{ display: showDestination ? "block" : 'none' }}>
                              <ul className="borderrow verticalScroll">
                                {destination?.length > 0 &&
                                  destination?.map((items, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                      <Accordion defaultActiveKey="0" className='predictveSearchCollapse'>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                          <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                              <div className="cityNameWrap"><i class="icon icon-flight"></i>{items.selected_data.cityname} <span className='allAirportsTitle'>(All airports)</span> <span className='countryCode'>{items.selected_data.citycode}</span></div>
                                              <div className='countryName'>{items.selected_data.countryname}</div>
                                            </div>
                                            <div className='d-flex'>
                                              <i class="icon icon-down-arrow"></i>
                                            </div>
                                          </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                          {items.selected_data.airports.map((air, idx) => (
                                            <div className='airportName' key={idx} onClick={() => { handleDestinationSearch(air, items.selected_data.countryname) }}><i class="icon icon-flight"></i>{air.name} <span className='countryCode'>{air.iata}</span></div>
                                          ))}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                        
                                          
                                          
                                          {items.nearby_routes.map((near, idx) => (
                                            <div className='nearByWrap' key={idx} onClick={() => { handleDestinationSearch(near) }}>
                                              <div className='airportName'><i class="icon icon-flight"></i>{near.name} <span className='countryCode'>{near.iata}</span></div>
                                              <div className='nearbyCity'><span className='nearByTitle'>Near by</span> <span className='cityName'>{near.cityname}</span> <span className='distance'>{near.distance}</span></div>
                                            </div>
                                          ))}
                                        </li>
                                      </>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                            {destinationClearEnable && (
                              <span className='removePrediSearch' onClick={clearDestination}>
                                <i class="icon icon-Close"></i>
                              </span>
                            )}
                        </Form.Group>
                        

                        {sliceData?.tripWay === 'round' && (
                        <div className='onHover' style={{position:"relative", display:"flex"}}>
                          <DateRangePicker 
                              onApply={handleDateRangeChange} 
                              ref={dateRangePickerRef}  
                              initialSettings={{ minDate: new Date() }}
                              // autoApply={false}
                              // autoUpdateInput={false}
                              // startDate={sliceData.departureDate ? sliceData.departureDate : null}
                              // endDate={sliceData.returnDate ? sliceData.returnDate : null}
                              // locale={{ format: "DD/MM/YYYY" }}
                              // onEvent={handleEvent}
                            >
                            <div style={{position:"absolute", top:"0px", left: "0px", width: "295px", height: "100%", opacity: "0"}}>
                              <Form.Group>
                              <Form.Label>Select Date Range:</Form.Label>
                              <Form.Control
                                type="text"
                                id="dateRange"
                                className="form-control" 
                                readOnly={true}
                                value={
                                  sliceData.departureDate && sliceData.returnDate
                                    ? `${sliceData?.departureDate} - ${sliceData.returnDate}`
                                    : ''
                                }
                                onFocus={() => {setShowCalendars(true); setShowOrigin(false); setShowDestination(false)}}
                              />
                              </Form.Group>
                            </div>
                          </DateRangePicker>
                          <Form.Group className={rtDate ? "depArrCalWrap depCal redBorder" : "depArrCalWrap depCal"}>
                            <Form.Label><i class="icon icon-calendar"></i>Departure</Form.Label>
                            <Form.Control
                              type="text"
                              id="startDate"
                              readOnly={true}
                              placeholder="DD-MM-YYYY"
                              onFocus={handleStartDateFocus}
                              value={sliceData?.departureDate ? sliceData?.departureDate : ''}
                            />
                            </Form.Group>
                          <Form.Group className={rtDate ? "depArrCalWrap arrCal redBorder" : "depArrCalWrap arrCal"}>
                            <Form.Label><i class="icon icon-calendar"></i>Arrival</Form.Label>
                            <Form.Control
                              type="text"
                              id="endDate"
                              readOnly={true}
                              placeholder="DD-MM-YYYY"
                              onFocus={handleEndDateFocus}
                              value={sliceData?.returnDate ? sliceData?.returnDate : ''}
                            />
                            </Form.Group>
                          {showCalendars && (
                            <div className="daterangepicker">
                              <div className="calendar left"></div>
                              <div className="calendar right"></div>
                            </div>
                          )}
                        </div>
                        )}

                        {sliceData?.tripWay === 'one' && (
                          <div style={{display:"flex"}}>
                          <div className="input-group date">
                            <Form.Group className={onewayDate ? "depArrCalWrap redBorder" : "depArrCalWrap"}>
                            <Form.Label><i class="icon icon-calendar"></i>Departure</Form.Label>
                            <DatePicker
                              selected={sliceData?.departureDate ? sliceData?.departureDate : ''}
                              // onChange={handleDateChange}
                              onChange={date => {dispatch(setDeparturedate(date)); setOnewayDate(false)}}
                              placeholderText="DD-MM-YYYY"
                              minDate={new Date()}
                              onFocus={() => { setShowOrigin(false); setShowDestination(false) }}
                              // popperPlacement="bottom"
                            />
                          </Form.Group>
                          </div>
                          
                          <div className="input-box addreturndiv dataactive" style={{ display: sliceData?.tripWay === 'one' ? 'block' : 'none' }}>
                            <div className="addreturn" onClick={() => { dispatch(setTripWays("round")) }}> + Return Date </div>
                          </div>
                          </div>
                        )}
                        <Dropdown className="travellerWrap" drop="start">
                              <Dropdown.Toggle>
                                  <div className="travellerTitleWrap">
                                      <div className="travellerLabel"><i class="icon icon-signin"></i> Travellers & Class</div>
                                      <div className="travellerPlaceholder">{sliceData.infant + sliceData.children + sliceData.adult} Traveller, {sliceData.ClassType}</div>
                                  </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="travellerDropdown">
                                      <div className="cabinClassWrap">
                                          <div className="cabinClassTitle"><i class="icon icon-signin"></i> Cabin Class</div>
                                          <div className="classOptionWrap">
                                              <div onClick={() => { dispatch(setClassType('Economy')) }} className={sliceData.ClassType === "Economy" ? "classOption active" : "classOption"}>Economy</div>
                                              <div onClick={() => { dispatch(setClassType('Premium')) }} className={sliceData.ClassType === "Premium" ? "classOption active" : "classOption"}>Premium Economy</div>
                                              <div onClick={() => { dispatch(setClassType('Business')) }} className={sliceData.ClassType === "Business" ? "classOption active" : "classOption"}>Business Class</div>
                                              <div onClick={() => { dispatch(setClassType('First Class')) }} className={sliceData.ClassType === "First Class" ? "classOption active" : "classOption"}>First Class</div>
                                          </div>
                                      </div>
                                      <div className="selectPaxWrap">
                                          <div className="selectTravellersTitle"><i class="icon icon-signin"></i> Select Travellers</div>
                                          <ul>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Adult</span>
                                                      <span className="age">(12+ years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseAdult()) }} className={sliceData.adult > 1 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.adult}</span>
                                                      <span onClick={() => { dispatch(increaseAdult()) }} className="plus">+</span>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Child</span>
                                                      <span className="age">(2 -11 years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseChildren()) }}className={sliceData.children > 0 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.children}</span>
                                                      <span onClick={() => { dispatch(increaseChildren()) }} className="plus">+</span>
                                                  </div>
                                                  {/* <ul className='child-ages' style={{ display: "flex" }}>
                                                    {
                                                      Array.from(Array(sliceData?.children)).map((c, index) => {
                                                        return (
                                                          <>
                                                            <li className='select-age' >
                                                              <label>Child {index + 1} Age</label>
                                                              <select onChange={(event) => { { handleChildAges(event, index) }; }} >
                                                                {
                                                                  Ages.map((items, indexx) => {
                                                                    return (
                                                                      <>
                                                                        <option key={items.id} value={items.value}>{items.value}</option>
                                                                      </>
                                                                    )
                                                                  })
                                                                }
                                                              </select>
                                                            </li>
                                                          </>
                                                        )
                                                      })
                                                    }
                                                  </ul> */}
                                              </li>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Infant </span>
                                                      <span className="age">(Under 2 years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseInfant()) }} className={sliceData.infant > 0 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.infant}</span>
                                                      <span  onClick={() => { dispatch(increaseInfant()) }} className="plus">+</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                      {/* <div className="applyBtnWrap">
                                          <div onClick={() => { handleShowTravel() }} className="applyBtn">Apply</div>
                                      </div> */}
                              </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="primary" onClick={handleSearchButton} className='searchBtn'><i class="icon icon-k-logo"></i></Button>
                      </div>
                      )}

                      <div className="multicityrow col-12" style={{ display: sliceData?.tripWay === 'multi' ? 'block' : 'none' }} >
                        <div className="search-inputs">
                          {
                            sliceData?.multicity?.map((datas, index) => {
                              return (
                                <>
                                <div className='d-flex multiCityWrap'>
                                  <div className="d-flex">
                                    <AutoCompleteDep
                                      index={index}
                                    />
                                    <AutoCompleteReturn
                                      index={index}
                                    />
                                  </div>
                                  <div className="date-option multioption">
                                    <Form.Group className='depArrCalWrap'>
                                      <Form.Label><i class="icon icon-calendar"></i>Departure</Form.Label>
                                      <ReactDatePicker minDate={new Date()} placeholderText={sliceData?.multicity[index]?.departureDate !== "" ? `${sliceData?.multicity[index]?.departureDate}` : 'DD-MM-YYYY'} onChange={(date) => { handleMultiDate(date, index) }} />
                                    </Form.Group>
                                  </div>
                                  {sliceData?.multicity?.length < 6 && (
                                    <div className="multiCity" style={{ display: index === 1 ? 'flex' : 'none' }} onClick={() => { dispatch(handleMulticity()) }}>
                                      <div className="addMoreFlights">+ Add Flights</div>
                                    </div>
                                  )}
                                  <div className="deleteCity" style={{ display: index === 0 || index === 1 ? 'none' : 'flex' }} onClick={() => { dispatch(handleRemoveMulticity(index)) }}><i class="icon icon-Close"></i></div>
                                  {/* <div className="travel-class" style={{ display: index === 0 ? 'block' : 'none' }}>
                                    <div className="input-box">
                                      <label><img src="assets/images/home/date.svg" alt="" />Travellers & Class</label>
                                      <strong onClick={handleShowTravel}>{sliceData.infant + sliceData.children + sliceData.adult} Traveller, {sliceData.ClassType}</strong>
                                      <div className="adultslist" style={{ display: showTravel ? 'block' : 'none' }}>
                                        <div className="title">Cabin Class</div>
                                        <div className="cabinbox d-flex">
                                          <span onClick={() => { dispatch(setClassType('Economy')) }}>Economy</span>
                                          <span onClick={() => { dispatch(setClassType('Premium Economy')) }}>Premium Economy</span>
                                          <span onClick={() => { dispatch(setClassType('Business Class')) }}>Business class</span>
                                          <span onClick={() => { dispatch(setClassType('First Class')) }}>First class</span>
                                        </div>
                                        <div className="selecttravel">
                                          <h3>Select Travellers</h3>
                                          <div className="adultfind">
                                            <span>Adult (12+ years)</span>
                                            <div className="counter">
                                              <div className="minussign" onClick={() => { dispatch(decreaseAdult()) }}>-</div>
                                              <div className="countno">{sliceData.adult}</div>
                                              <div className="plussign" onClick={() => { dispatch(increaseAdult()) }}>+</div>
                                            </div>
                                          </div>
                                          <div className="adultfind">
                                            <span>Child (2 -11 years)</span>
                                            <div className="counter">
                                              <div className="minussign" onClick={() => { dispatch(decreaseChildren()) }}>-</div>
                                              <div className="countno">{sliceData.children}</div>
                                              <div className="plussign" onClick={() => { dispatch(increaseChildren()) }}>+</div>
                                            </div>
                                          </div>
                                          <ul className='child-ages' style={{ display: "flex" }}>
                                            {
                                              Array.from(Array(sliceData?.children)).map((c, index) => {
                                                return (
                                                  <>
                                                    <li className='select-age' >
                                                      <label>Child {index + 1} Age</label>
                                                      <select onChange={(event) => { { handleChildAges(event, index) }; }} >
                                                        {
                                                          Ages.map((items, indexx) => {
                                                            return (
                                                              <>
                                                                <option key={items.id} value={items.value}>{items.value}</option>
                                                              </>
                                                            )
                                                          })
                                                        }
                                                      </select>
                                                    </li>
                                                  </>
                                                )
                                              })
                                            }
                                          </ul> 
                                          <div className="adultfind">
                                            <span>Infant (Under 2 years)</span>
                                            <div className="counter">
                                              <div className="minussign" onClick={() => { dispatch(decreaseInfant()) }}>-</div>
                                              <div className="countno">{sliceData.infant}</div>
                                              <div className="plussign" onClick={() => { dispatch(increaseInfant()) }}>+</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="applybutton">
                                          <button onClick={() => { handleShowTravel() }}>Apply</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <Dropdown className="travellerWrap" drop="start" style={{ display: index === 0 ? 'block' : 'none' }}>
                              <Dropdown.Toggle>
                                  <div className="travellerTitleWrap">
                                      <div className="travellerLabel"><i class="icon icon-signin"></i> Travellers & Class</div>
                                      <div className="travellerPlaceholder">{sliceData.infant + sliceData.children + sliceData.adult} Traveller, {sliceData.ClassType}</div>
                                  </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="travellerDropdown">
                                      <div className="cabinClassWrap">
                                          <div className="cabinClassTitle"><i class="icon icon-signin"></i> Cabin Class</div>
                                          <div className="classOptionWrap">
                                              <div onClick={() => { dispatch(setClassType('Economy')) }} className={sliceData.ClassType === "Economy" ? "classOption active" : "classOption"}>Economy</div>
                                              <div onClick={() => { dispatch(setClassType('Premium')) }} className={sliceData.ClassType === "Premium" ? "classOption active" : "classOption"}>Premium Economy</div>
                                              <div onClick={() => { dispatch(setClassType('Business')) }} className={sliceData.ClassType === "Business" ? "classOption active" : "classOption"}>Business Class</div>
                                              <div onClick={() => { dispatch(setClassType('First Class')) }} className={sliceData.ClassType === "First Class" ? "classOption active" : "classOption"}>First Class</div>
                                          </div>
                                      </div>
                                      <div className="selectPaxWrap">
                                          <div className="selectPaxTitle"><i class="icon icon-signin"></i> Select Travellers</div>
                                          <ul>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Adult</span>
                                                      <span className="age">(12+ years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseAdult()) }} className={sliceData.adult > 1 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.adult}</span>
                                                      <span onClick={() => { dispatch(increaseAdult()) }} className="plus">+</span>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Child</span>
                                                      <span className="age">(2 -11 years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseChildren()) }}className={sliceData.children > 0 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.children}</span>
                                                      <span onClick={() => { dispatch(increaseChildren()) }} className="plus">+</span>
                                                  </div>
                                                  {/* <ul className='child-ages' style={{ display: "flex" }}>
                                                    {
                                                      Array.from(Array(sliceData?.children)).map((c, index) => {
                                                        return (
                                                          <>
                                                            <li className='select-age' >
                                                              <label>Child {index + 1} Age</label>
                                                              <select onChange={(event) => { { handleChildAges(event, index) }; }} >
                                                                {
                                                                  Ages.map((items, indexx) => {
                                                                    return (
                                                                      <>
                                                                        <option key={items.id} value={items.value}>{items.value}</option>
                                                                      </>
                                                                    )
                                                                  })
                                                                }
                                                              </select>
                                                            </li>
                                                          </>
                                                        )
                                                      })
                                                    }
                                                  </ul> */}
                                              </li>
                                              <li>
                                                  <div className="label">
                                                      <span className="paxType">Infant </span>
                                                      <span className="age">(Under 2 years)</span>
                                                  </div>
                                                  <div className="count">
                                                      <span onClick={() => { dispatch(decreaseInfant()) }} className={sliceData.infant > 0 ? "minus active" : "minus"}>-</span>
                                                      <span className="countValue">{sliceData.infant}</span>
                                                      <span  onClick={() => { dispatch(increaseInfant()) }} className="plus">+</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                      {/* <div className="applyBtnWrap">
                                          <div onClick={() => { handleShowTravel() }} className="applyBtn">Apply</div>
                                      </div> */}
                              </Dropdown.Menu>
                                  </Dropdown>
                                  {/* <button className="searchbutton button-4" style={{ display: index === 0 ? 'block' : 'none' }} onClick={handleSearchButton}> Search flights <img src="assets/images/home/k.svg"
                                    alt="" /></button> */}
                                    <Button variant="primary" className='searchBtn' style={{ display: index === 0 ? 'block' : 'none' }} onClick={handleSearchButton}><i class="icon icon-k-logo"></i></Button>
                                    </div>
                                </>
                              )
                            })
                          }

                        </div >
                      </div>
                      {/* <div className="flight-direct">
                        <div className="form-group">
                          <input type="checkbox" id="html" />
                          <label for="html">Direct Flights</label>
                        </div>
                      </div> */}

                    </div>

                    {/* <div className="flight-refundable">
                      <div className="flight-direct d-flex">
                        <div className="form-group">
                          <input type="checkbox" id="html1" />
                          <label for="html1">Refundable</label>
                        </div>
                        <div className="form-group">
                          <input type="checkbox" id="html2" />
                          <label for="html2">Baggage Only</label>
                        </div>
                      </div>
                      <div className="airlineselect">
                        <select>
                          <option>Preffered Airlines</option>
                          <option>Preffered Airlines</option>
                          <option>Preffered Airlines</option>
                          <option>Preffered Airlines</option>
                          <option>Preffered Airlines</option>
                          <option>Preffered Airlines</option>
                        </select>
                      </div>
                    </div> */}
                  </div>



                
              
              <div className="d-flex align-items-center topRelative7">
                  <div className='switchBoxWrap directFlights'>
                  <label class="switch" for="switch">
                      <input value={sliceData?.ns} onClick={handleDirectFlights} type="checkbox" id='switch' />
                      <span class="slider round"></span>
                      <span className='switchTitle'>Direct Flights</span>
                  </label>
                  
                  </div>
                  <div className={`moreOptionSlide ${isActive ? 'active' : ''}`} >
                  <div className="moreOptionsWrap leftSeparatorLine" onClick={toggleActive}>
                      More Options <i class="icon icon-down-arrow"></i>
                  </div>
                  <div className='d-flex align-items-center moreOptionList'>
                    <div className='switchBoxWrap moreOptionItem'>
                    <label class="switch">
                        <input type="checkbox" value={sliceData?.refundable} onClick={() => moreOptions("refundable")} />
                        <span class="slider round"></span>
                        <span className='switchTitle'>Refundable</span>
                    </label>
                    
                    </div>                    
                    <div className='switchBoxWrap moreOptionItem'>
                    <label class="switch">
                        <input type="checkbox" value={sliceData?.baggage} onClick={() => moreOptions("baggage")}/>
                        <span class="slider round"></span>
                        <span className='switchTitle'>Baggage Only</span>
                    </label>
                    
                    </div>
                    {/* <div className="prefferedAirlines">Preferred Airlines <i class="icon icon-down-arrow"></i></div> */}
                    <Select
                      isMulti
                      value={prefAirlinesSelected}
                      onChange={handlePrefAirlines}
                      options={airportsData}
                      placeholder="Preferred Airlines"
                    />
                  </div>
                  </div>
              </div>
              
                  </div>
                  <div className='hotelWrap'>
                      {/* <h1>Hotels</h1>
                      <p>Coming soon.......</p> */}
                      <HotelSearch />
                  </div>
                  </div>
                </div>
            </div> 
          </Col>
        </Row>
      </div>

    </section>


      {/* <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
        <img src={Loader} alt='' />
      </div>
      <section className="banner">
        <div className="container">
          <div className="heading">Let us show you the world!</div>
          <div className="banner-search">
            <div className="banner-tabs">
              <ul className="tabs">
                <li className="tab-link current" data-tab="flightnav"> <i className="planeicon"></i> Flight</li>
                <li className="tab-link" data-tab="tab-2"><i className="hotelicon"></i> Hotel</li>
              </ul>
            </div>

            

            <div id="tab-2" className="tab-content">
              {/* <HotelBox /> 
            </div>

          </div>
        </div>
      </section > */}
    </>
  )
}

export default SearchBox