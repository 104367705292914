/**
 * @ Author: Aman Goyal
 * @ Description: Flight Details Box
 */

import React, { useEffect, useRef, useState, useReducer, useContext } from 'react'
import { Context } from '../../../../../App';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { convertDate, handleFormatTime, handleTimeChange } from '../../../../common/services/DateValidations';
import LoaderImg from '../../../../../assets/Gifs/Double Ring-1s-200px.gif'
import { handlePostApi } from '../../../../../utilities/Axios';
import { Form, Button, Modal  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AirlineLogo from "../../../../../assets/images/result/QR.png";
import FlightDetailsPopup from './FlightDetailsPopup';
import FlightReviewLoader from '../../Review/FlightReviewLoader';
import NoResults from "../../../../../assets/images/home/other/noResults.png";
import Whatsap from "../../../../../assets/images/home/other/whatsapp.png";
import Messenger from "../../../../../assets/images/home/other/messenger.png";
const B2C_FLIGHT_URL  = process.env.REACT_APP_FLIGHT_CONNECTER;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const initialstate= {
  response: "",
  initRes: {}
}

const reducer = (state, action) => {
  switch (action.type) {
    case "test":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function FlightDetails(props) {
  const currency = props?.currency;
  // const cur = props?.cur;
  // const base_usr_exrate = props?.base_usr_exrate;
  const data = props?.data;
  const request = props?.request;
  const [context, setContext] = useContext(Context);
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [cur, setUserCur] = useState("");
  const filterFlight = useSelector((state) => state.flightfilter.value);
  const sliceData = useSelector((state) => state.searchflight.value)
  const [state, dispatch] = useReducer(reducer, initialstate);
  const [loader, setLoader] = useState(false)
  const EndDivRef = useRef(null);
  const [flightDetails, setFlightDetails] = useState(false)
  const [showResults, setShowResults] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [shareItineraryShow, setshareItineraryShow] = useState(false);
  const [filterIcon, setFilterIcon] = useState("icon-Pin");
  const navigate = useNavigate();
  const handleFlightDetails = () => {
    // setFlightDetails(!flightDetails)
    setModalShow(true);
  }
  const handleShareItinerary = () => {
    setshareItineraryShow(true);
  }
  const hideShareItinerary = () => {
    setshareItineraryShow(false);
  }
  
  // console.log("filterFlight", filterFlight.direct) 
  // filters....
  const [filter, setFilter] = useState([...data])

  useEffect(() => {
    setShowResults(10);
    dispatch({ type: "response", payload: props?.data });
    dispatch({ type: "initRes", payload: props?.data });
    state.initRes = props?.data;
  }, [data]);

  useEffect(() => {
    props.sendResultsCount(state.response.length)
  }, [state.response])

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("KF_EX_RATES"))
    if(context.ex_rates === undefined || context.ex_rates === "") {
      setContext({...context, ex_rates: exRates})
    }
    if((context.ex_rates !==undefined && context.ex_rates!=="") || (exRates !== undefined && exRates !== "") && userCur!==undefined && userCur!==""){
      if(BASE_CURRENCY!==userCur){
          // finalData.cur=userCur;
          let currecny=BASE_CURRENCY+"-"+userCur;
          getExchangeRate(currecny)
      }else if(BASE_CURRENCY===userCur){
        setBaseToUserExrate(1)
        // finalData.cur=userCur;
      }
    }else{
      setBaseToUserExrate(1)
    }
  }, [context.userCur])
  
  useEffect(() => {
  if(context.ex_rates!=="" && context.ex_rates!==undefined){
    let currecny=BASE_CURRENCY+"-"+cur;
    getExchangeRate(currecny)
  } 
  }, [context.ex_rates])

  const getExchangeRate=(usrCur)=>{
    if(context.ex_rates!=="" && context.ex_rates!==undefined && context.ex_rates!==null){
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  useEffect(() => {
    const divElement = EndDivRef.current;
    // props.closeLoader()
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (state.response) {
            if (showResults >= state.response.length) {
              return;
            }

            // Update the number of results to show
            setShowResults((prevShowResults) => prevShowResults + 10);
          }
        } else {
          console.log('Div is not visible in the viewport');
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0, // Adjust this value to define the visibility threshold
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (divElement) {
      observer.observe(divElement);
    }
    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
    
  }, [state.response]);

  useEffect(() => {
    // console.log("running...", filterFlight);

    let cloneRes = state.initRes;
    // Sort data
    if (filterFlight.sortBy !== '') {
      setLoader(true)
      let sortedData;
      if (filterFlight.sortBy === 'Cheapest') {
        sortedData = cloneRes.sort((a, b) => Number(a?.price?.total_price) - Number(b?.price?.total_price));
      } else if (filterFlight.sortBy === 'Fastest') {
        sortedData = cloneRes.sort((a, b) => Number(a?.totaltime) - Number(b?.totaltime));
      } else if (filterFlight.sortBy === 'Bestest') {
        sortedData = cloneRes.sort((a, b) => Number(a?.totaltime) - Number(b?.totaltime) && Number(a?.price?.total_price) - Number(b?.price?.total_price));
      }
      setFilter(sortedData);
      dispatch({ type: "response", payload: sortedData });
      setLoader(false)
    }

    // Filter by airline
    if (filterFlight.airlineFilter !== '') {
      setLoader(true)
      const airline = filterFlight.airlineFilter.substring(1, filterFlight.airlineFilter.length);
      const airlineArray = airline.split(',').map(code => code.trim());
      const filterData = cloneRes.filter(item => airlineArray.some(code => item.Airlinelists?.includes(code)));
      setFilter(filterData);
      dispatch({ type: "response", payload: filterData });
      setLoader(false)
      console.log(filterData, "filter", filter);
    }

    //sort by sortlist...
    if (filterFlight.sortlist !== '' && filterFlight.sortlist !== 'Select') {
      setLoader(true)
      let sortedData;
      if (filterFlight.sortlist === 'Price - Low To High') {
        sortedData = cloneRes.sort((a, b) => Number(a?.price?.total_price) - Number(b?.price?.total_price));
      } else if (filterFlight.sortlist === 'Price - High To Low') {
        sortedData = cloneRes.sort((a, b) => Number(b?.price?.total_price) - Number(a?.price?.total_price));
      } else if (filterFlight.sortlist === 'Depart - Duration Shortest') {
        sortedData = cloneRes.sort((a, b) => Number(a?.totaltime) - Number(b?.totaltime));
      } else if (filterFlight.sortlist === 'Return - Duration Shortest') {
        sortedData = cloneRes.sort((a, b) => Number(a?.totaltime) - Number(b?.totaltime));
      }
      setFilter(sortedData);
      dispatch({ type: "response", payload: sortedData });
      setLoader(false)
    }


    // Filter By search....
    if (filterFlight.searchFlight !== '' && filterFlight.searchFlight !== null && filterFlight.searchFlight !== undefined) {
      setLoader(true)
      const filteredData = cloneRes.filter(item => {
        return item.OutboundInboundlist.some(flight => {
          return flight.flightlist.some(f => f.Aircraft === filterFlight.searchFlight);
        });
      });
      setFilter(filteredData);
      dispatch({ type: "response", payload: filteredData });
      setLoader(false)
    }
    //filter by direct
    if (filterFlight.direct === true) {
      setLoader(true)
      const filteredData = cloneRes.filter(item => {
        return filterFlight.stops.every((stop, index) => {

          return item.OutboundInboundlist[index].flightlist.length === 1;
        })
      })
      setFilter(filteredData.length > 0 ? filteredData : data);
      dispatch({ type: "response", payload: filteredData.length > 0 ? filteredData : data });
      setLoader(false)
    }
    // Filter by stops
    if (filterFlight.stops.some(stop => stop.direct || stop.onestop || stop.morethanonestop)) {
      setLoader(true)
      const filteredData = cloneRes.filter(item => {
        return filterFlight.stops.every((stop, index) => {
          if (stop.direct) {
            return item.OutboundInboundlist[index].flightlist.length === 1;
          } else if (stop.onestop) {
            return item.OutboundInboundlist[index].flightlist.length === 2;
          } else if (stop.morethanonestop) {
            return item.OutboundInboundlist[index].flightlist.length > 2;
          }
          return true;
        });
      });
      setFilter(filteredData.length > 0 ? filteredData : data);
      dispatch({ type: "response", payload: filteredData.length > 0 ? filteredData : data });
      setLoader(false)
    }

    // onboard Timimgs
    //Departure...
    if (filterFlight?.boardTime[0]?.earlymorning === true ||
      filterFlight?.boardTime[0]?.morning === true ||
      filterFlight?.boardTime[0]?.afternoon === true ||
      filterFlight?.boardTime[0]?.evening === true) {
      let filteredData;
      filterFlight.boardTime.forEach((stime, index) => {
        if (filterFlight.boardTime[index].earlymorning === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) > '00:00:00' &&
              handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) <= '06:00:00';
          });
        } else if (filterFlight.boardTime[index].morning === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) > '06:01:00' &&
              handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) <= '11:59:00';
          });
        } else if (filterFlight.boardTime[index].afternoon === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) > '12:00:00' &&
              handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) <= '17:59:00';
          });
        } else if (filterFlight.boardTime[index].evening === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) > '17:00:00' &&
              handleFormatTime(item.OutboundInboundlist[0].flightlist[0].Departure.Datetime) <= '23:59:00';
          });
        }
      })
      console.log(filteredData.length, "departure")
      setFilter(filteredData?.length > 0 ? filteredData : data);
      dispatch({ type: "response", payload: filteredData.length > 0 ? filteredData : data });
    }
    // return...
    if (filterFlight?.boardTime[1]?.earlymorning === true ||
      filterFlight?.boardTime[1]?.morning === true ||
      filterFlight?.boardTime[1]?.afternoon === true ||
      filterFlight?.boardTime[1]?.evening === true) {
      let filteredData;
      filterFlight.boardTime.forEach((stime, index) => {
        if (filterFlight.boardTime[1].earlymorning === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) > '00:00:00' &&
              handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) <= '06:00:00';
          });
        } else if (filterFlight.boardTime[1].morning === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) > '06:00:00' &&
              handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) <= '12:00:00';
          });
        } else if (filterFlight.boardTime[1].afternoon === true) {
          filteredData = cloneRes.filter(item => {
            return handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) > '12:00:00' &&
              handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) <= '18:00:00';
          });
        } else if (filterFlight.boardTime[1].evening === true) {
          filteredData = cloneRes.filter(item => {
            // const vvvv = handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime)
            // console.log(vvvv, "vvvv")
            return handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) > '18:00:00' &&
              handleFormatTime(item.OutboundInboundlist[1].flightlist[0].Departure.Datetime) <= '23:59:00';
          });
        }
      })
      console.log(filteredData.length, "return")
      setFilter(filteredData?.length > 0 ? filteredData : data);
      dispatch({ type: "response", payload: filteredData.length > 0 ? filteredData : data });
    }


  }, [filterFlight])

  // Airlines logo...
  const handleLogo = (data) => {
    let logo;
    try {
      logo = require(`../../../../../assets/airlines-logo/${data}.png`);
    } catch (error) {
      logo = null;
    }
    return logo
  }

  //handle show details...
  const [flightItineary, setFlightItineary] = useState(true)
  const [flightBaggage, setFlightBaggage] = useState(false)
  const [flightFare, setFlightFare] = useState(false)
  const handleDetailBox = (val) => {
    if (val === 'Itineary') {
      setFlightItineary(true);
      setFlightBaggage(false)
      setFlightFare(false)
    } else if (val === 'Baggage') {
      setFlightItineary(false);
      setFlightBaggage(true)
      setFlightFare(false)
    } else if (val === 'Fare') {
      setFlightItineary(false);
      setFlightBaggage(false)
      setFlightFare(true)
    }
  }
  const [detailData, setDetailData] = useState()
  const filterDetails = (code) => {
    console.log(code)
    const find = filter.filter(item => item.tid === code);
    console.log("OutboundInboundlist", find)
    setDetailData(find)
  }

  const handleAvailability = async (selectedFlight) => {
    setLoader(true);  
    props.skeletonloader(true);  
    let arrCountry = sliceData?.arrival?.countryname?.toUpperCase();
    let deptCountry = sliceData?.departure?.countryname?.toUpperCase();
    const payload = {
      ...selectedFlight, 
      adults:request?.adults, 
      children:request?.children,
      infants:request?.infants,
      tt:request?.tt,
      international: arrCountry === deptCountry ? false : true,
      dep: sliceData?.departure?.iata,
      arr: sliceData?.arrival?.iata,
      sty: selectedFlight?.mix_carrier ? "split" : "normal",
    }
    let response = await handlePostApi(`${B2C_FLIGHT_URL}/getFlightDetail`, payload);
      if (response?.data?.success) { 
        setLoader(false);
        payload.base_usr_exrate = base_usr_exrate;
        payload.cur = cur;
        navigate("/flight/review", {
          state: {
            req: payload,
            res: response?.data ? response?.data : [],
          }
        });
      } else {
        setLoader(false);
        props.skeletonloader(false);
      }    
  }

  const handlePinClick = () => {
    if(filterIcon === "icon-Pin") {
      setFilterIcon("icon-Pin-fill")
    } else {
      setFilterIcon("icon-Pin");
    }
  }

  const closeFlightDetails = (data) => {
    setModalShow(data);
  }

  return (
    <>
      <div className="resulSideWrap">
        {loader ? (
          <FlightReviewLoader />
        ) : (
          <>
        {/* <div className="d-flex justify-content-between align-items-center">
          <div className="showingFlights">
            Showing {state.response.length} of {props.totalflights} flights
          </div>
          <div className="showPricingCalendarWrap">
          <i class="icon icon-calendar"></i> Show Pricing Calendar <i class="icon icon-down-arrow"></i>
          </div>
        </div> */}
        {state.response &&
          state.response.map((data, index) => {
            return (
              index < showResults &&
              <>
              <div className="resultBlock">  
              <i class="icon icon-Share" onClick={handleShareItinerary}></i>                
                <div className="rbTop">
                      {/* <div className='refundType refund'>Refundable</div> */}
                      {/* <span style={{ display: data?.supplier === 1 ? "flex" : 'none' }}>TRAVELPORT </span>
                      <span style={{ display: data?.supplier === 2 ? "flex" : 'none' }}>MYSTIFLY</span>
                      <span style={{ display: data?.supplier === 3 ? "flex" : 'none' }}>ITAI</span> */}
                      <div className='seatsLeft'>
                        {/* <i class="icon icon-Seats-Left"></i> 9 Seats Left */}
                      </div>
                      <div className='d-flex'>
                        <span className='installment'>Installments</span>
                        <div>
                          <div>
                            <span className='currCode leftSeparatorLine'>
                              {/* {data.price.currency} */}
                              {cur ? cur : data.price.currency} &nbsp;
                            </span>
                            <span className='currValue'>{(Number(data?.price?.total_price) * base_usr_exrate).toFixed(2)}</span>
                          </div>
                          <div>
                            <del className='changePrice'>{cur ? cur : data.price.currency} &nbsp;
                            {(Number((parseInt(data?.price?.total_price) * base_usr_exrate) + 380)).toFixed(2)}</del>
                          </div>
                        </div>
                        <Button onClick={() => handleAvailability(data)} variant="primary selectBtn">Select <i class="icon icon-down-arrow"></i></Button>
                      </div>
                    </div>
                  {/* {
                    data.OutboundInboundlist.map((outbounddata) => {
                      const lengths = outbounddata.flightlist.length;
                      const flightListData = outbounddata.flightlist[0];
                      const depertureDatas = outbounddata.flightlist[0].Departure;
                      const arrivalDatas = outbounddata.flightlist[lengths - 1].Arrival;
                      const layover = outbounddata?.flightlist[lengths - 1]?.connectiontime;
                      const layovername = outbounddata?.flightlist[0]?.Arrival?.Iata;
                      return (
                        <>
                          <div className="flight-to">
                            <div className="flyinner d-flex">
                              <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
                              <div className="info-text">
                                <span>{depertureDatas.city}</span>
                                <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
                                <span>{arrivalDatas.city}</span>
                                |
                                <span>{convertDate(depertureDatas.Date)}</span>
                              </div>
                              <picture><img src="assets/images/flight-result/pinicon.svg" alt="" /></picture>
                            </div>


                          </div>

                          <div className="flt-eco d-flex">

                            <div className="egypt-air">
                              <strong><img src={handleLogo(data.Airlinelists)} alt="" />{data.Airlinelists}</strong>
                              <div className="d-flex eco">
                                <span>{flightListData.Aircraft}</span> | <span>{flightListData.CabinClassText}</span>
                              </div>
                              <p>Operated by : {flightListData?.OperatingAirline?.name}</p>
                              <div className="flt-bag">
                                <div className="fl-dels inbg" onClick={() => { { handleFlightDetails() }; { filterDetails(data.Offercode) } }}><img src="assets/images/flight-result/copy.svg" alt="" />Flight
                                  Details
                                </div>
                                <div className="fl-dels"><img src="assets/images/flight-result/bag.svg" alt="" />
                                  {flightListData?.CheckinBaggage[0]?.Value}
                                </div>
                              </div>
                            </div>

                            <div className="flt-roots">
                              <div className="left">
                                <strong>{depertureDatas.time}</strong>
                                <span>{depertureDatas.city}</span>
                                <p>{depertureDatas.name}</p>
                              </div>
                              <div className="flt-middle-info">
                                <p>{lengths - 1 === 0 ? 'Direct' : `${lengths - 1} Stop`}
                                  ,{lengths > 1 ? `Layover : ${handleTimeChange(layover)} (${layovername}) ` : ''}
                                </p>
                                <div className="bordericons">
                                  <img src="assets/images/flight-result/fly-right.svg" alt="" />
                                  <img src="assets/images/flight-result/pin.svg" alt="" />
                                </div>
                                <p>Duration : {handleTimeChange(outbounddata.totaltime)}</p>
                              </div>
                              <div className="right">
                                <strong>{arrivalDatas.time}</strong>
                                <span>{arrivalDatas.city}</span>
                                <p>{arrivalDatas.name}</p>
                              </div>
                            </div>

                          </div>

                        </>
                      )
                    })
                  } */}
                  
                  {
                    data.OutboundInboundlist.map((outbounddata, index) => {
                      const lengths = outbounddata.flightlist.length;
                      const flightListData = outbounddata.flightlist[0];
                      const depertureDatas = outbounddata.flightlist[0].Departure;
                      const arrivalDatas = outbounddata.flightlist[lengths - 1].Arrival;
                      const layover = outbounddata?.flightlist[lengths - 1]?.connectiontime;
                      const layovername = outbounddata?.flightlist[0]?.Arrival?.Iata;
                      return (
                        <>
                          <div className="rbMid">
                            <div className='d-flex align-items-center'>
                              <div className="flightDepWrap">
                                <div className="flightDep">
                                  <i class={index === 0 ? "icon icon-Flight-Circle" : "icon icon-Flight-Circle return"}></i> {index === 0 ? "Departure" : "Return"}
                                </div>
                              </div>
                              <div className="routeTimeWrap">
                                <div className="routeTime">
                                  <span className='route'>{depertureDatas.city}</span>
                                  <i class="icon icon-Arrow"></i>
                                  <span className='route'>{arrivalDatas.city}</span>
                                  <span className='time leftSeparatorLine'>{convertDate(depertureDatas.Date)}</span>
                                  {/* <em> <img src="assets/images/flight-result/seat.svg" alt="" /> 9 Seats Left</em> */}
                                </div>
                              </div>
                              <div className='pinWrap'>
                                <i onClick={handlePinClick} class={`icon ${filterIcon}`}></i>
                                {/* <i onClick={() => handlePinClick("icon-Pin-fill")} class="icon icon-Pin-fill"></i> */}
                              </div>
                            </div>
                              <div className='suplier'>Sup : {flightListData?.supplier}</div>
                            </div>
                            <div className="rbBot">
                              <div className='planeInfo'>
                                <div className="planeName">
                                  {/* <img src={handleLogo(data.Airlinelists)} alt="AirlineLogo" className='img-fluid planeImage' />  */}
                                {handleLogo(flightListData?.MarketingAirline?.code) && (
                                  <img src={handleLogo(flightListData?.MarketingAirline?.code)} alt="AirlineLogo" className='img-fluid planeImage' /> 
                                )}
                                {flightListData?.MarketingAirline?.name}
                                </div>
                                <div className="planeCodeClassWrap"><span className='planeCode'>{flightListData?.MarketingAirline?.code}-{flightListData?.MarketingAirline?.number}</span><span className='planeClass leftSeparatorLine'>{flightListData.CabinClassText}</span></div>
                                <div className="operatedBy">Operated by : {flightListData?.OperatingAirline?.name}</div>
                                <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => { { handleFlightDetails() }; { filterDetails(data.tid) } }}><i class="icon icon-Pop"></i> Flight Details</span><span className='pieces'><i class="icon icon-Bag"></i> {flightListData?.CheckinBaggage[0]?.Value}</span></div>
                                
                              </div>
                              <div className={props.width ? 'airlineInfoactive' : 'airlineInfo'}>
                                <div className='deparrInfo'>
                                  <div className='deparrTime'>{depertureDatas.time}</div>
                                  <div className='deparrLocation'>{depertureDatas.city}</div>
                                  <div className='airportName'>{depertureDatas.name}</div>
                                </div>
                                <div className='layoverInfo text-center'>
                                  <div className='layoverDuration'>{lengths - 1 === 0 ? 'Direct' : `${lengths - 1} Stop`}{layover && layover!=="" && lengths > 1 ? `, Layover : ${handleTimeChange(layover)} (${layovername}) ` : ''}</div>
                                  <div className='layoverSeparator'><span></span></div>
                                  <div className='totalDuration'>Duration : {handleTimeChange(outbounddata.totaltime)}</div>
                                </div>
                                <div className='deparrInfo'>
                                  <div className='deparrTime'>{arrivalDatas.time}</div>
                                  <div className='deparrLocation'>{arrivalDatas.city}</div>
                                  <div className='airportName'>{arrivalDatas.name}</div>
                                </div>
                              </div>
                            </div>
                        </>
                      )
                    })
                  }
                </div>

              </>
            )
          })
        }
        {state.response && state.response.length === 0 &&(
          <div className='d-flex justify-content-center align-items-center noResultWrap'>
            <div className='text-center'>
              <img src={NoResults} alt="errorIcon" />
              <div className='noresult'>No results match your search criteria</div>
              <div className='yourSearch'>Try modifying your search</div>
              <div className='reset'><i class="icon icon-Reset"></i> Reset Filter</div>
            </div>
          </div>
        )}
        <div style={{ float: "right", visibility: "hidden" }}  ref={EndDivRef}>Loading...</div>
        </>
        )}
      </div>

      {/* Share Itinerary Popup Start Here.... */}

      <Modal
      show={shareItineraryShow}
      centered
      className='shareItineraryPopup'
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <div className='d-flex justify-content-between align-items-center'>
          <div className="shareItineraryTitle">Share Itinerary</div>
          <i class="icon icon-Cross" onClick={hideShareItinerary}></i>
        </div>
        <Form className='d-flex justify-content-between align-items-center mt-15 mb-30'>
          <Form.Group>
            <Form.Control type="text" placeholder='https://kwickfly.com/w/rts' />
          </Form.Group>
          <Button variant="primary" type="submit" className='copyBtn'>
            Copy <i class="icon icon-down-arrow"></i>
          </Button>
        </Form>
        <div className='d-flex'>
          <div className="socialIcon"><img src={Whatsap} alt="socialIcon" /></div>
          <div className="socialTitle">WhatsApp</div>
        </div>
        <div className='socialLine'></div>
        <div className='d-flex'>
          <div className="socialIcon"><img src={Messenger} alt="socialIcon" /></div>
          <div className="socialTitle">Messenger</div>
        </div>
      </Modal.Body>
    </Modal>


      {/* Flight details popup start.... */}
      <FlightDetailsPopup data={detailData} cur={props?.currency} modalShow={modalShow} closeModal={closeFlightDetails} />

      <section className="flt-detail-popup" style={{ display: flightDetails ? 'flex' : 'none' }}>
        <div className="inner-box">
          <div className="top-row d-flex">
            <span>Flight Details</span>
            <div className="closeicon" onClick={handleFlightDetails}><img src="assets/images/flight-result/cross.svg" alt="" /></div>
          </div>
          <div className="tabs-nav">
            <ul>
              <li className="active"><a onClick={() => handleDetailBox('Itineary')}> <img src="assets/images/home/plane.svg" alt="" /> Flight
                Itinerary</a></li>
              <li><a onClick={() => handleDetailBox('Baggage')} > <img src="assets/images/flight-result/Subtraction.svg" alt="" /> Baggage</a></li>
              <li><a onClick={() => handleDetailBox('Fare')}> <img src="assets/images/flight-result/money.svg" alt="" /> Fare Breakup</a></li>
            </ul>
          </div>

          <div className="tabs-content">
            <div className="tabshow" style={{ display: flightItineary ? 'block' : 'none' }}>
              <div className="flightmain-info">
                {detailData &&
                  detailData[0]?.OutboundInboundlist?.map((outbounddata) => {
                    const lengths = outbounddata?.flightlist?.length;
                    const depertureDatas = outbounddata?.flightlist[0]?.Departure;
                    const arrivalDatas = outbounddata?.flightlist[lengths - 1]?.Arrival;
                    return (
                      <>

                        <div className="flight-to">
                          <div className="flyinner d-flex">
                            <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
                            <div className="info-text">
                              <span>{depertureDatas?.city}</span>
                              <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
                              <span>{arrivalDatas?.city}</span>
                              |
                              <span>{depertureDatas?.Date}</span>
                            </div>
                            <picture><img src="assets/images/flight-result/pinicon.svg" alt="" /></picture>
                          </div>
                        </div>
                        <div className="flt-eco-popup-box">
                          {
                            outbounddata?.flightlist?.map((listdata) => {
                              return (
                                <>

                                  <div className="flt-eco d-flex">

                                    <div className="egypt-air">
                                      <strong>{detailData?.Airlinelists}</strong>
                                      <div className="d-flex eco">
                                        <span>{listdata?.MarketingAirline?.number}</span> | <span>{listdata?.CabinClassText}</span>
                                      </div>
                                      <p>Operated by : {listdata?.MarketingAirline?.name}</p>
                                      <div className="flt-bag">

                                        <div className="fl-dels"><img src="assets/images/flight-result/bag.svg" alt="" />{listdata?.CheckinBaggage[0]?.Value}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flt-roots">
                                      <div className="left">
                                        <strong>{listdata?.Departure?.time}</strong>
                                        <span>{listdata?.Departure?.city}</span>
                                        <p>{listdata?.Departure?.name}</p>
                                      </div>
                                      <div className="flt-middle-info">

                                        <div className="bordericons">
                                          <img src="assets/images/flight-result/fly-right.svg" alt="" />
                                          <p>Duration : {handleTimeChange(listdata?.FlightMinutes)}</p>
                                          <img src="assets/images/flight-result/pin.svg" alt="" />
                                        </div>

                                      </div>
                                      <div className="right">
                                        <strong>{listdata?.Arrival?.time}</strong>
                                        <span>{listdata?.Arrival?.city}</span>
                                        <p>{listdata?.Arrival?.name}</p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="Layovertext">
                                    <div className="timetext">Layover : {handleTimeChange(listdata?.connectiontime)}</div>
                                  </div>


                                </>
                              )
                            })
                          }
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
            <div className="tabshow" style={{ display: flightBaggage ? 'block' : 'none' }}>
              <div>
                {detailData &&
                  detailData[0]?.OutboundInboundlist?.map((outbound) => {
                    const lengths = outbound?.flightlist?.length;
                    const depertureDatas = outbound?.flightlist[0]?.Departure;
                    const arrivalDatas = outbound?.flightlist[lengths - 1]?.Arrival;
                    // console.log(outbound.flightlist)
                    return (
                      <>
                        <div className="flight-to">
                          <div className="flyinner d-flex">
                            <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
                            <div className="info-text">
                              <span>{depertureDatas?.city}</span>
                              <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
                              <span>{arrivalDatas?.city}</span>
                              |
                              <span>{depertureDatas?.Date}</span>
                            </div>
                            <picture><img src="assets/images/flight-result/pinicon.svg" alt="" /></picture>
                          </div>
                        </div>

                        <div className="dxbflex-row d-flex">
                          {
                            outbound?.flightlist?.map((listItem) => {
                              return (
                                <>
                                  <ul className="d-flex">
                                    <li>
                                      <small>{listItem?.Departure?.Iata}</small> <img src="assets/images/flight-result/rightarow.svg" alt="" />
                                      <small>{listItem?.Arrival?.Iata}</small>
                                    </li>
                                    <li>
                                      <small>Check In</small>
                                    </li>
                                    <li>
                                      <small>Cabin</small>
                                    </li>
                                    {
                                      listItem?.CheckinBaggage?.map((baggage) => {
                                        return (
                                          <>
                                            <li><span>{baggage?.Type}</span></li>
                                            <li><span>{listItem?.CabinClassText}</span></li>
                                            <li><span>{baggage?.Value}</span></li>
                                          </>
                                        )
                                      })
                                    }

                                  </ul>
                                </>
                              )
                            })
                          }
                        </div>
                        {/* <p className="ckib"><em>Check-in Baggage :</em> Economy class: 1 piece, up to 23kg | usiness class: 1
                          piece, up
                          to 32kg.</p> */}
                      </>
                    )
                  })
                }
              </div>
            </div>

            <div className="tabshow" style={{ display: flightFare ? 'block' : 'none' }}>
              {
                detailData &&
                detailData[0]?.OutboundInboundlist?.map((outbound) => {
                  const lengths = outbound?.flightlist?.length;
                  const depertureDatas = outbound?.flightlist[0]?.Departure;
                  const arrivalDatas = outbound?.flightlist[lengths - 1]?.Arrival;
                  return (
                    <>
                      <div className="flight-to">
                        <div className="flyinner d-flex">
                          <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
                          <div className="info-text">
                            <span>{depertureDatas?.city}</span>
                            <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
                            <span>{arrivalDatas?.city}</span>
                            |
                            <span>{depertureDatas?.Date}</span>
                          </div>
                          <picture><img src="assets/images/flight-result/pinicon.svg" alt="" /></picture>
                        </div>
                      </div>
                      <div className="infotable">
                        {
                          outbound?.flightlist?.map((list) => {
                            return (
                              <>
                                <table>
                                  <tr>
                                    <th>Pax Type</th>
                                    <th>Base Fare</th>
                                    <th>Fee & Tax</th>
                                    <th>No. Of Pax</th>
                                    <th>Total Per Pax</th>
                                    <th>Total</th>
                                  </tr>

                                  {
                                    list?.CheckinBaggage?.map((baggage, id) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{baggage.Type}</td>
                                            <td>{currency} {detailData[0]?.price.net_base_price}</td>
                                            <td>{currency} {detailData[0]?.price.tax_price}</td>
                                            <td>{detailData[0].price?.tarriffinfo[id]?.quantity}</td>
                                            <td>{currency} {detailData[0]?.price.tarriffinfo[id]?.per_pax_total_price} </td>
                                            <td>{currency} {detailData[0]?.price.tarriffinfo[id]?.totalprice}</td>
                                          </tr>
                                        </>
                                      )
                                    })
                                  }

                                </table>
                              </>
                            )
                          })
                        }
                      </div>
                    </>
                  )
                })
              }

            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default FlightDetails
