import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    departure: [],
    arrival: [],
    adult: 1,
    children: 0,
    childAges: [],
    infant: 0,
    departureDate: '',
    returnDate: '',
    ClassType: 'Economy',
    tripWay: 'round',
    currency: 'PKR',
    ns: false,
    baggage: false,
    refundable: false,
    prefAirlines: [],
    multicity: [
      {
        departure: [],
        arrival: [],
        departureDate: '',
      },
      {
        departure: [],
        arrival: [],
        departureDate: '',
      }
    ]
  }
}

export const SearchFlightSlice = createSlice({
  name: 'searchflight',
  initialState,
  reducers: {
    handleMulticity: (state, action) => {
      if (state.value.multicity.length < 6) {
        const newCity = state.value.multicity.length + 1;
        state.value.multicity = [
          ...state.value.multicity, {
            departure: [],
            arrival: [],
            departureDate: '',
          }
        ]
      } else {
        alert('You exceed the limit')
      }

    },
    handleRemoveMulticity: (state, action) => {
      const indexToRemove = action.payload
      if (indexToRemove !== -1) {
        state.value.multicity.splice(indexToRemove, 1);
      }
      const values = state.value.multicity

    },
    setMultiDeparture: (state, action) => {
      console.log(action.payload.index, action.payload.code)
      state.value.multicity[action.payload.index].departure = action.payload.code;
    },
    setMultiArrival: (state, action) => {
      console.log(action.payload.index, action.payload.code)
      state.value.multicity[action.payload.index].arrival = action.payload.code;
    },
    setMultiDeparturedate: (state, action) => {
      console.log(action.payload.index, action.payload.value)
      state.value.multicity[action.payload.index].departureDate = action.payload.date;
    },
    increaseAdult: (state) => {
      if (state.value.adult <= 5) {
        state.value.adult += 1
      } else {
        alert("you reached the maximum limit...")
      }
    },
    decreaseAdult: (state) => {
      if (state.value.adult >= 2) {
        state.value.adult -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    increaseChildren: (state) => {
      if (state.value.children <= 2) {
        state.value.children += 1
      } else {
        alert("you reached the maximum limit...")
      }
    },
    decreaseChildren: (state) => {
      if (state.value.children >= 0 && state.value.children !== 0) {
        state.value.children -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    increaseInfant: (state) => {
      if (state.value.infant <= 1) {
        state.value.infant += 1
      } else {
        alert("you reached the maximum limit...")
      }
    },
    decreaseInfant: (state) => {
      if (state.value.infant >= 1) {
        state.value.infant -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    setDeparturedate: (state, action) => {
      const departuree = action.payload;
      state.value.departureDate = departuree;
    },
    setReturnDate: (state, action) => {
      const returndate = action.payload;
      state.value.returnDate = returndate;
    },
    setChildAges: (state, action) => {
      const fst = action.payload.newData;
      state.value.childAges[action.payload.index] = fst;
    },
    setClassType: (state, action) => {
      const classT = action.payload;
      state.value.ClassType = classT;
    },
    setDepartureDatas: (state, action) => {
      const dept = action.payload;
      state.value.departure = dept;
    },
    setArrivalDatas: (state, action) => {
      const arrive = action.payload;
      state.value.arrival = arrive;
    },
    setTripWays: (state, action) => {
      const trip = action.payload;
      state.value.tripWay = trip;
    },
    setTotalAdults: (state, action) => {
      const adt = action.payload;
      state.value.adult = adt;
    },
    setTotalInfants: (state, action) => {
      const infant = action.payload;
      state.value.infant = infant;
    },
    setTotalChildrens: (state, action) => {
      const child = action.payload;
      state.value.children = child;
    },
    setCurrency: (state, action) => {
      const cur = action.payload;
      state.value.currency = cur;
    },
    setNs: (state, action) => {
      const ns = action.payload;
      state.value.ns = ns;
    },
    setRefundable: (state, action) => {
      const refundable = action.payload;
      state.value.refundable = refundable;
    },
    setBaggage: (state, action) => {
      const baggage = action.payload;
      state.value.baggage = baggage;
    },
    setPrefAirlines: (state, action) => {
      const airlines = action.payload;
      state.value.prefAirlines = airlines;
    },
  },

})
export const {
  increaseAdult,
  decreaseAdult,
  increaseChildren,
  decreaseChildren,
  increaseInfant,
  decreaseInfant,
  setDeparturedate,
  setReturnDate,
  setChildAges,
  setClassType,
  setDepartureDatas,
  setArrivalDatas,
  setTripWays,
  setTotalAdults,
  setTotalChildrens,
  setTotalInfants,
  setCurrency,
  setNs,
  setPrefAirlines,
  setRefundable,
  setBaggage,
  handleMulticity,
  setMultiArrival,
  setMultiDeparturedate,
  setMultiDeparture,
  handleRemoveMulticity,
} = SearchFlightSlice.actions
export default SearchFlightSlice.reducer