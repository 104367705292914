
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
function Amenities(props) {
    const [state, setState] = useState({})
    const [showAmenities, setShowAmenities] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
                    <div className='servicesTitle'>Most Popular Amenities</div>
                    {props.amenities !== undefined &&
                        <>
                            <ul className='servicesWrap'>
                                {Object.keys(props.amenities).map(key => (
                                    <li value={key.name}><i className="icon icon-tick" title={props.amenities[key].name}></i><span>{props.amenities[key].name}</span></li>
                                )).slice(0, showAmenities ? props.amenities.length : '12')}
                            </ul>
                        </>
                    }
                    {Object.keys(props.amenities).length >= 13 ?
                        <div className="moreServices" variant="link" onClick={() => setShowAmenities(!showAmenities)}>
                            {showAmenities ? "- Show less" : "+ Show All"}
                        </div> : ''}
        </>
    )
}
export default Amenities;
