import React, { useState } from 'react'
import { handleAirportSearch } from '../../bookingflow/Flights/common/AirportValid'
import { useDispatch } from 'react-redux'
import { setMultiDeparture } from '../../../toolkit/Slices/SearchFlightSlice';
import { Form} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function AutoCompleteDep(props) {
  const index = props.index;
  const dispatch = useDispatch();
  const [origin, setOrigin] = useState([])
  const [originSearch, setOriginSearch] = useState('')
  const [showOrigin, setShowOrigin] = useState(false)
  const [sourceClearEnable, setSourceClearEnable] = useState(false);
  // handles origin search....
  const handleShowOrigin = () => {
    setShowOrigin(!showOrigin)
  }
  const handleOrigin = (e) => {
    const search = e.target.value;
    if (search.length >= 3) {
      const response = handleAirportSearch(search)
      response.then(function (result) {
        if (result?.data?.suc) {
          setOrigin(result.data?.res?.data);
          setShowOrigin(true)
        } else {
          setOrigin([]);
        }
      }).catch(error => {
        console.log("Error occurred in Predective search", error)
      });
    } else if (search.length === 0){
      setOrigin([]);
      setShowOrigin(false);
      setSourceClearEnable(false);
  }
  }
  const handleOriginSearch = (code) => {    
    const value = origin.find(item => item.selected_data.cityname == code.cityname)
    setOriginSearch(code?.cityname + "(" + code?.iata + ")" + ", " + code?.name)
    handleShowOrigin(false)
    dispatch(setMultiDeparture({ index, code }))
  }

  //For clearing the source field
  const clearSource = () => {
    setOrigin([]);
    setOriginSearch('');
    handleShowOrigin(false)
    setSourceClearEnable(false);
  };

  return (
    <>
      <Form.Group className="destFrom position-relative">
      <Form.Label><i class="icon icon-flight"></i>From</Form.Label>
        <Form.Control type="text" placeholder="Origin" value={originSearch}
          onChange={(e) => { { setOriginSearch(e.target.value); setSourceClearEnable(true) }; handleOrigin(e) }}
        />
        <div className="autolist predictiveWrap" style={{ display: showOrigin ? "block" : 'none' }}>
          <ul className="borderrow verticalScroll">
            {origin?.length > 0 &&
              origin?.map((items, i) => {
                return (
                  <>
                   <li key={i}>
                    <Accordion defaultActiveKey="0" className='predictveSearchCollapse'>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <div className="cityNameWrap"><i class="icon icon-flight"></i>{items.selected_data.cityname} <span className='allAirportsTitle'>(All airports)</span> <span className='countryCode'>{items.selected_data.citycode}</span></div>
                              <div className='countryName'>{items.selected_data.countryname}</div>
                            </div>
                            <div className='d-flex'>
                              <i class="icon icon-down-arrow"></i>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        {items.selected_data.airports.map((air, idx) => (
                          <div className='airportName' key={idx} onClick={() => { handleOriginSearch(air) }}><i class="icon icon-flight"></i>{air.name} <span className='countryCode'>{air.iata}</span></div>
                        ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>                        
                    {items.nearby_routes.map((near, idx) => (
                      <div className='nearByWrap' key={idx} onClick={() => { handleOriginSearch(near) }}>
                        <div className='airportName'><i class="icon icon-flight"></i>{near.name} <span className='countryCode'>{near.iata}</span></div>
                        <div className='nearbyCity'><span className='nearByTitle'>Near by</span> <span className='cityName'>{near.cityname}</span> <span className='distance'>{near.distance}</span></div>
                      </div>
                    ))}
                  </li>
                  </>
                )
              })
            }
          </ul>
        </div>
        {sourceClearEnable && (
          <span className='removePrediSearch' onClick={clearSource}>
            <i class="icon icon-Close"></i>
          </span>
        )}
      </Form.Group>
    </>
  )
}

export default AutoCompleteDep
