import React, { useEffect, useState, useContext } from 'react';
import { Form, Col, Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import { Typeahead } from 'react-bootstrap-typeahead';
import InputRange from 'react-input-range';
import { Context } from '../../../../App';
import {getNumberFormat} from '../../../utils/validators'
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

/**
 * @description:Rooms Details info
 * @author: Praveen Varma
 * @param {*}
 * @function Room Price details
 * @date : 15-05-2024
 */
function HotelResultDetails({ hotels, fetchRooms, guests }) {
  const [context, setContext] = useContext(Context);
  const [hotelNamesFilter, setHotelNamesFilter] = useState([]);
  const [starFilter, setStarFilter] = useState([]);
  const [ameniesFilter, setAmenitiesFilter] = useState([]);
  const [originalData, setOriginalData] = useState(hotels?.prpts);
  const [filteredResults, setFilteredResults] = useState([]);
  const [price_filter, set_price_filter] = useState({ min: 0, max: 0 });

  const [filter_htl_name, set_filter_htl_name] = useState([]);
  const [filter_star, set_filter_star] = useState();
  const [filter_price, set_filter_price] = useState({ min: 0, max: 0 });
  const [filter_ameniites, set_filter_amenities] = useState([]);
  const [sort_price, set_sort_price] = useState("rec");
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [cur, setUserCur] = useState("");
  const [showMoreAmenities, setShowMoreAmenities] = useState(false);


  useEffect(() => {
    if (sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({ ...context, userCur: sessionStorage.getItem("SEL_CUR") });
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])


  useEffect(() => {
    var userCur = context.userCur !== undefined && context.userCur !== "" ? context.userCur : sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("KF_EX_RATES"))
    if (context.ex_rates === undefined || context.ex_rates === "") {
      setContext({ ...context, ex_rates: exRates })
    }
    if ((context.ex_rates !== undefined && context.ex_rates !== "") || (exRates !== undefined && exRates !== "") && userCur !== undefined && userCur !== "") {
      if (BASE_CURRENCY !== userCur) {
        let currecny = BASE_CURRENCY + "-" + userCur;
        getExchangeRate(currecny)
      } else if (BASE_CURRENCY === userCur) {
        setBaseToUserExrate(1)
      }
    } else {
      setBaseToUserExrate(1)
    }
  }, [context.userCur])

  useEffect(() => {
    if (context.ex_rates !== "" && context.ex_rates !== undefined) {
      let currecny = BASE_CURRENCY + "-" + cur;
      getExchangeRate(currecny)
    }
  }, [context.ex_rates])

  const getExchangeRate = (usrCur) => {
    let sup =1;
    if (context.ex_rates !== "" && context.ex_rates !== undefined && context.ex_rates !== null) {
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
    set_price_filter({
      min: Number(hotels?.filters?.price?.min),
      max: Number(hotels?.filters?.price?.max)
    });
    set_filter_price({
      min: Number(hotels?.filters?.price?.min),
      max: Number(hotels?.filters?.price?.max)
    });
  }


  useEffect(() => {
    setFilteredResults(originalData);
    setHotelNamesFilter(hotels?.filters?.names || []);
    setStarFilter(hotels?.filters?.stars || []);
    setAmenitiesFilter(hotels?.filters?.amenities || []);
    set_price_filter({
      min: (base_usr_exrate * Number(hotels?.filters?.price?.min))?.toFixed(2),
      max: (base_usr_exrate * Number(hotels?.filters?.price?.max))?.toFixed(2),
    });
    set_filter_price({
      min: (base_usr_exrate * Number(hotels?.filters?.price?.min))?.toFixed(2),
      max: (base_usr_exrate * Number(hotels?.filters?.price?.max))?.toFixed(2),
    });
  }, []);

  useEffect(() => {
    let res = originalData;
    if (filter_htl_name && filter_htl_name.length > 0) {
      res = res?.filter(itm => itm.pid === filter_htl_name[0].pid);
    }
    if (filter_star && filter_star.length > 0) {
      res = res?.filter(itm => filter_star.includes(Number(itm?.con?.StarCategoryId)));
    }
    if (filter_price !== undefined && filter_price !== null && filter_price !== "" && filter_price?.min > 0) {
      res = res?.filter(fi => Number(filter_price?.min) <= Number(fi?.pr?.tf));
      res = res?.filter(fi => Number(filter_price?.max) >= Number(fi?.pr?.tf));
    }
    if (filter_ameniites !== null && filter_ameniites.length > 0) {
      let modify_prpts = [];
      res?.forEach(pr => {
        if (pr?.con?.topAmenities !== undefined && pr?.con?.topAmenities !== null
          && pr?.con?.topAmenities !== "" && Object.keys(pr?.con?.topAmenities).length > 0) {
          let topAmenities = Object.values(pr?.con?.topAmenities);
          if (topAmenities !== undefined && topAmenities !== null && topAmenities.length > 0) {
            topAmenities?.forEach(fil => {
              if (fil !== null && filter_ameniites.includes(fil)) {
                let is_exist = modify_prpts.filter(mo => mo.pid === pr.pid);
                if (is_exist?.length === 0) {
                  modify_prpts.push(pr);
                }
              }
            })
          }
        }
      })
      res = modify_prpts;
    }
    if (sort_price !== undefined && sort_price !== "" && sort_price !== null) {
      if (sort_price === "lth") {
        res = [...res].sort((a, b) => Number(a?.pr?.tf) - Number(b?.pr?.tf));
      } else if (sort_price === "htl") {
        res = [...res].sort((a, b) => Number(b?.pr?.tf) - Number(a?.pr?.tf));
      }
    }
    setFilteredResults(res);
  }, [filter_htl_name, filter_star, filter_price, filter_ameniites, sort_price]);


  useEffect(() => {
    //sorting_prices();
  }, [sort_price])

  const sorting_prices = async () => {
    let resp = filteredResults;
    if (sort_price !== undefined && sort_price !== "" && sort_price !== null && resp !== undefined && resp !== null && resp !== "" && resp.length > 0) {
      if (sort_price === "lth") {
        resp = [...resp].sort((a, b) => Number(a?.pr?.tf) - Number(b?.pr?.tf));
      } else if (sort_price === "htl") {
        resp = [...resp].sort((a, b) => Number(b?.pr?.tf) - Number(a?.pr?.tf));
      }
    }
    setFilteredResults(resp);
  }

  const getHotelImage = (images) => {
    let img = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJRS-4chjWMRAmrtz7ivK53K_uygrgjzw9Uw&s";
    if (images !== undefined && images !== null && images !== null) {
      if (images.length > 0) {
        img = images[0];
      } else if (images.length > 1) {
        img = images[1];
      } else if (images.length > 2) {
        img = images[2];
      }
    }
    if (img !== null && img !== "") {
      img = img.replace("_t", "_z");
    }
    return img;
  }

  const getStarRating = (star) => {
    let ary = [];
    if (star !== undefined && star !== null && star !== "") {
      for (var s = 0; s < Number(star); s++) {
        ary.push(s);
      }
    }
    return ary
  }

  const filterApplyPrice = (e) => {
    set_filter_price(e);
  };

  const options = [
    { value: 'rec', label: 'Recommended' },
    { value: 'lth', label: 'Low to High' },
    { value: 'htl', label: 'High to Low' },
  ];

  return (
    <>
      <section className='pb-30'>
        <div className="mainContainer">
          <Row>
            <Col>
              <div className='d-flex'>
                <div className="filterSideWrap">
                  <div className="appliedFilter mt-20">
                    <div className="d-flex justify-content-between">
                      <span className='appliedFilterTitle'>Applied Filters</span>
                      <span className='appliedFilterClear' onClick={() => {
                        set_filter_star([]);
                        set_filter_amenities([]);
                        set_filter_htl_name([]);
                        set_sort_price("");
                        set_filter_price({
                          min: hotels?.filters?.price?.min,
                          max: hotels?.filters?.price?.max
                        });
                      }}>Clear All</span>
                    </div>
                    <div className="d-flex flex-wrap columnGap">
                      {filter_star?.length > 0 &&
                        <div className="appliedFilterResult">Star <i class="icon icon-Close" onClick={() => {
                          set_filter_star([]);
                        }}></i></div>
                      }
                      {filter_ameniites?.length > 0 &&
                        <div className="appliedFilterResult">Amenities <i class="icon icon-Close" onClick={() => {
                          set_filter_amenities([]);
                        }}></i></div>
                      }
                      {filter_htl_name?.length > 0 &&
                        <div className="appliedFilterResult">Hotel name <i class="icon icon-Close" onClick={() => {
                          set_filter_htl_name([]);
                        }}></i></div>
                      }
                      {sort_price !== undefined && sort_price !== null && sort_price !== "" &&
                        <div className="appliedFilterResult">Sort by Price <i class="icon icon-Close" onClick={() => {
                          set_sort_price("");
                        }}></i></div>
                      }

                      {/* <div className="appliedFilterResult">Breakfast Included <i class="icon icon-Close"></i></div>
                      <div className="appliedFilterResult">Free Internet <i class="icon icon-Close"></i></div> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-20">
                    <span className='filterTitle'>Filters</span>
                    <span className='clearFilterTitle' onClick={() => {
                      set_filter_star([]);
                      set_filter_amenities([]);
                      set_filter_htl_name([]);
                      set_sort_price("");
                      set_filter_price({
                        min: hotels?.filters?.price?.min,
                        max: hotels?.filters?.price?.max
                      });
                    }}>Clear filters</span>
                  </div>
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Hotel Name <span onClick={() => set_filter_htl_name([])}>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        <Form>
                          <Form.Group className='position-relative searchByFilterWrap'>
                            <div className="search filterSearch d-flex align-items-center">
                              <Typeahead
                                id="htllistid"
                                labelKey={htl => `${htl.hname}`}
                                options={hotelNamesFilter && hotelNamesFilter.length > 0 ? hotelNamesFilter : []}
                                onChange={htl => set_filter_htl_name(htl)}
                                placeholder="Search by hotel name"
                                selected={filter_htl_name}
                                name="names_filter"
                                readOnly
                                minLength={1}
                                clearable
                                renderMenuItemChildren={(htl) => (<> <span title={htl.hname}>{htl.hname}</span> </>)} />
                              {/* <button className="searchIcon ezyIcon icon-search"></button> */}
                              {filter_htl_name && filter_htl_name.length > 0 &&
                                <i class="icon icon-Close" onClick={() => set_filter_htl_name([])}></i>
                              }
                            </div>
                          </Form.Group>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {price_filter !== null &&
                    <Accordion defaultActiveKey="0" >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><i class="icon icon-down-arrow"></i> Price <span onClick={() => set_filter_price({
                          min: hotels?.filters?.price?.min,
                          max: hotels?.filters?.price?.max
                        })}>Clear</span></Accordion.Header>
                        <Accordion.Body>
                          <InputRange
                            formatLabel={(value) => `${cur} ${value}`}
                            allowSameValues={true}
                            maxValue={price_filter?.max}
                            minValue={price_filter?.min}
                            value={filter_price}
                            onChangeComplete={(value) => set_filter_price(value)}
                            onChange={(value) => filterApplyPrice(value)}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  }

                  {starFilter?.length > 0 &&
                    <Accordion defaultActiveKey="0" >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><i class="icon icon-down-arrow"></i> Star Rating <span onClick={() => {
                          set_filter_star([])
                        }}>Clear</span></Accordion.Header>
                        <Accordion.Body>
                          <div className="hotelStarRating">
                            {starFilter?.map((itm) => {
                              return (
                                <>
                                <div className="starRatingBox">
                                  <input type="checkbox" checked={filter_star?.includes(itm)} aria-label="option 1" id={itm} name="star_filter" onClick={(event) => {
                                    let star_itms = filter_star ? [...filter_star] : [];
                                    if (event?.target?.name === "star_filter") {
                                      if (event?.target?.checked) {
                                        star_itms.push(itm);
                                      } else {
                                        star_itms = star_itms.filter(str => str !== itm);
                                      }
                                      set_filter_star(star_itms);
                                    }
                                  }} />
                                  <label for={itm} className='starRatingInnerBox'>
                                    <span>{itm}</span>
                                    <i class="icon icon-star"></i>
                                  </label>
                                </div>
                                </>
                                )
                            })}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  }

                  {ameniesFilter?.length > 0 &&
                    <Accordion defaultActiveKey="0" >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><i class="icon icon-down-arrow"></i> Aminities <span onClick={() => set_filter_amenities([])}>Clear</span></Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            {ameniesFilter?.map((am) => {
                              return (
                                <div key={`inline-checkbox`} className="inlineCheckboxWrap d-flex justify-content-between">
                                  <Form.Check
                                    inline
                                    label={am?.name}
                                    checked={filter_ameniites?.includes(am?.name)}
                                    name="filter_amenities"
                                    onChange={(e) => {
                                      let fil = [...filter_ameniites];
                                      if (e.target.checked) {
                                        if (!fil.includes(am?.name)) {
                                          fil.push(am?.name)
                                          set_filter_amenities(fil);
                                        }
                                      } else {
                                        fil = fil.filter((itm) => itm !== am?.name);
                                        set_filter_amenities(fil);
                                      }
                                    }}
                                    type="checkbox"
                                    id={`inline-checkbox-1`}
                                  />
                                  <div className="filterNum">{am?.count}</div>
                                </div>
                              )
                            }).slice(0, showMoreAmenities ? ameniesFilter.length: '5')}
                          </Form>
                          {ameniesFilter && ameniesFilter?.length >= 5 &&
                            <div className="moreWrap" onClick={() => setShowMoreAmenities(!showMoreAmenities)}>
                              {showMoreAmenities ? "- Show less" : "+ Show More"}
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  }
                </div>
                <div className="resulSideWrap">
                  <div className='hotelSorting'>
                    <div className="showingProperty">Showing {filteredResults?.length || 0} of {filteredResults?.length || 0} Property</div>
                    <div className='recommBoxWrap'>
                      <div className="recommBox d-flex">
                        <i class="icon icon-Sort"></i>
                        <Select
                          value=""
                          options={options}
                          placeholder={sort_price === "lth" ? "Low to High" : (sort_price === "htl" ? "High to Low" : "Recommended")}
                          onChange={(e) => {
                            set_sort_price(e?.value)
                          }}
                        />
                      </div>
                      {/* <div className="recommBox">
                        <i class="icon icon-Dollar"></i>
                        Price per room per night
                        <i class="icon icon-down-arrow"></i>
                      </div> */}
                    </div>
                  </div>
                  {filteredResults?.map((itm, index) => {
                    return (
                      <>
                        <div className="hotelResultBlock">
                          <div className="hotelImage">
                            <span className='hotelTag'>{itm?.con?.category ? itm?.con?.category : "Hotel"}</span>
                            <img src={getHotelImage(itm?.con?.images)} alt="hotelImage" />
                          </div>
                          <div className="hotelDetails">
                            <div className="leftDetails">
                              <div className="d-flex align-items-center">
                                <div className="hotelName" title={itm.hname}>
                                  {itm.hname}
                                </div>
                                <div className="hotelRating">
                                  {getStarRating(itm?.con?.StarCategoryId).map((mp) => {
                                    return (
                                      <>
                                        <i class="icon icon-star"></i>
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                              <div className="hotelLocationWrap" title={itm?.con?.Address}>
                                <i class="icon icon-Pointer"></i>{itm?.con?.Address} {/* <span className='showonMap'><span className='dot'></span>Show on Map</span> */}
                              </div>
                              {/* {itm?.con?.brdbs && itm?.con?.brdbs?.length > 0 &&
                                <div className="hotelroomWrap">
                                  <div className="roomType">
                                    {itm?.con?.brdbs.toString()}
                                  </div>
                                </div>
                              } */}
                              {itm?.con?.attractions?.nearby &&
                                <div className="hotelroomWrap">
                                  <div className="roomType">
                                    Near by: {itm?.con?.attractions?.nearby}
                                  </div>
                                  {/* <div className="freeCancel">
                                    <i class="icon icon-cancellation-tag"></i> Free Cancellation
                                  </div> */}
                                </div>
                              }
                              {itm?.con?.ha !== undefined && itm?.con?.ha?.length > 0 &&
                                <div className="aminitiesWrap">
                                  {itm?.con?.ha?.includes("Free Breakfast") &&
                                    <div className="aminities">
                                      <i class="icon icon-Breakfast"></i> Free Breakfast
                                    </div>
                                  }
                                  {itm?.con?.ha?.includes("WIFI") &&
                                    <div className="aminities">
                                      <i class="icon icon-wifi"></i> Free Wifi
                                    </div>
                                  }
                                  {itm?.con?.ha?.includes("POOL") &&
                                    <div className="aminities">
                                      <i class="icon icon-pool"></i> Swimming pool
                                    </div>
                                  }
                                  {itm?.con?.ha?.includes("GYM") &&
                                    <div className="aminities">
                                      <i class="icon icon-gym"></i> Gym
                                    </div>
                                  }
                                  {itm?.con?.ha?.includes("BAR") &&
                                    <div className="aminities">
                                      <i class="icon icon-bar"></i> Bar
                                    </div>
                                  }
                                  {itm?.con?.ha?.includes("Fitness facilities") &&
                                    <div className="aminities">
                                      <i class="icon icon-pool"></i> Fitness facilities
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                           
                            <div className="rightDetails">
                              {itm?.con?.urat &&
                                <div className="reviewsWrap">
                                  <span className="reviewsIcons">
                                    {getStarRating(itm?.con?.urat).map((mp) => {
                                      return (
                                        <>
                                          <i class="icon icon-star"></i>
                                        </>
                                      )
                                    })}
                                  </span>
                                  <span className="reviewTitle">
                                    {itm?.con?.expediareview} Reviews
                                  </span>
                                </div>
                              }
                              <div className="hotelPrice">
                                {cur} {getNumberFormat((itm?.pr?.tf * base_usr_exrate).toFixed(2))}
                              </div>
                              <div className="roomTaxes">
                                {guests ?
                                  `${guests.rms} Rooms x ${guests.nights} Nights incl. taxes` : ""
                                }
                              </div>
                              <Button variant="primary showroomBtn" onClick={(e) => fetchRooms(itm)}>Show Rooms<i class="icon icon-down-arrow"></i></Button>
                            </div>
                            
                          </div>
                        </div>
                      </>
                    )
                  })}

                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default HotelResultDetails
