import React, { useEffect, useState } from "react";
import Select from 'react-select';

/**
 * @description:This function will creaet all the Add Baggage details
 * @author: Ubedullah
 * @param {*}
 * @function Baggagr details
 * @date : 04-07-2024
 */

const AddBaggage = (props) => {
  const [bagDefault, setBagDefault] = useState([]);
  const [bagOptions, setBagOptions] = useState([]);

  useEffect(() => {
    if (props.bagList !== undefined) {
      if (props.bagList.includes("Select")) {
        setBagDefault([]);
      } else {
        if (
          props.bagList.length !== 0 &&
          props.bagList[0] !== undefined &&
          props.bagList[0].code !== undefined
        ) {
          setBagOptions(props.bagList);
          setBagDefault({
            value: props.bagList[0]?.code,
            label: props.bagList[0]?.label,
          });
        } else {
          for (let data of props.bagList) {
            setBagOptions(data);
            setBagDefault({ value: data[0]?.code, label: data[0].label });
          }
        }
      }
    }
  }, []);

  const setBaggagePrice = (value) => {
    const obj = bagOptions.find((option) => option.code === value.code);
    props.sendBagprice(obj);
    if (!props.bagList.includes("Select")) {
      setBagDefault(value);
    }
  };

  return (
    <>
    <div className="addBaggageWrap">
        <Select 
            options={bagOptions}
            values={bagDefault} 
            onChange={setBaggagePrice}
            placeholder="Select Baggage"
            > 
            {bagOptions.map((option) => {
              return (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>
      
      </div>
    </>
  );
};

export default AddBaggage;