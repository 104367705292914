import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import FormateCurrency from "../../../utils/FormateCurrency";
import { isArrayEmpty } from "../../../../utilities/Validators";
import Utilities from "../../../../utilities/Utilities";
import { isArrayNotEmpty } from "../../../utils/validators";

function FareDetails(props) {
  const [fareInfo, serFareInfo] = useState("");

  useEffect(() => {
    if(props.fare_info && props.fare_info?.pasngrs && props.fare_info?.pasngrs[0]?.pax && props.fare_info?.pasngrs[0]?.pax?.length !== 0) {
      serFareInfo(props.fare_info?.pasngrs[0]?.pax)
    }
  }, [props.fare_info])

  const totalSales = (data) => {
    let total = 0;
    if(isArrayNotEmpty(data)) {
      for (let val of data) {
        if(total === 0) {
          total = Utilities.addPrice(val.sales.total, 0, "Add");
        } else {
          total = Utilities.addPrice(total, val.sales.total, "Add");
        }
      }
    }
    return Number(total);
  }

  return (
    <>
      <div className="confirmFareWrap">
        <div class="leftSemicircle"></div>
        <div className="confirmFareTitle">Fare Details</div>
        <Accordion defaultActiveKey="0" className='tripSumCollapse'>
          {fareInfo && fareInfo.length !== 0 && fareInfo.map((fare,index) => (
            <Accordion.Item eventKey={index} className='travellerItem'>
            <Accordion.Header className='tripSumHeader travellerHeader'>
                <div className="d-flex justify-content-between align-items-center">
                <div className="travellerType"><i class="icon icon-down-arrow"></i> Traveller {index + 1} ({fare.ptype === "adult" ? "ADT" : fare.ptype === "child" ? "CHD" : "INF"})</div>
                <div className="currency">{props.cur} <FormateCurrency value={fare?.sales?.total} /></div>
                </div>
            </Accordion.Header>
            <Accordion.Body className='fareDetailBody'>
                <div className="d-flex justify-content-between align-items-center mb-12">
                <div className="fareDetailBodyTitle">Base Fare</div>
                <div className="currAmnt">{props.cur} <FormateCurrency value={fare?.sales?.basefare} /></div>
                </div>
                {fare?.sales?.taxes !== 0 && (
                <div className="d-flex justify-content-between align-items-center">
                <div className="fareDetailBodyTitle">Taxes $ Fees <i class="icon icon-Info"></i></div>
                <div className="currAmnt">{props.cur} <FormateCurrency value={fare?.sales?.taxes} /></div>
                </div>
                )}
            </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {/* <div className="vatWrap">
            <div className="vatTitle">VAT</div>
            <div className="vatValue">{props.cur} 100.00</div>
        </div>
        <div className="discountWrap">
            <div className="discountTitle">Discount</div>
            <div className="discountValue">- {props.cur} 10.00</div>
        </div> */}
        <div className="payableAmntWrap">
            <div className="payableAmntTitle">Total Payable Amount</div>
            <div className="currAmnt">{props.cur} <FormateCurrency value={totalSales(fareInfo)} /></div>
        </div>
        {/* <div className="installmentTitle"><i class="icon icon-tick"></i> Installments</div> */}
        <div class="rightSemicircle"></div>
    </div>
    </>
  );
}

export default FareDetails;
