import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { useLocation } from 'react-router-dom';
import HotelPaymentDetails from './HotelPaymentDetails';

/**
 * @author: Praveen Varma
 * @param {*}
 * @description Hotel payment
 * @date : 29-07-2024
 */
function HotelPayment(props) {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState(
        location?.state !== null && location?.state?.req !== undefined && location?.state?.req !== null 
         ? location.state.req : ""
    );
    const [prebook, setPrebook] = useState(
        location?.state !== null && location?.state?.resp !== undefined && location?.state?.resp !== null 
            ? location.state.resp : ""
    );
    const [itinerary_info, setItinerary_info] = useState(
        location?.state !== null && location?.state?.itinerary !== undefined && location.state.itinerary !== null
            ? location.state.itinerary : ""
    );

    useEffect(() => {
        setShow(true);
    }, [])
    return (
        <>
            <div className="fixedHeader">
                <Header page="fixedHeader" />
            </div>
            {show &&
                <HotelPaymentDetails bookingId={prebook?.bookingId} itinerary_info={itinerary_info} transId={prebook?.trans_id} />
            }
            <Footer />
        </>
    )
}

export default HotelPayment
