import React, { useState } from 'react'
import FlightReviewBar from '../common/FlightReviewBar';
import FlightPaymentDetails from './FlightPaymentDetails';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { useLocation } from 'react-router-dom';

function FlightPayment() {
  const location = useLocation();
  const [request, setRequest] = useState(
    location.state!==null && location.state.req !== null &&
      location.state.req &&
      location.state.req !== undefined
      ? location.state.req
      : ""
  );
  const [prebook, setPrebook] = useState(
    location.state!==null && location.state.resp !== null &&
      location.state.resp &&
      location.state.resp !== undefined
      ? location.state.resp
      : ""
  );
  return (
    <>
       <div className="fixedHeader">
        <Header page="fixedHeader" />
      </div>
      {/* <FlightReviewBar /> */}
      <FlightPaymentDetails sup={request.supplier} bookingId={prebook.bookingId} amt={prebook?.amt} tid={prebook.tid} transId={prebook.trans_id} />
      <Footer />
    </>
  )
}

export default FlightPayment
