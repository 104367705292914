import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
let months = require('../bookingflow/Flights/common/masterData/Months.json')

function Month(props) {
  const [month, setMonth] = useState("");

  useEffect(() => {
    setMonth(props.updateMonth)
  }, [props.updateMonth]);

  const updateMonth = (month) => {
    setMonth(month);
    props.sendMonth(month);
  };

  return (
  //   <Form.Select 
  //     aria-label="Default select example" 
  //     className='monthSelect'
  //     value={month}
  //     onChange={(value) => {updateMonth(value)}}
  //   >                      
  //     <option value="">Month</option>
  //     {months.map((option, index) => (
  //       <option key={index} value={option.value}>
  //         {option.label}
  //       </option>
  //     ))}
  // </Form.Select>
    <Select 
        options={months}
        values={month}
        placeholder="Month"
        onChange={(value) => {updateMonth(value)}}
        > 
        {months.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )
          }
        )}                 
    </Select>
  )
}

export default Month
