import React, {useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../../assets/css/loader.css';
import { Button } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import AirlineLogo from "../../../../assets/images/result/QR.png";
import Plane from "../../../../assets/images/result/plane.png";

function FlightSearchLoader() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="resultPage">
    <section className='showingWrap'>
      <div className="mainContainer">
        <Row>
          <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className="showingFlights emptyLoader200-12">
                  
                </div>
                <div className='position-relative'>
                {/* <div class="clouds"></div> */}
                  <img src={Plane} className='plsneImg'/>
                  <div className='stripImg'></div>
                  <div className='imgStrip'></div>
                </div>
                <div className="showPricingCalendarWrap d-flex align-items-center">
                <span className='emptyLoaderCircle34'></span> <span className='emptyLoader175-12'></span>
                </div>
              </div>
          </Col>
        </Row>
      </div>
    </section>
    <section>
      <div className="mainContainer">
        <Row>
          <Col>
              <div className="sortingFilterWrap d-flex justify-content-between align-items-center">
                <div className="sortingFilterBoxOfGetfare d-flex align-items-center" onClick={() => setOpen(!open)}>
                  <i className="emptyLoaderCircle34"></i>
                  <div className='sortingFilterTitle emptyLoader150-12'></div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                <div className="sortingFilterBox">
                  <i className="emptyLoaderSquare34"></i>
                  <div className='w68'>
                    <div className='sortingFilterTitle emptyLoader68-12'></div>
                    <div className='sortingFilterValue emptyLoader68-12'></div>
                  </div>
                </div>
                <div className="sortingFilterBox ml-15">
                  <i className="emptyLoaderSquare34"></i>
                  <div className='w72'>
                    <div className='sortingFilterTitle emptyLoader68-12'></div>
                    <div className='sortingFilterValue emptyLoader72-12'></div>
                  </div>
                </div>
                <div className="sortingFilterBox ml-15">
                  <i className="emptyLoaderSquare34"></i>
                  <div className='w72'>
                    <div className='sortingFilterTitle emptyLoader72-12'></div>
                    <div className='sortingFilterValue emptyLoader72-12'></div>
                  </div>
                </div>
                </div>
                <div className="sortingFilterBoxOfGetfare d-flex align-items-center">
                  <i className="emptyLoaderSquare34"></i>
                  <div className='sortingFilterTitle emptyLoader150-12'></div>
                </div>
                {/* <div className="sortingFilterBox sortBy">
                  <i className="emptyLoaderSquare34"></i>
                  <div className='sortBySelect emptyLoader100-12'>
              </div>
                </div> */}
              </div>
          </Col>
        </Row>
      </div>
    </section>
    <section>
      <div className="mainContainer">
        <Row>
          <Col>
          <Collapse in={open}>
          <div className='getFareSummaryWrap'>
              <div className="headerBox">
                <div className="headerTitle first">
                  Airlines <i class="icon icon-down-arrow"></i>
                </div>
                <div className="headerTitle second">
                  Non Stop (50) <i class="icon icon-down-arrow"></i>
                </div>
                <div className="headerTitle third">
                  1+ Stops (223) <i class="icon icon-down-arrow"></i>
                </div>
                <div className='clearFilter'>Clear Filters</div>
              </div>
              <div className="dataBoxWrap custumScroll">
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
                <div className="dataBox">
                  <div className='oneData d-flex justify-content-between'><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span><span><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid'/>Egypt Air</span></div>
                  <div className='secondData'>AED 4,610.00 <span>+ 10 Flights</span></div>
                  <div className='thirdData'>AED 3,208.00<span>+ 1 Flights</span></div>
                </div>
              </div>
            </div>
          </Collapse>
          </Col>
        </Row>
      </div>
    </section>
    <section className='pb-30'>
      <div className="mainContainer">
        <Row>
          <Col>
          <div className="advanceFilterWrap">
                {/* <div className="leftSide emptyLoader150-12"></div> */}
                <div className="rightSide">
                  <div className='d-flex'>
                    <div className='switchBoxWrap emptyLoader175-12'>
                    
                    </div>
                    <div className='switchBoxWrap ml-30 emptyLoader150-12'>
                    
                    </div>
                  </div>
                  <div className='switchBoxWrap emptyLoader280-12 ml-30'>
                      
                    </div>
                </div>
              </div>
              <div className="resulSideWrap">
                  <div className="resultBlock">
                    <div className="rbTop">
                      <div className='refundType refund'><span className='emptyLoader100-12'></span></div>
                      <div className='d-flex'>
                      <div className='d-flex flex-column align-items-end justify-content-between'>
                      <div className='emptyLoader150-12'></div>
                      <div className='emptyLoader75-12 text-right'></div>
                      </div>
                        <Button variant="primary selectBtn"><span className='emptyLoader75-12'></span></Button>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep emptyLoader80-12">
                            
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime emptyLoader280-12">
                            
                          </div>
                        </div>
                      </div>
                      <div className='emptyLoader50-12'></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName d-flex align-items-center"><span className='emptyLoaderCircle20'></span> <span className='emptyLoader150-12 ml-10'></span></div>
                        <div className="planeCodeClassWrap emptyLoader150-12"></div>
                        <div className="operatedBy"><span className='emptyLoader175-12'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='emptyLoader100-12'></span><span className='pieces emptyLoader50-12 ml-20'></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration emptyLoader175-12'></div>
                          <div className='d-flex align-items-center'>
                            <span className='emptyLoaderCircle20 mr-10'></span>
                            <span className='emptyLoader200-4'></span>
                            <span className='emptyLoaderCircle20 ml-10'></span>
                          </div>
                          <div className='totalDuration emptyLoader120-12'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep emptyLoader80-12">
                            
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime emptyLoader280-12">
                            
                          </div>
                        </div>
                      </div>
                      <div className='emptyLoader50-12'></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName d-flex align-items-center"><span className='emptyLoaderCircle20'></span> <span className='emptyLoader150-12 ml-10'></span></div>
                        <div className="planeCodeClassWrap emptyLoader150-12"></div>
                        <div className="operatedBy"><span className='emptyLoader175-12'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='emptyLoader100-12'></span><span className='pieces emptyLoader50-12 ml-20'></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration emptyLoader175-12'></div>
                          <div className='d-flex align-items-center'>
                            <span className='emptyLoaderCircle20 mr-10'></span>
                            <span className='emptyLoader200-4'></span>
                            <span className='emptyLoaderCircle20 ml-10'></span>
                          </div>
                          <div className='totalDuration emptyLoader120-12'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="moreOptionWrap">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> Egypt Air</div>
                        <div className="planeCodeClassWrap"><span className='planeCode'>MS-911</span><span className='planeClass leftSeparatorLine'>Economy - K</span></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> Flight Details</span><span className='pieces'><i class="icon icon-Bag"></i> 2 Piece</span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'>23:00</div>
                            <div className='deparrLocation'>Dubai</div>
                            <div className='airportName'>Dubai International Airport, Dubai</div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'>1 Stop, Layover : 2h 20m (CAI)</div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'>Duration : 11h 15m</div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'>05:40</div>
                          <div className='deparrLocation'>London</div>
                          <div className='airportName'>Heathrow Airport, London</div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionTitle text-center">
                      +4 more flight options <i class="icon icon-down-arrow"></i>
                    </div> */}
                  </div>
                  <div className="resultBlock">
                    <div className="rbTop">
                      <div className='refundType refund'><span className='emptyLoader100-12'></span></div>
                      <div className='d-flex'>
                      <div className='d-flex flex-column align-items-end justify-content-between'>
                      <div className='emptyLoader150-12'></div>
                      <div className='emptyLoader75-12 text-right'></div>
                      </div>
                        <Button variant="primary selectBtn"><span className='emptyLoader75-12'></span></Button>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep emptyLoader80-12">
                            
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime emptyLoader280-12">
                            
                          </div>
                        </div>
                      </div>
                      <div className='emptyLoader50-12'></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName d-flex align-items-center"><span className='emptyLoaderCircle20'></span> <span className='emptyLoader150-12 ml-10'></span></div>
                        <div className="planeCodeClassWrap emptyLoader150-12"></div>
                        <div className="operatedBy"><span className='emptyLoader175-12'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='emptyLoader100-12'></span><span className='pieces emptyLoader50-12 ml-20'></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration emptyLoader175-12'></div>
                          <div className='d-flex align-items-center'>
                            <span className='emptyLoaderCircle20 mr-10'></span>
                            <span className='emptyLoader200-4'></span>
                            <span className='emptyLoaderCircle20 ml-10'></span>
                          </div>
                          <div className='totalDuration emptyLoader120-12'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep emptyLoader80-12">
                            
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime emptyLoader280-12">
                            
                          </div>
                        </div>
                      </div>
                      <div className='emptyLoader50-12'></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName d-flex align-items-center"><span className='emptyLoaderCircle20'></span> <span className='emptyLoader150-12 ml-10'></span></div>
                        <div className="planeCodeClassWrap emptyLoader150-12"></div>
                        <div className="operatedBy"><span className='emptyLoader175-12'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='emptyLoader100-12'></span><span className='pieces emptyLoader50-12 ml-20'></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader80-12'></span>
                            <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration emptyLoader175-12'></div>
                          <div className='d-flex align-items-center'>
                            <span className='emptyLoaderCircle20 mr-10'></span>
                            <span className='emptyLoader200-4'></span>
                            <span className='emptyLoaderCircle20 ml-10'></span>
                          </div>
                          <div className='totalDuration emptyLoader120-12'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime emptyLoader100-12'></div>
                          <div className='deparrLocation emptyLoader100-12'></div>
                          <div className='airportName'>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader80-12'></span>
                              <span className='emptyLoader50-12'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="moreOptionWrap">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> Egypt Air</div>
                        <div className="planeCodeClassWrap"><span className='planeCode'>MS-911</span><span className='planeClass leftSeparatorLine'>Economy - K</span></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> Flight Details</span><span className='pieces'><i class="icon icon-Bag"></i> 2 Piece</span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'>23:00</div>
                            <div className='deparrLocation'>Dubai</div>
                            <div className='airportName'>Dubai International Airport, Dubai</div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'>1 Stop, Layover : 2h 20m (CAI)</div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'>Duration : 11h 15m</div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'>05:40</div>
                          <div className='deparrLocation'>London</div>
                          <div className='airportName'>Heathrow Airport, London</div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionTitle text-center">
                      +4 more flight options <i class="icon icon-down-arrow"></i>
                    </div> */}
                  </div>
                </div>
          </Col>
        </Row>
      </div>
  </section>
  </div> 
      {/* <section className='showingWrap'>
      <div className="mainContainer">
        <Row>
          <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className="showingFlights">
                  
                </div>
                <div className="showPricingCalendarWrap">
                <i class="icon icon-calendar"></i>  <i class="icon icon-down-arrow"></i>
                </div>
              </div>
          </Col>
        </Row>
      </div>
    </section>
    <section>
      <div className="mainContainer">
        <Row>
          <Col>
              <div className="sortingFilterWrap d-flex justify-content-between align-items-center">
                <div className="sortingFilterBoxOfGetfare d-flex align-items-center" onClick={() => setOpen(!open)}>
                  <i class="icon icon-Multi-flight"></i>
                  <div className='sortingFilterTitle'> <i class="icon icon-down-arrow"></i></div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                <div className="sortingFilterBox">
                  <i class="icon icon-Dollar"></i>
                  <div>
                    <div className='sortingFilterTitle'></div>
                    <div className='sortingFilterValue'></div>
                  </div>
                </div>
                <div className="sortingFilterBox ml-15">
                  <i class="icon icon-Fastest"></i>
                  <div>
                    <div className='sortingFilterTitle'></div>
                    <div className='sortingFilterValue'></div>
                  </div>
                </div>
                <div className="sortingFilterBox ml-15">
                  <i class="icon icon-Best-Star"></i>
                  <div>
                    <div className='sortingFilterTitle'></div>
                    <div className='sortingFilterValue'></div>
                  </div>
                </div>
                </div>
                <div className="sortingFilterBox sortBy">
                  <i class="icon icon-flip-arrow"></i>
                  <div>
                    <div className='sortingFilterTitle'></div>
                    <div className='sortingFilterValue'></div>
                  </div>
                  <span><i class="icon icon-down-arrow"></i></span>
                </div>
              </div>
          </Col>
        </Row>
      </div>
    </section>
    <section>
    </section> 
     <section className='pb-30'>
      <div className="mainContainer" style={{overflow:"hidden"}}>
        <Row>
          <Col>
              <div className='d-flex'>
                <div className="resulSideWrap">
                  <div className="resultBlock">
                    <div className="rbTop">
                      <div className='refundType refund'></div>
                      <div className='d-flex'>
                        <span className='installment'></span>
                        <div>
                          <div>
                            <span className='currCode leftSeparatorLine'> </span>
                            <span className='currValue'></span>
                          </div>
                          <div>
                            <del className='changePrice'></del>
                          </div>
                        </div>
                        <Button variant="primary selectBtn"> <i class="icon icon-down-arrow"></i></Button>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle return"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i> </div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionWrap">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionTitle text-center">
                       <i class="icon icon-down-arrow"></i>
                    </div>
                  </div>
                  <div className="resultBlock">
                    <div className="rbTop">
                      <div className='refundType refund'></div>
                      <div className='d-flex'>
                        <span className='installment'></span>
                        <div>
                          <div>
                            <span className='currCode leftSeparatorLine'> </span>
                            <span className='currValue'></span>
                          </div>
                          <div>
                            <del className='changePrice'></del>
                          </div>
                        </div>
                        <Button variant="primary selectBtn"> <i class="icon icon-down-arrow"></i></Button>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle return"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i> </div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionWrap">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionTitle text-center">
                       <i class="icon icon-down-arrow"></i>
                    </div>
                  </div>
                  <div className="resultBlock">
                    <div className="rbTop">
                      <div className='refundType refund'></div>
                      <div className='d-flex'>
                        <span className='installment'></span>
                        <div>
                          <div>
                            <span className='currCode leftSeparatorLine'> </span>
                            <span className='currValue'></span>
                          </div>
                          <div>
                            <del className='changePrice'></del>
                          </div>
                        </div>
                        <Button variant="primary selectBtn"> <i class="icon icon-down-arrow"></i></Button>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i></div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="rbMid">
                      <div>
                        <div className="flightDepWrap">
                          <div className="flightDep">
                            <i class="icon icon-Flight-Circle return"></i> 
                          </div>
                        </div>
                        <div className="routeTimeWrap">
                          <div className="routeTime">
                            <span className='route'></span>
                            <i class="icon icon-Arrow"></i>
                            <span className='route'></span>
                            <span className='time leftSeparatorLine'></span>
                          </div>
                        </div>
                      </div>
                      <div className='seatsLeft'><i class="icon icon-Seats-Left"></i> </div>
                    </div>
                    <div className="rbBot">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="operatedBy"></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i> </span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionWrap">
                      <div className='planeInfo'>
                        <div className="planeName"><img src={AirlineLogo} alt="AirlineLogo" className='img-fluid planeImage' /> </div>
                        <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                        <div className="flightDetailsPiecesWrap"><span className='flightDetails' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> </span><span className='pieces'><i class="icon icon-Bag"></i></span></div>
                      </div>
                      <div className='airlineInfo'>
                        <div className='deparrInfo'>
                            <div className='deparrTime'></div>
                            <div className='deparrLocation'></div>
                            <div className='airportName'></div>
                        </div>
                        <div className='layoverInfo text-center'>
                          <div className='layoverDuration'></div>
                          <div className='layoverSeparator'><span></span></div>
                          <div className='totalDuration'></div>
                        </div>
                        <div className='deparrInfo'>
                          <div className='deparrTime'></div>
                          <div className='deparrLocation'></div>
                          <div className='airportName'></div>
                        </div>
                      </div>
                    </div>
                    <div className="moreOptionTitle text-center">
                       <i class="icon icon-down-arrow"></i>
                    </div>
                  </div>
                </div>
              </div>
          </Col>
        </Row>
      </div>
  </section>  */}
    </div>
  )
}

export default FlightSearchLoader
