import { useSelector } from "react-redux";
import DateUtils from "../../utils/DateUtils";
// Returns DD/MM/YYYY Date format
export const handleCompleteDate = (value) => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
}


// returns YYYY-MM-DD date format  
export const handleDateFormat = (value) => {
  var type = typeof value;
  var inputDate = "";
  if(type === "string") {
    inputDate = value
  } else {
    inputDate = DateUtils.convertStringToDate(value)
  }  
  var [day, month, year] = [undefined, undefined, undefined];  
  if(inputDate?.includes("/")) {
    [day, month, year] = inputDate?.split('/');
  } else if(inputDate?.includes("-")) {
    [day, month, year] = inputDate?.split('-');
  }
  return `${year}-${month}-${day}`;
}


// // returns complete date....

export const handleSingledate = ({ sliceData, index }) => {
  let inputDate = sliceData?.multicity[index]?.departureDate
  if (inputDate === '') {
    return ''
  } else {
    console.log(inputDate)
    const [day, month, year] = inputDate.split('/');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    const monthName = formattedDate.toLocaleString('default', { month: 'short' });
    const dayName = formattedDate.toLocaleString('default', { weekday: 'short' });
    const time = formattedDate.toLocaleTimeString('en-US', { hour12: false });
    const result = `${dayName} ${monthName} ${day} ${year} ${time} GMT${formattedDate.getTimezoneOffset() < 0 ? '+' : '-'}${Math.abs(formattedDate.getTimezoneOffset() / 60).toString().padStart(2, '0')}:00 (India Standard Time)`;
    console.log(result)
    return result;
  }
}

// returns time into valid format...
export const handleTimeChange = (event) => {
  let result = "";
  if(event) {
    const timeValue = parseInt(event);
    const hours = Math.floor(timeValue / 60);
    const minutes = timeValue % 60;
    result = `${hours}h : ${minutes}m`;
  }
  return result;
};


// converts date into dd-mmm-yyyy....
export const convertDate = (originalDate) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var [day, month, year] = [undefined, undefined, undefined];
  var type = typeof originalDate;
  var inputDate = "";
  if(type === "string") {
    if(originalDate?.includes("T")) {
      inputDate = originalDate?.split("T")[0]
    } else {
      inputDate = originalDate
    }
  } else {
    inputDate = DateUtils.convertStringToDate(originalDate)
  }  
  if(inputDate?.includes("/")) {
    var first = inputDate?.split('/');
    var len = first[0].length;
    if(len === 4) {
      [year, month, day] = inputDate?.split('/');
    } else {      
      [day, month, year] = inputDate?.split('/');
    }
  } else if(inputDate?.includes("-")) {
    var first = inputDate?.split('-');
    var len = first[0].length;
    if(len === 4) {
      [year, month, day] = inputDate?.split('-');
    } else {      
      [day, month, year] = inputDate?.split('-');
    }
  }
  if(day!==undefined && month!==undefined && year!==undefined) {
    const newDate = new Date(year, parseInt(month) - 1, day);
    const convertedDate = `${day}-${months[newDate.getMonth()]}-${year}`;
    return convertedDate;
  } else {
    return inputDate;
  }
};


// returns time in 24 hrs format 
export const handleFormatTime = (dats) => {
  const datess = new Date(dats);
  try {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
    const val = datess.toLocaleString('en-US', options);
    // console.log(val, "val")
    return val;
  } catch (error) {
    console.error("Error formatting time:", error);
    return null;
  }
};

// returns time format in 12hrs
export const handleFormatTime12 = (dats) => {
  const datess = new Date(dats);
  try {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const val = datess.toLocaleString('en-US', options);
    return val;
  } catch (error) {
    console.error("Error formatting time:", error);
    return null;
  }
};