import React, { useState, useReducer, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { emailValidation, passwordValidation } from '../../utilities/FormValidations';
import { handlePostApi, handleGetApi } from "../../utilities/Axios";
import SocialMediaGoogle from './SocialMediaGoogle'
const B2C_COMMON_URL = process.env.REACT_APP_COMMON_URL;

//Initial State
const initialState = {
  registerEmail: "",
  signinEmail: "",
  password: "",
  confirmPassword: "",
  signinPassword: "",
  errorEmailMsg: false,
  errorEmailValidate: false,
  errorPasswordMsg: false,
  errorPasswordValidate: false,
  errorConfirmPasswordMsg: false,
  passwordNotMatch: false,
  alertMessage: "",
  showAlertMessage: false,
  alertMessageVarient: "",
  emailExists: false,
  errorCredentials: false,

};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function Registration({showSignIn, showRegister, closeSignin, closeRegister, sendLoggedInData}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [btnLoader, setBtnLoader] = useState(false);
    const [signInShow, setSignInShow] = useState(false);
    const [signInShowInner, setSignInShowInner] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);
    const [registerShowInner, setRegisterShowInner] = useState(false);    

    useEffect(() => {
        setSignInShow(showSignIn);
        setRegisterShow(showRegister);
    }, [showSignIn, showRegister])

    useEffect(() => {
      let loggedInfo = localStorage.getItem("logged");
      if (loggedInfo && loggedInfo !== null && loggedInfo !== "") {
        sendLoggedInData(JSON.parse(loggedInfo));
      } else {
        sendLoggedInData(null)
      }
    }, [localStorage?.getItem("logged")]);

    useEffect(() => {
      dispatch({ type: "emailExists", payload: false });
    }, [state.registerEmail])

    /**
     * @author Praveen Varma
     * @description Gogogle login
     * @date 05-06-2024
     * @param {*} code 
     */
    const loginWithGmail = async (code) => {
      let ip_info = await handleGetApi("https://api.ipify.org/?format=json");
      let payload = {
        code: code,
        ip_addr: ip_info?.data?.ip,
        source: "GOOGLE",
      }
      let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/user/googleLogin`, payload);
      if (response && response.data && response.data.suc) {
        console.log(":::: Google Loged in resp", JSON.stringify(response.data.res));
        let loginInfo = {
          fname: response.data.res.fname,
          unique_id: response.data.res?.usr_uniq_id
        }
        localStorage.setItem("logged", JSON.stringify(loginInfo));
        sendLoggedInData(loginInfo);
        closeSignin(false);
        setRegisterShow(false);
        setRegisterShowInner(false);
        dispatch({ type: "showAlertMessage", payload: true });
        dispatch({ type: "alertMessage", payload: "Loggedin Successfully"});
        dispatch({ type: "alertMessageVarient", payload: "success" });
        hidemessage();
      } else {
        console.log("::::: Google Loged in resp no data");
      }
    }

    const handleFormData = (e) => {
      const { id, value } = e.target;
      dispatch({ type: id, payload: value });
      if(id === "registerEmail") {
        dispatch({ type: "errorEmailMsg", payload: false });
      } else if (id === "password") {
        dispatch({ type: "errorPasswordMsg", payload: false });
        if (value === state.confirmPassword) {      
          dispatch({ type: "passwordNotMatch", payload: false });          
        }
        // if (passwordValidation(value)) {
        //   dispatch({ type: "errorPasswordValidate", payload: false });
        // }
      } else if (id === "confirmPassword") {
        dispatch({ type: "errorConfirmPasswordMsg", payload: false });
        if (state.password === value) {      
          dispatch({ type: "passwordNotMatch", payload: false });          
        }
      }
    }
  
    const validateRegister = () => {
      if(state.registerEmail === "" && state.password === "" && state.confirmPassword === "") {
        dispatch({ type: "errorEmailMsg", payload: true });
        dispatch({ type: "errorPasswordMsg", payload: true });
        dispatch({ type: "errorConfirmPasswordMsg", payload: true });
        return false;
      } else if (state.registerEmail === "") {
        dispatch({ type: "errorEmailMsg", payload: true });
        return false;
      } else if(!emailValidation(state.registerEmail)) {
        dispatch({ type: "errorEmailValidate", payload: true });
        return false;
      } else if (state.password === "") {
        dispatch({ type: "errorPasswordMsg", payload: true });
        return false;
      } 
      // else if (!passwordValidation(state.password)) {
      //   dispatch({ type: "errorPasswordValidate", payload: true });
      //   return false;
      // }
       else if (state.confirmPassword === "") {
        dispatch({ type: "errorConfirmPasswordMsg", payload: true });
        return false;
      } else if (state.password !== state.confirmPassword) {
        dispatch({ type: "passwordNotMatch", payload: true }); 
        return false;     
      }
      return true;
    }
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      if(validateRegister()) {
        setBtnLoader(true);
        let ip_info = await handleGetApi("https://api.ipify.org/?format=json");
        let payload = {
          email: state.registerEmail,
          ip_addr: ip_info?.data?.ip,
          password: state.password,
          // region_type: "Qatar"
        }
        let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/user/signup`, payload);
          if (response?.data?.suc) { 
            if(response?.data?.res?.res?.isUserExists) {
              dispatch({ type: "emailExists", payload: true });
              setBtnLoader(false);
            } else {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({ type: "alertMessage", payload: "Registered Successfully"});
              dispatch({ type: "alertMessageVarient", payload: "success" });
              closeRegister(false);
              setBtnLoader(false);
              hidemessage();
              dispatch({ type: "registerEmail", payload: "" });
              dispatch({ type: "password", payload: "" });
              dispatch({ type: "confirmPassword", payload: "" });
              return false;  
            }        
          }
          // else {
          //   dispatch({ type: "showAlertMessage", payload: true });
          //   dispatch({ type: "alertMessage", payload: "Something went wrong"});
          //   dispatch({ type: "alertMessageVarient", payload: "error" });
          //   hidemessage();
          //   return false;
          // }
      }
    }
  
    const validateSignin = () => {
      if(state.signinEmail === "" && state.signinPassword === "") {
        dispatch({ type: "errorEmailMsg", payload: true });
        dispatch({ type: "errorPasswordMsg", payload: true });
        return false;
      } else if (state.signinEmail === "") {
        dispatch({ type: "errorEmailMsg", payload: true });
        return false;
      } else if(!emailValidation(state.signinEmail)) {
        dispatch({ type: "errorEmailMsg", payload: false });
        dispatch({ type: "errorEmailValidate", payload: true });
        return false;
      } else if (state.signinPassword === "") {
        dispatch({ type: "errorPasswordMsg", payload: true });
        return false;
      } 
      return true;
    }
  
    const handleSignIn = async (e) => {
      e.preventDefault();
      if(validateSignin()) {
        setBtnLoader(true);
        let payload = {
          email: state.signinEmail,
          pwd: state.signinPassword,
        }
        let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/user/login`, payload);
          if (response?.data?.suc) { 
            console.log(":::: Sign in resp", JSON.stringify(response.data.res));
            if(response?.data?.res?.err === "Please enter valid credentials") {
              dispatch({ type: "errorCredentials", payload: true });
              setBtnLoader(false);
              return;
            } else {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({ type: "alertMessage", payload: "Login Successfully"});
              dispatch({ type: "alertMessageVarient", payload: "success" });
              closeSignin(false);
              setBtnLoader(false);
              let loginInfo = {
                fname: response?.data?.res[0]?.fname ? `${response?.data?.res[0]?.fname.substring(0,1).toUpperCase()}${response?.data?.res[0]?.fname.substring(1,response?.data?.res[0]?.fname?.length).toUpperCase()}` : response.data?.res[0]?.usr_uniq_id,
                unique_id: response.data?.res[0]?.usr_uniq_id,
                profile_pic:  response.data?.res[0]?.profile_pic,
              }
              localStorage.setItem("logged", JSON.stringify(loginInfo));
              sendLoggedInData(loginInfo);
              hidemessage();
              dispatch({ type: "signinEmail", payload: "" });
              dispatch({ type: "signinPassword", payload: "" });
              return false;  
            }
          } else {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({ type: "alertMessage", payload: "Something went wrong"});
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            setBtnLoader(false);
            hidemessage();
            return false;
          }
      }
    };

    const handleSignInClose = () => {
      closeSignin(false);
      setSignInShowInner(false);
      dispatch({ type: "errorEmailMsg", payload: false });
      dispatch({ type: "errorPasswordMsg", payload: false });
      dispatch({ type: "errorConfirmPasswordMsg", payload: false });
    }
    const handleSignInShow = () => {
      closeSignin(true);
      closeRegister(false);
      setSignInShowInner(true);
      setRegisterShowInner(false);
      dispatch({ type: "errorEmailMsg", payload: false });
      dispatch({ type: "errorPasswordMsg", payload: false });
      dispatch({ type: "errorConfirmPasswordMsg", payload: false });
    }
  
    const handleRegisterClose = () => {
      closeRegister(false);
      setRegisterShowInner(false);
      dispatch({ type: "errorEmailMsg", payload: false });
      dispatch({ type: "errorPasswordMsg", payload: false });
      dispatch({ type: "errorConfirmPasswordMsg", payload: false });
    }
    const handleRegisterShow = () => {
      closeRegister(true);
      closeSignin(false);
      setRegisterShowInner(true);
      setSignInShowInner(false);
      dispatch({ type: "errorEmailMsg", payload: false });
      dispatch({ type: "errorPasswordMsg", payload: false });
      dispatch({ type: "errorConfirmPasswordMsg", payload: false });
    }

    const hidemessage = () => {
      setTimeout(function () {
        dispatch({ type: "showAlertMessage", payload: false });
        dispatch({ type: "alertMessage", payload: "" });
        dispatch({ type: "alertMessageVarient", payload: "" });
      }, 5000);
    };

    return (
        <>
            {state.showAlertMessage &&
            <Alert className="notification" variant={state.alertMessageVarient} show={state.showAlertMessage}
                onClose={() => dispatch({ type: "showAlertMessage", payload: false })}
                dismissible> {state.alertMessage}</Alert>
            }
            <Modal show={signInShow || signInShowInner} onHide={handleSignInClose} className='formPopup signInPopup'>
                <Modal.Header closeButton>
                    <div className='popupTitle'>Sign In</div>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="signinEmail" className='position-relative'>
                            <i className="icon icon-email"></i>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                onChange={handleFormData}
                                value={state.signinEmail}
                                autoComplete="off"
                            />
                            {state.errorEmailMsg && <small className='error'>Please enter email</small>}
                            {state.errorEmailValidate && <small className='error'>Please enter valid email</small>}
                        </Form.Group>

                        <Form.Group controlId="signinPassword" className='position-relative'>
                            <i className="icon icon-email"></i>
                            <Form.Control
                                type="password"
                                placeholder="Enter your Password"
                                onChange={handleFormData}
                                value={state.signinPassword}
                                autoComplete="off"
                            />
                            {state.errorPasswordMsg && <small className='error'>Please enter password</small>}
                            {state.errorCredentials && <small className='error'>Please enter valid credentials</small>}
                        </Form.Group>
                        {/* <div className='forgotPassword'>Forgot Password?</div> */}
                        {!btnLoader ?                         
                        <Button variant="primary" type="submit" className='submitBtrn' onClick={handleSignIn}>
                            Sign In
                        </Button>
                        :                                            
                        <Button variant="primary" type="submit" className='submitBtrn'>
                            Loading...
                        </Button>
                        }
                    </Form>
                    <div className='signInWith'>or sign in using</div>
                    <div className='otherOptionWrap'>
                        {/* <span className="fb"><img src={ArabicFlag} alt="flag-icon" /> Facebook</span> */}
                        <SocialMediaGoogle loginWithGmail={loginWithGmail}/>
                        {/* <span className="apple"><img src={ArabicFlag} alt="flag-icon" /> Apple ID</span> */}
                    </div>
                    <div className="alreadyAccount">Do not have an account? <span onClick={handleRegisterShow}>Register Now</span></div>
                    <div className="privacyPolicyWrap">By signing in or creating an account, you agree with our <span>Terms & Conditions</span> and <span>Privacy Policy.</span></div>
                </Modal.Body>
            </Modal>
            <Modal show={registerShow || registerShowInner} onHide={handleRegisterClose} className='formPopup registerPopup'>
                <Modal.Header closeButton>
                    <div className='popupTitle'>Create an account!</div>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="registerEmail" className='position-relative'>
                            <i className="icon icon-email"></i>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                onChange={handleFormData}
                                value={state.registerEmail}
                                autoComplete="off"
                            />
                            {state.errorEmailMsg && <small className='error'>Please enter email</small>}
                            {state.errorEmailValidate && <small className='error'>Please enter valid email</small>}
                            {state.emailExists && <small className='error'>You already have an account with this email id</small>}
                        </Form.Group>

                        <Form.Group controlId="password" className='position-relative'>
                            <i className="icon icon-email"></i>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={handleFormData}
                                value={state.password}
                                autoComplete="off"
                            />
                            {state.errorPasswordMsg && <small className='error'>Please enter password</small>}
                            {state.errorPasswordValidate && <small className='error'>Please enter valid password</small>}
                        </Form.Group>

                        <Form.Group controlId="confirmPassword" className='position-relative'>
                            <i className="icon icon-email"></i>
                            <Form.Control
                                type="password"
                                placeholder="Confirm password"
                                onChange={handleFormData}
                                value={state.confirmPassword}
                                autoComplete="off"
                            />
                            {state.errorConfirmPasswordMsg && <small className='error'>Please enter confirm password</small>}
                            {state.passwordNotMatch && <small className='error'>Password does not match</small>}
                        </Form.Group>
                        <div className='checkPasswordWrap mt-15'>
                            <span className='checkPasswordTitle'>Your password must have</span>
                            <ul className='checkPasswordList'>
                                <li>At least 8 characters, no spaces</li>
                                <li>Upper & lowercase Latin letters</li>
                                <li>At least one number</li>
                            </ul>

                        </div>
                        {!btnLoader ?    
                        <Button variant="primary" type="submit" className='submitBtrn' onClick={handleSignUp}>
                            Register
                        </Button>
                        :      
                        <Button variant="primary" type="submit" className='submitBtrn'>
                            Loading...
                        </Button>       
                        }
                    </Form>
                    <div className='signInWith'>or sign in using</div>
                    <div className='otherOptionWrap'>
                        {/* <span className="fb"><img src={ArabicFlag} alt="flag-icon" /> Facebook</span> */}
                        <SocialMediaGoogle loginWithGmail={loginWithGmail}/>
                        {/* <span className="apple"><img src={ArabicFlag} alt="flag-icon" /> Apple ID</span> */}
                    </div>
                    <div className="alreadyAccount">Do you have an account? <span onClick={handleSignInShow}>Sign In</span></div>
                    <div className="privacyPolicyWrap">By signing in or creating an account, you agree with our <span>Terms & Conditions</span> and <span>Privacy Policy.</span></div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Registration
