
import React from 'react';
// import CurrencyFormat from 'react-currency-format';

/**
 * @description:Currency info
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-05-2024
 */

function FormateCurrency(props) {
    return (
        <>
                {props.value!==undefined &&
                    // <CurrencyFormat value={parseFloat(props.value)} displayType={'text'} fixedDecimalScale={true} decimalScale={'2'} thousandSeparator={true} prefix={''} />
                    props.value.toFixed(2)
                }
        </>
    )
}


export default FormateCurrency;
