import React, { useState, useReducer, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { convertDate, handleTimeChange } from '../../../common/services/DateValidations';
import FlightDetailsPopup from '../result/ChildComp/FlightDetailsPopup';
import FormateCurrency from '../../../utils/FormateCurrency';

/**
* Initial State Declaration
*/
const initialState = {
     mealAdt:0,mealChd:0,mealInf:0,adtList:[],totalExtra:0,adtBagList:[],chdBagList:[],mealAdtlist:[],mealChdlist:[]
  };
  
  //====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

function TripSummary(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [modalShow, setModalShow] = useState(false);
    const data = props?.data;

    useEffect(()=>{
        if(props.baggagePrice!="" && props.baggagePrice!==undefined){
         if(props.baggagePrice[1]==="ADT"){
              const payload={
                "no":props.baggagePrice[2],
                "flow":props.baggagePrice[3],
                "price":props.baggagePrice[0].price
              }
              if(state.adtBagList.length===0){
                let list=[];
                list.push(payload);
                dispatch({ type: 'adtBagList', payload: list })
              }else{
                let match=true;
                for(let val of state.adtBagList){
                  if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
                     val.price=props.baggagePrice[0].price;
                     match=false;
                  }
                }
                if(match){
                  let bagsList=[];
                  bagsList=state.adtBagList;
                  bagsList.push(payload);
                  dispatch({ type: 'adtBagList', payload: bagsList })
                }else{
                  dispatch({ type: 'adtBagList', payload: state.adtBagList })
                }
              }
         }else if(props.baggagePrice[1]==="CHD"){
          const payload={
            "no":props.baggagePrice[2],
            "flow":props.baggagePrice[3],
            "price":props.baggagePrice[0].price
          }
          if(state.chdBagList.length===0){
            let list=[];
            list.push(payload);
            dispatch({ type: 'chdBagList', payload: list })
          }else{
            let match=true;
            for(let val of state.chdBagList){
              if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
                 val.price=props.baggagePrice[0].price;
                 match=false;
              }
            }
            if(match){
              let bagsList=[];
              bagsList=state.chdBagList;
              bagsList.push(payload);
              dispatch({ type: 'chdBagList', payload: bagsList })
            }else{
              dispatch({ type: 'chdBagList', payload: state.chdBagList })
            }
          }
         }else if(props.baggagePrice[1]==="INF"){
          if(props.baggagePrice[3]==="O"){
            dispatch({ type: 'oInfp', payload:Number(props.baggagePrice[0].price) })
            dispatch({ type: 'bagInf', payload:state.rInfp+Number(props.baggagePrice[0].price) })
           }else if(props.baggagePrice[3]==="R"){
            dispatch({ type: 'rInfp', payload:Number(props.baggagePrice[0].price) })
            dispatch({ type: 'bagInf', payload:state.oInfp+Number(props.baggagePrice[0].price) })
           }
         }
    
        }
      },[props.baggagePrice])
    
      useEffect(()=>{
        if(props.selMealprice!="" && props.selMealprice!==undefined){
         if(props.selMealprice[1]==="ADT"){
          const payload={
            "no":props.selMealprice[2],
            "flow":props.selMealprice[3],
            "price":props.selMealprice[0].price,
            "route":props.selMealprice[4]
          }
          if(state.mealAdtlist.length===0){
            let list=[];
            list.push(payload);
            dispatch({ type: 'mealAdtlist', payload: list })
          }else{
            let match=true;
            for(let val of state.mealAdtlist){
              if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
                 val.price=props.selMealprice[0].price;
                 match=false;
              }
            }
            if(match){
              let bagsList=[];
              bagsList=state.mealAdtlist;
              bagsList.push(payload);
              dispatch({ type: 'mealAdtlist', payload: bagsList })
            }else{
              dispatch({ type: 'mealAdtlist', payload: state.mealAdtlist })
            }
          }
         }else if(props.selMealprice[1]==="CHD"){
          const payload={
            "no":props.selMealprice[2],
            "flow":props.selMealprice[3],
            "price":props.selMealprice[0].price,
            "route":props.selMealprice[4]
          }
          if(state.mealChdlist.length===0){
            let list=[];
            list.push(payload);
            dispatch({ type: 'mealChdlist', payload: list })
          }else{
            let match=true;
            for(let val of state.mealChdlist){
              if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
                 val.price=props.selMealprice[0].price;
                 match=false;
              }
            }
            if(match){
              let bagsList=[];
              bagsList=state.mealChdlist;
              bagsList.push(payload);
              dispatch({ type: 'mealChdlist', payload: bagsList })
            }else{
              dispatch({ type: 'mealChdlist', payload: state.mealChdlist })
            }
          }
         }else if(props.selMealprice[1]==="INF"){
          dispatch({ type: 'mealInf', payload:state.mealInf + Number(props.selMealprice[0].price) })
         }
        }
      },[props.selMealprice])
    
      const calAdtBagPrice = () => {
        let totalPrice=0;
           if(state.adtBagList.length!==0){
             for(let val of state.adtBagList){
               if(totalPrice===0 && val.price!==undefined){
                totalPrice=Number(val.price);
               }else if(val.price!==undefined){
                totalPrice=Number(totalPrice+val.price);
               }
              }
              props.sendAdtPrice(totalPrice);
           } else if(data?.adtBagPrice !== undefined && data?.adtBagPrice !== 0 ){
              totalPrice=Number(totalPrice+data?.adtBagPrice);
           }
        return totalPrice * data.base_usr_exrate;
      }
    
      const calChdBagPrice = () => {
          let totalPrice=0;
          if(state.chdBagList.length!==0){
            for(let val of state.chdBagList){
              if(totalPrice===0 && val.price!==undefined){
              totalPrice=Number(val.price);
              }else if(val.price!==undefined){
              totalPrice=Number(totalPrice+val.price);
              }
            }
            props.sendChdPrice(totalPrice);
          } else if(data?.chdBagPrice !== undefined && data?.chdBagPrice !== 0 ){
            totalPrice=Number(totalPrice+data?.chdBagPrice);
          }
    
        return totalPrice * data.base_usr_exrate;
      }
    
      const calAdtMealPrice = () => {
        let totalPrice=0;
          if(state.mealAdtlist.length!==0){
            for(let val of state.mealAdtlist){
              if(totalPrice===0 && val.price!==undefined){
                totalPrice=Number(val.price);
              }else if(val.price!==undefined){
                totalPrice=Number(totalPrice+val.price);
              }
              }
              props.sendMealAdtPrice(totalPrice);
          } else if(data?.adtMealPrice !== undefined && data?.adtMealPrice !== 0 ){
            totalPrice=Number(totalPrice+data?.adtMealPrice);
          }
        return totalPrice * data.base_usr_exrate;
      }
    
      const calChdMealPrice = () => {
        let totalPrice=0;
          if(state.mealChdlist.length!==0){
            for(let val of state.mealChdlist){
              if(totalPrice===0 && val.price!==undefined){
                totalPrice=Number(val.price);
              }else if(val.price!==undefined){
                totalPrice=Number(totalPrice+val.price);
              }
              }
              props.sendMealChdPrice(totalPrice);
          } else if(data?.chdMealPrice !== undefined && data?.chdMealPrice !== 0 ){
            totalPrice=Number(totalPrice+data?.chdMealPrice);
          }
        return totalPrice * data.base_usr_exrate;
      }

    // Airlines logo...
    const handleLogo = (data) => {
      let logo;
      try {
        logo = require(`../../../../assets/airlines-logo/${data}.png`);
      } catch (error) {
        logo = null;
      }
      return logo
    }

    const closeFlightDetails = (data) => {
      setModalShow(data);
    }

    return (
        <div className="tripSummaryWrap">
            <div class="leftSemicircle"></div>
            <div className="tripSummaryTitle">
                <span>Trip Summary</span>
                <span className='fDet' onClick={() => setModalShow(true)}><i class="icon icon-Pop"></i> Flight Details</span>
            </div>
            <Accordion defaultActiveKey="0" className='tripSumCollapse'>
                {
                    data.OutboundInboundlist.map((outbounddata, index) => {
                    const lengths = outbounddata.flightlist.length;
                    const flightListData = outbounddata.flightlist[0];
                    const depertureDatas = outbounddata.flightlist[0].Departure;
                    const arrivalDatas = outbounddata.flightlist[lengths - 1].Arrival;
                    const layover = outbounddata?.flightlist[lengths - 1]?.connectiontime;
                    const layovername = outbounddata?.flightlist[0]?.Arrival?.Iata;
                    const trip = outbounddata.flightlist[0].flightindex
                    return (
                        <>    
                        <Accordion.Item eventKey={index === 0 ? "0" : index}>                    
                            <Accordion.Header className='tripSumHeader'>
                                <i class="icon icon-down-arrow"></i>
                                <i class="icon icon-Flight-Circle"></i>
                                <div class="routeTime">
                                    <span class="route">{depertureDatas.city}</span>
                                    <i class="icon icon-Arrow"></i>
                                    <span class="route">{arrivalDatas.city}</span>
                                    <span class="time leftSeparatorLine">{convertDate(depertureDatas.Date)}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex align-items-center">
                                    <div class="planeName">     
                                    {handleLogo(flightListData?.MarketingAirline?.code) && (
                                      <img src={handleLogo(flightListData?.MarketingAirline?.code)} alt="AirlineLogo" className='img-fluid planeImage' /> 
                                    )} 
                                    {flightListData?.MarketingAirline?.name}
                                    </div>
                                    <div class="planeCodeClassWrap"><span class="planeCode">{data.Airlinelists}-{flightListData?.MarketingAirline?.number}</span><span class="planeClass leftSeparatorLine">{flightListData.CabinClassText}</span></div>
                                </div>

                                <div className='airlineInfo'>
                                    <div className='deparrInfo'>
                                        <div className='deparrTime'>{depertureDatas.time}</div>
                                        <div className='deparrLocation'>{depertureDatas.city}</div>
                                    </div>
                                    
                                    <div class="layoverInfo text-center">
                                        <div class="layoverDuration">{lengths - 1 === 0 ? 'Direct' : `${lengths - 1} Stop`}</div>
                                        <div class="layoverSeparator"><span></span></div>
                                        <div class="totalDuration">Duration : {handleTimeChange(outbounddata.totaltime)}</div>
                                    </div>

                                    <div className='deparrInfo'>
                                        <div className='deparrTime'>{arrivalDatas.time}</div>
                                        <div className='deparrLocation'>{arrivalDatas.city}</div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        {/* <div className="flight-to">

                            <div className="brBot">
                            <div className='airlineInfo'> */}

                                {/* <div className="fl-dels"><img src="assets/images/flight-result/bag.svg" alt="" />
                                {flightListData?.CheckinBaggage[0]?.Value}
                                </div>
                                <div className="flt-bag">
                                <div className="fl-dels inbg" onClick={() => { { handleFlightDetails() }; { filterDetails(data.Offercode) } }}><img src="assets/images/flight-result/copy.svg" alt="" />Flight
                                    Details
                                </div>
                                </div> */}
                                {/* </div>
                                </div>
                            </div> */}
                            </Accordion.Item>
                            </>
                        )
                        })
                    }
                <div className="fareDetailsWrap">
                    <div className='fareDetailTitle'>Fare Details</div>
                    {/* <div className='fareRuleTitle'>Fare Rules</div> */}
                </div>
                {data?.price?.tarriffinfo?.map((pax ,idx) => (                    
                  <Accordion.Item eventKey={idx + 2} className='travellerItem' key={idx}>
                      <Accordion.Header className='tripSumHeader travellerHeader'>
                          <div className="d-flex justify-content-between align-items-center">
                              <div className="travellerType"><i class="icon icon-down-arrow"></i> Traveller {pax.quantity} ({pax.paxtype === "ADULT" ? "Adult" : pax.paxtype === "CHD" ? "Child" : "Infant" })</div>
                              <div className="currency">{data?.cur ? data?.cur : data?.price?.currency} {(Number(pax?.totalprice) * data.base_usr_exrate).toFixed(2)}</div>
                          </div>
                      </Accordion.Header>
                      <Accordion.Body className='fareDetailBody'>
                          <div className="d-flex justify-content-between align-items-center mb-12">
                              <div className="fareDetailBodyTitle">Base Fare</div>
                              <div className="currAmnt">{data?.cur ? data?.cur : data?.price?.currency} {(Number(pax.baseprice) * data.base_usr_exrate).toFixed(2)}</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                              <div className="fareDetailBodyTitle">Taxes $ Fees <i class="icon icon-Info"></i></div>
                              <div className="currAmnt">{data?.cur ? data?.cur : data?.price?.currency} {(Number(pax?.tax) * data.base_usr_exrate).toFixed(2)}</div>
                          </div>
                      </Accordion.Body>
                  </Accordion.Item>
                ))}
                          
                          {(calAdtBagPrice() !== 0 || calAdtMealPrice() !== 0) && (
                <Accordion.Item eventKey="3" className='travellerItem'>
                    <Accordion.Header className='tripSumHeader travellerHeader'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="travellerType"><i class="icon icon-down-arrow"></i> Extras </div>
                            <div className="currency">{data?.cur ? data?.cur : data?.price?.currency} {(calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice()).toFixed(2)}</div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='fareDetailBody'>
                      
                          {calAdtBagPrice() !== 0 && (                          
                          <div className="d-flex justify-content-between align-items-center mb-12">
                              <div className="fareDetailBodyTitle">Baggage</div>
                              <div className="currAmnt">{data?.cur ? data?.cur : data?.price?.currency}  {(calAdtBagPrice() + calChdBagPrice()).toFixed(2)}</div>
                          </div>
                          )}
                          {calAdtMealPrice() !== 0 && (                         
                          <div className="d-flex justify-content-between align-items-center mb-12">
                              <div className="fareDetailBodyTitle">Meals</div>
                              <div className="currAmnt">{data?.cur ? data?.cur : data?.price?.currency}  {(calAdtMealPrice() + calChdMealPrice()).toFixed(2)}</div>
                          </div>
                          )}
                    </Accordion.Body>
                </Accordion.Item>
            )}
            </Accordion>
            {props?.promoDisc !== undefined && props?.promoDisc !== 0 && (
              <div>{"Promo Discount:"} <FormateCurrency value={props?.promoDisc} /></div>
            )}
            <div className="payableAmntWrap">
                <div className="payableAmntTitle">Total Payable Amount</div>
                <div className="currAmnt">{data?.cur ? data?.cur : data?.price?.currency} {((Number(data?.price?.total_price - (props?.promoDisc ? props?.promoDisc : 0)) * data.base_usr_exrate) + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice()).toFixed(2)}</div>
            </div>
            {/* <div className="installmentTitle"><i class="icon icon-tick"></i> Installments</div> */}
            <div class="rightSemicircle"></div>
            {/* Flight details popup start.... */}
            <FlightDetailsPopup data={data} cur={data?.cur} modalShow={modalShow} closeModal={closeFlightDetails} />
        </div>
    )
}

export default TripSummary
