import react from 'react'
import moment from 'moment';
import { isNotNull } from './validators';
class DateUtils {

  ConvertIsoToSimpleDateFormat(isoDate) {
    // Format : DD/MM/YYYY
    const dob = new Date(isoDate);
    let year = dob.getUTCFullYear();
    let month = dob.getUTCMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let date = dob.getUTCDate();
    date = date < 10 ? '0' + date : date;
    return date + "/" + month + "/" + year;
  }
  secondsToHm = (seconds) => {
    //d = Number(d);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "mins" : "mins") : "";

    return dDisplay + hDisplay + mDisplay;
  }
  convertStringToDate(dateString) {
    const dateObj = moment(dateString).format('YYYY-MM-DD');
    return dateObj;
  }

  convertNewToDate(dateString) {
    if (dateString === "" || dateString === undefined) return "";
    const dateObj = new Date(dateString);
    return dateObj;
  }

  convertStringdateFormat(dateString) {
    const dateObj = moment(dateString).format('MMM DD YYYY');
    return dateObj;
  }

  timeStampToDate(duration) {
    var d = new Date(duration);

    return d.toDateString();
  }
  prettyDate(timeStamp, format) {
    const dateObj = moment(timeStamp).format(format);
    return dateObj;
  }


  prettyTTLDate(timeStamp, format) {
    //moment(1369266934311).utcOffset(60).format('YYYY-MM-DD HH:mm')
    //moment(1369266934311).utcOffset('+0100').format('YYYY-MM-DD HH:mm')
    const dateObj = moment(timeStamp).utcOffset(0).format(format);
    return dateObj;
  }

  msTTime(timeStamp) {
    // Pad to 2 or 3 digits, default is 2
    var ms = Date.parse(timeStamp);
    return new Date(ms).toISOString().slice(11, -1);
  }


  timeStampToTime(duration) {
    var d = new Date(duration);

    return d.toLocaleTimeString();
  }


  convertToDate(dateString) {
    const dateObj = moment(dateString).format('DD/MM/YYYY');
    return dateObj;
  }
  convertToDateFormate(dateString) {
    const dateObj = moment(dateString).format('DD-MMM-YYYY HH:MM');
    return dateObj;
  }

  convertToDateTime(dateString) {
    const dateObj = moment(dateString).format('DD-MM-YYYYTHH:mm:ss');
    return dateObj;
  }

  convertStringdateFormat(dateString) {
    const dateObj = moment(dateString).format('MMM DD YYYY');
    return dateObj;
  }

  convertToDateFormatewithoutTime(dateString) {
    let dateObj = '';
    if (isNotNull(dateString)) {
      dateObj = moment(dateString).format('DD-MMM-YYYY');
    } else {
      dateObj = "-"
    }
    return dateObj;
  }

  timeStampToDate(duration) {
    var d = new Date(duration);

    return d.toDateString();
  }

  msTTime(timeStamp) {
    // Pad to 2 or 3 digits, default is 2
    var ms = Date.parse(timeStamp);
    return new Date(ms).toISOString().slice(11, -1);
  }


  timeStampToTime(duration) {
    var d = new Date(duration);

    return d.toLocaleTimeString();
  }
  msToTime(duration) {
    let days = Math.floor(duration / (24 * 60 * 60 * 1000));
    let daysms = duration % (24 * 60 * 60 * 1000);
    let hours = Math.floor((daysms) / (60 * 60 * 1000));
    let hoursms = duration % (60 * 60 * 1000);
    let minutes = Math.floor((hoursms) / (60 * 1000));
    let minutesms = duration % (60 * 1000);
    let sec = Math.floor((minutesms) / (1000));
    var dDisplay = days > 0 ? days + (days == 1 ? "d, " : "d, ") : "";
    var hDisplay = hours > 0 ? hours + (hours == 1 ? "h" : "h ") : "";
    var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? "m" : "m ") : "";
    return dDisplay + hDisplay + mDisplay;


  }

  convertToDate(dateString) {
    const dateObj = moment(dateString).format('DD/MM/YYYY');
    return dateObj;
  }

  convertToDateHifen(dateString) {
    const dateObj = moment(dateString).format('DD-MM-YYYY');
    return dateObj;
  }

  convertHourMin(x, n) {
    while (x.toString().length < n) {
      x = "0" + x;
    }
    return x;
  }
  convertToLetterDate(dateString) {
    return moment(dateString).format('ddd, DD MMM, YYYY');
  };

  convertToHours(dateString) {
    return moment(dateString, 'DD-MM-YYYYTHH:mm:ss').format('HH');
  };

  isAfter(one, two) {
    var oneConverted = moment(one, 'DD-MM-YYYYTHH:mm:ss')
    var twoConverted = moment(two, 'DD-MM-YYYYTHH:mm:ss')
    var result = oneConverted.isAfter(twoConverted);
    console.log(one)
    console.log(two)
    console.log(result)
    return result;
  };
  isBefore(one, two) {
    var oneConverted = moment(one, 'DD-MM-YYYYTHH:mm:ss')
    var twoConverted = moment(two, 'DD-MM-YYYYTHH:mm:ss')
    var result = oneConverted.isBefore(twoConverted);
    return result;
  }

  isAfterTime(one, two) {
    var oneConverted = moment(one, 'HH:mm:ss')
    var twoConverted = moment(two, 'HH:mm:ss')
    var result = oneConverted.isAfter(twoConverted);
    return result;
  };
  isBeforeTime(one, two) {
    var oneConverted = moment(one, 'HH:mm:ss')
    var twoConverted = moment(two, 'HH:mm:ss')
    var result = oneConverted.isBefore(twoConverted);
    return result;
  };

  calculateHoursandMinsbetweendates(endDate, startDate) {

    console.log('endDate---' + endDate);
    console.log('startDate----' + startDate);
    var timeStart = new Date(startDate).getTime();
    var timeEnd = new Date(endDate).getTime();

    var hourDiff = timeEnd - timeStart; //in ms
    var secDiff = hourDiff / 1000; //in s
    var minDiff = hourDiff / 60 / 1000; //in minutes
    var hDiff = hourDiff / 3600 / 1000; //in hours
    let hours_ = Math.floor(hDiff);
    let mins_ = Math.floor(minDiff - 60 * hours_);
    let hDisplay = hours_ > 0 ? (hours_ + (hours_ === 1 ? " hr " : " hrs ")) : "";
    let mDisplay = mins_ > 0 ? (mins_ + (mins_ === 1 ? " mins" : " mins")) : "";
    return (hDisplay + mDisplay);


  }

  addDatePlusOne(dateString) {
    const dateObj = moment(dateString).add(1, 'days');
    return dateObj._d;
  }
  addDatePlusSeven(dateString) {
    const dateObj = moment(dateString).add(7, 'days');
    return dateObj._d;
  }
  addDateMinusOne(dateString) {
    const dateObj = moment(dateString).subtract(1, 'days');
    return dateObj._d;
  }

  checkHayyCardDate=(date,country)=>{
    let check = false;
    date = new Date(date);  
    var d1=new Date('2022-11-1');
    var d2=new Date('2022-12-23');
    if(date >=d1 && date<=d2 && country==="QA"){
      check = true;
    }
    return check;
  }
}
export default new DateUtils()