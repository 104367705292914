import React, { useState, useReducer, useEffect, useContext } from 'react';
import Footer from '../../../common/Footer';
import moment from "moment";
import FlightReviewDetails from './FlightReviewDetails';
import FlightReviewBar from '../common/FlightReviewBar';
import { Context } from '../../../../App';
import Header from '../../../common/Header';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Form, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import AirlineLogo from '../../../../assets/images/review/QR.png';
import Baggage from '../../../../assets/images/review/Badge.png';
import { convertDate, handleFormatTime, handleTimeChange } from '../../../common/services/DateValidations';
import {
  characterNumberValidation,
  characterValidation,
  emailValidation,
  mobileNumberValidation,
} from "../../../../utilities/FormValidations";
import Flag from '../../../../assets/images/review/Flag.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import TravellerDetails from './TravellerDetails';
import TripSummary from './TripSummary';
import PromocodesList from './PromocodesList';
import { handleGetApi, handlePostApi } from '../../../../utilities/Axios';
import Registration from '../../../common/Registration';
const B2C_COMMON_URL  = process.env.REACT_APP_COMMON_URL;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * Initial State Declaration
 */
const initialState = {
  psngers: [],
  alertMessage: "",
  showAlertMessage: false,
  alertMessageVarient: "",
  baggagePrice: 0,
  mealPrice: 0,
  adtBagPrice: 0,
  chdBagPrice: 0,
  adtMealPrice: 0,
  chdMealPrice: 0,
  promoCodeList: [],
  appliedList: [],
  promo_disc: 0,
};

function FlightReview() {
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [flightDetails, setFlightDetails] = useState(false)
  const [detailData, setDetailData] = useState()
  const [request, setRequest] = useState(location?.state?.req);
  const [cur, setUserCur] = useState("");
  const [loggedIn, setLoggedIn] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [signInShow, setSignInShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [fareruleResp, setFareRuleResp] = useState(
    location.state!==null && location.state.res !== null &&
      location.state.res &&
      location.state.res !== undefined
      ? location.state.res
      : ""
  );
  const handleFlightDetails = () => {
    setFlightDetails(!flightDetails)
  }
  const data = location?.state?.req;
  const filterDetails = (code) => {
    console.log(code)
    // const find = filter.filter(item => item.Offercode === code);
    // console.log("OutboundInboundlist", find)
    // setDetailData(find)
  }

  useEffect(() => {
    try {      
      handleGetApi(`${B2C_COMMON_URL}/b2c/book/getpromolist`).then(function (result) {
        if (result?.data?.suc) {          
          dispatch({ type: "promoCodeList", payload: result?.data?.res });
        } else {
          dispatch({ type: "promoCodeList", payload: [] });          
        }
      }).catch(error => {
        console.log("Erroe in promocodes fetch API", error)
        
      });
    } catch (error) {
      console.log("Erroe in promocodes  fetch API", error)
    }

  }, [])

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  useEffect(() => {
    let loggedInfo = localStorage.getItem("logged");
    if (loggedInfo && loggedInfo !== null && loggedInfo !== "") {
      setLoggedIn(JSON.parse(loggedInfo));
    } else {
      setLoggedIn(null);
    }
  }, [localStorage?.getItem("logged")]);

  const handleLoggedInData = (data) => {
    setLoggedIn(data);
  }

  const handleSignInClose = () => {
    setSignInShow(false);
  }
  const handleSignInShow = () => {
    setSignInShow(true);
    setRegisterShow(false);    
  }

  const handleRegisterClose = () => {
    setRegisterShow(false);
  }
  const handleRegisterShow = () => {
    setRegisterShow(true);
    setSignInShow(false);
  }

  // Airlines logo...
  const handleLogo = (data) => {
    let logo;
    try {
      logo = require(`../../../../assets/airlines-logo/${data}.png`);
    } catch (error) {
      logo = null;
    }
    return logo
  }

  const passengrsData = (data) => {
    dispatch({ type: "psngers", payload: data });
  };

  //=====================================
  // This functiona will Create the Prebook request Json and Call Pre presistance service
  //=====================================
  const countinueBooking = () => {
    for(let pax of state.psngers){
      if(pax.fn!=="" && !pax.fn.includes(" ")){
      let fn=pax.fn;
      pax.fn=fn.trim();
      }else{
       var str =pax.fn;
       let fn = str.replace(/ +/g, " ");
       pax.fn=fn.trim();
      }
      if(pax.ln!=="" && !pax.ln.includes(" ")){
       let ln=pax.ln;
       pax.ln=ln.trim();
       }else{
       var str =pax.ln;
       let ln = str.replace(/ +/g, " ");
       pax.ln=ln.trim();
       }
     if(pax.dob===""){
       pax.dob=null;
       }
      if(pax.doc.isd===""){
        pax.doc.isd=null;
      }
      if(pax.doc.exd===""){
        pax.doc.exd=null;
      }
    if(pax.inf!=="" && pax.inf!==undefined){
     if(pax.inf.fn!=="" && pax.inf.fn!==undefined){
       let fn=pax.inf.fn;
       pax.inf.fn=fn.trim();
       }
       if(pax.inf.ln!=="" && pax.inf.ln!==undefined){
        let ln=pax.inf.ln;
        pax.inf.ln=ln.trim();
        }
      if(pax.inf.dob===""){
      pax.inf.dob=null;
      }
      if(pax.inf.doc!==undefined && pax.inf.doc.isd===""){
      pax.inf.doc.isd=null;
      }
      if(pax.inf.doc!==undefined && pax.inf.doc.exd===""){
      pax.inf.doc.exd=null;
      }
    }
   }
    if (validatePax()){
      continuePersistance();
    }
  };

  const continuePersistance = async () => {
    setBtnLoader(true);
    // setIsLoading(true);
    dispatch({ type: "showPriceCheck", payload: false });
    try {
      //UPDATE DOB,ISSUE DATE AND EXP DATE
      for (let pax of state.psngers) {
        if (pax.ty === "ADT" || pax.ty === "CHD") {
          if (pax.dob_year && pax.dob_month && pax.dob_day) {
            pax.dob = `${pax.dob_year}-${pax.dob_month}-${pax.dob_day}`;
          } else {
            pax.dob = "";
          }
          if (pax.doc.isd_year && pax.doc.isd_month && pax.doc.isd_day) {
            pax.doc.isd = `${pax.doc.isd_year}-${pax.doc.isd_month}-${pax.doc.isd_day}`;
          } else {
            pax.doc.isd = "";
          }
          if (pax.doc.exp_year && pax.doc.exp_month && pax.doc.exp_day) {
            pax.doc.exd = `${pax.doc.exp_year}-${pax.doc.exp_month}-${pax.doc.exp_day}`;
          } else {
            pax.doc.exd = "";
          }
        } else {
          pax.inf.dob = `${pax.inf.dob_year}-${pax.inf.dob_month}-${pax.inf.dob_day}`;
          pax.inf.doc.isd = `${pax.inf.doc.isd_year}-${pax.inf.doc.isd_month}-${pax.inf.doc.isd_day}`;
          pax.inf.doc.exd = `${pax.inf.doc.exp_year}-${pax.inf.doc.exp_month}-${pax.inf.doc.exp_day}`;
        }
      }
      //Converting Total Price
      // convertTotalPrice();      
      const passengers = state.psngers.map((type) => {
        let adt = type.ty==="ADT" ? type.ty = "adult" : "";
        let chd = type.ty==="CHD" ? type.ty = "child" : "";
        let inf = type.inf !== "" && type.inf.ty==="INF" ? type.ty = "infant" : "";
        return type
      })
      let total_amount = ((Number(request?.price?.total_price) + state.adtBagPrice + state.adtMealPrice + state.chdBagPrice + state.chdMealPrice) * request?.base_usr_exrate).toFixed(2);
      const prebook = {
        "pbreq": {
        tid: fareruleResp.data.tid,
        offline: fareruleResp.data.offline,
        flow_type: request?.mix_carrier ? "split" : "normal",
        psngrs: passengers,
        bktype:"DIRECT",
        utype: loggedIn && loggedIn.unique_id ? "Registered" : "Guest",
        bkby: loggedIn && loggedIn.unique_id ? loggedIn.unique_id : "",
        req_cur: cur,
        base_cur: BASE_CURRENCY,
        tt: request?.tt,
        user_tot_price : total_amount - state.promo_disc,
        base_usr_exrate: request?.base_usr_exrate,
        // region_type: "Pakistan",
        }
      };
      let response = await handlePostApi(`${B2C_COMMON_URL}/b2c/book/prebook`, prebook);
          if (response?.data!== undefined && response?.data?.res!==undefined && response?.data?.suc) {
          if (
            response?.data?.res?.pbres?.status !== undefined &&
            response?.data?.res?.pbres?.status === "inserted"
          ) {
            setBtnLoader(false);
            request.adtBagPrice = state.adtBagPrice;
            request.adtMealPrice = state.adtMealPrice;
            request.chdBagPrice = state.chdBagPrice;
            request.chdMealPrice = state.chdMealPrice;
            request.promoCode = state.promo_disc;
            navigate("/flight/payment", {
              state: {
                resp : response?.data?.res?.pbres,
                req: request,
              },
            });
          }
        } else {
          setBtnLoader(false);
        }
    } catch (err) {
      setBtnLoader(false);
      console.error("Error in save Booking+++++++++++++++ : " + err);
    }
  };
  const validatePax = (check) => {
    var count = 1;
    let pType = {
      ADT: "Adult",
      INF: "Infant",
      CHD: "Child",
    };
    if (state.psngers && state.psngers.length !== 0) {
      for (let val of state.psngers) {
        if (val.dob_year && val.dob_month && val.dob_day) {
          val.dob = `${val.dob_year}-${val.dob_month}-${val.dob_day}`;
        }
        if (val.doc.isd_year && val.doc.isd_month && val.doc.isd_day) {
          val.doc.isd = `${val.doc.isd_year}-${val.doc.isd_month}-${val.doc.isd_day}`;
        }
        if (val.doc.exp_year && val.doc.exp_month && val.doc.exp_day) {
          val.doc.exd = `${val.doc.exp_year}-${val.doc.exp_month}-${val.doc.exp_day}`;
        }
        if (val.inf.dob_year && val.inf.dob_month && val.inf.dob_day) {
          val.inf.dob = `${val.inf.dob_year}-${val.inf.dob_month}-${val.inf.dob_day}`;
          val.inf.doc.isd = `${val.inf.doc.isd_year}-${val.inf.doc.isd_month}-${val.inf.doc.isd_day}`;
          val.inf.doc.exd = `${val.inf.doc.exp_year}-${val.inf.doc.exp_month}-${val.inf.doc.exp_day}`;
        }

        if (val.tl === "") {
          return validateTitle(val.fesno, pType[val.ty]);
        }
        if (val.fn === "") {
            return validateName(false, "first name", val.fesno, pType[val.ty]);
          } else {
            if (!characterValidation(val.fn)) {
              return validateName(true, "first name", val.fesno, pType[val.ty]);
            }
          }
        if (val.ln === "") {
          return validateName(false, "last name", val.fesno, pType[val.ty]);
        } else {
          if (!characterValidation(val.ln)) {
            return validateName(true, "last name", val.fesno, pType[val.ty]);
          }
        }

        if (
          val.ty === "ADT" &&
          (val.dob === null || val.dob === "")
        ) {
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({
            type: "alertMessage",
            payload:
            "Please select Date of Birth for " +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "alertMessageVarient", payload: "danger" });
          hidemessage();
          return false;
        } else if (
          val.ty === "ADT" &&
          (val.dob === null || val.dob === "")
        ) {
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({
            type: "alertMessage",
            payload:
            "Please select Date of Birth for " +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "alertMessageVarient", payload: "danger" });
          hidemessage();
          return false;
        }

        // validation date of birth for adult
        if (
          val.ty === "ADT" &&
          (val.dob !== null || val.dob !== "")
        ) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be greater than 12 years for Adult" +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }
        if (val.ty === "ADT" && val.dob !== null) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be greater than 12 years for Adult" +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }

        // validation date of birth for adult
        if (
          val.ty === "ADT" &&
          (val.dob !== null || val.dob !== "")
        ) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be greater than 12 years for Adult" +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }
        if (val.ty === "ADT" && val.dob !== null) {
          // return ValidateAdtDob(val.dob, val.fesno)

          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365.25);

          // Check if the date of birth is greater than 2 years from the current date
          if (differenceInYears < 12) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be greater than 12 years for Adult" +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }
        if (checkMandatoryFlagNat() === true && val.doc.na === "") {
          return validateNationality(val.fesno, pType[val.ty]);
        }
        if (val.ty === "CHD" && val.dob === null) {
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({
            type: "alertMessage",
            payload:
            "Please select Date of Birth for " +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "alertMessageVarient", payload: "danger" });
          hidemessage();
          return false;
        }

        // Child date of birth validation
        if (val.ty === "CHD" && val.dob !== null) {
          // "year-month-day" (e.g., "2021-04-3")
          const DobString = val.dob;
          const Dob = new Date(DobString);

          // Get the current date
          const currentDate = new Date();
          // Calculate the difference between the current date and the date of birth in milliseconds
          const differenceInMs = currentDate - Dob;

          // Calculate the difference in years
          const differenceInYears =
            differenceInMs / (1000 * 60 * 60 * 24 * 365);

          // Check if the date of birth is less than 2 years from the current date
          if (differenceInYears < 2) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be greater than 2 years for " +
                pType[val.ty] +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }

          // Check if the date of birth is greater than 12 years from the current date
          if (differenceInYears > 12) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Date of birth should be less than 12 years for " +
                pType[val.ty] +
                " " +
                count,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }
        if (
          val.fn !== "" &&
          val.fn !== undefined &&
          val.fn.length === 1
        ) {
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({
            type: "alertMessage",
            payload:
            "First name should be minimum two characters " +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "alertMessageVarient", payload: "danger" });
          hidemessage();
          return false;
        }
        if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({
            type: "alertMessage",
            payload:
            "Last name should be minimum two characters " +
              pType[val.ty] +
              "" +
              val.fesno,
          });
          dispatch({ type: "alertMessageVarient", payload: "danger" });
          hidemessage();
          return false;
        }
        //Infant validations
        if (val.inf !== undefined && request.inf > 0) {
          if (val.inf.tl === "") {
            return validateTitle(val.fesno, "Infant");
          }
          if (state.onwardSupp !== "0" && val.inf.fn === "") {
            return validateName(false, "first name", val.fesno, "Infant");
          } else {
            if (state.onwardSupp !== "0" && !characterValidation(val.inf.fn)) {
              return validateName(true, "first name", val.fesno, "Infant");
            }
          }
          if (val.inf.ln === "") {
            return validateName(false, "last name", val.fesno, "Infant");
          } else {
            if (!characterValidation(val.inf.ln)) {
              return validateName(true, "last name", val.fesno, "Infant");
            }
          }

          if (
            val.inf !== "" &&
            val.inf.fn !== undefined &&
            val.inf.fn !== "" &&
            val.inf.fn.length === 1
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "First name should be minimum two characters for Infant " +
                val.fesno,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf.ln !== undefined &&
            val.inf.ln !== "" &&
            val.inf.ln.length === 1
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Last name should be minimum two characters for Infant " +
                val.fesno,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            (val.inf.dob === "" || val.inf.dob === null)
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please select Date of Birth for INF " + val.fesno,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            (val.inf.dob !== "" || val.inf.dob !== null)
          ) {
            // return ValidateInfDob(val.inf.dob, val.fesno)

            // "year-month-day" (e.g., "2021-04-3")
            const DobString = val.inf.dob;
            const Dob = new Date(DobString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date of birth is a future date
            if (Dob > currentDate) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Date of birth cannot be a future date for INF" + " " + count,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }

            // Calculate the difference between the current date and the date of birth in milliseconds
            const differenceInMs = currentDate - Dob;

            // Calculate the difference in years
            const differenceInYears =
              differenceInMs / (1000 * 60 * 60 * 24 * 365);

            // Check if the date of birth is greater than 2 years from the current date
            if (differenceInYears > 2) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Date of birth should be less than 2 years for INF" +
                  " " +
                  count,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }

          //alJazeera conditions handle for inf dob > 14 days from flight date
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            val.inf.dob !== "" &&
            val.inf.dob !== null &&
            (state.onwardSupp == "3" || state.returnSupp == "3") &&
            state.departure_date
          ) {
            let departureTime = new Date(state.departure_date).getTime();
            let dobTime = new Date(val.inf.dob).getTime();
            let hourDiff = departureTime - dobTime;
            var hDiff = hourDiff / 3600 / 1000; //in hours
            let hours = Math.floor(hDiff);
            if (hours < 336) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Date of Birth for INF cannot be less than 14 Days - for Infant " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }
          // inf_dept_date
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            val.inf.dob !== "" &&
            val.inf.dob !== null &&
            state.inf_dept_date
          ) {
            let dobinNum = Number(moment(val.inf.dob).format("YYYYMMDD"));
            let deptinNum = Number(
              moment(state.inf_dept_date).format("YYYYMMDD")
            );
            if (dobinNum < deptinNum) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Infant Date of Birth is more than 2 years of Travelling for INF " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }
          if (
            val.inf !== "" &&
            val.inf !== undefined &&
            val.inf.doc.exd === null
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please select Expiry Date for INF " + val.fesno,
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          
          //Passport validations
          if (
            fareruleResp.data !== undefined &&
            fareruleResp.data.doc
          ) {
            if (val.inf !== undefined && val.inf.doc !== undefined) {
              if (val.inf.doc.na === undefined) {
                return validateNational(val.fesno, "Infant");
              } else if (
                val.inf.doc !== undefined &&
                val.inf.doc.isc === undefined
              ) {
                return validateIssuCntry(val.fesno, "Infant");
              } else if (val.inf.doc !== undefined && val.inf.doc.num === "") {
                return validatePassport(false, val.fesno, "Infant");
              } else {
                if (!characterNumberValidation(val.inf.doc.num)) {
                  return validatePassport(true, val.fesno, "Infant");
                }
              }
            }
          }

          //Validating Passport and Expiry date for Indigo
          // if (
          //   (request.origns.length > 0 &&
          //     request.origns[0].srcorigns[0].sup === "4") ||
          //   (request.origns.length > 0 &&
          //     request.origns[0].destorigins !== undefined &&
          //     request.origns[0].destorigins.length > 0 &&
          //     request.origns[0].destorigins[0].sup === "4")
          // ) {
          //   if (state.indigo_passport) {
          //     if (
          //       val.inf.doc !== undefined &&
          //       val.inf.doc.num !== undefined &&
          //       val.inf.doc.num === ""
          //     ) {
          //       return validatePassport(false, val.fesno, "Infant");
          //     } else if (
          //       val.inf.doc !== undefined &&
          //       val.inf.doc.exd !== undefined &&
          //       val.inf.doc.exd === null
          //     ) {
          //       return validateExpiry(val.fesno, "Infant");
          //     }
          //   }
          // }
        }
        //Lead pax validations
        if (val.ty === "ADT" && val.lead) {
          if (
            // state.bookingType === "DIRECT" &&
            (val.phc === "" || val.phc === null)
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please select phone code for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (val.phn === "") {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please enter phone number for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          } else if (!mobileNumberValidation(val.phn)) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please enter valid phone number for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (
            state.bookingType === "DIRECT" &&
            val.destnum !== undefined &&
            val.destnum !== "" &&
            !mobileNumberValidation(val.destnum)
          ) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload:
              "Please enter valid destination contact number for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
          if (val.em === "") {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please enter email id for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          } else if (!emailValidation(val.em)) {
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({
              type: "alertMessage",
              payload: "Please enter valid email id for Lead pax",
            });
            dispatch({ type: "alertMessageVarient", payload: "danger" });
            hidemessage();
            return false;
          }
        }
        //Passport validations
        if (
            fareruleResp.data !== undefined &&
            fareruleResp.data.doc
        ) {
          if (val.doc.na === "") {
            return validateNational(val.fesno, pType[val.ty]);
          } else if (val.doc.isc === "") {
            return validateIssuCntry(val.fesno, pType[val.ty]);
          } else if (val.doc.num === "") {
            return validatePassport(false, val.fesno, pType[val.ty]);
          }
          // else if(val.doc.isd===""){
          //   return validateIssuDate(val.fesno,  pType[val.ty]);
          // }
          else if (val.doc.isd !== null) {
            // "year-month-day" (e.g., "2021-04-3")
            const IsdString = val.doc.isd;
            const isd = new Date(IsdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (isd > currentDate) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Issued Date cannot be a future date for " +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          } else if (val.doc.exd === null) {
            return validateExpiry(val.fesno, pType[val.ty]);
          } else if (val.doc.exd !== null) {
            // "year-month-day" (e.g., "2021-04-3")
            const ExdString = val.doc.exd;
            const exd = new Date(ExdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (exd < currentDate) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Expiry Date cannot be a past date for " +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          } else {
            if (!characterNumberValidation(val.doc.num)) {
              return validatePassport(true, val.fesno, pType[val.ty]);
            }
          }
        }

        //Validating Passport Number for Indigo
        // if (
        //   (request.origns.length > 0 &&
        //     request.origns[0].srcorigns[0].sup === "4") ||
        //   (request.origns.length > 0 &&
        //     request.origns[0].destorigins !== undefined &&
        //     request.origns[0].destorigins.length > 0 &&
        //     request.origns[0].destorigins[0].sup === "4")
        // ) {
        //   if (state.indigo_passport) {
        //     if (val.doc.num === "") {
        //       return validatePassport(false, val.fesno, pType[val.ty]);
        //     } else if (val.doc.isd !== null) {
        //       // "year-month-day" (e.g., "2021-04-3")
        //       const IsdString = val.doc.isd;
        //       const isd = new Date(IsdString);

        //       // Get the current date
        //       const currentDate = new Date();
        //       // Check if the date is a past date
        //       if (isd > currentDate) {
        //         dispatch({ type: "showAlertMessage", payload: true });
        //         dispatch({
        //           type: "alertMessage",
        //           payload:
        //           "Issued Date cannot be a future date for " +
        //             pType[val.ty] +
        //             " " +
        //             val.fesno,
        //         });
        //         dispatch({ type: "alertMessageVarient", payload: "danger" });
        //         hidemessage();
        //         return false;
        //       }
        //     } else if (val.doc.exd === null) {
        //       return validateExpiry(val.fesno, pType[val.ty]);
        //     } else if (val.doc.exd !== null) {
        //       // "year-month-day" (e.g., "2021-04-3")
        //       const ExdString = val.doc.exd;
        //       const exd = new Date(ExdString);

        //       // Get the current date
        //       const currentDate = new Date();
        //       // Check if the date is a past date
        //       if (exd < currentDate) {
        //         dispatch({ type: "showAlertMessage", payload: true });
        //         dispatch({
        //           type: "alertMessage",
        //           payload:
        //           "Expiry Date cannot be a past date for " +
        //             pType[val.ty] +
        //             " " +
        //             val.fesno,
        //         });
        //         dispatch({ type: "alertMessageVarient", payload: "danger" });
        //         hidemessage();
        //         return false;
        //       }
        //     }
        //   }
        // }

        // validating if issued date and expiry date of adult and child only after filled fields
        if (val.doc.isd_year && val.doc.isd_month && val.doc.isd_day) {
          if (val.doc.isd !== null && val.doc.isd !== "") {
            // "year-month-day" (e.g., "2021-04-3")
            const IsdString = val.doc.isd;
            const isd = new Date(IsdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (isd > currentDate) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Issued Date cannot be a future date for " +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }
        }
        if (val.doc.exp_year && val.doc.exp_month && val.doc.exp_day) {
          if (val.doc.exd !== null && val.doc.exd !== "") {
            // "year-month-day" (e.g., "2021-04-3")
            const ExdString = val.doc.exd;
            const exd = new Date(ExdString);

            // Get the current date
            const currentDate = new Date();
            // Check if the date is a past date
            if (exd < currentDate) {
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({
                type: "alertMessage",
                payload:
                "Expiry Date cannot be a past date for " +
                  pType[val.ty] +
                  " " +
                  val.fesno,
              });
              dispatch({ type: "alertMessageVarient", payload: "danger" });
              hidemessage();
              return false;
            }
          }
        }

        // validating if issued date and expiry date of infant only after filled fields
        if(val.inf !== "" && val.inf !== undefined) {
          if(val.inf.doc.isd_year && val.inf.doc.isd_month && val.inf.doc.isd_day) {
            if (val.inf.doc.isd !== null && val.inf.doc.isd !== "") {
              // "year-month-day" (e.g., "2021-04-3")
              const IsdString = val.inf.doc.isd;
              const isd = new Date(IsdString);
    
              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (isd > currentDate) {
                dispatch({ type: "showAlertMessage", payload: true });
                dispatch({
                  type: "alertMessage",
                  payload:
                  "Issued Date cannot be a future date for" +
                    " Infant " +
                    val.fesno,
                });
                dispatch({ type: "alertMessageVarient", payload: "danger" });
                hidemessage();
                return false;
              }
            }
          }
          if(val.inf.doc.exp_year && val.inf.doc.exp_month && val.inf.doc.exp_day) {
            if (val.inf.doc.exd !== null && val.inf.doc.exd !== "") {
              // "year-month-day" (e.g., "2021-04-3")
              const ExdString = val.inf.doc.exd;
              const exd = new Date(ExdString);
    
              // Get the current date
              const currentDate = new Date();
              // Check if the date is a past date
              if (exd < currentDate) {
                dispatch({ type: "showAlertMessage", payload: true });
                dispatch({
                  type: "alertMessage",
                  payload:
                  "Expiry Date cannot be a past date for" +
                    " Infant " +
                    val.fesno,
                });
                dispatch({ type: "alertMessageVarient", payload: "danger" });
                hidemessage();
                return false;
              }
            }
          }
        }
        //Validating the Seat
        if (state.bookingType === "DIRECT") {
          var seat = "";
          if (val.ty === "ADT" || val.ty === "CHD") {
            if (request.sty === "normal") {
              if (request.tt === "1") {
                seat = request.origns[0].srcorigns[0].pr.seats;
                if (
                  seat !== undefined &&
                  seat === "Free" &&
                  val.seats.length === 0
                ) {
                  dispatch({ type: "showAlertMessage", payload: true });
                  dispatch({
                    type: "alertMessage",
                    payload: "Please select Seat for " + val.ty + val.fesno,
                  });
                  dispatch({ type: "alertMessageVarient", payload: "danger" });
                  hidemessage();
                  return false;
                }
              } else if (request.tt === "2") {
                seat = request.origns[0].srcorigns[0].pr.seats;
                var retSeat = request.origns[0].destorigins[0].pr.seats;
                if (
                  seat !== undefined &&
                  seat !== "" &&
                  retSeat !== "" &&
                  val.seats.length === 0 &&
                  (seat === "Free" || retSeat === "Free")
                ) {
                  dispatch({ type: "showAlertMessage", payload: true });
                  dispatch({
                    type: "alertMessage",
                    payload: "Please select Seat for " + val.ty + val.fesno,
                  });
                  dispatch({ type: "alertMessageVarient", payload: "danger" });
                  hidemessage();
                  return false;
                }
              }
              //Validating the Meal
              if (
                request.origns[0].srcorigns[0].sup === "4" &&
                request.region_type === "International"
              ) {
                if (
                  request.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") ||
                  request.origns[0].srcorigns[0].pr.ftyp.includes("Corporate")
                ) {
                  let count = 1;
                  for (let meal of val.meals) {
                    if (meal.label === "Select") {
                      dispatch({ type: "showAlertMessage", payload: true });
                      if (count === 1) {
                        dispatch({
                          type: "alertMessage",
                          payload:
                          "Please select Onward Meal for " +
                            val.ty +
                            val.fesno,
                        });
                      } else {
                        dispatch({
                          type: "alertMessage",
                          payload:
                          "Please select Return Meal for " +
                            val.ty +
                            val.fesno,
                        });
                      }
                      dispatch({ type: "alertMessageVarient", payload: "danger" });
                      hidemessage();
                      return false;
                    }
                    count = count + 1;
                  }
                }
              }
            } else {
              var retSeat = "";
              if (state.onwardSupp === "2") {
                seat = request.origns[0].srcorigns[0].pr.seats;
              } else if (
                state.returnSupp === "2" &&
                request.origns[0].destorigins !== undefined
              ) {
                retSeat = request.origns[0].destorigins[0].pr.seats;
              }
              if (
                seat !== undefined &&
                retSeat !== "" &&
                val.seats.length === 0 &&
                (seat === "Free" || retSeat === "Free")
              ) {
                dispatch({ type: "showAlertMessage", payload: true });
                dispatch({
                  type: "alertMessage",
                  payload: "Please select Seat for " + val.ty + val.fesno,
                });
                dispatch({ type: "alertMessageVarient", payload: "danger" });
                hidemessage();
                return false;
              }
              //Validating the Meal
              if (
                request.region_type === "International" &&
                (request.origns[0].srcorigns[0].sup === "4" ||
                  request.origns[0].destorigins[0].sup === "4")
              ) {
                let onwardftype = false;
                let returnftype = false;
                if (
                  request.origns[0].srcorigns[0].sup === "4" &&
                  (request.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") ||
                    request.origns[0].srcorigns[0].pr.ftyp.includes(
                      "Corporate"
                    ))
                ) {
                  onwardftype = true;
                }
                if (
                  request.origns[0].destorigins[0].sup === "4" &&
                  (request.origns[0].destorigins[0].pr.ftyp.includes("Flexi") ||
                    request.origns[0].destorigins[0].pr.ftyp.includes(
                      "Corporate"
                    ))
                ) {
                  returnftype = true;
                }
                if (onwardftype || returnftype) {
                  let count = 1;
                  for (let meal of val.meals) {
                    if (meal.label === "Select") {
                      dispatch({ type: "showAlertMessage", payload: true });
                      if (count === 1) {
                        dispatch({
                          type: "alertMessage",
                          payload:
                          "Please select Onward Meal for " +
                            val.ty +
                            val.fesno,
                        });
                      } else {
                        dispatch({
                          type: "alertMessage",
                          payload:
                          "Please select Return Meal for " +
                            val.ty +
                            val.fesno,
                        });
                      }
                      dispatch({ type: "alertMessageVarient", payload: "danger" });
                      hidemessage();
                      return false;
                    }
                    count = count + 1;
                  }
                }
              }
            }
          }
        }
      }
      count = count + 1;
    } else {
      dispatch({ type: "showAlertMessage", payload: true });
      dispatch({
        type: "alertMessage",
        payload: "Please enter Passenger details",
      });
      dispatch({ type: "alertMessageVarient", payload: "danger" });
      hidemessage();
      return false;
    }

    return true;
  };

  const validateTitle = (count, type) => {
    dispatch({ type: "showAlertMessage", payload: true });
    dispatch({
      type: "alertMessage",
      payload: "Please select title for passenger " + type + " " + count,
    });
    dispatch({ type: "alertMessageVarient", payload: "danger" });
    hidemessage();
    return false;
  };
  const validateNationality = (count, pax) => {
    dispatch({ type: "showAlertMessage", payload: true });
    dispatch({
      type: "alertMessage",
      payload: "Please select Nationality for " + pax + " " + count,
    });
    dispatch({ type: "alertMessageVarient", payload: "danger" });
    hidemessage();
    return false;
  };
  const validateName = (name, type, count, pax) => {
    if (!name) {
      dispatch({ type: "showAlertMessage", payload: true });
      dispatch({
        type: "alertMessage",
        payload: "Please enter " + type + " for passenger " + pax + " " + count,
      });
      dispatch({ type: "alertMessageVarient", payload: "danger" });
      hidemessage();
      return false;
    } else {
      dispatch({ type: "showAlertMessage", payload: true });
      dispatch({
        type: "alertMessage",
        payload:
        "Please enter valid " + type + " for passenger " + pax + " " + count,
      });
      dispatch({ type: "alertMessageVarient", payload: "danger" });
      hidemessage();
      return false;
    }
  };

  const validatePassport = (name, count, type) => {
    if (!name) {
      dispatch({ type: "showAlertMessage", payload: true });
      dispatch({
        type: "alertMessage",
        payload:
        "Please enter passport number for passenger " + type + " " + +count,
      });
      dispatch({ type: "alertMessageVarient", payload: "danger" });
      hidemessage();
      return false;
    } else {
      dispatch({ type: "showAlertMessage", payload: true });
      dispatch({
        type: "alertMessage",
        payload:
        "Please enter valid passport number for passenger " +
          type +
          " " +
          +count,
      });
      dispatch({ type: "alertMessageVarient", payload: "danger" });
      hidemessage();
      return false;
    }
  };
  const validateNational = (count, type) => {
    dispatch({ type: "showAlertMessage", payload: true });
    dispatch({
      type: "alertMessage",
      payload: "Please select nationality for passenger " + type + " " + count,
    });
    dispatch({ type: "alertMessageVarient", payload: "danger" });
    hidemessage();
    return false;
  };
  const validateIssuCntry = (count, type) => {
    dispatch({ type: "showAlertMessage", payload: true });
    dispatch({
      type: "alertMessage",
      payload:
      "Please select issued country for passenger " + type + " " + count,
    });
    dispatch({ type: "alertMessageVarient", payload: "danger" });
    hidemessage();
    return false;
  };

  const validateExpiry = (count, type) => {
    dispatch({ type: "showAlertMessage", payload: true });
    dispatch({
      type: "alertMessage",
      payload: "Please select Expiry Date for passenger " + type + " " + count,
    });
    dispatch({ type: "alertMessageVarient", payload: "danger" });
    hidemessage();
    return false;
  };
  const checkMandatoryFlagNat = () => {
    if (
      state.onwardSupp === "2" ||
      state.returnSupp === "2" ||
      state.onwardSupp === "3" ||
      state.returnSupp === "3"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "showAlertMessage", payload: false });
      dispatch({ type: "alertMessage", payload: "" });
      dispatch({ type: "alertMessageVarient", payload: "" });
    }, 5000);
  };

  const updateBaggagePrice = (obj) => {    
    dispatch({ type: "baggagePrice", payload: obj });
  }

  const updateMealPrice = (obj) => {    
    dispatch({ type: "mealPrice", payload: obj });
  }
  
  const updateAdtPrice=(obj)=>{
    if(state.adtBagPrice!==obj) {
      state.adtBagPrice=obj;
      applyPromoCode(promoCode, "autoApply")
    }
  }

  const updateChdPrice=(obj)=>{
    if(state.chdBagPrice!==obj) {
      state.chdBagPrice=obj;
      applyPromoCode(promoCode, "autoApply")
    }
  }

  const updateAdtMealPrice=(obj)=>{
    if(state.adtMealPrice!==obj) {
      state.adtMealPrice=obj;
      applyPromoCode(promoCode, "autoApply")
    }
  }

  const updateChdMealPrice=(obj)=>{
    if(state.chdMealPrice!==obj) {
      state.chdMealPrice=obj;
      applyPromoCode(promoCode, "autoApply")
    }
  }

  const removeAppliedData=()=>{
    dispatch({ type: "promo_disc", payload: 0 });
    dispatch({ type: "promo_remarks", payload: "" });
    dispatch({ type: "promo_code", payload: "" });
    dispatch({ type: "promo_id", payload: 0 });
  }

  // APPLY PROMO CODE
  const applyPromoCode = async (code, SSR)=>{
    if(code!=="") {
      setPromoCode(code);
      try {
        let checkPromo = state.promoCodeList.length > 0 && state.promoCodeList.filter((promo) => promo.promo_code===code && promo.is_active === 1);
        let promoExistList = [];
        if(state.appliedList.length > 0){
          promoExistList = state.appliedList.length > 0 && state.appliedList.filter((obj) => obj.promo_code===code);
         if(promoExistList===undefined){
            promoExistList = [];
         }
        }
        if(code!=="" && (checkPromo.length!==0 || SSR==="autoApply")){
          // let adtFare = request.paxpr.filter((pax =>pax.ptype==="ADT"));
          // let chdFare = request.paxpr.filter((pax =>pax.ptype==="CHD"));
          // let infFare = request.paxpr.filter((pax =>pax.ptype==="INF"));
          // let baseFare=Number(adtFare[0].bf) * Number(request.adt);
          // if(request.chd > 0 && chdFare.length > 0){
          //   baseFare=Number(baseFare) + Number(chdFare[0].bf) * Number(request.chd);
          // }
          // if(request.inf > 0 && infFare.length > 0){
          //   baseFare=Number(baseFare) + Number(infFare[0].bf) * Number(request.inf);
          // }
          // let total=request.pr.tf;
          // if(request.servicefee!==undefined && request.servicefee.length >0){
          //   total=Number(total) + Number(request.servicefee[0].complete_sfvalue);
          // }
          // if(state.otherCharge!==0 && state.otherCharge!==undefined){
          //   total=Number(total) + Number(state.otherCharge);
          // }
          // if(state.adtBagPrice!==0 || state.chdBagPrice!==0){
          //   total=Number(total) + Number(state.adtBagPrice) + Number(state.chdBagPrice);
          // }
          // if(state.adtMealPrice!==0 || state.chdMealPrice!==0){
          //   total=Number(total) + Number(state.adtMealPrice) + Number(state.chdMealPrice);
          // }
          // if(state.seatPrice!==0 && state.seatPrice!==undefined){
          //   total= Number(total) + Number(state.seatPrice);
          // }
          // if(state.totalAmount !== total || promoExistList.length===0) {
          if(promoExistList.length===0) {
            const req={
              "promo_code":code,
              // "user_id":context.user_resp?.res?.id,
              "promo_id":checkPromo[0].promo_id,
              // "base_fare":baseFare,
              // "total_fare":total,
              "product_type":"Flight"
           }
           handlePostApi(`${B2C_COMMON_URL}/b2c/book/getpromodetails`, req).then((response) => {
            if (response?.data !== undefined && response?.data?.suc) {
              if(response?.data?.res[0]?.discount_type === "p") {
                if(response?.data?.res[0]?.discount_on === "bf") {
                  let discount = response?.data?.res[0]?.discount_value * Number(request?.price?.base_price) / 100
                  dispatch({ type: "promo_disc", payload: discount });
                } else {                  
                  let discount = response?.data?.res[0]?.discount_value * Number(request?.price?.total_price) / 100
                  dispatch({ type: "promo_disc", payload: discount });
                }
              } else {
                dispatch({ type: "promo_disc", payload: response?.data?.res[0]?.discount_value });
              }
              // dispatch({ type: "promo_remarks", payload: response.data.res.remarks });
              dispatch({ type: "promo_code", payload: code });
              // dispatch({ type: "promo_id", payload: response.data.res.promo_id });
              let noPax=request.adults+request.children+request.infants;
              let paxDisc = response?.data?.res[0]?.discount_value/noPax;
              dispatch({ type: "pax_pdisc", payload: request.sty==="normal"?paxDisc:Number(paxDisc)/2 });
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({ type: "alertMessage", payload: "Promo code applied succesfully..."});
              dispatch({ type: "alertMessageVarient", payload: "success" });
              response.data.res[0].pro_code=code;
              response.data.res[0].pax_pdisc=request.sty==="normal"?paxDisc:Number(paxDisc)/2
              let list = state.appliedList;
              list.unshift(response.data.res);
              dispatch({ type: "appliedList", payload: list });
              // dispatch({ type: "totalAmount", payload: total });
              hidemessage();
              }else{
              if(response.data.res===500){
                dispatch({ type: "alertMessage", payload: "Sorry for the incovenience; it appears that this promo discount is no longer available"});
              }else if(response.data.res===501){
                dispatch({ type: "alertMessage", payload: "Sorry the maximum number of redemptions for this code has been reached"});
              }else if(response.data.res===502){
                dispatch({ type: "alertMessage", payload: "Sorry the maximum number of redemptions for this code has been reached"});
              }else if(response.data.res===503){
                dispatch({ type: "alertMessage", payload: "To use this promo code, your booking must meet a certain minimum amount"});
              }else if(response.data.res===504){
                dispatch({ type: "alertMessage", payload: "Something went wrong on our end while processing your promo code. Please try again later or contact customer support for assistance"});
              }
              dispatch({ type: "showAlertMessage", payload: true });
              dispatch({ type: "alertMessageVarient", payload: "error" });
              dispatch({ type: "promo_disc", payload: 0 });
              dispatch({ type: "pax_pdisc", payload: 0 });
              dispatch({ type: "promo_code", payload: "" });
              hidemessage();
               }
            }).catch(function (error) {
              console.error("Error in applyPromoCode : " + error);      
            });
          } else if(promoExistList.length!==0){
            dispatch({ type: "promo_disc", payload: promoExistList[0].discount_value });
            // dispatch({ type: "promo_remarks", payload: promoExistList[0].remarks });
            dispatch({ type: "promo_code", payload: code });
            // dispatch({ type: "promo_id", payload: promoExistList[0].promo_id });
            dispatch({ type: "pax_pdisc", payload: promoExistList[0].pax_pdisc });
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({ type: "alertMessage", payload: "Promo code applied succesfully..."});
            dispatch({ type: "alertMessageVarient", payload: "success" });
            hidemessage()
          }else{
            dispatch({ type: "showAlertMessage", payload: true });
            dispatch({ type: "alertMessage", payload: "Enter valid promo code"});
            dispatch({ type: "alertMessageVarient", payload: "error" });
            dispatch({ type: "promo_disc", payload: 0 });
            dispatch({ type: "promo_code", payload: "" });
            hidemessage();
          }      
        }else if(promoExistList.length!==0){
          dispatch({ type: "promo_disc", payload: promoExistList[0].discount_value });
          // dispatch({ type: "promo_remarks", payload: promoExistList[0].remarks });
          dispatch({ type: "promo_code", payload: code });
          // dispatch({ type: "promo_id", payload: promoExistList[0].promo_id });
          dispatch({ type: "pax_pdisc", payload: promoExistList[0].pax_pdisc });
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({ type: "alertMessage", payload: "Promo code applied succesfully..."});
          dispatch({ type: "alertMessageVarient", payload: "success" });
          hidemessage()
        }else{
          dispatch({ type: "showAlertMessage", payload: true });
          dispatch({ type: "alertMessage", payload: "Enter valid promo code"});
          dispatch({ type: "alertMessageVarient", payload: "error" });
          dispatch({ type: "promo_disc", payload: 0 });
          dispatch({ type: "promo_code", payload: "" });
          hidemessage();
        }
      } catch (err) {
        console.error("Error in applyPromoCode" + err);
      }
    }
  }

  return (
    <>
    {state.showAlertMessage &&
      <Alert className="notification alert" style={{position:"fixed", width: "100%"}} variant={state.alertMessageVarient} show={state.showAlertMessage}
        onClose={() => dispatch({ type: "showAlertMessage", payload: false })}
        dismissible> {state.alertMessage}</Alert>
    }
       <div className="fixedHeader">
        <Header page="fixedHeader" />
      </div>

      <div className="flightmain-info">

        {/* <div className="pricetop-root d-flex">
          <article className="left">
            <span>Refundable</span>
          </article>
          <span style={{ display: data?.supplier === 1 ? "flex" : 'none' }}>TRAVELPORT </span>
          <span style={{ display: data?.supplier === 2 ? "flex" : 'none' }}>MYSTIFLY</span>
          <span style={{ display: data?.supplier === 3 ? "flex" : 'none' }}>ITAI</span>
          <article className="right d-flex">
            <div className="ptext d-flex">
              <b><img src="assets/images/flight-result/tick.svg" alt="" /> Installments</b> |
              <div className="">
                <strong><sub>{data.price.currency}</sub>{data?.price?.total_price}</strong>
                <small>{data.price.currency} {parseInt(data?.price?.total_price) + 380}</small>
              </div>
            </div>
            <button onClick={() => handleAvailability(data)}>Select <img src="assets/images/flight-result/button-arrow.svg" alt="" /></button>
            </article>
        </div> */}

      </div>



      <section className="statusBarWrap">
        <div className="mainContainer">
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div className="statusBarItem">
                  <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                  <span>Review Flight</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='atPresent smallCircle'>2</span>
                  <span>Traveller Details</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='pending smallCircle'>3</span>
                  <span>Payment</span>
                </div>
                <div className="rightArrBar">
                  <i class="icon icon-down-arrow"></i>
                </div>
                <div className="statusBarItem">
                  <span className='pending smallCircle'>4</span>
                  <span>Confirmation</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="reviewPage">
        <section>
          <div className="mainContainer">
            <Row>
              <Col>
                <div className="d-flex mt-20">
                  <div className="reviewSideWrap">
                    <Accordion defaultActiveKey="0" className='bookingReviewWrap'>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><span className='bookingReviewTitle'>Review Booking</span> <i class="icon icon-Plus"></i> <i class="icon icon-Minus"></i></Accordion.Header>
                        <Accordion.Body>
                          <div>
                            {
                              data?.OutboundInboundlist?.map((outbounddata, index) => {
                                const lengths = outbounddata.flightlist.length;
                                const flightListData = outbounddata.flightlist[0];
                                const depertureDatas = outbounddata.flightlist[0].Departure;
                                const arrivalDatas = outbounddata.flightlist[lengths - 1].Arrival;
                                const layover = outbounddata?.flightlist[lengths - 1]?.connectiontime;
                                const layovername = outbounddata?.flightlist[0]?.Arrival?.Iata;
                                const trip = outbounddata.flightlist[0].flightindex
                                return (
                                  <>
                                    <div className="flight-to">
                                      <div className="flyinner d-flex">

                                        <div className="flightDepWrap">
                                          <div className="flightDep">
                                            <i class="icon icon-Flight-Circle"></i> {index === 0 ? "Departure" : "Return"}
                                          </div>
                                        </div>
                                        <div className="routeTimeWrap">
                                          <div className="routeTime">
                                            <span className='route'>{depertureDatas.city}</span>
                                            <i class="icon icon-Arrow"></i>
                                            <span className='route'>{arrivalDatas.city}</span>
                                            <span className='time leftSeparatorLine'>{convertDate(depertureDatas.Date)}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="brBot">
                                        <div className='planeInfo'>
                                          <div className="planeName">        
                                          {handleLogo(flightListData?.MarketingAirline?.code) && (
                                            <img src={handleLogo(flightListData?.MarketingAirline?.code)} alt="AirlineLogo" className='img-fluid planeImage' />
                                          )}
                                          {flightListData?.MarketingAirline?.name}
                                          </div>
                                          <div className="planeCodeClassWrap"><span className='planeCode'>{data.Airlinelists}-{flightListData?.MarketingAirline?.number}</span><span className='planeClass leftSeparatorLine'>{flightListData.CabinClassText}</span></div>
                                          <div className="operatedBy">Operated by : {flightListData?.OperatingAirline?.name}</div>
                                        </div>
                                        <div className='airlineInfo'>
                                          <div className='deparrInfo'>
                                            <div className='deparrTime'>{depertureDatas.time}</div>
                                            <div className='deparrLocation'>{depertureDatas.city}</div>
                                            <div className='airportName'>{depertureDatas.name}</div>
                                          </div>
                                          <div className='layoverInfo text-center'>
                                            <div className='layoverDuration'>{lengths - 1 === 0 ? 'Direct' : `${lengths - 1} Stop`}{lengths > 1 ? `, Layover : ${handleTimeChange(layover)} (${layovername}) ` : ''}</div>
                                            <div className='layoverSeparator'><span></span></div>
                                            <div className='totalDuration'>Duration : {handleTimeChange(outbounddata.totaltime)}</div>
                                          </div>
                                          <div className='deparrInfo'>
                                            <div className='deparrTime'>{arrivalDatas.time}</div>
                                            <div className='deparrLocation'>{arrivalDatas.city}</div>
                                            <div className='airportName'>{arrivalDatas.name}</div>
                                          </div>

                                          {/* <div className="fl-dels"><img src="assets/images/flight-result/bag.svg" alt="" />
                                {flightListData?.CheckinBaggage[0]?.Value}
                              </div>
                              <div className="flt-bag">
                                <div className="fl-dels inbg" onClick={() => { { handleFlightDetails() }; { filterDetails(data.Offercode) } }}><img src="assets/images/flight-result/copy.svg" alt="" />Flight
                                  Details
                                </div>
                              </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })
                            }
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {!loggedIn && !loggedIn?.unique_id &&
                      <section>
                        <div className="mainContainer">
                          <Row>
                            <Col>
                              <div className="signInWrap">
                                <i class="icon icon-Login2"></i>
                                <span onClick={handleRegisterShow}>Sign Up</span>
                                &nbsp;now and speed up your booking experience. Already a member ?&nbsp;
                                <span onClick={handleSignInShow}>Sign In</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </section>
                    }
                    <Registration showSignIn={signInShow} showRegister={registerShow} closeSignin={handleSignInClose} closeRegister={handleRegisterClose} sendLoggedInData={handleLoggedInData} />
                    <section>
                      <div className="mainContainer">
                        <Row>
                          <Col>
                            <Accordion defaultActiveKey="0" className='bookingReviewWrap mt-20'>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header className='baggageTitleWrap'><span><img src={Baggage} alt="baggageImage" className='img-fluid' /> Baggage Protection</span> <span className='d-flex align-items-center'><span className='addNow'>+ Add Now</span> <i class="icon icon-Plus"></i> <i class="icon icon-Minus"></i></span></Accordion.Header>
                                <Accordion.Body>
                                  <div className="baggageProtectionWrap">
                                    <div className="protectionTitle">Get compensated if your baggage is delayed or lost, at just <span>AED 10</span>/ Traveller.</div>
                                    <div className="protectionBoxWrap">
                                      <div className="protectionBox">
                                        <div className="protectionBoxTitle"><i class="icon icon-Tracking"></i> Live Tracking</div>
                                        <div className="protectionBoxCont">Real time updated on Email & SMS about baggage status.</div>
                                      </div>
                                      <div className="protectionBox">
                                        <div className="protectionBoxTitle"><i class="icon icon-Doc"></i> No Proof Required</div>
                                        <div className="protectionBoxCont">No need to provide the details of the bag's content.</div>
                                      </div>
                                      <div className="protectionBox">
                                        <div className="protectionBoxTitle"><i class="icon icon-Pay"></i> Guaranteed Payment</div>
                                        <div className="protectionBoxCont">Receive AED 2000 for every bag that doesn't arrive in 96 hours.</div>
                                      </div>
                                    </div>
                                    <div className="protectionBottomLine">By adding baggage protection you agree to the <span>Blue Ribbon Bags Terms & Conditions</span></div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Col>
                        </Row>
                      </div>
                    </section>
                    <section>
                      <div className="mainContainer">
                        <Row>
                          <Col>
                            <div className="insureTripWrap">
                              <div className='insureWrap'>
                                <i class="icon icon-Insure"></i>
                                <div className='insureTitle'>Insure your trip</div>
                                <div className="insureCont">Protect you from unexpected disruptions.</div>
                              </div>
                              <div className='chooseWrap'>Choose <i class="icon icon-down-arrow"></i></div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </section>
                    <TravellerDetails 
                      srequest={request}                      
                      sendpsngersdata={passengrsData}
                      fareresp={fareruleResp}
                      base_usr_exrate={request.base_usr_exrate}
                      selbagprice={updateBaggagePrice}
                      selmealprice={updateMealPrice}
                    />
                    <div className="contonuePaymentBtnWrap">
                      {!btnLoader ? 
                        <Button onClick={countinueBooking} variant="primary contonuePaymentBtn">Continue Payment <i class="icon icon-down-arrow"></i></Button>
                        :                      
                        <Button variant="primary contonuePaymentBtn">Loading... <i class="icon icon-down-arrow"></i></Button>
                      }
                    </div>
                  </div>
                  <div className="summarySideWrap">
                    <TripSummary 
                      data={request}
                      baggagePrice={state.baggagePrice}
                      selMealprice={state.mealPrice}
                      sendAdtPrice={updateAdtPrice}
                      sendChdPrice={updateChdPrice}
                      sendMealAdtPrice={updateAdtMealPrice}
                      sendMealChdPrice={updateChdMealPrice}
                      promoDisc={state.promo_disc}
                    />
                    {state.promoCodeList.length !== 0 && (
                    <PromocodesList promo_codes={state.promoCodeList} sendPromo={applyPromoCode} promo_disc={state.promo_disc} removeData={removeAppliedData} />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>

      {/* <FlightReviewBar /> */}
      {/* <FlightReviewDetails /> */}
      <Footer />
    </>
  )
}

export default FlightReview
