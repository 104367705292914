import React, { useState } from 'react'
import { handleAirportSearch } from '../../bookingflow/Flights/common/AirportValid'
import { useDispatch } from 'react-redux'
import { setMultiArrival, setMultiDeparture } from '../../../toolkit/Slices/SearchFlightSlice';
import { Form} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function AutoCompleteReturn(props) {
  const index = props.index;
  const dispatch = useDispatch();
  const [showDestination, setShowDestination] = useState(false)
  const [destination, setDestination] = useState([])
  const [destinationSearch, setDestinationSearch] = useState('')
  const [destinationClearEnable, setDestinationClearEnable] = useState(false);
  const handleShowDestination = () => {
    setShowDestination(!showDestination)
  }
  const handleDestination = (e) => {
    const search = e.target.value;
    if (search.length >= 3) {
      const response = handleAirportSearch(search)
      response.then(function (result) {
        if (result?.data?.suc) {
          setDestination(result.data?.res?.data);
          setShowDestination(true)
        } else {
          setDestination([]);
        }
      }).catch(error => {
        console.log("Error occurred in Predective search", error)
      });
    } else if (search.length === 0){
      setDestination([]);
      setShowDestination(false);
      setDestinationClearEnable(false);
    }
  }
  const handleDestinationSearch = (code) => {    
    handleShowDestination(false)
    const items = destination.find(item => item.selected_data.cityname == code.cityname)
    setDestinationSearch(code?.cityname + "(" + code?.iata + ")" + ", " + code?.name)
    // dispatch(setArrivalDatas(items))
    dispatch(setMultiArrival({ index, code }))
  }

  //For clearing the destination field
  const clearDestination = () => {
    setDestination([]);
    setDestinationSearch('');
    handleShowDestination(false)
    setDestinationClearEnable(false);
  };


  return (
    <>
      <Form.Group className="destTo position-relative">
      <Form.Label><i class="icon icon-flight"></i>To</Form.Label>
        <Form.Control type="text" placeholder="Destination" value={destinationSearch}
          onChange={(e) => { { setDestinationSearch(e.target.value); setDestinationClearEnable(true) }; { handleDestination(e) } }}
        />
        <div className="autolist predictiveWrap" style={{ display: showDestination ? "block" : 'none' }}>
          <ul className="borderrow verticalScroll">
            {destination?.length > 0 &&
              destination?.map((items, index) => {
                return (
                  <>
                    <li key={index}>
                      <Accordion defaultActiveKey="0" className='predictveSearchCollapse'>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <div className="cityNameWrap"><i class="icon icon-flight"></i>{items.selected_data.cityname} <span className='allAirportsTitle'>(All airports)</span> <span className='countryCode'>{items.selected_data.citycode}</span></div>
                              <div className='countryName'>{items.selected_data.countryname}</div>
                            </div>
                            <div className='d-flex'>
                              <i class="icon icon-down-arrow"></i>
                            </div>
                          </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          {items.selected_data.airports.map((air, idx) => (
                            <div className='airportName' key={idx} onClick={() => { handleDestinationSearch(air) }}><i class="icon icon-flight"></i>{air.name} <span className='countryCode'>{air.iata}</span></div>
                          ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>                      
                      {items.nearby_routes.map((near, idx) => (
                        <div className='nearByWrap' key={idx} onClick={() => { handleDestinationSearch(near) }}>
                          <div className='airportName'><i class="icon icon-flight"></i>{near.name} <span className='countryCode'>{near.iata}</span></div>
                          <div className='nearbyCity'><span className='nearByTitle'>Near by</span> <span className='cityName'>{near.cityname}</span> <span className='distance'>{near.distance}</span></div>
                        </div>
                      ))}
                    </li>
                  </>
                )
              })
            }
          </ul>
        </div>
        {destinationClearEnable && (
          <span className='removePrediSearch' onClick={clearDestination}>
            <i class="icon icon-Close"></i>
          </span>
        )}
      </Form.Group>
    </>
  )
}

export default AutoCompleteReturn
