/**
 * @author Praveen Varma
 * @description Adding guests and rooms
 * @date 14-06-2024
 */
import React, { useState, useReducer, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';

const initialState = {
  adultCount: 1, childCount: 0, adtmax: 6, chdmax: 5, noOfRooms: 1, childAgeArray: [], roomsArray: [], age: 0, removedRm: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AddGuestRooms(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addRooms, setAddRooms,] = useState(false);
  const [chdList, setchdList] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [showGuestPop, setShowGuestPop] = useState(props?.showPopup)


  useEffect(() => {
    if (props?.roomsInfo?.length !== 0) {
      dispatch({ type: "roomsArray", payload: props.roomsInfo })
      dispatch({ type: "noOfRooms", payload: props.roomsInfo.length })
    }
  }, [props.roomsInfo]);

  const handleIncrementAdlt = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
    }
    for (let val of state.roomsArray) {
      if (val.adt < 6 && val.rmno === rmno) {
        val.adt = Number(val.adt) + 1;
        dispatch({ type: "adultCount", payload: Number(state.adultCount) + 1 })
        props.sendGuseCount(adtsCount + 1 + chdCounts);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const handleDecrementAdlt = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
    }
    for (let val of state.roomsArray) {
      if (val.adt > 1 && val.rmno === rmno) {
        val.adt = Number(val.adt) - 1;
        dispatch({ type: "adultCount", payload: Number(state.adultCount) - 1 })
        props.sendGuseCount(adtsCount - 1 + chdCounts);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const handleIncrementChild = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
    }
    for (let val of state.roomsArray) {
      if (val.chd < 5 && val.rmno === rmno) {
        val.chd = Number(val.chd) + 1;
        const pay = {
          "chd": val.chd,
          "age": 1
        }
        val.chdArray.push(pay);
        val.chdAges=val.chdArray?.map(isd => isd.age);
        props.sendGuseCount(adtsCount + chdCounts + 1);
        dispatch({ type: "childCount", payload: Number(state.childCount) + 1 })
      }
    }
    chdList.push(state.childCount + 1);
    dispatch({ type: "childAgeArray", payload: chdList })
    props.sendRoomsInfo(state.roomsArray);
  };


  const handleDecrementChild = (rmno) => () => {
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
    }
    for (let val of state.roomsArray) {
      if (val.chd !== 0 && val.rmno === rmno) {
        val.chd = Number(val.chd) - 1;
        val.chdArray.splice(val.chd);
        val.chdAges = val.chdArray?.map(it => it.age)
        dispatch({ type: "childCount", payload: state.childCount - 1 })
        props.sendGuseCount(adtsCount + chdCounts - 1);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const ChildernAge = [
    { value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' }, { value: '9', label: '9' }, { value: '10', label: '10' }, { value: '11', label: '11' }]

  const handleChange = (room, index) => e => {
    for (let val of state.roomsArray) {
      if (val.rmno === room.rmno && val.chd === room.chd) {
        let count = 1;
        for (let chd of val.chdArray) {
          if (count === index) {
            chd.age = Number(e.value);
          }
          count = count + 1;
        }
        val.chdAges=val?.chdArray?.map(im => im.age);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const addMoreRooms = (e) => {
    setAddRooms(true)
    let roomNo = 0;
    let list = state.roomsArray;
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of list) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
      roomNo = roomNo + 1;
    }
    const payload = {
      rmno: list.length + 1,
      adt: 1,
      chd: 0,
      chdArray: [],
      chdAges: []
    }
    list.push(payload);

    dispatch({ type: "adultCount", payload: Number(adtsCount) + 1 })
    dispatch({ type: "noOfRooms", payload: list.length })
    props.sendGuseCount(Number(adtsCount) + 1 + Number(chdCounts));
    props.sendRoomsCount(list.length)
    dispatch({ type: "roomsArray", payload: list })
    props.sendRoomsInfo(list);
  }
  const dltMoreRooms = (room) => (e) => {
    setAddRooms(false)
    let rcount = state.roomsArray.length - 1;
    let adtsCount = 0;
    let chdCounts = 0;
    for (let val of state.roomsArray) {
      if (adtsCount === 0) {
        adtsCount = val.adt ? Number(val.adt) : 0;
        chdCounts = val.chd ? Number(val.chd) : 0;
      } else {
        adtsCount = Number(adtsCount) + Number(val.adt);
        chdCounts = Number(chdCounts) + Number(val.chd);
      }
    }
    state.roomsArray?.splice(state.roomsArray.findIndex(a => a.rmno === room.rmno), 1);
    let count = 1;
    for (let val of state.roomsArray) {
      val.rmno = count;
      count = count + 1;
    }
    dispatch({ type: "noOfRooms", payload: rcount })
    dispatch({ type: "roomsArray", payload: state.roomsArray })
    dispatch({ type: "removedRm", payload: room })
    dispatch({ type: "adultCount", payload: Number(state.adultCount) - Number(room.adt) })
    dispatch({ type: "childCount", payload: Number(state.childCount) - Number(room.chd) })
    props.sendGuseCount(Number(adtsCount) - Number(room.adt) + Number(chdCounts) - Number(room.chd));
    dispatch({ type: "adultCount", payload: Number(adtsCount) - Number(room.adt) })
    dispatch({ type: "childCount", payload: Number(chdCounts) - Number(room.chd) })
    props.sendRoomsCount(rcount)
  }

  return (
    <>
      {showGuestPop &&
        <div className={state.roomsArray.length !== 1 ? 'guestDropdown addplus' : 'guestDropdown'}>
          {state.roomsArray.length !== 0 && state.roomsArray.map((room, idx) => (
            <div className="selectPaxWrap guestWrap">
              <React.Fragment key={idx}>
                <div className='d-flex justify-content-between align-items-center'><span className="selectPaxTitle"> <i class="icon icon-hotel"></i> Room {room.rmno} </span>{room.rmno !== 1 && <span className="removeRoom"><i class="icon icon-Close" onClick={dltMoreRooms(room)}></i></span>}</div>
                <ul>
                  <li>
                    <div className="label">
                      <span className="paxType">Adult</span>
                      <span className="age">(12+ years)</span>
                    </div>
                    <div className="count">
                      <span onClick={handleDecrementAdlt(room.rmno)} className={room.adt > 1 ? "minus active" : "minus"}>-</span>
                      <span className="countValue">{room.adt}</span>
                      <span onClick={handleIncrementAdlt(room.rmno)} className="plus">+</span>
                    </div>
                  </li>
                  <li>
                    <div className="label">
                      <span className="travellersType">Child</span>
                      <span className="age">(2 -11 years)</span>
                    </div>
                    <div className="count">
                      <span onClick={handleDecrementChild(room.rmno)} className={room.chd > 0 ? "minus active" : "minus"}>-</span>
                      <span className="countValue">{room.chd}</span>
                      <span onClick={handleIncrementChild(room.rmno)} className="plus">+</span>
                    </div>
                  </li>
                </ul>
                {room.chdArray !== undefined &&
                  <div className='childAgeWrap'>
                    <Row>
                      {room.chdArray.map((chd, index) => (
                        <React.Fragment key={index}>

                          <Col md={4} className='childAgeInnerWrap'>
                            {index >= 0 && <div className="childAge">Child {index + 1} Age</div>}
                            <Select
                              isSearchable={false}
                              className='react-select-container-age'
                              classNamePrefix="react-select-age"
                              onChange={handleChange(room, index + 1)}
                              value={[{ "value": chd.age, "label": chd.age }]}
                              theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#F0F0F0',
                                  primary: '#475F7B',
                                },
                              })}
                              options={ChildernAge}
                            />
                          </Col>
                        </React.Fragment>
                      ))}
                    </Row>
                  </div>
                }
              </React.Fragment>

            </div>))}
          <div className="clickToAdd">
            {state.noOfRooms < 8 &&
              <div className="addRoom" onClick={addMoreRooms}> + Add Room</div>
            }
            <div className='applyRoomBtn' onClick={() => setShowGuestPop(false)}>Apply</div>
          </div>
        </div>
      }
    </>
  )
}
export default AddGuestRooms;
