
import React, { useRef, useState, useEffect, useReducer, useContext } from 'react';
import { Form, Col, Row, Button } from "react-bootstrap";
import validators, { isArrayNotEmpty } from '../../../utils/validators';
import { handleGetApi, handlePostApi } from '../../../../utilities/Axios'
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../../../assets/Gifs/Double Ring-1s-200px.gif';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { Context } from '../../../../App';
import Amenities from './Amenities';
import Policies from './Policies';
import HotelSearch from '../../../homepage/ChildComp/HotelSearch';
import {getNumberFormat} from '../../../utils/validators'
const HOTEL_CONTENT_URL = process.env.REACT_APP_HOTEL_CONTENT_URL;
const HOTEL_COMMON_URL = process.env.REACT_APP_HOTEL_COMMON_URL;
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

const ITEM_WIDTH = 200;

const initialState = {
  na: "", nights:"", no_of_rooms:"", ameneties_filter:[], final_amount:0, selected_amenities_filter:[],
  total_guests:"", total_no_of_adts:"", total_no_of_chds:"", final_amount: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:Rooms Selection info
 * @author: Praveen Varma
 * @param {*}
 * @function Room Price details
 * @date : 15-05-2024
 */
function RoomSelection(props) {
  const nav = useNavigate();
  const [context, setContext] = useContext(Context);
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loader, setloader] = useState(false);
  const params = new URLSearchParams(location.search);
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [cur, setUserCur] = useState("");

  const [roomsList, setRoomsRawList] = useState([]);
  const [hotel_details, setHotelDetails] = useState();

  const [roomOneOCP, setRoomOneOCP] = useState([]);
  const [roomTwoOCP, setRoomTwoOCP] = useState([]);
  const [roomThreeOCP, setRoomThreeOCP] = useState([]);
  const [roomFourOCP, setRoomFourOCP] = useState([]);
  const [roomFiveOCP, setRoomFiveOCP] = useState([]);
  const [roomSixOCP, setRoomSixOCP] = useState([]);
  const [roomSevenOCP, setRoomSevenOCP] = useState([]);
  const [roomEightOCP, setRoomEightOCP] = useState([]);

  const [temp_roomOneOCP, setTemp_RoomOneOCP] = useState([]);
  const [temp_roomTwoOCP, setTemp_RoomTwoOCP] = useState([]);
  const [temp_roomThreeOCP, setTemp_RoomThreeOCP] = useState([]);
  const [temp_roomFourOCP, setTemp_RoomFourOCP] = useState([]);
  const [temp_roomFiveOCP, setTemp_RoomFiveOCP] = useState([]);
  const [temp_roomSixOCP, setTemp_RoomSixOCP] = useState([]);
  const [temp_roomSevenOCP, setTemp_RoomSevenOCP] = useState([]);
  const [temp_roomEightOCP, setTemp_RoomEightOCP] = useState([]);

  const [selRoomOne, setSelRoomOne] = useState({});
  const [selRoomTwo, setSelRoomTwo] = useState({});
  const [selRoomThree, setSelRoomThree] = useState({});
  const [selRoomFour, setSelRoomFour] = useState({});
  const [selRoomFive, setSelRoomFive] = useState({});
  const [selRoomSix, setSelRoomSix] = useState({});
  const [selRoomSeven, setSelRoomSeven] = useState({});
  const [selRoomEight, setSelRoomEight] = useState({});

  const [displaySecondRooms, setDisplaySecondRooms] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [selRomms, setSelRooms] = useState([]);
  const [noOfRooms, setNoOfRooms] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState("");

  const [room1_expand, setroom1_expand] = useState(false);
  const [room2_expand, setroom2_expand] = useState(false);
  const [room3_expand, setroom3_expand] = useState(false);
  const [room4_expand, setroom4_expand] = useState(false);
  const [room5_expand, setroom5_expand] = useState(false);
  const [room6_expand, setroom6_expand] = useState(false);
  const [room7_expand, setroom7_expand] = useState(false);
  const [room8_expand, setroom8_expand] = useState(false);
  const[dispaly_images, setDispaly_images] = useState([]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef();

  const chRoomSec = useRef(null);
  const overViewSec = useRef(null);
  const amenityViewSec = useRef(null);
  const policyViewSec = useRef(null);
  const photoViewSec = useRef(null)

  const [photos, setPhotos] = useState(true);
  const [chRoom, setChRoom] = useState(false);
  const [overView, setOverview] = useState(false);
  const [amenityRef, setAmenityRef] = useState(false)
  const [policies, setPolicies] = useState(false)






  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
  }


  useEffect(() => {
    let payload = {};
    for (const [key, value] of params.entries()) {
      payload[key] = value;
    }
    setSearchCriteria(payload);
    sessionStorage.setItem("H_S_C", JSON.stringify(payload));
    setNoOfRooms(payload.rms ? Number(payload.rms) : 0);
    loadHotelStaticData(payload.othcode);
    dispatch({ type: "na", payload: payload?.na })
    loadRooms(payload);
    construct_nights(payload);
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({ ...context, userCur: sessionStorage.getItem("SEL_CUR") });
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])


  useEffect(() => {
    var userCur = context.userCur !== undefined && context.userCur !== "" ? context.userCur : sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("KF_EX_RATES"))
    if (context.ex_rates === undefined || context.ex_rates === "") {
      setContext({ ...context, ex_rates: exRates })
    }
    if ((context.ex_rates !== undefined && context.ex_rates !== "") || (exRates !== undefined && exRates !== "") && userCur !== undefined && userCur !== "") {
      if (BASE_CURRENCY !== userCur) {
        let currecny = BASE_CURRENCY + "-" + userCur;
        getExchangeRate(currecny)
      } else if (BASE_CURRENCY === userCur) {
        setBaseToUserExrate(1)
      }
    } else {
      setBaseToUserExrate(1)
    }
  }, [context.userCur])

  useEffect(() => {
    if (context.ex_rates !== "" && context.ex_rates !== undefined) {
      let currecny = BASE_CURRENCY + "-" + cur;
      getExchangeRate(currecny)
    }
  }, [context.ex_rates])

  const getExchangeRate = (usrCur) => {
    if (context.ex_rates !== "" && context.ex_rates !== undefined && context.ex_rates !== null) {
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  const construct_nights = (crit) => {
    let nights=1;
    let no_of_rooms= crit?.rms;
    try {
      let s_dateString = crit.cin;
      let [sday, smonth, syear] = s_dateString.split('-')
      let date = new Date(+syear, +smonth - 1, +sday);

      let t_dateString = crit.cout;
      let [tday, tmonth, tyear] = t_dateString.split('-')
      let end_date = new Date(+tyear, +tmonth - 1, +tday);

      if (end_date !== undefined && date !== undefined && end_date !== "") {
        var diff_In_Time = end_date.getTime() - date.getTime();
        var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
        nights = diff_In_Days;
      }
      dispatch({ type: "nights", payload: nights });
      dispatch({ type: "no_of_rooms", payload: no_of_rooms });

      let adt_inf = crit?.adt?.split(",");
      let chd_inf = crit?.chdages !== undefined && crit?.chdages !== null
        && crit?.chdages !== "" ? crit?.chdages.split("|") : [];
      let adt_count=0;
      let chd_count = 0;
      if (adt_inf.length > 0) {
        adt_inf?.forEach((adt) => {
          if (adt !== null) {
            adt_count = adt_count + Number(adt);
          }
        })
      }
      if(chd_inf.length > 0){
        chd_inf.forEach((chd) =>{
          if(chd !== null){
            let count = chd.split(",");
            chd_count = chd_count+count?.length
          }
        })
      }
      let total_guests = adt_count  + chd_count;
      dispatch({ type: "total_guests", payload: total_guests });
      dispatch({ type: "total_no_of_adts", payload: adt_count || 0 });
      dispatch({ type: "total_no_of_chds", payload: chd_count || 0 });
    } catch (error) {
       console.log("Error construct_nights catch", error);
    }
  }


  useEffect(() => {
    setEnableBtn(false);
    if (selRomms && selRomms.length > 0 && selRomms.length === noOfRooms) {
      setEnableBtn(true);
    }
  }, [selRoomOne, selRoomTwo, selRoomThree, selRoomFour, selRoomFive, selRoomSix, selRoomSeven, selRoomEight])

  useEffect(() => {
    if (selRomms !== null && selRomms.length > 0) {
      let final_amt = 0;
      try {
        if (selRomms.length > 0) {
          let amounts = selRomms?.map(itm => itm ? Number(itm.rmpr[0].pr[0].tf) : 0);
          amounts.forEach( num => {
            final_amt += num;
          })
        }
      } catch (error) {
        console.log("Error : getFinalAmount calc ", error);
      }
      dispatch({ type: "final_amount", payload: final_amt });
    }
  }, [selRomms]);

  const loadHotelStaticData = async (hotel_id) => {
    setDispaly_images([]);
    let response = await handleGetApi(`${HOTEL_CONTENT_URL}/htl/content/property_info?hotel_id=${hotel_id}`);
    if (response?.data?.suc) {
      setHotelDetails(response?.data?.res);
      try {
        let images = [];
        let content = response?.data?.res?.images?.length > 0 ? response?.data?.res?.images : [];
        if (content.length > 0) {
          for (let img of content) {
            images.push(img?.links['1000Px']?.href);
          }
        }
        setDispaly_images(images);
      } catch (error) {
          console.log("error for images in room selection", error)
      }
    } else {
      setHotelDetails("")
    }
  }

  const loadRooms = async (payload) => {
    setloader(true);
    let response = await handleGetApi(`${HOTEL_COMMON_URL}/htl/common/check?cin=${payload.cin}&cout=${payload.cout}&na=${payload.na}&rms=${payload.rms}&adt=${payload.adt}&rty=${payload.rty}&chdages=${payload.chdages}&otctid=${payload.otctid}&othcode=${payload.othcode}`);
    response?.data?.res?.rooms.forEach((itm) => itm.grp_key = `${itm.ocp}__${itm.rmno}`);
    setRoomsRawList(response?.data?.res);
    constructRoomsOCP(response?.data?.res);
    amenitieis_constrction(response?.data?.res)
    setloader(false);
  }


  const constructRoomsOCP = async (rooms_data) => {
    if (rooms_data !== null) {
      let groupByOCP = validators.groupBy(rooms_data?.rooms, "grp_key");
      if (groupByOCP !== null) {
        for (const [key, valuees] of Object.entries(groupByOCP)) {
          let value = valuees.sort((e1, e2) => Number(e1?.rmpr[0]?.pr[0]?.tf) > Number(e2?.rmpr[0]?.pr[0]?.tf) ? 1 : -1);
          if (key !== null) {
            let rommNoSplit = key.split("__");
            let romm_no = rommNoSplit[1];
            if (romm_no === "1") {
              setRoomOneOCP(value)
              setTemp_RoomOneOCP(value)
            } else if (romm_no === "2") {
              setRoomTwoOCP(value)
              setTemp_RoomTwoOCP(value)
            } else if (romm_no === "3") {
              setRoomThreeOCP(value)
              setTemp_RoomThreeOCP(value)
            } else if (romm_no === "4") {
              setRoomFourOCP(value)
              setTemp_RoomFourOCP(value)
            } else if (romm_no === "5") {
              setRoomFiveOCP(value)
              setTemp_RoomFiveOCP(value)
            } else if (romm_no === "6") {
              setRoomSixOCP(value)
              setTemp_RoomSixOCP(value)
            } else if (romm_no === "7") {
              setRoomSevenOCP(value)
              setTemp_RoomSevenOCP(value)
            } else if (romm_no === "8") {
              setRoomEightOCP(value)
              setTemp_RoomEightOCP(value)
            }
          }
        }
      }
    }
  }


  const removeSelectedRoom = async(rm_num) =>{
      let sel = selRomms?.filter(itm => itm.rmno != rm_num);
      if(rm_num == 1){
        setSelRoomOne({})
        let room1 = temp_roomOneOCP;
        room1.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomOneOCP(room1);
      }else if(rm_num == 2){
        setSelRoomTwo({});
        let room2 = temp_roomTwoOCP;
        room2.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomTwoOCP(room2);
      }else if(rm_num == 3){
        setSelRoomThree({});
        let room3 = temp_roomThreeOCP;
        room3.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomThreeOCP(room3);
      }else if(rm_num == 4){
        setSelRoomFour({});
        let room4 = temp_roomFourOCP;
        room4.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomFourOCP(room4);
      }else if(rm_num == 5){
        setSelRoomFive({});
        let room5 = temp_roomFiveOCP;
        room5.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomFiveOCP(room5);
      }else if(rm_num == 6){
        setSelRoomSix({});
        let room6 = temp_roomSixOCP;
        room6.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomSixOCP(room6);
      }else if(rm_num == 7){
        setSelRoomSeven({});
        let room7 = temp_roomSevenOCP;
        room7.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomSevenOCP(room7);
      }else if(rm_num == 8){
        setSelRoomEight({});
        let room8 = temp_roomEightOCP;
        room8.forEach(rm => {
          rm.checked = false;
        })
        setTemp_RoomEightOCP(room8);
      }
      setSelRooms(sel);
  }
  const handleRadioChange = async (e, itm, rmno) => {
    if (itm.rmno === 1) {
      setSelRoomTwo({});
      setSelRoomThree({});
      setSelRoomFour({});
      setSelRoomFive({});
      setSelRoomSix({});
      setSelRoomSeven({});
      setSelRoomEight({});
      let room2 = roomTwoOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      room2.forEach(itm => itm.checked = false);
      setTemp_RoomTwoOCP(room2);
      let room3 = roomThreeOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomThreeOCP(room3);
      let room4 = roomFourOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomFourOCP(room4);
      let room5 = roomFiveOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomFiveOCP(room5);
      let room6 = roomSixOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomSixOCP(room6);
      let room7 = roomSevenOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomSevenOCP(room7)
      let room8 = roomEightOCP.filter(flt => flt.sup === itm.sup && flt.vendor === itm.vendor);
      setTemp_RoomEightOCP(room8);
      setSelRoomOne(itm);
      setDisplaySecondRooms(true);
      setSelRooms([itm]);
      return;
    } else if (itm.rmno === 2) {
      let room2 = temp_roomTwoOCP;
      room2.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomTwoOCP(room2);
      setSelRoomTwo(itm);
    } else if (itm.rmno === 3) {
      let room3 = temp_roomThreeOCP;
      room3.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomThreeOCP(room3);
      setSelRoomThree(itm);
    } else if (itm.rmno === 4) {
      let room4 = temp_roomFourOCP;
      room4.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomFourOCP(room4);
      setSelRoomFour(itm);
    } else if (itm.rmno === 5) {
      let room5 = temp_roomFiveOCP;
      room5.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomFiveOCP(room5);
      setSelRoomFive(itm);
    } else if (itm.rmno === 6) {
      let room6 = temp_roomSixOCP;
      room6.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomSixOCP(room6);
      setSelRoomSix(itm);
    } else if (itm.rmno === 7) {
      let room7 = temp_roomSevenOCP;
      room7.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomSevenOCP(room7);
      setSelRoomSeven(itm);
    } else if (itm.rmno === 8) {
      let room8 = temp_roomEightOCP;
      room8.forEach(rm => {
        if (rm.index == itm.index) {
          rm.checked = true;
        } else {
          rm.checked = false;
        }
      })
      setTemp_RoomEightOCP(room8);
      setSelRoomEight(itm);
    }
    let exisitng_rooms = selRomms.filter((itm) => itm.rmno !== rmno);
    exisitng_rooms.push(itm);
    exisitng_rooms.sort((a, b) => a?.rmno - b?.rmno)
    setSelRooms(exisitng_rooms);
  }

  const handleBook = async () => {
    window.scrollTo(0, 0);
    setloader(true);
    if (selRomms && selRomms.length > 0 && selRomms.length === noOfRooms) {
      setloader(true);
      let htlImg ="";
      if(dispaly_images !== undefined && dispaly_images.length > 0){
        htlImg = dispaly_images[0];
      }
      let sup_cur="";
      if(selRomms?.length > 0){
        sup_cur = selRomms[0]?.rmpr[0]?.pr[0]?.sup_cur;
      }
      let get_all_exchange_rates = await get_ex_rates(cur, sup_cur, "USD");
      get_all_exchange_rates.sup_to_base_ex_rate = isArrayNotEmpty(selRomms) ? selRomms[0]?.rmpr[0]?.pr[0]?.sup_to_base_ex_rate : 0
      let price_check_save = {
        usr_cur: cur,
        base_cur: "USD",
        sup_cur: sup_cur,
        sup: 1,
        cityId: searchCriteria?.otctid,
        pid: searchCriteria?.othcode,
        kft_id: roomsList?.kft_id,
        na: state?.na,
        final_amt: ((base_usr_exrate * state?.final_amount).toFixed(2)),
        ...get_all_exchange_rates,
        htldtls: {
          cin: searchCriteria?.cin,
          cout: searchCriteria?.cout,
          htnm: hotel_details?.HotelName,
          htimg: htlImg,
          pid: searchCriteria?.othcode,
          no_nights: state?.nights,
          no_rooms: state?.no_of_rooms,
          total_no_of_adts :state?.total_no_of_adts,
          total_no_of_chds :state?.total_no_of_chds,
          total_no_of_guests : state?.total_guests,
          address: hotel_details?.Address,
          city: hotel_details?.CityName,
          country: hotel_details?.CountryName,
          rating: hotel_details?.StarCategoryId,
          phone_no: hotel_details?.Telephone,
          location: {
            latitude: hotel_details?.Latitude,
            longitude: hotel_details?.Longitude
          }
        },
      }
      let rooms = [];
      selRomms.forEach((itm) => {
        let adt_count=0;
        let chd_count=0;
        let ocp_spt = itm.ocp ? (itm.ocp+"").split("-"):[];
        if(ocp_spt !== null && ocp_spt.length >0){
           adt_count=Number(ocp_spt[0])
        }
        if(ocp_spt !== null && ocp_spt.length >1){
          let chd_inf = ocp_spt[1] !== null ? (ocp_spt[1]+"").split(",") : [];
          if(chd_inf !== null && chd_inf.length >0){
            chd_count=chd_inf.length
          }
       }
        let room = {
          ocp: itm.ocp + "",
          index: itm.index,
          rmno: itm.rmno,
          rmimg: "",
          no_of_adts :adt_count,
          no_of_chds :chd_count,
          sup_token_id: itm.sup_token_id,
          hKey: itm.hKey,
          rateKey: itm?.rmpr[0]?.rateKey,
          refundable: itm?.rmpr[0]?.refundable,
          freeCancelDate: itm?.rmpr[0]?.freeCancelDate,
          room_ameneties: itm?.rmpr[0]?.ra,
          bed: itm?.rmpr[0]?.bed,
          pr: itm?.rmpr[0]?.pr,
          room_status: "Incomplete"
        }
        rooms.push(room);
      })
      price_check_save.rooms = rooms;
      price_check_save.booking_status = "Incomplete"
      let response = await handlePostApi(`${HOTEL_COMMON_URL}/htl/common/price/save`, price_check_save);
      setloader(false);
      if (response && response.data && response.data.suc) {
        nav(`/hotel/review`, {
          state: {
            resp: { itinerary_id: response.data.res.itinerary_id }
          },
        })
      } else {
        alert("Rooms not available");
      }
    } else {
      setloader(false);
      alert("Please select rooms")
    }
  }

  const get_ex_rates = async (usr_currency, sup_currency, base_currency) => {
    let rates = {
      sup_to_base_ex_rate: 1,
      base_to_usr_ex_rate: 1
    }
    try {
      if (localStorage.getItem("KF_EX_RATES") !== null) {
        var exRates = JSON.parse(localStorage.getItem("KF_EX_RATES"))
        rates.sup_to_base_ex_rate = exRates[`${sup_currency}-${base_currency}`] || 1;
        rates.base_to_usr_ex_rate = exRates[`${base_currency}-${usr_currency}`] || 1
     }
    } catch (error) {
      console.log("ERROR :::: get_ex_rates in Room selection", error)
    }
    return rates;
  }

  const getStarRating = (star) => {
    let ary = [];
    if (star !== undefined && star !== null && star !== "") {
      for (var s = 0; s < Number(star); s++) {
        ary.push(s);
      }
    }
    return ary
  }

  const getOccupancy = (rooms) =>{
    let out="";
    if(rooms !== null && rooms?.length > 0){
      let ocp = rooms[0]?.ocp+"";
      if(ocp !== undefined && ocp !== null && ocp !== ""){
          let ocp_splt = ocp.split("-");
          if(ocp_splt.length > 0){
            out = ocp_splt[0]+" Adult(s) "
          }
          if(ocp_splt.length > 1){
            let chd_split = ocp_splt[1].split(",");
            out = out+chd_split.length+" Child(s) "
          }
      }
    }
    return out;
  }

  const amenitieis_constrction = (payload) =>{
    try {
      let ameneties = [];
      if(payload !== null && payload?.rooms !== null && payload?.rooms?.length > 0){
        payload.rooms?.forEach((room) =>{
            if(room.rmpr.length > 0){
              room.rmpr?.forEach(rm =>{
                if(rm?.ra !== null && rm?.ra.length > 0){
                  rm?.ra?.forEach((am) =>{
                    if(am?.name !== undefined && am?.name !== null && 
                      am?.name !== "" && !ameneties.includes(am?.name)){
                      ameneties.push(am.name);
                    }
                  })
                }
              })
            }
        })
      }
      dispatch({ type: "ameneties_filter", payload: ameneties });
    } catch (error) {
       console.log("Error : amenitieis_constrction ", error);
    }
  }

  const setRoomFilter = (am) => {
    try {
      let filter_data = state?.selected_amenities_filter || [];
      if (!filter_data.includes(am)) {
        filter_data.push(am);
      } else {
        filter_data = filter_data.filter(itm => itm !== am);
      }
      dispatch({ type: "selected_amenities_filter", payload: filter_data })
      if(filter_data.length === 0){
         setTemp_RoomOneOCP(roomOneOCP);
         setTemp_RoomTwoOCP(roomTwoOCP);
         setTemp_RoomThreeOCP(roomThreeOCP);
         setTemp_RoomFourOCP(roomFourOCP);
         setTemp_RoomFiveOCP(roomFiveOCP);
         setTemp_RoomSixOCP(roomSixOCP);
         setTemp_RoomSevenOCP(roomSevenOCP);
         setTemp_RoomEightOCP(roomEightOCP);
      }else{
        let room1data = roomOneOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomOneOCP(room1data);

        let room2data = roomTwoOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomTwoOCP(room2data);

        let room3data = roomThreeOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomThreeOCP(room3data);

        let room4data = roomFourOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomFourOCP(room4data);

        let room5data = roomFiveOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomFiveOCP(room5data);

        let room6data = roomSixOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomSixOCP(room6data);
        
        let room7data = roomSevenOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomSevenOCP(room7data);

        let room8data = roomEightOCP.filter(room => {
          let data = room.rmpr[0].ra.filter(obj => filter_data.includes(obj?.name));
          if (data?.length > 0) {
            return room;
          }
        })
        setTemp_RoomEightOCP(room8data);
      }
    } catch (error) {
      console.log("Error : setRoomFilter ", error);
    }
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 3px 6px #00000029" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 3px 6px #00000029" }}
        onClick={onClick}
      />
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };


  return (
    <>
      <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
        <img src={Loader} alt='' />
      </div>
      <div className="fixedHeader">
        <Header page="fixedHeader" flow="RESULT" />
      </div>
      <div class="roomselectionStatus"></div>
      <div className="roomselectionPage">
        <section>
          <div className="mainContainer">
            <Row>
              <Col>
                <div className="selectroomDetailWrap topPage">
                  <div className="selectroomDetail">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="hotelName" title={hotel_details?.HotelName}>
                          {hotel_details?.HotelName}
                        </div>
                        <div className="hotelRating">
                          {getStarRating(hotel_details?.StarCategoryId).map((mp) => {
                            return (
                              <>
                                <i class="icon icon-star"></i>
                              </>
                            )
                          })}
                        </div>
                      </div>
                      <div className="d-flex align-items-center locationReviewWrap">
                        <div className="hotelLocationWrap" title={hotel_details?.Address}>
                          <i class="icon icon-Pointer"></i> {hotel_details?.Address}
                        </div>
                        <div class="reviewsWrap">
                          <span class="reviewsIcons">
                            <i class="icon icon-star"></i>
                            <i class="icon icon-star"></i>
                            <i class="icon icon-star"></i>
                          </span>
                          <span class="reviewTitle">{hotel_details?.ratings?.guest?.count || 0} reviews</span>
                        </div>
                      </div>
                    </div>
                    <div className='hotelShowroomWrap'>
                      {selRomms && selRomms.length > 0 ?
                        <div>
                          <div class="hotelPrice"> {cur} {getNumberFormat((base_usr_exrate * state?.final_amount).toFixed(2))}</div>
                          <div class="hotelTotalprice">Total Price : {cur} {getNumberFormat((base_usr_exrate * state?.final_amount).toFixed(2))}</div>
                        </div> : ""
                      }
                      <Button variant="primary showroomBtn">Show Rooms <i class="icon icon-down-arrow"></i></Button>
                    </div>
                  </div>
                  <div className="selectroomTitleWrap">
                    <div className={photos ? "selectroomTitle active" :"selectroomTitle"} onClick={(e) =>{
                      setPhotos(true);
                      setChRoom(false);
                      setOverview(false)
                      setAmenityRef(false)
                      setPolicies(false)
                      photoViewSec.current.scrollIntoView()
                    }}>Photos</div>
                    <div className={chRoom ? "selectroomTitle active" :"selectroomTitle"} onClick={(e) =>{
                      setChRoom(true);
                      setOverview(false)
                      setAmenityRef(false)
                      setPolicies(false)
                      setPhotos(false);
                      chRoomSec.current.scrollIntoView()
                    }}>Rooms</div>
                    <div className={overView ? "selectroomTitle active" :"selectroomTitle"} onClick={(e) =>{
                      setChRoom(false);
                      setOverview(true)
                      setAmenityRef(false)
                      setPolicies(false)
                      setPhotos(false);
                      overViewSec.current.scrollIntoView()
                    }} >About Property</div>
                    <div className={amenityRef ? "selectroomTitle active" :"selectroomTitle"} onClick={(e) =>{
                      setChRoom(false);
                      setOverview(false)
                      setAmenityRef(true);
                      setPolicies(false)
                      setPhotos(false);
                      amenityViewSec.current.scrollIntoView()
                    }}>Services & Amenities</div>
                    <div className={policies ? "selectroomTitle active" :"selectroomTitle"} onClick={(e) =>{
                      setChRoom(false);
                      setOverview(false)
                      setAmenityRef(false);
                      setPolicies(true)
                      setPhotos(false);
                      policyViewSec.current.scrollIntoView()
                    }}>Policies</div>
                    <div className="selectroomTitle">Guest Reviews</div>
                  </div>
                </div>
                {dispaly_images && dispaly_images?.length > 0 &&
                <div className="galleryWrap" ref={photoViewSec}>
                  <div className="bigrectImage"><span className='allphotos'>See all Photos</span><img src={dispaly_images && dispaly_images.length >0 ? dispaly_images[0] :""} alt="galleryImage" /></div>
                  <div className="longrectImage"><img src={dispaly_images && dispaly_images.length >1 ? dispaly_images[1] :""} alt="galleryImage" /></div>
                  <div className="smallrectWrap">
                    <div className="smallrectImage">
                      <img src={dispaly_images && dispaly_images.length >2 ? dispaly_images[2] :""} alt="galleryImage" />
                      <img src={dispaly_images && dispaly_images.length >3 ? dispaly_images[3] :""} alt="" />
                    </div>
                    <div className="smallrectImage mt-15">
                      <img src={dispaly_images && dispaly_images.length >4 ? dispaly_images[4] :""} alt="" />
                      <img src={dispaly_images && dispaly_images.length >5 ? dispaly_images[5] :""} alt="" />
                    </div>
                  </div>
                </div>
                }
                <div className="amenitiesMapWrap">
                  <div className="popularAmities">
                    <div className="popularAmitiesTitle">Popular Amenities</div>
                    <div className="aminitiesDeskWrap">
                      <div class="aminities"><i class="icon icon-Breakfast"></i> Free Breakfast</div>
                      <div class="aminities"><i class="icon icon-wifi"></i> Free Wifi</div>
                      <div class="aminities"><i class="icon icon-pool"></i> Swimming pool</div>
                      <div class="aminities"> 24-hour front desk</div>
                    </div>
                  </div>
                  <div className="mapWrap position-relative">
                    <img src="https://images.unsplash.com/photo-1524661135-423995f22d0b?q=80&w=1474&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                    <span className='showMap'>Show on Map</span>
                  </div>
                </div>
                {searchCriteria &&
                  <div className="hotelModfySearch">
                    <div className="hotelModfySearchTitle">Modify Search</div>
                    <HotelSearch modify={true} mod_criteria={searchCriteria} ismodify_from_details={true}/>
                  </div>
                }
                <div className="selectroomOptionsWrap" ref={chRoomSec}>
                  <div className="selectroomTitle">Select Rooms</div>
                  <div className="selectroomOptions">
                    <div>
                      <div ref={containerRef} style={{
                        width: "700px", overflowX: "scroll", scrollBehavior: "smooth"
                      }}>
                        <div className="roomOptionBoxWrap">
                      {state?.ameneties_filter?.length > 0 ?
                        state?.ameneties_filter?.map((am) =>{
                          return (
                            <>
                            <div className={state?.selected_amenities_filter.length >0 &&
                            state?.selected_amenities_filter.includes(am) ? "roomOptionBox active" : "roomOptionBox"} onClick={() => setRoomFilter(am)}><i class="icon icon-Breakfast"></i> {am}</div>
                            </>
                          )
                        })
                      :""
                      }
                      </div>
                      </div>
                      <div className="actionBtn">
                        <button onClick={()=>{handleScroll(-ITEM_WIDTH)}}>Scroll Left</button>
                        <button onClick={()=>{handleScroll(ITEM_WIDTH)}}>Scroll Right</button>
                      </div>
                    </div>
                    <div className="radioWrap">
                    <Form.Group >
                      <Form.Check
                        type='radio'
                        id={`default-radio`}
                        name="classes"
                        label={`Total for Stay`}
                      />
                      <Form.Check
                        type='radio'
                        id={`default-radio`}
                        name="classes"
                        label={`Average Price Per Night`}
                      />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                {selRomms && selRomms.length > 0 &&
                <section>
                  <div className="mainContainer">
                    <Row>
                      <Col>
                        <div className="selectedRoomWrap">
                          <div className='reviewRoomSelectionTitle'>
                            <i class="icon icon-tick1"></i> Review Room Selection
                          </div>
                          <div className="selectedBoxRoom">
                            {selRomms.map((rm) => {
                              return (
                                <>
                                  <div className="selectedBoxWrap">
                                    <i class="icon icon-Close" onClick={() => removeSelectedRoom(rm?.rmno)}></i>
                                    <div className="selectedBoxLeftside">
                                      <div className="selectedRoomTitle"><span>Room {rm?.rmno} :</span>{rm?.rmpr[0]?.bed}</div>
                                      <div className="bedDetails">{rm?.rmpr[0]?.bed}</div>
                                    </div>
                                    <div className="selectedBoxRightside">
                                      <div className="amountPrice">{cur} {getNumberFormat((base_usr_exrate * rm?.rmpr[0]?.pr[0].tf).toFixed(2))}</div>
                                      <div className="perNight">{state?.nights} Night(s)</div>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                            
                          </div>
                          <div className='d-flex align-items-center selectedAmountOuterWrap'>
                              <div className="selectedAmountWrap">
                                <div className='textRight'>
                                  <div className="selectedAmount">{cur} {getNumberFormat((base_usr_exrate * state?.final_amount).toFixed(2))}</div>
                                  <div className="tPrice">Total Price : {cur} {getNumberFormat((base_usr_exrate * state?.final_amount).toFixed(2))}</div>
                                </div>
                                <div className="bNowBtn" onClick={handleBook}>Book Now <i class="icon icon-down-arrow"></i></div>
                              </div>
                            </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>
                }
                <div className="selectroomdetailWrap">
                  <div className="selectroomTitle">Select Room 1: <span>Room Occupancy </span> <span>{getOccupancy(temp_roomOneOCP)}</span></div>
                  <div className="selectroomdetailInnerWrap verticalScroll">
                  {temp_roomOneOCP && temp_roomOneOCP.length > 0 && temp_roomOneOCP?.map((itm) => {
                    return (
                      <>
                      
                        <div className="selectroomdetail">
                          {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJRS-4chjWMRAmrtz7ivK53K_uygrgjzw9Uw&s" alt="" /> */}
                          <div className="selectroomdetailBox">
                            <div className='roomBedWrap'>
                              <div className="roomSize">{itm?.rnm}</div>
                              <div className="bedTypeTitle">Bed Type</div>
                              <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                            </div>
                            <div>
                              <div className="roomOnly"><i class="icon icon-tick"></i>{itm?.rmpr[0]?.ra?.length > 0 ? itm?.rmpr[0]?.ra[0].name : "Room Only"}</div>
                              <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                              {itm?.rmpr[0]?.refundable ?  <div className="refundWrap">Refundable</div> :
                              <div className="nonrefundWrap">Non Refundable</div>}
                            </div>
                            <div>
                              <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                              <div className="stayDuration">1st Room x {state?.nights} Night(s)</div>
                            </div>
                            <div>
                              <Form.Check type="radio"
                                label=""
                                className='form-check'
                                id={itm.index}
                                checked={itm.checked}
                                value={itm.selected}
                                custom name={`selectRoom${itm.rmno}`}
                                onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />
                            </div>
                          </div>
                        </div>
                      
                        {/* <div className="roomdetailTitle">+ Room Details</div> */}
                        {/* <div className="solidHorizontalLine"></div> */}
                      </>
                    )
                    
                  }).slice(0, room1_expand ? temp_roomOneOCP.length : '2')}
                  </div>
                  {temp_roomOneOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom1_expand(prev => !prev)}>{room1_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>

                {temp_roomTwoOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 2 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomTwoOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomTwoOCP && temp_roomTwoOCP.length > 0 && temp_roomTwoOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">2nd Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room2_expand ? temp_roomTwoOCP.length : '2')}
                    </div>
                    {temp_roomTwoOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom2_expand(prev => !prev)}>{room2_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomThreeOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 3 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomThreeOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomThreeOCP && temp_roomThreeOCP.length > 0 && temp_roomThreeOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">3rd Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room3_expand ? temp_roomThreeOCP.length : '2')}
                    </div>
                    {temp_roomThreeOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom3_expand(prev => !prev)}>{room3_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomFourOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 4 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomFourOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomFourOCP && temp_roomFourOCP.length > 0 && temp_roomFourOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">4th Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room4_expand ? temp_roomFourOCP.length : '2')}
                    </div>
                    {temp_roomFourOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom4_expand(prev => !prev)}>{room4_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomFiveOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 5 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomFiveOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomFiveOCP && temp_roomFiveOCP.length > 0 && temp_roomFiveOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">5th Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room5_expand ? temp_roomFiveOCP.length : '2')}
                    </div>
                    {temp_roomFiveOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom5_expand(prev => !prev)}>{room5_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomSixOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 6 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomSixOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomSixOCP && temp_roomSixOCP.length > 0 && temp_roomSixOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">6th Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room6_expand ? temp_roomSixOCP.length : '2')}
                    </div>
                    {temp_roomSixOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom6_expand(prev => !prev)}>{room6_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomSevenOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 7 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomSevenOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomSevenOCP && temp_roomSevenOCP.length > 0 && temp_roomSevenOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">7th Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room7_expand ? temp_roomSevenOCP.length : '2')}
                    </div>
                    {temp_roomSevenOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom7_expand(prev => !prev)}>{room7_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }

                {temp_roomEightOCP?.length > 0 &&
                  <div className="selectroomdetailWrap">
                    <div className="selectroomTitle">Select Room 8 : <span>Room Occupancy </span> <span>{getOccupancy(temp_roomEightOCP)}</span></div>
                    <div className="selectroomdetailInnerWrap verticalScroll">
                    {temp_roomEightOCP && temp_roomEightOCP.length > 0 && temp_roomEightOCP.map((itm) => {
                      return (
                        <>
                          <div className="selectroomdetail">
                            <div className="selectroomdetailBox">
                              <div className='roomBedWrap'>
                                <div className="roomSize">{itm?.rnm}</div>
                                <div className="bedTypeTitle">Bed Type</div>
                                <div className="bedType">{itm?.rmpr[0]?.bed}</div>
                              </div>
                              <div>
                                <div className="roomOnly"><i class="icon icon-tick"></i> Room Only</div>
                                <div className="cancelPolicy"><i class="icon icon-tick"></i> Cancellation Policy <i class="icon icon-Info"></i></div>
                                {itm?.rmpr[0]?.refundable ? <div className="refundWrap">Refundable</div> :
                                  <div className="nonrefundWrap">Non Refundable</div>}
                              </div>
                              <div>
                                <div className="roomAmount">{cur} {getNumberFormat((itm?.rmpr[0]?.pr[0]?.tf * base_usr_exrate).toFixed(2))}</div>
                                <div className="stayDuration">8th Room x {state?.nights} Night(s)</div>
                              </div>
                              <div>
                                <Form.Check type="radio"
                                  label="" id={itm.index}
                                  checked={itm.checked}
                                  value={itm.selected}
                                  className='form-check'
                                  custom name={`selectRoom${itm.rmno}`}
                                  onChange={(e) => handleRadioChange(e, itm, itm.rmno)} />

                              </div>
                            </div>
                          </div>
                          {/* <div className="roomdetailTitle">+ Room Details</div> */}
                          {/* <div className="solidHorizontalLine"></div> */}
                        </>
                      )
                    }).slice(0, room8_expand ? temp_roomEightOCP?.length : '2')}
                    </div>
                    {temp_roomEightOCP?.length >= 3 ?
                    <div className="showMoreOption" onClick={() => setroom8_expand(prev => !prev)}>{room8_expand ? `Show Less Room Options` : `Show More Room Options`}<i class="icon icon-down-arrow"></i></div> : ""}
                </div>
                }
                
                {hotel_details !== undefined && hotel_details !== null && hotel_details !== "" && 
                hotel_details?.descriptions !== undefined && hotel_details?.descriptions !== null &&  hotel_details?.descriptions !== "" &&
                  <div className="aboutPropertyWrap">
                    <div className="aboupropertyTitle" ref={overViewSec}>About Property</div>
                    <div className="aboutPropertyContent">
                        <p>{hotel_details?.descriptions?.location !== undefined && hotel_details?.descriptions?.lengthlocation}
                          {hotel_details?.descriptions?.amenities !== undefined && hotel_details?.descriptions?.amenities}
                          {hotel_details?.descriptions?.businessAmenities !== undefined && hotel_details?.descriptions?.businessAmenities}
                          {hotel_details?.descriptions?.rooms !== undefined && hotel_details?.descriptions?.rooms}
                          {hotel_details?.descriptions?.dining !== undefined && hotel_details?.descriptions?.dining}
                        </p>
                    </div>
                  </div>
                }
                {hotel_details?.amenities &&
                  <div className="serviceAndAminitiesWrap" ref={amenityViewSec}>
                    <div className="serviceAndAminitiesTitle">Services & Amenities</div>
                    <Amenities amenities={hotel_details?.amenities} />
                  </div>
                }
                {hotel_details && Object.keys(hotel_details).length > 0 && hotel_details?.policies !== undefined &&
                  hotel_details?.policies !== null && hotel_details?.policies !== "" &&
                    <div ref={policyViewSec}><Policies cin={hotel_details?.checkin} cout={hotel_details?.checkout} payments={hotel_details?.onsitePayments !== null && hotel_details?.onsitePayments} policy={hotel_details?.policies} canpolicy={hotel_details?.checkin} /></div>
                  
                }
                {/* <div className="guestReviewWrap">
                  <div className="guestReviewTitle">Guest Reviews</div>
                  <div class="reviewsWrap">
                    <span class="reviewsIcons">
                      <i class="icon icon-star"></i>
                      <i class="icon icon-star"></i>
                      <i class="icon icon-star"></i>
                    </span>
                    <span class="reviewTitle">335 reviews</span>
                  </div>
                  <div className="guestReviewBox">
                    <div className="d-flex justify-content-between">
                      <div className="guestTitleWrap">
                        <img src="https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?q=80&w=1380&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                        <div className="guestTitle">In publishing and graphic design, Lorem ipsum is a placeholder text</div>
                        <div className="contribution">1 Contribution</div>
                      </div>
                      <div className='moreDots'>...</div>
                    </div>
                    <span class="reviewsIcons">
                      <i class="icon icon-star"></i>
                      <i class="icon icon-star"></i>
                      <i class="icon icon-star"></i>
                    </span>
                    <div className="guestSubtitle">Great Experience</div>
                    <div className="guestReviewContent mt-15">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available</div>
                    <div className="readMore">Read more <i class="icon icon-down-arrow"></i></div>
                    <div className="guestReviewDate mt-15"><span>Date of stay: </span>May 2024</div>
                    <div className="guestMoreCont">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</div>
                    <div className="like"></div>
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <Footer />
      {enableBtn ?
        <button className="modify-search" onClick={handleBook}>Book<img src="assets/images/flight-result/edit1.svg"
          alt="" /></button> : ""
      }
    </>
  )
}


export default RoomSelection;