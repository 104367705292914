import React from 'react'
import Header from './Header'
import Footer from './Footer'

function About() {
  return (
    <div>
      <Header />
      <div className='text-center'>
        About us page
      </div>
      <Footer />
    </div>
  )
}

export default About
