import React from 'react';
import Mobile from "../../assets/images/home/download-app/mobile-img.png";
import AppStore from "../../assets/images/home/download-app/app-store.svg";
import GooglePlay from "../../assets/images/home/download-app/google-play.svg";

function DownloadApp() {
  return (
    <section className='mt-125 downloadAppWrap'>
        <img src={Mobile} alt='mobile-image' className='img-fluid mobileImage' />
        <div className="d-flex justify-content-between align-items-center">
          <div className='contentWrap'> 
            <h3 className='mb-15'>Download our APP & get the latest offers</h3>
            <p className='mb-0'>Download our app now for easier flights & hotel bookings. Enjoy the best booking experience.</p>
          </div>
          <div className='d-flex flex-column'>
            <img src={AppStore} alt='app-store-image' className='img-fluid appStoreImage mb-20' />
            <img src={GooglePlay} alt='google-play-image' className='img-fluid googlePlayImage' />
          </div>
        </div>
      </section>
  )
}

export default DownloadApp
