import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import WhiteLogo from "../../assets/images/home/other/white-logo.svg";
import ColorLogo from "../../assets/images/home/other/color-logo.svg";
import EnglishFlag from "../../assets/images/home/other/englishFlag.svg";
import ArabicFlag from "../../assets/images/home/other/arabicFlag.svg";
import { useNavigate } from 'react-router-dom';
import { Context } from '../../App';
import Select from 'react-select';
import Registration from './Registration';


function Header(props) {
  const [context, setContext] = useContext(Context);
  const nav = useNavigate();
  const [scrolltopdata, setscrolltopdata] = useState('');
  const [loggedIn, setLoggedIn] = useState(""); 
  const [signInShow, setSignInShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [userCur, setUserCur] = useState("USD"); 
  const [selCur, setSelCur] = useState(""); 
  const [currencyList, setCurrencyList] = useState([{value:"AED",label:"AED"},{value:"USD",label:"USD"},{value:"INR",label:"INR"},{value:"PKR",label:"PKR"}]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 400) {
        setscrolltopdata('');
      } else {
        setscrolltopdata('scrolled');
      }
    });
  }, [])

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setSelCur({value:sessionStorage.getItem("SEL_CUR"),label:sessionStorage.getItem("SEL_CUR")});
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      if(context.userCur !== "") {
        setUserCur(context.userCur);
        sessionStorage.setItem("SEL_CUR", context.userCur)
        setSelCur({value:context.userCur,label:context.userCur});
      } else {
        setUserCur("USD");
        sessionStorage.setItem("SEL_CUR", "USD")
        setSelCur({value:"USD",label:"USD"});
      }
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur || selCur === ""])


  useEffect(() => {
    let loggedInfo = localStorage.getItem("logged");
    if (loggedInfo && loggedInfo !== null && loggedInfo !== "") {
      setLoggedIn(JSON.parse(loggedInfo));
    } else {
      setLoggedIn(null);
    }
  }, [localStorage?.getItem("logged")]);

  const handleLoggedInData = (data) => {
    setLoggedIn(data);
  }

  const handleSignInClose = () => {
    setSignInShow(false);
  }
  const handleSignInShow = () => {
    setSignInShow(true);
    setRegisterShow(false);    
  }

  const handleRegisterClose = () => {
    setRegisterShow(false);
  }
  const handleRegisterShow = () => {
    setRegisterShow(true);
    setSignInShow(false);
  }
  const selectCurrency = (value) => {
    setSelCur(value)
    setUserCur(value.value)
    setContext({...context, userCur: value.value});
    sessionStorage.setItem("SEL_CUR", value.value);
  }

  return (
    <>
    <header className={`headerWrapper ${scrolltopdata} ${props?.modifyHeader ? "modify" : ""}`} id="nav-menu" aria-label="navigation bar">
      <div className="mainContainer d-flex align-items-center justify-content-between">
        <div className="nav-start d-flex align-items-center">
          <a className="logo" onClick={() => nav("/")}>
            <img src={props.page === "fixedHeader" ? ColorLogo : scrolltopdata === "" ? WhiteLogo : ColorLogo} alt="Logo" className="logo" />
          </a>
          <ul className={`ml-5 leftNav ${props.page === "fixedHeader" ? "d-flex" : scrolltopdata === "" ? "d-none" : "d-flex"}`}>
            <li className= {window.location.href?.includes("/flight/") ? 'active' :''}><a className="nav-link" href="/"><i className="icon icon-flight"></i><span className='menu-bar-item'>Flight</span></a></li>
            <li className= {window.location.href?.includes("/hotel/") ? 'active' :''}><a className="nav-link" href="/"><i className="icon icon-hotel"></i><span className='menu-bar-item'>Hotel</span></a></li>
          </ul>
        </div>

        <div className="nav-end">
          <nav className="menu">
            <ul className="menu-bar d-flex align-items-center">
                {loggedIn &&                    
                  <li onClick={() => nav("/mytrips")}><a className="nav-link"><i className="icon icon-trip"></i><span className='menu-bar-item'>My Trips</span></a></li>
                }
              <li>
                
              {props.flow === "RESULT" && (
                <>
                  {/* <img src={ArabicFlag} alt="flag-icon" /> */}
                  <Select 
                    options={currencyList}
                    onChange={(value) => selectCurrency(value)} 
                    placeholder="Change Currency"
                    values={selCur} 
                    defaultValue={{value:sessionStorage.getItem("SEL_CUR"), label:sessionStorage.getItem("SEL_CUR")}}
                    className='changeCurrency'
                  >                  
                  </Select>
                </>
              )}
                {/* <Dropdown>
                  <Dropdown.Toggle className='dropdownTitle'>
                    <img src={ArabicFlag} alt="flag-icon" />
                    <span className='menu-bar-item'>{userCur}</span><i className="icon icon-down-arrow"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdownMenu'> */}
                    {/* <div className='menuItem'>Change Country<i className="icon icon-down-arrow"></i></div> */}
                    {/* <div className='menuItem'>Change Currency<i class="icon icon-down-arrow"></i></div> */}
                    {/* {props.flow === "RESULT" && (
                      <Select 
                        options={currencyList}
                        values={selCur} 
                        defaultValue={selCur}
                        onChange={(value) => selectCurrency(value)} 
                        placeholder="Change Currency"
                      >                  
                      </Select>
                    )} */}
                  {/* </Dropdown.Menu>
                </Dropdown> */}
              </li>
              <li className='lang'>
                <Dropdown>
                  <Dropdown.Toggle className='dropdownTitle'>
                    <i className="icon icon-Globe"></i>
                    <span className='menu-bar-item'>عربي</span><i className="icon icon-down-arrow"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dropdownMenu'>
                    <div className='menuItem'><img src={EnglishFlag} alt="flag-icon" /> English </div>
                    <div className='menuItem'><img src={ArabicFlag} alt="flag-icon" /> عربي </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                {/* <Dropdown drop="start"> */}
                  {/* {loggedIn && loggedIn.fname ?
                    <span onClick={() => nav("/myprofile")}> <i class="icon icon-signin"></i>
                      Hi, {loggedIn?.fname.substring(0,1).toUpperCase()}{loggedIn?.fname.substring(1,loggedIn?.fname.length).toLowerCase()}
                    </span>
                    :
                    <Dropdown.Toggle className='formPopupTitle'>
                      <i class="icon icon-signin"></i><span className='menu-bar-item'>
                        Sign In
                      </span>
                    </Dropdown.Toggle>
                  } */}
                  
                  {/* <Dropdown.Menu className='formPopup'>
                    <div className='popupTitle'>Sign In</div>
                    <Form>
                      <Form.Group controlId="signinEmail" className='position-relative'>
                        <i class="icon icon-email"></i>
                        <Form.Control 
                          type="email" 
                          placeholder="Enter your email address" 
                          onChange={handleFormData}
                          value={state.signinEmail}
                        />
                        {state.errorEmailMsg && <small>Please enter email</small>}
                        {state.errorEmailValidate && <small>Please enter valid email</small>}
                      </Form.Group>

                      <Form.Group controlId="signinPassword" className='position-relative'>
                        <i class="icon icon-email"></i>
                        <Form.Control 
                          type="password" 
                          placeholder="Enter your Password" 
                          onChange={handleFormData}
                          value={state.signinPassword}
                        />
                        {state.errorPasswordMsg && <small>Please enter password</small>}
                      </Form.Group>
                      <div className='forgotPassword'>Forgot Password?</div>
                      <Button variant="primary" type="submit" className='submitBtrn' onClick={handleSignIn}>
                        Sign In
                      </Button>
                    </Form>
                    <div className='signInWith'>or sign in using</div>
                    <div className='otherOptionWrap'>
                      <span className="fb"><img src={ArabicFlag} alt="flag-icon" /> Facebook</span>
                      <span className="google">
                        <GoogleOAuthProvider clientId={GOOGLE_CLEINT_ID}>
                          <GoogleLogin
                            shape="circle"
                            type="icon"
                            size="small"
                            onSuccess={credentialResponse => {
                              console.log("GOOGLE RESP : " + JSON.stringify(credentialResponse));
                              if (credentialResponse?.credential) {
                                loginWithGmail(credentialResponse?.credential);
                              } else {
                                console.log(":::: GOOGLE Login Failed ::::");
                              }
                            }}
                            onError={(e) => {
                              console.log('GoogleOAuthProvider Failed', e);
                            }}
                          />
                        </GoogleOAuthProvider>
                      </span>
                      <span className="apple"><img src={ArabicFlag} alt="flag-icon" /> Apple ID</span>
                    </div>
                    <div className="alreadyAccount">Do not have an account? <span>Register Now</span></div>
                    <div className="privacyPolicyWrap">By signing in or creating an account, you agree with our <span>Terms & Conditions</span> and <span>Privacy Policy.</span></div>
                  </Dropdown.Menu> */}
                {/* </Dropdown> */}
                <div className='afterLogin'>
                {loggedIn && loggedIn.fname ?
                    <span onClick={() => nav("/myprofile")} className='hiprofile'> <i className="icon icon-signin"></i>
                      Hi, {loggedIn?.fname ? `${loggedIn?.fname?.substring(0,1).toUpperCase()}${loggedIn?.fname?.substring(1,loggedIn?.fname?.length).toLowerCase()}`:""}
                    </span>
                    :
                    <div onClick={handleSignInShow} className='formPopupTitle'>
                      <i className="icon icon-signin"></i><span className='menu-bar-item signIn'>
                        Sign In
                      </span>
                    </div>
                  }
                  </div>                  
              </li>
              <li className='register'>
              {!loggedIn && !loggedIn?.fname &&
                <div className='formPopupTitle' onClick={handleRegisterShow}>
                  <span className='menu-bar-item'>Register</span>
                </div>
              }
              </li>
            </ul>
            <Registration showSignIn={signInShow} showRegister={registerShow} closeSignin={handleSignInClose} closeRegister={handleRegisterClose} sendLoggedInData={handleLoggedInData} />
          </nav>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header
