import React from 'react'
import ReviewBooking from './ChildComp/ReviewBooking'
import BaggageProtection from './ChildComp/BaggageProtection'
import ContactDetails from './ChildComp/ContactDetails'
import PaxDetails from './ChildComp/PaxDetails'
import TripSummary from '../common/TripSummary'
import { Link } from 'react-router-dom'

function FlightReviewDetails() {
  return (
    <>
      <section class="review-booking-flex">
        <div class="container">
          <div class="review-booking-left">
            <ReviewBooking />
            <BaggageProtection />
            <ContactDetails />
            <PaxDetails />
            <Link to='/payment'> <button className='next-btn'>Next <img src="assets/images/flight-result/iconright1.svg" alt=""></img></button></Link>
          </div>
          <TripSummary />
        </div>
      </section>
    </>
  )
}

export default FlightReviewDetails
