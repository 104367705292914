import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mpr1 from "../../assets/images/home/most-popular-routes/mpr1.png";
import Mpr2 from "../../assets/images/home/most-popular-routes/mpr2.png";
import Mpr3 from "../../assets/images/home/most-popular-routes/mpr3.png";
import Mpr4 from "../../assets/images/home/most-popular-routes/mpr4.png";
import RoundTrip from "../../assets/images/home/other/round-trip.svg";

function MostPopularRoutes() {
  return (
    <section className='mt-30'>
        <div className="mainContainer">
          <h3 className='headingTitle mb-15'>Most Popular Routes</h3>
          <Row>
            <Col>
              <div className='mprWrap'>
                <figure><img src={Mpr1} alt='most-popular-routes-image' className='img-fluid locationImage' /></figure>
                <div className='routes'>
                  <span>Dubai</span>
                  <i class="icon icon-round-trip"></i>
                  <span>London</span>
                </div>
                <div className='origin'>Starts from</div>
                <div className='amount'>AED 128.00</div>
              </div>
            </Col>
            <Col>
              <div className='mprWrap'>
                <figure><img src={Mpr2} alt='most-popular-routes-image' className='img-fluid locationImage' /></figure>
                <div className='routes'>
                  <span>Dubai</span>
                  <i class="icon icon-round-trip"></i>
                  <span>London</span>
                </div>
                <div className='origin'>Starts from</div>
                <div className='amount'>AED 371.00</div>
              </div>
            </Col>
            <Col>
              <div className='mprWrap'>
                <figure><img src={Mpr3} alt='most-popular-routes-image' className='img-fluid locationImage' /></figure>
                <div className='routes'>
                  <span>Dubai</span>
                  <i class="icon icon-round-trip"></i>
                  <span>London</span>
                </div>
                <div className='origin'>Starts from</div>
                <div className='amount'>AED 157.00</div>
              </div>
            </Col>
            <Col>
              <div className='mprWrap'>
                <figure><img src={Mpr4} alt='most-popular-routes-image' className='img-fluid locationImage' /></figure>
                <div className='routes'>
                  <span>Dubai</span>
                  <i class="icon icon-round-trip"></i>
                  <span>London</span>
                </div>
                <div className='origin'>Starts from</div>
                <div className='amount'>AED 381.00</div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
  )
}

export default MostPopularRoutes
