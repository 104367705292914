import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Star from "../../assets/images/home/other/star.svg";
import Shd1 from "../../assets/images/home/special-hotel-deal/shd1.png";
import Shd2 from "../../assets/images/home/special-hotel-deal/shd2.png";
import Shd3 from "../../assets/images/home/special-hotel-deal/shd3.png";
import Shd4 from "../../assets/images/home/special-hotel-deal/shd4.png";

function SpecialHotelDeals() {
  return (
    <section className='mt-50'>
        <div className="mainContainer">
          <h3 className='headingTitle mb-15'>Special Hotel Deals</h3>
          <div className='hotelLocation d-flex mt-20 mb-20'>
            <span className='sparkle-effect active'>Dubai</span> 
            <span className='sparkle-effect'>Bangkok</span> 
            <span className='sparkle-effect'>Singapore</span> 
            <span className='sparkle-effect'>New Delhi</span> 
          </div>
          <Row>
            <Col>
              <div className='shdWrap'>
                <figure><img src={Shd1} alt='special-hotel-image' className='img-fluid hotelImage' /></figure>
                <div className='hotelNmame'>Carlton Palace Hotel</div>
                <div className='starRating'>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                </div>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <div className='origin'>Starts from</div>
                    <div className='amount'>AED 128.00</div>
                  </div>
                  <div className='rightArrowWrap'>
                  <i class="icon icon-down-arrow rightArrowOne"></i>
                  <i class="icon icon-down-arrow rightArrowTwo"></i>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='shdWrap'>
                <figure><img src={Shd2} alt='special-hotel-image' className='img-fluid hotelImage' /></figure>
                <div className='hotelNmame'>Carlton Palace Hotel</div>
                <div className='starRating'>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                </div>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <div className='origin'>Starts from</div>
                    <div className='amount'>AED 371.00</div>
                  </div>
                  <div className='rightArrowWrap'>
                  <i class="icon icon-down-arrow rightArrowOne"></i>
                  <i class="icon icon-down-arrow rightArrowTwo"></i>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='shdWrap'>
                <figure><img src={Shd3} alt='special-hotel-image' className='img-fluid hotelImage' /></figure>
                <div className='hotelNmame'>Carlton Palace Hotel</div>
                <div className='starRating'>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                </div>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <div className='origin'>Starts from</div>
                    <div className='amount'>AED 157.00</div>
                  </div>
                  <div className='rightArrowWrap'>
                  <i class="icon icon-down-arrow rightArrowOne"></i>
                  <i class="icon icon-down-arrow rightArrowTwo"></i>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='shdWrap'>
                <figure><img src={Shd4} alt='special-hotel-image' className='img-fluid hotelImage' /></figure>
                <div className='hotelNmame'>Carlton Palace Hotel</div>
                <div className='starRating'>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                  <i class="icon icon-star"></i>
                </div>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <div className='origin'>Starts from</div>
                    <div className='amount'>AED 381.00</div>
                  </div>
                  <div className='rightArrowWrap'>
                  <i class="icon icon-down-arrow rightArrowOne"></i>
                  <i class="icon icon-down-arrow rightArrowTwo"></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
  )
}

export default SpecialHotelDeals
