/**
 * Axios ctions
 */
import axios from "axios"

/**
 * Post calls
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const handlePostApi = async (url, data) => {
  try {
    const response = await axios.post(`${url}`, data);
    return response;
  } catch (err) {
    console.log(`${url} Error`, err)
  }
}

/**
 * Get Calls
 * @param {*} url 
 * @param {*} extention 
 * @returns 
 */
export const handleGetApi = async (url, extention) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (err) {
    console.log(`${url} Error`, err)
  }
}