import React, { useState,useEffect } from "react";
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function Days(props) {
    const [day,setDay] = useState("")
    const [days,setDays] = useState([])
    
    useEffect(()=>{
        var list = [];
        for (var i = 1; i <= 31; i++){
        if(String(i).length===1){
          i = "0"+i;
        }
        list.push({label:i,value:i});
        }
        setDays(list)
      },[])

      useEffect(() => {
        setDay(props.updateDay)
      }, [props.updateDay]);
   
      const updateDay=(day)=>{
        setDay(day)
        props.sendDay(day)
      }

  return (   
    <>
    {/* <Form.Select 
      aria-label="Default select example" 
      className='daySelect'
      value={day}
      onChange={(value) => (updateDay(value))}
    >
        <option value="">Day</option>
        {days.map((option, index) => (
        <option key={index} value={option.value}>
            {option.label}
        </option>
        ))}
    </Form.Select> */}
    <Select 
        options={days}
        values={day}
        placeholder="Day"
        onChange={(value) => (updateDay(value))}
        > 
        {days.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )
          }
        )}                 
    </Select>
    </>
  );
}
export default Days;
