import React, { useEffect, useState } from "react";
import Select from 'react-select';

/**
 * @description:This function will creaet all the Add Meal details
 * @author: Ubedullah
 * @param {*}
 * @function Meal details
 * @date : 04-07-2024
 */

const AddMeal = (props) => {
  const [mealDefault, setMealDefault] = useState([]);
  const [mealOptions, setMealOptions] = useState([]);

  useEffect(() => {
    if (props.mealList !== undefined) {
      if (props.mealList.includes("Select")) {
        setMealDefault([]);
      } else {
        if (
          props.mealList.length !== 0 &&
          props.mealList[0] !== undefined &&
          props.mealList[0].code !== undefined
        ) {
          setMealOptions(props.mealList);
          setMealDefault({
            value: props.mealList[0]?.code,
            label: props.mealList[0]?.label,
          });
        } else {
          for (let data of props.mealList) {
            setMealOptions(data);
            setMealDefault({ value: data[0]?.code, label: data[0].label });
          }
        }
      }
    }
  }, []);

  const setMealPrice = (value) => {
    const obj = mealOptions.find((option) => option.code === value.code);
    props.sendMealprice(obj);
    if (!props.mealList.includes("Select")) {
      setMealDefault(value);
    }
  };

  return (
    <>
    <div className="addBaggageWrap">
        <Select 
            options={mealOptions}
            values={mealDefault} 
            onChange={setMealPrice}
            placeholder="Select Meals"
            > 
            {mealOptions.map((option) => {
              return (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>
      
      </div>
    </>
  );
};

export default AddMeal;