import React from 'react';
import Countdown from 'react-countdown';
import SessionTimeOut from './SessionTimeOut';

/**
 * @description: This function will display countdown timer in payment page
 * @author: Ubedullah
 * @param {*}
 * @function Timer
 * @date : 22-07-2024
 */

function Timer() {
    const Completionist = () => {
        return (
            <SessionTimeOut />
        )
    }

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {

        minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
        seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        if (completed) {
            return <Completionist />;
        } else {
            return <span>{minutes}:{seconds}</span>;
        }
    };

    return (
        <Countdown date={Date.now() + 300000} renderer={renderer} />
    )
}

export default Timer

