import React, { useState ,useEffect } from "react";
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function Years(props) {
    const [year,setyear] = useState("")
    const [yearsAdultList,setAearsAdultList]=useState([])
    const currentYear = new Date().getFullYear();
    const currentYearAdult = new Date().getFullYear() - 12;
    const currentYearHtlAdult = new Date().getFullYear() - 18;
    const currentYearChild = new Date().getFullYear() - 2;
    const yearsRange = 80;
    const infDobyearsRange = 3;
    const childDobYearsRange = 11;
    const htlChildDobYearsRange = 18;
    const htlAdultDobYearsRange = 62;
    const years = Array.from({ length: yearsRange }, (_, index) => {
      const year = currentYear - index;
      return { value: year.toString(), label: year };
    });
    const yearsAdult = Array.from({ length: yearsRange }, (_, index) => {
      const year = currentYearAdult - index;
      return { value: year.toString(), label: year };
    });
    const yearsChild = Array.from({ length: childDobYearsRange }, (_, index) => {
      const year = currentYearChild - index;
      return { value: year.toString(), label: year };
    });
    const yearsHtlAdult = Array.from({ length: htlAdultDobYearsRange }, (_, index) => {
      const year = currentYearHtlAdult - index;
      return { value: year.toString(), label: year };
    });
    const yearsHtlChild = Array.from({ length: htlChildDobYearsRange }, (_, index) => {
      const year = currentYear - index;
      return { value: year.toString(), label: year };
    });
    const expiryYears = Array.from({ length: yearsRange }, (_, index) => {
      const year = currentYear + index;
      return { value: year.toString(), label: year };
    });
    const infDobYears = Array.from({ length: infDobyearsRange }, (_, index) => {
      const year = currentYear - index;
      return { value: year.toString(), label: year };
    });

    useEffect(() => {
      setyear(props.updateYear)
    }, [props.updateYear]);

    const updateYear=(month)=>{
    setyear(month)
    props.sendYear(month)
    }

  return (
    <>
    {(props.type === "ADT" || props.type === "adult") && (        
      //   <Form.Select 
      //       aria-label="Default select example" 
      //       className='yearSelect'
      //       value={year}
      //       onChange={(value) => (updateYear(value))}
      //   >
      //       <option value="">Year</option>
      //       {yearsAdult.map((year) => (
      //       <option key={year} value={year}>
      //           {year}
      //       </option>
      //       ))}
      // </Form.Select>    
      <Select 
          options={yearsAdult}
          values={year}
          placeholder="Year"
          onChange={(value) => (updateYear(value))}
          > 
          {yearsAdult.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
            }
          )}                 
      </Select>  
      )
      }    

      {(props.type === "CHD" || props.type === "child") && (     
      //   <Form.Select 
      //       aria-label="Default select example" 
      //       className='yearSelect'
      //       value={year}
      //       onChange={(value) => (updateYear(value))}
      //   >
      //       <option value="">Year</option>
      //       {yearsChild.map((year) => (
      //       <option key={year} value={year}>
      //           {year}
      //       </option>
      //       ))}
      // </Form.Select>
      <Select 
          options={yearsChild}
          values={year}
          placeholder="Year"
          onChange={(value) => (updateYear(value))}
          > 
          {yearsChild.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
            }
          )}                 
      </Select>  
      )
      }    

      {props.type === "HTLADT" && (    
        // <Form.Select 
        //     aria-label="Default select example" 
        //     className='yearSelect'
        //     value={year}
        //     onChange={(value) => (updateYear(value))}
        // >
        //     <option value="">Year</option>
        //     {yearsHtlAdult.map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        //     ))}
        // </Form.Select>   
        <Select 
            options={yearsHtlAdult}
            values={year}
            placeholder="Year"
            onChange={(value) => (updateYear(value))}
            > 
            {yearsHtlAdult.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>       
        )
        }     

      {props.type === "HTLCHD" && ( 
        // <Form.Select 
        //     aria-label="Default select example" 
        //     className='yearSelect'
        //     value={year}
        //     onChange={(value) => (updateYear(value))}
        // >
        //     <option value="">Year</option>
        //     {yearsHtlChild.map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        //     ))}
        // </Form.Select>    
        <Select 
            options={yearsHtlChild}
            values={year}
            placeholder="Year"
            onChange={(value) => (updateYear(value))}
            > 
            {yearsHtlChild.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>   
        )
      }   
      
      {props.type === "Inf" && (
        // <Form.Select 
        //     aria-label="Default select example" 
        //     className='yearSelect'
        //     value={year}
        //     onChange={(value) => (updateYear(value))}
        // >
        //     <option value="">Year</option>
        //     {infDobYears.map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        //     ))}
        // </Form.Select>    
        <Select 
            options={infDobYears}
            values={year}
            placeholder="Year"
            onChange={(value) => (updateYear(value))}
            > 
            {infDobYears.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>   
        )
      }    

      {props.type === "isuDate" && (
        // <Form.Select 
        //     aria-label="Default select example" 
        //     className='yearSelect'
        //     value={year}
        //     onChange={(value) => (updateYear(value))}
        // >
        //     <option value="">Year</option>
        //     {years.map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        //     ))}
        // </Form.Select>   
        <Select 
            options={years}
            values={year}
            placeholder="Year"
            onChange={(value) => (updateYear(value))}
            > 
            {years.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>  
        )
      }      
      
      {props.type === "expDate" && (
        // <Form.Select 
        //     aria-label="Default select example" 
        //     className='yearSelect'
        //     value={year}
        //     onChange={(value) => (updateYear(value))}
        // >
        //     <option value="">Year</option>
        //     {expiryYears.map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        //     ))}
        // </Form.Select>  
        <Select 
            options={expiryYears}
            values={year}
            placeholder="Year"
            onChange={(value) => (updateYear(value))}
            > 
            {expiryYears.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
              }
            )}                 
        </Select>  
        )
      }                                        
    </>
  );
}
export default Years;