import { configureStore } from "@reduxjs/toolkit";
import SearchFlightSlice from "./Slices/SearchFlightSlice";
import SearchHotelSlice from "./Slices/SearchHotelSlice";
import UserSlice from "./Slices/UserSlice";
import FlightFilter from "./Slices/FlightFilter";
export const store = configureStore({
  reducer: {
    searchflight: SearchFlightSlice,
    searchhotel: SearchHotelSlice,
    userslice: UserSlice,
    flightfilter: FlightFilter
  }
})