import React from 'react'

function BaggageProtection() {
  return (
    <>
      <div className="signup-row d-flex">
        <figure><img src="assets/images/flight-result/sign.svg" alt="" /></figure>
        <p><a href="">Sign Up</a> now and speed up your booking experience.</p>
        <p>Already a member ? <a href="">Sign In</a></p>
      </div>

      <div className="baggage-protection">
        <div className="top-title d-flex">
          <div className="left d-flex">
            <figure><img src="assets/images/flight-result/bags.svg" alt="" /></figure>
            <span>Baggage Protection</span>
          </div>
          <div className="right d-flex">
            <strong>+ Add Now</strong>
            <i><img src="assets/images/flight-result/group.svg" alt="" /></i>
          </div>
        </div>

        <div className="get-baggage">
          <small>Get compensated if your baggage is delayed or lost, at just AED 10/ Traveller.</small>
          <ul className="d-flex">
            <li>
              <div className="title">
                <img src="assets/images/flight-result/locationicon.svg" alt="" /> Live Tracking
              </div>
              <p>Real time updated on Email & SMS about baggage status.</p>
            </li>
            <li>
              <div className="title">
                <img src="assets/images/flight-result/proof.svg" alt="" /> No Required
              </div>
              <p>No need to provide the details of the bag's content.</p>
            </li>
            <li>
              <div className="title">
                <img src="assets/images/flight-result/payment.svg" alt="" /> Guaranteed Payment
              </div>
              <p>Receive AED 2000 for every bag that doesn't arrive in 96 hours.</p>
            </li>
          </ul>

          <label>By adding baggage protection you agree to the <em>Blue Ribbon Bags Terms &
            Conditions</em></label>
        </div>
      </div>
    </>
  )
}

export default BaggageProtection
