
/**
 * @author Praveen Varma
 * @description Predective search and Home page
 * @date 14-06-2024
 */
import React, { useReducer, useRef, useCallback, useState, useEffect } from 'react';
import { Form, Button, Row, Col } from "react-bootstrap";
import { handleGetApi } from '../../../utilities/Axios'
import debounce from 'lodash.debounce';
import DatePicker from "react-datepicker";
import DateUtils from '../../utils/DateUtils';
import AddGuestRooms from './AddGuestRooms';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import moment from "moment";
import { isArrayNotEmpty, isNotNull } from '../../utils/validators';
var nationality_json = require("../../maasterdata/NationalityData.json");

/**
 * Initial values setting to state
 */
const initialState = {
    nights: 1, isSearching: false, rmCount: 1, guestCount: 2, hotelData: null, selHotel: "", notiMessage: "", notiMessageShow: false, notiVarient: "danger", roomsInfo: [], ismodify: false, ismodify_from_details: false,flow: "Results", destName: "", hotelPredectiveDropDown: false, _startDateCal: false, _endDateCal: false, openArivalDate: new Date(), selDest: [], cleardb: false, showInboundPopup: false,
    sel_nationality: "", nationality: ""
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state,
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};

/**
 * HotelSearch Component starts here
 * @param {*} props 
 */
function HotelSearch(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const destination = useRef();
    const nav = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endtDate, setEndtDate] = useState(DateUtils.addDatePlusOne(new Date()));
    const [checkOutDate, setCheckOutDate] = useState(DateUtils.addDatePlusOne(new Date()));
    const [addGuests, setAddGuests] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [modify_srch_riteria, setModifySrchCriteria] = useState(props?.mod_criteria);
    const [nationalityList, setNationalityList] = useState([]);
    const [showRoomsPopup, setShowRoomsPopup] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (modify_srch_riteria) {
            setStartDate(new Date(moment(modify_srch_riteria?.cin, "DD-MM-YYYY")));
            setEndtDate(new Date(moment(modify_srch_riteria?.cout, "DD-MM-YYYY")));
            let hcode = modify_srch_riteria?.othcode;
            if(!isNotNull(hcode)){
                hcode = modify_srch_riteria?.tophcode
            }
            getModifySearchCity(modify_srch_riteria?.otctid, hcode);
            if (modify_srch_riteria?.na) {
                let na = nationality_json?.filter((itm) => itm.CountryId == Number(modify_srch_riteria?.na));
                if (na && na.length > 0) {
                    let na_itm = {
                        label: na[0].Country,
                        value: na[0].CountryId
                    }
                    dispatch({ type: "nationality", payload: na[0].CountryId })
                    dispatch({ type: "sel_nationality", payload: na_itm })
                }
            }
            modifiy_rooms_construction();
        }
    }, [modify_srch_riteria]);

    const modifiy_rooms_construction = async () => {
        if (modify_srch_riteria != null) {
            try {
                let constucton = [];
                let crit = modify_srch_riteria;

                let s_dateString = crit.cin;
                let [sday, smonth, syear] = s_dateString.split('-')
                let date=new Date(+syear, +smonth - 1, +sday);
                setStartDate(date)

                let t_dateString = crit.cout;
                let [tday, tmonth, tyear] = t_dateString.split('-')
                let end_date =new Date(+tyear, +tmonth - 1, +tday);
                setEndtDate(end_date);
               
                if (end_date !== undefined && date !== undefined && end_date !== "") {
                    var diff_In_Time = end_date.getTime() - date.getTime();
                    var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
                    dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
                }

                let rms = crit?.adt?.split(",") || [];
                dispatch({ type: "rmCount", payload: rms.length });
                let adults_info = crit?.adt?.split(",") || [];
                let adt_count = 0;
                adults_info?.forEach((itm) => {
                    adt_count = itm ? adt_count + Number(itm) : adt_count;
                })
                let chd_info = crit?.chdages?.split("|");
                let chd_count = 0;
                chd_info?.forEach(itm => {
                    let aa = itm ? itm.split(",") : [];
                    aa?.forEach((b) => {
                        chd_count = b && Number(b) > 0 ? chd_count + 1 : chd_count;
                    })
                })
                let total_guests = adt_count + chd_count;
                dispatch({ type: "guestCount", payload: total_guests })
                if (rms.length > 0 && crit.rms && Number(crit.rms) === rms.length) {
                    let rm_inx = 0;
                    for (let inx in rms) {
                        let adt_rm = rms[inx]
                        let itm = {
                            rmno: rm_inx + 1,
                            adt: adt_rm,
                            chd: 0,
                            chdArray: [],
                            chdAges: []
                        }
                        if (crit.chdages !== undefined && crit.chdages !== null && crit.chdages !== "") {
                            let chd_ages_arry = crit.chdages.split("|");
                            let chdAges = chd_ages_arry[rm_inx];
                            let chdArray = [];
                            if (chdAges !== undefined && chdAges !== null && chdAges !== "") {
                                let chdAges_split = chdAges.split(",");
                                let ad_index = 1;
                                for (let ag of chdAges_split) {
                                    let chd = {
                                        chd: ad_index,
                                        age: Number(ag)
                                    }
                                    chdArray.push(chd);
                                    ad_index = ad_index + 1;
                                }
                            }
                            itm.chd = chdArray ? chdArray.length : 0
                            itm.chdArray = chdArray;
                            if(chdArray.length > 0){
                                let ch_ag = chdArray?.map(ims => ims.age);
                                itm.chdAges = ch_ag;
                            }else{
                                itm.chdAges = []
                            }
                        }
                        constucton.push(itm);
                        rm_inx = rm_inx + 1;
                    }
                }
                dispatch({ type: "roomsInfo", payload: constucton });
            } catch (error) {
                console.log("ERROR:::: modifiy_rooms_construction", error);
            }
        }
    }

    useEffect(() => {
        let listdata = [];
        nationality_json?.forEach(itm => {
            let itmdata = {
                label: itm.Country,
                value: itm.CountryId
            }
            listdata.push(itmdata);
        })

        setNationalityList(listdata);
    }, [])

    const getModifySearchCity = async (city, hcode) => {
        try {
            handleGetApi(`${process.env.REACT_APP_HOTEL_CONTENT_URL}/htl/elastic/modify/search?otCityId=${city}&otHtlId=${hcode}&skip=0&limit=10`).then((response) => {
                if (response?.data?.suc && isArrayNotEmpty(response?.data?.res)) {
                    let obj = response?.data?.res[0];
                    dispatch({ type: "hotelData", payload: response?.data?.res })
                    dispatch({ type: "destName", payload: obj?.description})
                    dispatch({ type: "selHotel", payload: obj })
                }
            });
        } catch (error) {
            console.log("ERROR ::: getModifySearchCity", error);
        }
    }

    const handleStartDate = date => {
        setStartDate(date);
        setCheckOutDate(DateUtils.addDatePlusOne(date));
        let end_date = DateUtils.addDatePlusOne(date)
        setEndtDate(end_date)
        dispatch({ type: 'openArivalDate', payload: date })
        if (end_date !== undefined && date !== undefined && end_date !== "") {
            var diff_In_Time = end_date.getTime() - date.getTime();
            var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
            dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
        }
        if (state?.roomsInfo.length === 0) {
            dispatch({ type: "roomsInfo", payload: [{ rmno: 1, adt: 2, chd: 0, chdArray: [], chdAges: [] }] });
        }
    }

    const handleChangOrigin = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
        dispatch({ type: "isSearching", payload: true });
        if (value.length >= 1) {
            let obj = {
                textValue: value
            }
            debouncedSave(obj);
        } else {
            dispatch({ type: 'hotelData', payload: "" })
            dispatch({ type: 'destName', payload: "" })
        }
        setErrors(errors.filter(itm => itm !== "destName"));
    }

    const debouncedSave = useCallback(
        debounce(obj => predectiveCall(obj.textValue), 500),
        [],
    );

    const predectiveCall = (input) => {
        dispatch({ type: "hotelData", payload: null });
        handleGetApi(`${process.env.REACT_APP_HOTEL_CONTENT_URL}/htl/elastic/search/${input}?skip=0&limit=10`).then((response) => {
            dispatch({ type: "hotelData", payload: response.data.res && response.data.res !== "" && response.data.res !== null ? response.data.res : null })
        });
    }

    const handleCheckout = date => {
        setEndtDate(new Date(date));
        if (startDate !== undefined && date !== undefined && startDate !== "") {
            var diff_In_Time = date.getTime() - startDate.getTime();
            var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
            dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
            setAddGuests(true);
        }
        if (state?.roomsInfo.length === 0) {
            dispatch({ type: "roomsInfo", payload: [{ rmno: 1, adt: 2, chd: 0, chdArray: [], chdAges: [] }] });
        }
    }

    const handleOnOrigin = (obj, type) => () => {
        if (obj !== undefined && obj !== null && obj !== "") {
            dispatch({ type: "isSearching", payload: false })
            obj.type = type;
            if (type !== "city") {
                dispatch({ type: "destName", payload: obj.description })
            } else {
                if (obj.description !== null) {
                    dispatch({ type: "destName", payload: obj.description })
                } else {
                    dispatch({ type: "destName", payload: obj.text })
                }
            }
            dispatch({ type: "selHotel", payload: obj })
        } else {
            dispatch({ type: "selHotel", payload: "" })
            dispatch({ type: "destName", payload: "" })
        }
    }

    const refOutClick = useOnclickOutside(() => {
        setAddGuests(false);
    });

    const handleChange = (e) => {
        if (addGuests) {
            setAddGuests(false)
        } else {
            setAddGuests(true)
        }
        setShowRoomsPopup(prev => !prev);
    }

    const guestCountupdate = (data) => {
        dispatch({ type: "guestCount", payload: data })
    }

    const roomCountupdate = (data) => {
        dispatch({ type: "rmCount", payload: data })
    }
    const roomInfoupdate = (data) => {
        dispatch({ type: "roomsInfo", payload: data })
    }

    const closeGust = () => {
        setAddGuests(false)
    }

    /**
     * Search API Call
     */
    const searchHotel = async () => {
        let errs = await validateSearch();
        if(errs.length > 0){
            return errs;
        }
        let adults = [];
        let chdAges = [];
        if (state.roomsInfo && state.roomsInfo.length > 0) {
            for (let room of state?.roomsInfo) {
                adults.push(room.adt);
                if (room.chdAges && room.chdAges.length > 0) {
                    let local_chd = room.chdAges;
                    local_chd = local_chd.join(",");
                    chdAges.push(local_chd);
                } else {
                    chdAges.push("")
                }
            }
            if (chdAges && chdAges.length > 0) {
                chdAges = chdAges.join("|")
            } else {
                chdAges = "";
            }
            adults = state?.roomsInfo?.map(itm => itm.adt)?.join(",") || "";
        }else{
            dispatch({ type: "roomsArray", payload: [{rmno: 1,adt: 1, chd: 0,chdArray: [],chdAges: []}] })
        }
        let append = "";
        let rty = "city";
        if (state?.selHotel !== undefined && state?.selHotel !== "" && state?.selHotel !== null) {
            let predective = state?.selHotel;
            append = `&otctid=${predective.otctid}`
            if (predective.type === "hotel") {
                //append = `${append}&othcode=${predective.hcode}`
                //rty = "prop";
                append = `${append}&tophcode=${predective.hcode}`
            }
        }
        console.log(append);
        let na = state?.sel_nationality?.value;
        window.location.href=`/hotel/result?cin=${DateUtils.convertToDateHifen(startDate)}&cout=${DateUtils.convertToDateHifen(endtDate)}&na=${na}&rms=${state.roomsInfo.length}&adt=${adults}&chdages=${chdAges}&rty=${rty}${append}`;
    }

    useEffect(() => {
        if ((props?.modify === undefined || props?.modify === false) && state?.roomsInfo?.length === 0) {
            dispatch({ type: "roomsInfo", payload: [{ rmno: 1, adt: 2, chd: 0, chdArray: [], chdAges: [] }] });
        }
    }, [])

    const validateSearch = async() =>{
        let errs = errors;
        if(state?.destName === undefined || state?.destName === null || state?.destName === ""){
            if(!errs.includes("destName"))errs.push("destName");
        }else{
            errs = errs.filter(itm => itm !== "destName");
        }
        if(startDate === undefined || startDate === null || startDate === ""){
            if(!errs.includes("startDate")) errs.push("startDate");
        }else{
            errs = errs.filter(itm => itm !== "startDate");
        }
        if(state?.sel_nationality === undefined || state?.sel_nationality === null || state?.sel_nationality === ""){
            if(!errs.includes("nationality")) errs.push("nationality");
        }else{
            errs = errs.filter(itm => itm !== "nationality");
        }
        if(endtDate === undefined || endtDate === null || endtDate === ""){
            if(!errs.includes("endDate")) errs.push("endDate");
        }else{
            errs = errs.filter(itm => itm !== "endDate");
        }
        errs= errs.filter((item, index) => errs.indexOf(item) === index);
        setErrors(errs);
        return errs;
    }

    const showRoomsPopupUpdate = async (status) => {
        setShowRoomsPopup(status)
    }

    return (
        <>
            <div className="inputFieldsWrap mt-30 modifyInputs">
                <Form.Group className="destination position-relative">
                    <Form.Label><i class="icon icon-Pointer"></i>Destination</Form.Label>
                    <Form.Control type="text" autoComplete="off" autoFocus id="destName" title={(state.destName)} value={state.destName} placeholder="Search City or Hotel Name" onChange={handleChangOrigin} ref={destination} className={errors.includes("destName") ? "redBorder" : "" } />
                    {state?.isSearching ? 
                        <>
                            <div className={state.hotelData !== undefined && state.hotelData !== null && state.hotelData?.city ? "pdtSerchPart" : "pdtSerchPart nocity"}>
                                <div className="searchContent verticalScroll">
                                    <>
                                        <Row>
                                            <Col md={6} className="">
                                                {state?.hotelData?.city?.map((cty, index) => (
                                                    <>
                                                        {index === 0 &&
                                                            <>
                                                                <div className="searchType">City</div>
                                                            </>
                                                        }
                                                        <div className="cityList" onClick={handleOnOrigin(cty, "city")}>
                                                            {cty.description !== null &&
                                                                <>
                                                                    <i class="icon icon-Pointer"></i><span className="plcNm" title={cty.description}> {cty.description}</span>
                                                                </>
                                                            }
                                                            {cty.description == null &&
                                                                <>
                                                                    <i class="icon icon-Pointer"></i><span className="plcNm" title={cty.text} > {cty.text}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                ))}

                                            </Col>
                                            <Col md={6} className="">
                                                {state?.hotelData?.hotel?.map((hotel, index) => (
                                                    <>
                                                        {index === 0 &&
                                                            <>
                                                                <div className="searchType">Hotel</div>
                                                            </>
                                                        }
                                                        <div className="hotelList" onClick={handleOnOrigin(hotel, "hotel")}><span className="plcNm" title={hotel.description} >  <i class="icon icon-hotel"></i>{hotel.description} </span>
                                                        </div>
                                                    </>
                                                ))}

                                            </Col>
                                        </Row>
                                    </>
                                </div>
                            </div>
                        </>: ""
                    }
                    {state?.destName &&
                        <span className='removePrediSearch' onClick={(e) => {
                            dispatch({ type: "selHotel", payload: "" })
                            dispatch({ type: 'destName', payload: "" })
                        }
                        }>
                            <i class="icon icon-Close"></i>
                        </span>
                    }
                </Form.Group>
                <div className='nationalitySelectWrap'>
                    <Form.Label><i class="icon icon-calendar"></i> Nationality</Form.Label>
                    <Form.Group className={errors.includes("nationality") ? "redBorder" : "" }>
                        <Select
                            value={state?.sel_nationality}
                            id="nationality"
                            onChange={(data) => {
                                dispatch({ type: "nationality", payload: data?.value })
                                dispatch({ type: "sel_nationality", payload: data })
                                setErrors(errors.filter(itm => itm !== "nationality"));
                            }}
                            options={nationalityList}
                            placeholder="Select"
                            className='nationalitySelect'
                        />
                    </Form.Group>
                </div>
                <div className={(errors.includes("startDate") || errors.includes("endDate")) ? "checkInOutWrap redBorder" : "checkInOutWrap" }>
                    <Form.Group className="checkInOut checkInWrap">
                        <Form.Label><i class="icon icon-calendar"></i> Check-In</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={handleStartDate}
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date()}
                            startDate={startDate}
                            endDate={endtDate}
                            onFocus={e => e.target.blur()}
                        />
                    </Form.Group>
                    <Form.Group className="night">
                        <Form.Label><span className="ezyIcon icon-night"></span>Nights</Form.Label>
                        <span>{state.nights}</span>
                    </Form.Group>
                    <Form.Group className="checkInOut checkOutWrap">
                        <Form.Label><i class="icon icon-calendar"></i> Check-Out</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={endtDate}
                            openToDate={state?.openArivalDate}
                            onChange={handleCheckout}
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MM-yyyy"
                            minDate={startDate == "" ? new Date() : startDate}
                            startDate={startDate}
                            endDate={endtDate}
                            onFocus={e => e.target.blur()}
                            ref={(c) => state._endDateCal = c}
                        />
                    </Form.Group>
                </div>
                <div className="roomsWrap">
                    <Form.Group ref={refOutClick} className='position-relative'>
                        <div className="rooms" onClick={handleChange} >
                            <div className='roomGuestTitle'><i class="icon icon-signin"></i>Rooms & Guest</div>
                            <div className='roomCount'>{state.rmCount + " Room, " + state.guestCount + " Guest(s)"}</div>
                        </div>
                        {addGuests && <AddGuestRooms showPopup={showRoomsPopupUpdate} sendGuseCount={guestCountupdate} sendRoomsCount={roomCountupdate} sendRoomsInfo={roomInfoupdate} roomsInfo={state.roomsInfo} closeBtn={closeGust} ismodify={state.ismodify} ismodify_from_details={state?.ismodify_from_details} />}
                    </Form.Group>
                </div>
                <Button
                    // size="sm"
                    className='searchBtn'
                    onClick={searchHotel}
                    variant="primary">
                    <span className={"d-flex loading ezyIcon icon-newsearch " + (isLoad ? 'progress-bar-striped progress-bar-animated' : '')}><i class="icon icon-k-logo"></i>{isLoad ? <> <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> </> : <> </>} </span>
                </Button>
            </div>
        </>
    )
}
export default HotelSearch;