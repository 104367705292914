import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PayMethod1 from "../../assets/images/home/footer/card.png";
import PayMethod2 from "../../assets/images/home/footer/visa.png";
import PayMethod3 from "../../assets/images/home/footer/express.png";
import PayMethod4 from "../../assets/images/home/footer/discover.png";
import PayMethod5 from "../../assets/images/home/footer/jcb.png";
import Khamisani from "../../assets/images/home/footer/khamisani.svg";
import Iata from "../../assets/images/home/footer/iata.svg";

function MyAccFooter() {
  return (
    <>
    <section className='myAccFooter'>
        <div className="mainContainer">
          <Row>
            <Col>
              <div className="upperFooter">
                <img src={Khamisani} alt="khamisani-logo" className='img-fluid' />  
              </div>
              <Row className="bottomFooter">
                <Col md={4} className='payMethodWrap '>
                  <ul className='d-flex'>
                    <li className='mb-0'><img src={PayMethod1} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod2} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod3} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod4} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod5} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                  </ul>
                </Col>
                <Col md={4} className='text-center'>
                  <span className='allRightsTitle'>2024 Kwickfly. All Rights Reserved</span>
                </Col>
                <Col md={4} className='d-flex align-items-center justify-content-end'>
                  <span><img src={Iata} alt="pay-method-image" className='img-fluid' /></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default MyAccFooter
