import React from 'react'

function PaxDetails() {
  return (
    <>
      <div className="enter-travellers">
        <div className="title">Enter Travellers Details</div>
        <div className="inner-box">
          <div className="entertext">
            <img src="assets/images/flight-result/enter.svg" alt="" />
            Enter your details as they appear on your passport. Use English only.
          </div>

          <div className="inputadult-box">
            <div className="adulttitle d-flex">
              <span>Adult 1</span>
              <i><img src="assets/images/flight-result/group.svg" alt="" /></i>
            </div>

            <div className="inflexbox">

              <div className="inputboxs">
                <label>First Name*</label>
                <input type="text" placeholder="Enter First Name" />
              </div>

              <div className="inputboxs">
                <label>Last Name*</label>
                <input type="text" placeholder="Date of Birth*" />
              </div>

              <div className="inputboxs">
                <label>Date of Birth*</label>
                <div className="m-boxs">
                  <select>
                    <option>Day</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select className="borderline">
                    <option>Month</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select>
                    <option>Year</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
              </div>

            </div>

            <div className="othertext">Passport Information</div>


            <div className="inflexbox bordernone">

              <div className="inputboxs">
                <label>Passport Number*</label>
                <input type="text" placeholder=" " />
              </div>

              <div className="inputboxs">
                <label>Nationality*</label>
                <input type="text" placeholder="Select" />
              </div>

              <div className="inputboxs">
                <label>Issuing Country*</label>
                <select>
                  <option>Select</option>
                </select>
              </div>

              <div className="inputboxs">
                <label>Passport Issue Date*</label>
                <div className="m-boxs">
                  <select>
                    <option>Day</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select className="borderline">
                    <option>Month</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select>
                    <option>Year</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
              </div>

              <div className="inputboxs">
                <label>Passport Expiry Date*</label>
                <div className="m-boxs">
                  <select>
                    <option>Day</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select className="borderline">
                    <option>Month</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select>
                    <option>Year</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
              </div>

            </div>

            <div className="optinaltext">Optinal Preferences
              <img src="assets/images/flight-result/upicon.svg" alt="" />
            </div>

            <div className="inflexbox bordernone">

              <div className="inputboxs">
                <label>Seat preference</label>
                <select>
                  <option>Select</option>
                </select>
              </div>

              <div className="inputboxs">
                <label>Airline*</label>
                <select>
                  <option>Select</option>
                </select>
              </div>

              <div className="inputboxs">
                <label>Frequent flyer number</label>
                <input type="text" placeholder="" />
              </div>


            </div>


          </div>

          <div className="inputadult-box">
            <div className="adulttitle d-flex">
              <span>Infant</span>
              <i><img src="assets/images/flight-result/group.svg" alt="" /></i>
            </div>




          </div>

          <div className="inputadult-box">
            <div className="adulttitle d-flex">
              <span>Adult 2</span>
              <i><img src="assets/images/flight-result/group.svg" alt="" /></i>
            </div>


          </div>

        </div>
      </div>

    </>
  )
}

export default PaxDetails
