import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {
    token: '',
    uuid: ''
  }
}

export const UserSlice = createSlice({
  name: 'userslice',
  initialState,
  reducers: {
    setToken: (state, action) => {
      const tok = action.payload;
      state.value.token = tok;
    },
    setUuids: (state, action) => {
      const id = action.payload;
      state.value.uuid = id
    }
  }
})

export const { setToken, setUuids } = UserSlice.actions
export default UserSlice.reducer