import { useEffect, useState, useReducer } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../../../assets/Gifs/Double Ring-1s-200px.gif';
import Select from 'react-select';
import { handlePostApi } from "../../../../utilities/Axios";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';
import PriceDetails from "./PriceDetails";
import Registration from "../../../common/Registration";
let std_codes_json = require("../../../maasterdata/CountrySTDCodes.json");
var nationality_json = require("../../../maasterdata/NationalityData.json");
const HOTEL_COMMON_URL = process.env.REACT_APP_HOTEL_COMMON_URL;
const HOTEL_CONTENT_URL = process.env.REACT_APP_HOTEL_CONTENT_URL;


/**
 * @author: Praveen Varma
 * @param {*}
 * @description HotelReview with Guest details
 * @date : 20-05-2024
 */
const initialState = {
    rooms_detail: [], total_usr_price:0, ph_code: "", sel_ph_code: "", email:"", phone_number:"", ph_code:""
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function HotelReview(props) {
    const nav = useNavigate();
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const params = new URLSearchParams(location.search);
    const [loader, setLoader] = useState(false);
    const [hotel_details, setHotelDetails] = useState();
    const [loggedIn, setLoggedIn] = useState("");
    const [signInShow, setSignInShow] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);
    const [raw_rooms, setRawRooms] = useState([]);
    const [request_info, setRequestInfo] = useState({
        prebook: true
    })
    const [request, setRequest] = useState(
        location?.state !== null && location?.state?.resp !== null ? location?.state?.resp : null
    );
    const [total_no_of_rooms, setTotal_no_of_rooms] = useState(0);
    const [iti_id, setItiId] = useState("");
    const [nationalityList, setNationalityList] = useState([]);
    const [errors, setErrors] = useState([]);

    const adt_title_master = [
        { label: "Mr", value: "Mr." },
        { label: "Mrs.", value: "Mrs" },
        { label: "Miss", value: "Miss" }
    ];
    const child_title_master = [
        { label: "Master", value: "Master" },
        { label: "Miss", value: "Miss" }
    ];
    useEffect(() => {
        let listdata = [];
        nationality_json?.forEach(itm => {
            let itmdata = {
                label: itm.Country,
                value: itm.CountryId
            }
            listdata.push(itmdata);
        })

        setNationalityList(listdata);
    }, [])

    useEffect(() => {
        if (request) {
            let itinerary_id = request?.itinerary_id;
            setItiId(itinerary_id);
            sessionStorage.setItem("h_itinerary", itinerary_id)
            loadItineraryDetails(itinerary_id);
        }
    }, [])

    useEffect(() => {
        let loggedInfo = localStorage.getItem("logged");
        if (loggedInfo && loggedInfo !== null && loggedInfo !== "") {
            setLoggedIn(JSON.parse(loggedInfo));
        } else {
            setLoggedIn(null);
        }
    }, [localStorage?.getItem("logged")]);
  
    const handleLoggedInData = (data) => {
       setLoggedIn(data);
    }
  
    const handleSignInClose = () => {
      setSignInShow(false);
    }
    const handleSignInShow = () => {
      setSignInShow(true);
      setRegisterShow(false);    
    }
  
    const handleRegisterClose = () => {
      setRegisterShow(false);
    }
    const handleRegisterShow = () => {
      setRegisterShow(true);
      setSignInShow(false);
    }

    const handlePreBook = async () => {
        setLoader(false);
        let errors = await prebookValidations(state.rooms_detail);
        setErrors(errors);
        if(errors.length > 0){
            return errors
        }
        let bk_by="0";
        let login = localStorage.getItem("logged");
        if (login !== undefined && login !== null && login !== "") {
            login = JSON.parse(login);
            if(login !== undefined && login !== null && login.unique_id !== null){
                bk_by= login?.unique_id;
            }
        }
        let req_payload = {
            "kft_id": hotel_details?.kft_id,
            "sup": 1,
            "isallpax": true,
            "region_type":"Pakistan",
            "bk_by": bk_by || "0",
            "prof": {
                "tl": "",
                "fn": "",
                "ln": "",
                "phc": state?.ph_code,
                "phn": state?.phone_number,
                "em": "",
                "cem": "",
                "profid": bk_by !== null && bk_by !== "0" ? bk_by : undefined
            }
        }
        if (state.rooms_detail && state.rooms_detail.length > 0) {
            let roompax = [];
            for (let rm of state.rooms_detail) {
                rm?.pax?.forEach((pt) =>{
                    if(pt.lead){
                        pt.em = state?.email;
                        pt.phc = state?.ph_code;
                        pt.phn = state?.phone_number
                    }
                    if(pt?.tl !== undefined && pt?.tl !== null && pt?.tl !== "" && pt?.tl?.length > 0){
                        pt.tl = pt?.tl[0]?.value;
                    }
                    if(pt?.na !== undefined && pt?.na !== null && pt?.na !== "" && pt?.na?.length > 0){
                        pt.na = pt?.na[0]?.value;
                    }
                })
                let room = {
                    rmno: rm.rmno,
                    pax: rm.pax
                }
                roompax.push(room);
            }
            req_payload.roompax = roompax;
        }
        let response = await handlePostApi(`${HOTEL_COMMON_URL}/htl/common/prebook`, req_payload);
        if (response && response.data && response.data.suc) {
            setLoader(false);
            setRequestInfo({
                prebook: false
            })
            nav("/hotel/payment", {
                state: {
                    resp: response?.data?.res?.pbres,
                    req: request,
                    itinerary: hotel_details
                },
            });
        } else {
            setLoader(false);
            alert("Prebook failed");
        }
    }

    const prebookValidations = async(rooms) =>{
        let error_msg = [];
        try {
            if(rooms === undefined || rooms.length === 0){
                error_msg.push("Please seltct rooms");
                setErrors(error_msg);
                return false;
            }
            rooms?.forEach((room) =>{
                room?.pax?.forEach((px) =>{
                    if (px.tl === undefined || px.tl === null || px.tl === "") {
                        error_msg.push(`${room.rmno}_${px.ty}_tl_${px.sno_ui}`);
                    }
                    if (px.fn === undefined || px.fn === null || px.fn === "") {
                        error_msg.push(`${room.rmno}_${px.ty}_fn_${px.sno_ui}`);
                    }
                    if (px.ln === undefined || px.ln === null || px.ln === "") {
                        error_msg.push(`${room.rmno}_${px.ty}_ln_${px.sno_ui}`);
                    }
                });
            })
            if(state?.email === undefined || state?.email ==="" || state?.email === null){
                error_msg.push("email_id")
            }
            if(state?.ph_code === undefined || state?.ph_code ==="" || state?.ph_code === null){
                error_msg.push("phone_code")
            }
            if(state?.phone_number === undefined || state?.phone_number ==="" || state?.phone_number === null){
                error_msg.push("phone_number")
            }
            setErrors(error_msg);
        } catch (error) {
            error_msg.push("Catch error")
            setErrors(error_msg);
            return error_msg;
        }
        return error_msg;
    }

    const loadItineraryDetails = async (itinerary_id) => {
        setLoader(true)
        let response = await handlePostApi(`${HOTEL_CONTENT_URL}/htl/common/price/fetch`, {
            itinerary_id: itinerary_id
        });
        if (response?.data?.suc) {
            sessionStorage.setItem("h_kft_id", response?.data?.res?.kft_id)
            setHotelDetails(response?.data?.res);
            setTotal_no_of_rooms(response?.data?.res?.req?.rooms?.length);
            setRawRooms(response?.data?.res?.req?.rooms);
            create_rooms_detail_list(response?.data?.res?.req?.rooms, response?.data?.res?.req?.na);
        } else {
            setHotelDetails("");
            setRawRooms([]);
            dispatch({ type: "rooms_detail", payload: [] });
        }
        setLoader(false)
    }

    const create_rooms_detail_list = async (rooms, nationality) => {
        let rooms_array = [];
        if (rooms !== undefined && rooms !== null && rooms?.length > 0) {
            let na =[];
            if(nationality !== undefined && nationality !== null && nationality !== ""){
                let natioanlity_ary = nationality_json?.filter(itm => Number(itm?.CountryId) === Number(nationality));
                if(natioanlity_ary.length > 0){
                    let itm = {
                        label : natioanlity_ary[0].Country,
                        value: natioanlity_ary[0].CountryId
                    }
                    na= [itm];
                }
            }
            for (let room of rooms) {
                let ocp = room?.ocp?.toString().split("-");
                let childs = ocp.length > 1 && ocp[1] !== null && ocp[1] !== undefined ? ocp[1].split(",") : []
                let adt = Number(ocp[0]) || 0;
                let chd = childs || [];
                let pax_array = [];
                let pax_n = 1;
                let pax_adt_ui=1;
                let pax_chd_ui=1;
                for (var ad = 1; ad <= adt; ad++) {
                    let pax = {
                        sno_ui:pax_adt_ui,
                        sno: pax_n,
                        tl: adt_title_master?.filter(itm => itm.label === "Mr"),
                        ty: "ADT",
                        plbl: "Adult",
                        lead: ad == 1 ? true : false,
                        fn: "",
                        ln: "",
                        em: "",
                        phc: "",
                        phn: "",
                        na: na?.length > 0 ? na: []
                    }
                    pax_array.push(pax);
                    pax_n++;
                    pax_adt_ui++;
                }
                for (var ch = 1; ch <= chd.length; ch++) {
                    let pax = {
                        sno_ui:pax_chd_ui,
                        sno: pax_n,
                        tl: child_title_master?.filter(itm => itm.label === "Master"),
                        ty: "CHD",
                        plbl: "Child",
                        lead: false,
                        fn: "",
                        ln: "",
                        em: "",
                        phc: "",
                        phn: "",
                        na: na?.length > 0 ? na: []
                    }
                    pax_array.push(pax);
                    pax_n++;
                    pax_chd_ui++
                }
                let payload = {
                    rmno: room.rmno,
                    bed: room?.bed,
                    adt: adt,
                    chd: chd,
                    pax: pax_array
                };
                rooms_array.push(payload);
            }
        }
        dispatch({ type: "rooms_detail", payload: rooms_array });
    }

    const handleChange = async (e, rmno, pxno, lbl) => {
        let data = state.rooms_detail;
        for (let room of data) {
            if (room.rmno === rmno) {
                for (let pax of room.pax) {
                    if (pax.sno === pxno) {
                        pax.rmno = rmno;
                        if (lbl !== null && lbl === "na") {
                            pax["na"] = [e]
                        }else if (lbl !== null && lbl === "tl") {
                            pax["tl"] = [e]
                        } else {
                            pax[e.target.name] = e.target.value;
                        }
                    }
                }
            }
        }
        dispatch({ type: "rooms_detail", payload: data });
    }
    return (
        <>
            <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
                <img src={Loader} alt='' />
            </div>
            <div className="fixedHeader">
                <Header page="fixedHeader" />
            </div>
            <section className="statusBarWrap">
                <div className="mainContainer">
                    <Row>
                        <Col>
                            <div className="d-flex align-items-center">
                                <div className="statusBarItem">
                                    <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                                    <span>Review Hotel</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='atPresent smallCircle'>2</span>
                                    <span>Guest Details</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='pending smallCircle'>3</span>
                                    <span>Payment</span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='pending smallCircle'>4</span>
                                    <span>Confirmation</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <div className="reviewPage">
                <section>
                    <div className="mainContainer">
                        <Row>
                            <Col>
                                <div className="d-flex mt-20">
                                    <div className="reviewSideWrap">
                                        <div className="guestDetailsWrap">
                                            <div className="guestDetailTitle">
                                                Enter Guest Details
                                            </div>
                                            <div className="p-15">
                                            {!loggedIn && !loggedIn?.unique_id &&
                                                <div className="signInWrap">
                                                    <i class="icon icon-Login2"></i>
                                                    <span onClick={handleRegisterShow}>Sign Up</span>
                                                    &nbsp;now and speed up your booking experience. Already a member ?&nbsp;
                                                    <span onClick={handleSignInShow}>Sign In</span>
                                                </div>
                                            }
                                            <Registration showSignIn={signInShow} showRegister={registerShow} closeSignin={handleSignInClose} closeRegister={handleRegisterClose} sendLoggedInData={handleLoggedInData} />
                                                <div className="paxList">
                                                    <div className="paxDetailsWrapper">
                                                        {state?.rooms_detail && state?.rooms_detail?.length > 0 && state.rooms_detail.map((room) => {
                                                            return (
                                                                <>
                                                                    <Accordion defaultActiveKey="0" className='enterDetailCollapse'>
                                                                        <Accordion.Item eventKey="0">
                                                                            <Accordion.Header><span>Room {room?.rmno} : {room?.bed}</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i></Accordion.Header>
                                                                            {room.pax && room.pax.length > 0 &&
                                                                                room.pax.map((pax, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Accordion.Body>
                                                                                                <div className="noOfAdult">{pax?.plbl} {pax?.sno_ui}</div>
                                                                                                <div className="enterDetailWrap">
                                                                                                    <div>
                                                                                                        <Form.Label>Title<span>*</span></Form.Label>
                                                                                                        <InputGroup className="">
                                                                                                            <Select
                                                                                                                value={pax?.tl}
                                                                                                                options={pax?.ty && pax?.ty === "CHD" ? child_title_master : adt_title_master}
                                                                                                                name="tl"
                                                                                                                onChange={(e) => handleChange(e, room.rmno, pax.sno, "tl")}
                                                                                                            >
                                                                                                            </Select>
                                                                                                            <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name'
                                                                                                                className={errors?.includes(`${room.rmno}_${pax.ty}_fn_${pax.sno_ui}`) ? "redBorder" : "" } name="fn" value={pax.fn} onChange={(e) => handleChange(e, room.rmno, pax.sno)}
                                                                                                            />
                                                                                                            {errors?.length > 0 && errors?.includes(`${room.rmno}_${pax.ty}_fn_${pax.sno_ui}`) && <div className="error">Please select first name</div>}
                                                                                                        </InputGroup>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Form.Label>Last Name<span>*</span></Form.Label>
                                                                                                        <Form.Control aria-label="Text input with dropdown button" placeholder='Enter Last Name'
                                                                                                            className={errors?.includes(`${room.rmno}_${pax.ty}_ln_${pax.sno_ui}`) ? "redBorder" : "" } name="ln" value={pax.ln} onChange={(e) => handleChange(e, room.rmno, pax.sno)}
                                                                                                        />
                                                                                                        {errors?.length > 0 && errors?.includes(`${room.rmno}_${pax.ty}_ln_${pax.sno_ui}`) && <div className="error">Please select last name</div>}
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Form.Label>Nationality</Form.Label>
                                                                                                        <Select
                                                                                                            id="na"
                                                                                                            name="na"
                                                                                                            value={pax?.na}
                                                                                                            options={nationalityList}
                                                                                                            onChange={(e) => handleChange(e, room.rmno, pax.sno, "na")}
                                                                                                        >

                                                                                                        </Select>

                                                                                                    </div>
                                                                                                </div>
                                                                                                {/*  <div key={`inline-checkbox`} className="inlineCheckboxWrap guestCheckbox">
                                                                                                    <Form.Check
                                                                                                        inline
                                                                                                        label="Enter Additional Guest Details"
                                                                                                        name="group1"
                                                                                                        type="checkbox"
                                                                                                        id={`inline-checkbox-1`}
                                                                                                        checked
                                                                                                    />
                                                                                                </div> */}
                                                                                                {/*  <div className="specialRequestWrap">
                                                                                                    <div className="specialRequestTitle">Special Request -</div>
                                                                                                    <div className="specialRequestBox">
                                                                                                        <div key={`inline-checkbox`} className="inlineCheckboxWrap specialCheckbox">
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Separate Beds"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Non Smoking Room"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div key={`inline-checkbox`} className="inlineCheckboxWrap specialCheckbox">
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Smoking Room"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Late Check-Out"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div key={`inline-checkbox`} className="inlineCheckboxWrap specialCheckbox">
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Interconnecting Rooms"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                            <Form.Check
                                                                                                                inline
                                                                                                                label="Swimming Pool"
                                                                                                                name="group1"
                                                                                                                type="checkbox"
                                                                                                                id={`inline-checkbox-1`}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="addSpeclReq">
                                                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                                                        <Form.Control as="textarea" rows={3} placeholder="Please enter any additional special request that you may have. (eg: Late Check-In, VIP Pax)" />
                                                                                                    </Form.Group>
                                                                                                </div>
                                                                                                <div className="noteWrap">
                                                                                                    <span>Note: </span>Special requests are not guaranteed and are at the hotel's discretion. Additional charges may apply depending on the hotel's policy.
                                                                                                </div> */}
                                                                                            </Accordion.Body>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </Accordion.Item>
                                                                    </Accordion>
                                                                </>
                                                            )
                                                        })}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contactWrap">
                                            <div className="contactTitle">Contact Details</div>
                                            <div className="p-15">
                                                <>
                                                    <div className="contactDetailsWrap">
                                                        <div>
                                                            <Form>
                                                                <Form.Group className="">
                                                                    <Form.Label>Email<span>*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        placeholder="Enter email id"
                                                                        name="email"
                                                                        value={state?.email}
                                                                        className={errors?.includes("email_id") ? "redBorder" : "" }
                                                                        onChange={(e) => {
                                                                            dispatch({ type: "email", payload: e?.target?.value })
                                                                        }}
                                                                    />
                                                                    {errors?.length > 0 && errors?.includes("email_id") && <div className="error">Please enter email id</div>}
                                                                </Form.Group>
                                                            </Form>
                                                        </div>
                                                        <div>
                                                            <Form.Label>Mobile Number<span>*</span></Form.Label>
                                                            <InputGroup className="">
                                                                <Select
                                                                    options={std_codes_json}
                                                                    value={state.sel_ph_code}
                                                                    onChange={(data) => {
                                                                        dispatch({ type: "ph_code", payload: data?.value })
                                                                        dispatch({ type: "sel_ph_code", payload: data })
                                                                    }}
                                                                    placeholder="Select country"
                                                                    className={errors?.includes("ph_code") ? " countrySelect redBorder" : "countrySelect" }
                                                                />
                                                                {errors?.length > 0 && errors?.includes("phone_code") && <div className="error">Please enter country code</div>}
                                                                <Form.Control
                                                                    aria-label="Text input with dropdown button"
                                                                    name="phone_number"
                                                                    max={15}
                                                                    value={state?.phone_number}
                                                                    placeholder='Enter mobile number'
                                                                    className={errors?.includes("phone_number") ? "redBorder" : "" }
                                                                    onChange={(e) =>{
                                                                        dispatch({ type: "phone_number", payload: e?.target?.value })
                                                                    }}
                                                                />
                                                                 {errors?.length > 0 && errors?.includes("phone_number") && <div className="error">Please enter mobile number</div>}
                                                            </InputGroup>
                                                        </div>
                                                        {/* <div><img src={GetoNWhatshapp} alt="" /> Get your E-ticket on WhatsApp <i class="icon icon-tick"></i></div> */}
                                                    </div>
                                                    <div className='contactNote'>*E-ticket will be sent to this email address and phone number.</div>
                                                </>
                                            </div>
                                        </div>
                                        <div className="contonuePaymentBtnWrap">
                                            <Button variant="primary contonuePaymentBtn"  onClick={handlePreBook} >Continue Payment <i class="icon icon-down-arrow"></i></Button>
                                        </div>
                                    </div>
                                    {hotel_details !== undefined && hotel_details !== null && hotel_details !== "" &&
                                        <PriceDetails itinerary={hotel_details} total_usr_price={state?.total_usr_price}/>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
            <Footer />
            {/* {hotel_details && hotel_details.req &&
                <GustDetails rooms={hotel_details?.req} room_detail={travDetails} />
            } */}
            {request_info && request_info?.prebook &&
                <button className="modify-search" onClick={handlePreBook}>Pre book<img src="assets/images/flight-result/edit1.svg"
                    alt="" /></button>
            }
        </>
    )
}

export default HotelReview