import React, { useEffect, useState } from 'react';
import HotelResultDetails from './HotelResultDetails'
import { useLocation, useNavigate } from "react-router-dom";
import { handleGetApi } from '../../../../utilities/Axios'
import { isArrayNotEmpty, isNotNull } from '../../../utils/validators';
import Loader from '../../../../assets/Gifs/Double Ring-1s-200px.gif';
import HotelSearch from '../../../homepage/ChildComp/HotelSearch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button, Modal } from 'react-bootstrap';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import moment from "moment";
import HotelResultLoader from '../result/HotelResultLoader'
const HOTEL_COMMON_URL=process.env.REACT_APP_HOTEL_COMMON_URL;
const FRONTEND_URL=process.env.REACT_APP_FRONTEND_URL
var nationality_json = require("../../../maasterdata/NationalityData.json");

/**
 * @description:Rooms Results info
 * @author: Praveen Varma
 * @param {*}
 * @function Room Price details
 * @date : 15-05-2024
 */
function HotelResult(props) {
  const [showModify, setShowModify] = useState(false);
  const location = useLocation();
  const nav = useNavigate();

  const params = new URLSearchParams(location.search);
  const [hotelResults, setHotelResults] = useState({});
  const [loader, setLoader] = useState(false)
  const [searchCriteria, setSearchCriteria] = useState("");
  const [modify_details, setModifyDetails] = useState({});
  const [selectedCity, setSelCity] = useState("");

  useEffect(() => {
    setLoader(true);
    let payload={};
    for (const [key, value] of params.entries()) {
      payload[key] = value;
    }
    displayCriteriaDetails(payload);
    if (isNotNull(payload?.cin) && isNotNull(payload?.cout) && isNotNull(payload?.rms) && isNotNull(payload?.adt)
      && isNotNull(payload?.rty)) {
      let param_val = `cin=${payload.cin}&cout=${payload.cout}&na=${payload.na}&rms=${payload.rms}&adt=${payload.adt}&chdages=${payload.chdages}&rty=${payload.rty}&otctid=${payload.otctid}`
      if(payload.tophcode){
        param_val=`${param_val}&tophcode=${payload.tophcode}`
      }
      setSearchCriteria(payload);
      loadHotels(param_val);
    } else {
      setLoader(false);
      alert("Invalid request");
    }
  }, []);

  const displayCriteriaDetails = async (crit) => {
    try {
      getModifySearchCity(crit?.otctid, crit?.othcode || crit?.tophcode);
      let details = {
        cin: moment(crit?.cin, "DD-MM-YYYY").format("ddd, DD MMM YYYY"),
        cout: moment(crit?.cout, "DD-MM-YYYY").format("ddd, DD MMM YYYY"),
        rms: crit.rms,
        adt: crit?.adt,
        chd: crit?.chdages
      }
      if (crit?.na) {
        let na = nationality_json?.filter((itm) => itm.CountryId == Number(crit?.na));
        if (na && na.length > 0) {
          details.nationlaity = na[0].Country
        }
      }
      let s_dateString = crit.cin;
      let [sday, smonth, syear] = s_dateString.split('-')
      let date = new Date(+syear, +smonth - 1, +sday);

      let t_dateString = crit.cout;
      let [tday, tmonth, tyear] = t_dateString.split('-')
      let end_date = new Date(+tyear, +tmonth - 1, +tday);

      if (end_date !== undefined && date !== undefined && end_date !== "") {
        var diff_In_Time = end_date.getTime() - date.getTime();
        var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
        details.nights = diff_In_Days;
      }
      let adults_info = crit?.adt?.split(",") || [];
      let adt_count = 0;
      adults_info?.forEach((itm) => {
        adt_count = itm ? adt_count + Number(itm) : adt_count;
      })
      let chd_info = crit?.chdages?.split("|");
      let chd_count = 0;
      chd_info?.forEach(itm => {
        let aa = itm ? itm.split(",") : [];
        aa?.forEach((b) => {
          chd_count = b && Number(b) > 0 ? chd_count + 1 : chd_count;
        })
      })
      let total_guests = adt_count + chd_count;
      details.guest_count =total_guests;
      setModifyDetails(details);
    } catch (error) {
      console.log("ERROR :::: displayCriteriaDetails in hotel results page", error)
    }
  }


  /**
   * Fetch hotels for result page
   * @param {*} param_val 
   */
  const loadHotels = async (param_val) => {
    setLoader(true);
    let response = await handleGetApi(`${HOTEL_COMMON_URL}/htl/common/check?${param_val}`);
    setHotelResults(response?.data?.res);
    setLoader(false);
  }


  const fetchRooms = async(itm) =>{
    let params = searchCriteria
    params.rty = "property";
    params.othcode=itm.pid
    setSearchCriteria(params);
    let req_url = `cin=${params.cin}&cout=${params.cout}&rms=${params.rms}&na=${params.na}&adt=${params.adt}&chdages=${params.chdages}&rty=${params.rty}&otctid=${params.otctid}&othcode=${params.othcode}`;
    window.open(`${FRONTEND_URL}/hotel/detail?${req_url}`,'_blank')
  }

  const hotelShowModify = () => {
    setShowModify(true);
  }
  const hotelHideModify = () => {
    setShowModify(false);
  }
  const getModifySearchCity = async (city ,hotel) => {
    try {
      setSelCity("");
      if ((city !== undefined && city !== null && city !== "") || (hotel !== undefined && hotel !== null && hotel !== "")) {
        handleGetApi(`${process.env.REACT_APP_HOTEL_CONTENT_URL}/htl/elastic/modify/search?otCityId=${city}&otHtlId=${hotel}&skip=0&limit=10`).then((response) => {
          if (response?.data?.suc && isArrayNotEmpty(response?.data?.res)) {
            let obj = response?.data?.res[0];
            setSelCity(obj?.description);
          }
        });
      }
    } catch (error) {
      console.log("ERROR ::: getModifySearchCity", error);
    }
  }

  return (
    <>
      <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
        <HotelResultLoader />
      </div>
      <div className="fixedHeader">
        <Header page="fixedHeader" flow="RESULT" />
      </div>
      <div className="resultPage">
      <section className='modifySearchWrap'>
      <div className="mainContainer">
        <Row>
          <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="guestDetailModify">
              {selectedCity &&
                <><div>
                  <span>{selectedCity}</span>,
                </div>
                </>
              }
             <div>
                <span>{modify_details?.cin}</span>
                <span> - </span>
                <span>{modify_details?.cout}</span>
                <span className='separatorLine'>Nights : {modify_details?.nights}</span>
                <span>Rooms : {modify_details?.rms}</span>
                <span className='leftSeparatorLine'>Guests : {modify_details?.guest_count}</span>
             </div>
            </div>
            <div className="modifyBtn">
              <Button variant="outline-primary" onClick={hotelShowModify}>Modify Search <i class="icon icon-Edit"></i> </Button>
            </div>
          </div>
          </Col>
        </Row>
      </div>
      </section>
      <Modal show={showModify} className='modifyWrap'>
        <Modal.Body>
          <div className="mainContainer">
          <div className="d-flex justify-content-between align-items-center">
            <div className="guestDetailModify">
            {selectedCity &&
                <><div>
                  <span>{selectedCity}</span>,
                </div>
                </>
              }
              <div>
                <span>{modify_details?.cin}</span>
                <span> - </span>
                <span>{modify_details?.cout}</span>
                <span className='separatorLine'>Nights : {modify_details?.nights}</span>
                <span>Rooms : {modify_details?.rms}</span>
                <span className='leftSeparatorLine'>Guests : {modify_details?.guest_count}</span>
             </div>
            </div>
            <div className="modifyBtn">
              <Button variant="outline-primary">Modify Search <i class="icon icon-Cross" onClick={hotelHideModify}></i> </Button>
            </div>
          </div>
            {searchCriteria &&
              <HotelSearch modify={true} mod_criteria={searchCriteria}/> 
            }
          </div>
        </Modal.Body>
      </Modal>
     
      {hotelResults && hotelResults.prpts && hotelResults.prpts.length > 0 &&
        <HotelResultDetails hotels={hotelResults} fetchRooms={fetchRooms} guests={modify_details}/>
      }
      </div>
      <Footer />
    </>
  )
}

export default HotelResult
