import React from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

/**
 * @description: This function will display popup after timeout
 * @author: Ubedullah
 * @param {*}
 * @function Popup
 * @date : 22-07-2024
 */

function SessionTimeOut() {
    const nav = useNavigate();
    const backToHome = () => {
        nav("/");
    }
    return (
        <div>
            <Modal
                show={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='flightDetailsPopup'
            >
                <Modal.Header className='justify-content-center'>
                    <Modal.Title id="contained-modal-title-vcenter" className="flightDetailTitle">
                        Session has Expired!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center p-3'>
                    <Button variant='primary' onClick={backToHome}>Back to Home</Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SessionTimeOut
