/**
 * below call belong to check null
 * @author: Ubedullah  
 * Date:13-05-2024
 */
const isNotNull = function (data) {
    if (data !== null
      && data !== undefined
      && data !== 'null'
      && data !== 'undefined'
      && data !== '') {
      return true;
    } else {
      return false;
    }
  }
  
  /**
   *this belongs to check success response
 * @author: Ubedullah  
 * Date:13-05-2024
   */
  const isSuccessResponse = function (status) {
    if (isNotNull(status) && status === 200) {
      return true;
    } else {
      return false;
    }
  
  }
  
  /**
   * this to check array is empty or not
 * @author: Ubedullah  
 * Date:13-05-2024
   */
  const isArrayNotEmpty = function (arr) {
    if (isNotNull(arr) && arr.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const isArrayEmpty = function (arr) {
    return !isArrayNotEmpty(arr);
  }
  
  module.exports = {
    isNotNull: isNotNull,
    isSuccessResponse: isSuccessResponse,
    isArrayNotEmpty: isArrayNotEmpty,
    isArrayEmpty: isArrayEmpty
  };