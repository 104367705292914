/**
 * @ Author: Aman Goyal
 * @ Description: Sort By Filters
 */

import React, { useState, useEffect, useContext } from 'react'
import { setDirectFlight, setShowFilter, setSortByFilter, setSortList } from '../../../../../toolkit/Slices/FlightFilter'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Context } from '../../../../../App';

function SortBy(props) {
  const dispatch = useDispatch();
  const slicedata = useSelector((state) => state.flightfilter.value);
  const [context, setContext] = useContext(Context);
  const [width, setWidth] = useState(false);
  // const handleShowFilter = () => {
  //   dispatch(setShowFilter(!slicedata.showFilters))
  //     if(document.querySelector(".filterSideWrap").style.width === "25%"){
  //       document.querySelector(".resulSideWrap").style.width = "100%";
  //       document.querySelector(".filterSideWrap").style.marginRight = "0px";
  //       document.querySelector(".filterSideWrap").style.width = "0px";
  //       document.querySelector(".filterSideWrap").style.left = "-100%";
  //       document.querySelector(".filterSideWrap").style.height = "0px";
  //     props.sendWidth("full");
  //     setWidth(false);
  //     }
  //     else{
  //       document.querySelector(".resulSideWrap").style.width = "75%";
  //       document.querySelector(".filterSideWrap").style.marginRight = "20px";
  //       document.querySelector(".filterSideWrap").style.width = "25%";
  //       document.querySelector(".filterSideWrap").style.left = "0";
  //       document.querySelector(".filterSideWrap").style.height = "100%";
  //     props.sendWidth("short");
  //     setWidth(true);
  //     }
  // }
  const sortData = props?.data;
  const currency = props.currency;
  const [showShortList, setShowShortList] = useState(false)
  const [open, setOpen] = useState(false);
  const [selSorting, setSelSorting] = useState([]); 
  const [active, setActive] = useState("Cheap");
  const [cur, setUserCur] = useState("");

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])
  
  const sortingDropdown = [
    {value:"Price - Low To High",label:"Price - Low To High"},
    {value:"Price - High To Low",label:"Price - High To Low"},
    {value:"Depart - Duration Shortest",label:"Depart - Duration Shortest"},
    {value:"Return - Duration Shortest",label:"Return - Duration Shortest"},
    // {value:"Depart - Time Early",label:"Depart - Time Early"},
    // {value:"Return - Time Early",label:"Return - Time Early"},
    // {value:"Depart - Time Late",label:"Depart - Time Late"},
    // {value:"Return - Time Late",label:"Return - Time Late"},
  ];

  const handlleSortList = () => {
    setShowShortList(!showShortList);
  }

  const handleSortbyPrice = (value) => {
    setSelSorting(value);
    dispatch(setSortList(value.value))
  }

  return (
    <>
    <section>
    <div className="mainContainer">
      <Row>
        <Col>
            <div className="sortingFilterWrap d-flex justify-content-between align-items-center">
              <div className="sortingFilterBoxOfGetfare d-flex align-items-center" onClick={() => setOpen(!open)}>
                <i class="icon icon-Multi-flight"></i>
                <div className='sortingFilterTitle'>Get Fare Summary <i class="icon icon-down-arrow"></i></div>
              </div>
              <div className="d-flex justify-content-between align-items-center activeEffect">
              <div className={active === "Cheap" ? "sortingFilterBox active" : "sortingFilterBox"} onClick={(e) => {
                    { dispatch(setSortByFilter('Cheapest')) }; dispatch(setSortList('Select')); setActive("Cheap")
                  }}>
                <i class="icon icon-Dollar"></i>
                <div>
                  <div className='sortingFilterTitle'>Cheapest</div>
                  <div className='sortingFilterValue'>{cur ? cur : currency} {(Number(sortData[0]?.price) * (props?.base_usr_exrate ? props?.base_usr_exrate : 1)).toFixed(2)}</div>
                </div>
              </div>
              <div className={active === "Fast" ? "sortingFilterBox ml-15 active" : "sortingFilterBox ml-15"} onClick={(e) => {
                    { dispatch(setSortByFilter('Fastest')) }; dispatch(setSortList('Select')); setActive("Fast")
                  }}>
                <i class="icon icon-Fastest"></i>
                <div>
                  <div className='sortingFilterTitle'>Fastest</div>
                  <div className='sortingFilterValue'>{cur ? cur : currency} {(Number(sortData[1]?.price) * (props?.base_usr_exrate ? props?.base_usr_exrate : 1)).toFixed(2)}</div>
                </div>
              </div>
              <div className={active === "Best" ? "sortingFilterBox ml-15 active" : "sortingFilterBox ml-15"} onClick={(e) => {
                    { dispatch(setSortByFilter('Bestest')) }; dispatch(setSortList('Select')); setActive("Best")
                  }}>
                <i class="icon icon-Best-Star"></i>
                <div>
                  <div className='sortingFilterTitle'>Best Value</div>
                  <div className='sortingFilterValue'>{cur ? cur : currency} {(Number(sortData[2]?.price) * (props?.base_usr_exrate ? props?.base_usr_exrate : 1)).toFixed(2)}</div>
                </div>
                {/* <div className="sortingFilterBox ml-15">
                  <i class="icon icon-Best-Star"></i>
                  <div>
                    <div className='sortingFilterTitle' onClick={(e) => {
                      { dispatch(setSortByFilter('Bestest')) }; dispatch(setSortList('Select'))
                    }}>Best Value</div>
                    <div className='sortingFilterValue'>{currency} {Number(sortData[2]?.price).toFixed(2)}</div>
                  </div>
                </div> */}
                </div>
                {/* <div className="sortingFilterBox sortBy">
                  <i class="icon icon-flip-arrow"></i>
                  <div>
                    <div className='sortingFilterTitle'>Sort By</div>
                    <div className='sortingFilterValue'>Price - Low To High</div>
                  </div>
                  <span><i class="icon icon-down-arrow"></i></span>
                </div> */}

                
          

              </div>
              <div className="sortingFilterBox sortBy">
              <i class="icon icon-Sort"></i>
              <div className="inbox arrowtoggle">
                  {/* <strong>Sort By</strong> */}
                  {/* <span>{slicedata?.sortlist}</span> */}
                  <Select 
                    options={sortingDropdown}
                    values={selSorting} 
                    onChange={(value) => handleSortbyPrice(value)} 
                    placeholder="Sort By"
                    className='sortBySelect'
                  >                  
                  </Select>
              </div>

              {/* <div className="sortlisting" style={{ display: showShortList ? 'block' : 'none' }}>
                <ul className="scrollbar" >
                  <li onClick={() => { { handlleSortList() }; dispatch(setSortList("Select")) }}>Select</li>
                  <li onClick={() => { { dispatch(setSortList("Price - Low To High")) }; handlleSortList() }}>Price - Low To High</li>
                  <li onClick={() => { { dispatch(setSortList("Price - High To Low")) }; handlleSortList() }}>Price - High To Low</li>
                  <li onClick={() => { { dispatch(setSortList("Depart - Duration Shortest")) }; handlleSortList() }}>Depart - Duration Shortest</li>
                  <li onClick={() => { { dispatch(setSortList("Return - Duration Shortest")) }; handlleSortList() }}>Return - Duration Shortest</li>
                  <li onClick={() => { { handlleSortList() }; }}>Depart - Time Early</li>
                  <li onClick={() => { { handlleSortList() }; }}>Return - Time Early</li>
                  <li onClick={() => { { handlleSortList() }; }}>Depart - Time Late</li>
                  <li onClick={() => { { handlleSortList() }; }}>Return - Duration Shortest</li>
                  <li onClick={() => { { handlleSortList() }; }}>Depart - Time Early</li>
                  <li onClick={() => { { handlleSortList() }; }}>Return - Time Early</li>
                  <li onClick={() => { { handlleSortList() }; }}>Depart - Time Late</li>
                </ul>
              </div> */}
            </div>


              </div>
          </Col>
        </Row>
      </div>
    </section>

    <section>
      <div className="mainContainer">
        <div className="advanceFilterWrap">
          <div className="leftSide"><span><i class="icon icon-Filter"></i> Advanced Filters <i class="icon icon-down-arrow"></i></span></div>
          <div className={width ? "rightSideactive" : 'rightSide'}>
            <div className='d-flex'>
              <div className='switchBoxWrap'>
              <span><i class="icon icon-flight"></i> Non Stop Flights</span>
              <label class="switch">
                  <input type="checkbox" onChange={(e) => { dispatch(setDirectFlight(e.target.checked)) }} />
                  <span class="slider round"></span>
              </label>
              </div>
              <div className='switchBoxWrap ml-30'>
              <span><i class="icon icon-Refundable"></i> Refundable</span>
              <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
              </label>
              </div>
            </div>
            <div>
              <div className='switchBoxWrap'>
                <span><i class="icon icon-Time"></i> Change Time Format AM/PM</span>
                <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SortBy
