/**
 * @ Author: Aman Goyal
 * @ Description: Flight Result
 */

import React, { useState } from 'react'
import Footer from '../../../common/Footer'
import FlightResultDetails from './FlightResultDetails'
import Header from '../../../common/Header'
import "../../../../assets/css/home.css";
function FlightResult() {
  const [active, setActive] = useState(false);

  const makeHeaderActive = (value) => {
    setActive(value);
  }

  return (
    <>
    <div className="fixedHeader">
      <Header page="fixedHeader" flow="RESULT" modifyHeader={active} />
    </div>
      <FlightResultDetails activeHeader={makeHeaderActive} />
      <Footer />
    </>
  )
}

export default FlightResult
