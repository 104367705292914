/**
 * @ Author: Aman Goyal
 * @ Description: Flight Filters
 */

import React, { useEffect, useState, useReducer, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputRange from 'react-input-range';
import { Context } from '../../../../../App';
import 'react-input-range/lib/css/index.css';
import AirlineLogo from "../../../../../assets/images/result/QR.png";
import NonStop from "../../../../../assets/images/result/nonStop.png";
import OneStop from "../../../../../assets/images/result/oneStop.png";
import MorethanOneStop from "../../../../../assets/images/result/morethanOneStops.png";
import { setAirlineFilter, setOnboardTime, setSearchFlight, setStopsFilter, setPriceFilter } from '../../../../../toolkit/Slices/FlightFilter';
// import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { handleTimeChange } from '../../../../common/services/DateValidations';

// Initial state
const initialstate = {
  price: { min: 0, max: 0 },
  priceFilterChanged: "",
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "min":
      return { ...state, price: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function FlightFilters(props) {
  const dispatch = useDispatch();
  const [context, setContext] = useContext(Context);
  const filterData = props.data;
  const currency = props.currency;
  const from = filterData?.destination_filters[0]?.from;
  const too = filterData?.destination_filters[0]?.to;
  const slicedata = useSelector((state) => state.flightfilter.value);
  // console.log("slicedata", slicedata)
  const [newCode, setNewCode] = useState('');
  const [priceFilterChanged, setPriceFilterChanged] = useState("")
  const [price, setPrice] = useState({ min: 0, max: 20});
  const [cur, setUserCur] = useState("");

  useEffect(() => {
    if(sessionStorage.getItem("SEL_CUR") !== null) {
      setUserCur(sessionStorage.getItem("SEL_CUR"));
      setContext({...context, userCur: sessionStorage.getItem("SEL_CUR")});
    } else {
      setUserCur(context.userCur);
      sessionStorage.setItem("SEL_CUR", context.userCur)
    }
  }, [sessionStorage.getItem("SEL_CUR") || context.userCur])

  // handleing flight codes...
  const handleFligtsCode = (code, value) => {
    if (value === true) {
      const updatedCode = slicedata.airlineFilter + ',' + code;
      setNewCode(updatedCode);
    } else {
      const codesArray = newCode.split(',');
      const indexToRemove = codesArray.indexOf(code);

      if (indexToRemove !== -1) {
        codesArray.splice(indexToRemove, 1);
        const updatedCode = codesArray.join(',');
        setNewCode(updatedCode);
      }
    }
  }
  // handles flight way (direct, one stop...., etc...)
  useEffect(() => { setNewCode(slicedata.airlineFilter) }, [slicedata])

  const handleFlightStops = (indexes, type, value) => {
    if (slicedata.stops.length == 0) {
      const filterDatas = [];
      let directFlight = false;
      let oneStopFlight = false;
      let morethanOneStopFlight = false;
      // let departure = "00:00;23:59";
      // let arrival = "00:00;23:59";
      // let journeyDuration = "1;9999999";

      filterData?.destination_filters?.forEach((data, i) => {

        if (i == indexes) {
          if (type === 1) {
            directFlight = value;
          }
          else if (type === 2) {
            oneStopFlight = value;
          }
          else if (type === 3) {
            morethanOneStopFlight = value;
          }
        }
        let fil = {
          index: i,
          direct: directFlight,
          onestop: oneStopFlight,
          morethanonestop: morethanOneStopFlight,
          // departure: departure,
          // arrival: arrival,
          // journeyDuration: journeyDuration
        }

        filterDatas.push(fil);
      });
      dispatch(setStopsFilter(filterDatas))
    } else {
      const filterDatas = [];
      slicedata.stops?.forEach((data, i) => {
        let directFlight = data.direct;
        let oneStopFlight = data.onestop;
        let morethanOneStopFlight = data.moreThanOneStop;
        // let departure = "00:00;23:59";
        // let arrival = "00:00;23:59";
        // let journeyDuration = "1;9999999";

        if (i == indexes) {
          if (type === 1) {
            directFlight = !directFlight;
          }
          else if (type === 2) {
            oneStopFlight = value;
          }
          else if (type === 3) {
            morethanOneStopFlight = value;
          }
        }
        let fil = {
          index: i,
          direct: directFlight,
          onestop: oneStopFlight,
          morethanonestop: morethanOneStopFlight,
          // departure: departure,
          // arrival: arrival,
          // journeyDuration: journeyDuration
        }

        filterDatas.push(fil);
      });
      dispatch(setStopsFilter(filterDatas))
    }
  }
  useEffect(() => {
    dispatch(setAirlineFilter(newCode))
  }, [newCode])

  const handlePriceFilter = () => {
    dispatch(setPriceFilter());
  }

  // On Board Timings....
  const [departureShow, setDepartureShow] = useState(true)
  const [returnShow, setReturnShow] = useState(false)
  const handleOnBoardTimings = (value) => {
    if (value === 'dep') {
      setDepartureShow(true);
      setReturnShow(false);
    } else if (value === 'ret') {
      setDepartureShow(false);
      setReturnShow(true);
    }
  }

  const handleOnboard = (text, index, value) => {
    console.log(text, index, value)
    dispatch(setOnboardTime({ text, index, value }))
  }
  // clear All filters...
  const handleClearAll = () => {
    // window.location.reload(false)
    props.clearFilter(props.clear);
  }
  // clear stops filter...
  const handleStops = () => {
    const filterDatas = [];
    let directFlight = false;
    let oneStopFlight = false;
    let morethanOneStopFlight = false;
    filterData?.destination_filters?.forEach((data, i) => {
      let fil = {
        index: i,
        direct: directFlight,
        onestop: oneStopFlight,
        morethanonestop: morethanOneStopFlight
      }
      filterDatas.push(fil);
    });
    dispatch(setStopsFilter(filterDatas))
  }
  // clear Airline filter....
  const handleAirlineFilter = () => {
    setNewCode('');
  }
  // searchFlight filter
  const handleSearchFlight = () => {
    dispatch(setSearchFlight(''))
  }
  useEffect(() => { console.log(slicedata) }, [slicedata])

  // handl trip duratons.,....
  // const handleTripDuration = (e, indexx) => {
  //   dispatch(setTripMin({ value: e.minValue, indexx }));
  //   dispatch(setTripMax({ value: e.maxValue, indexx }))
  // }
  

  // Airlines logo...
  const handleLogo = (data) => {
    let logo;
    try {
      logo = require(`../../../../../assets/airlines-logo/${data}.png`);
    } catch (error) {
      logo = null;
    }
    return logo
  }

  const filterApplyPrice = (name, e) => {
    // dispatch({ type: "price", payload: e})
    setPrice(e)
  }

  const filterApply = (name, e) => {
    if (name == "price") {
      // dispatch({ type: "price", payload: e });
      setPrice(e)
      // state.priceFilterChanged = e;
      setPriceFilterChanged(e);
    }

    //once price filter departed or changed filter across the flow
    // if (
    //   (priceFilterChanged &&
    //     priceFilterChanged?.min &&
    //     priceFilterChanged?.max) ||
    //   (price?.min && price?.max)
    // )
    if (
      (e &&
        e?.min &&
        e?.max) ||
      (price?.min && price?.max)
    ) {
      let priceRange = {};
      // if (priceFilterChanged?.min && priceFilterChanged?.max) {
      //   priceRange = priceFilterChanged;
      // } else {
      //   priceRange = price;
      // }
      if (e?.min && e?.max) {
        priceRange = e;
      } else {
        priceRange = price;
      }
      let res = []
      Promise.all(
        res = props.totalData?.filter((flights) => {
          let prc = flights?.price?.total_price;
          // if (flights.pr.tax) {
          //   price =
          //     Number(flights.pr.tf)
          // }
          return (
            Number(prc) >= Number(priceRange.min) &&
            Number(prc) <= Number(priceRange.max)
          );
        })
      );
      props.sendPriceFilterData(res);
    }
  }

  return (
    <>
    
      {/* <aside className="flight-fliters" style={{ display: slicedata.showFilters ? 'block' : 'none' }}> */}
      <div className="filterSideWrap">
        <div className="appliedFilter">
          <div className="d-flex justify-content-between">
            <span className='appliedFilterTitle'>Applied Filters</span>
            <span className='appliedFilterClear' onClick={handleClearAll}>Clear All</span>
          </div>
          <div className="d-flex flex-wrap columnGap">
            <div style={{ display: slicedata?.airlineFilter !== '' ? "block" : 'none' }} className="appliedFilterResult">Airline <i class="icon icon-Close"></i></div>
            <div className="appliedFilterResult" style={{ display: slicedata?.searchFlight !== '' ? "block" : 'none' }}> Search Flight <i class="icon icon-Close"></i></div>
            <div className="appliedFilterResult" style={{ display: slicedata?.sortBy !== '' ? "block" : 'none' }}> Sort By <i class="icon icon-Close"></i></div>

            <div className="appliedFilterResult" style={{ display: slicedata?.direct !== false ? "block" : 'none' }}> Direct <i class="icon icon-Close"></i></div>

            <div className="appliedFilterResult" style={{ display: slicedata?.stops.length > 0 ? "block" : 'none' }}> Stops <i class="icon icon-Close"></i></div>
            {/* <div className="appliedFilterResult" style={{ display: slicedata?.boardTime.length > 0 ? "block" : 'none' }}> On Board <i class="icon icon-Close"></i></div> */}
            
            
          </div>
        </div>
                  <div className="d-flex justify-content-between mt-20">
                      <span className='filterTitle'>Filters</span>
                      <span className='clearFilterTitle' onClick={handleClearAll}>Clear filters</span>
                  </div>

                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Search Flight <span onClick={handleSearchFlight}>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        <Form>
                          <Form.Group className='position-relative searchByFilterWrap'>
                              <Form.Control 
                                type="text" 
                                placeholder="Search by flight number" 
                                className='searchByFilter' 
                                onChange={(e) => { dispatch(setSearchFlight(e.target.value)) }}
                                value={slicedata.searchFlight}
                              />
                              <i class="icon icon-Search"></i>
                          </Form.Group>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Price <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      {/* <div className="set-price d-flex">
                        <span>{props.currency} 3015</span>
                        <span>{props.currency} 1572</span>
                      </div> */}
                      <InputRange
                        formatLabel={(value) => `${cur ? cur : props.currency} ${value}`}
                        draggableTrack
                        allowSameValues={true}
                        maxValue={20}
                        minValue={0}
                        value={price}
                        onChangeComplete={value => filterApplyPrice("price", value)}
                        onChange={value => filterApply("price", value)} 
                      />
                      
                      {/* <InputRange
                        draggableTrack
                        formatLabel={(value) => `${currency} ${value}`}
                        allowSameValues={true}
                        maxValue={100}
                        minValue={0}
                        onChange={value => filterApply("price", value)} 
                        onChangeComplete={value => filterApplyPrice("price", value)}
                        // value={state.price}
                      /> */}
                      
                      {/* <div className="setprice">
                        <img src="assets/images/flight-result/price-line.svg" alt="" />
                        <MultiRangeSlider
                          min={10}
                          max={100}
                          canMinMaxValueSame={false}
                          label={false}
                          ruler={false}
                          style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                          barLeftColor="white"
                          barInnerColor="#033B55"
                          barRightColor="white"
                          thumbLeftColor="white"
                          thumbRightColor="white"
                        />
                      </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Airlines <span onClick={handleAirlineFilter}>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        {
                          filterData?.Airline?.map((airlines, index) => {
                            return (
                              <>
                              <div className="inlineCheckboxWrap d-flex align-items-center"  key={index}>
                              <input 
                                type="checkbox" 
                                aria-label="option 1" 
                                className='form-check-input'
                                id={airlines.Code + index} checked={slicedata.airlineFilter?.includes(airlines.Code) ? true : false}
                                onChange={(e) => {
                                  { handleFligtsCode(airlines.Code, e.target.checked) }
                                }}
                              />
                              <label for={airlines.Code + index} className=''>
                                {handleLogo(airlines.Code) && (
                                  <img src={handleLogo(airlines.Code)} alt="nonStopImage" className='img-fluid' />
                                )}
                                <span>{airlines.Value}</span>
                              </label>
                              </div>
                              </>
                            )
                          })
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Baggage <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      <Form>
                      <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="Include checked baggage"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="All baggage options"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Fare Type <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      <Form>
                      <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="Refundable"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="Non Refundable"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Stops <span onClick={handleStops}>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        {
                          filterData?.destination_filters?.map((data, index) => {
                            return (
                              <>
                                {/* <div className="fltab">
                                  <div className="inner">
                                    <span className="active">{index === 0 ? "Departure" : "Return"} ({data.from}-{data.to})</span>
                                  </div>
                                  <ul className="d-flex nonstop-row">
                                    <li >
                                      <input type="checkbox" checked={slicedata.stops[index]?.direct === true ? true : false} className='stops-checkbox' onClick={(e) => {
                                        handleFlightStops(index, 1, e.target.checked)
                                      }} />
                                      <strong>Non Stop</strong>
                                      <i><img src="assets/images/flight-result/icon1.svg" alt="" /></i>
                                    </li>
                                    <li >
                                      <input type="checkbox" checked={slicedata.stops[index]?.onestop === true ? true : false} className='stops-checkbox' onClick={(e) => {
                                        handleFlightStops(index, 2, e.target.checked)
                                      }} />
                                      <strong>1 Stop</strong>
                                      <i><img src="assets/images/flight-result/icon2.svg" alt="" /></i>
                                    </li>
                                    <li style={{ display: data.stops?.morethanonestop === 0 ? 'none' : 'flex' }}>
                                      <input type="checkbox" checked={slicedata.stops[index]?.morethanonestop === true ? true : false} className='stops-checkbox' onClick={(e) => {
                                        handleFlightStops(index, 3, e.target.checked)
                                      }} />
                                      <strong>Morethan 1 Stop</strong>
                                      <i> <img src="assets/images/flight-result/icon3.svg" alt="" /></i>
                                    </li>
                                  </ul>
                                </div> */}
                        <Tabs
                            defaultActiveKey="departure"
                            id="uncontrolled-tab-example"
                            className="filterTab"
                          >
                          <Tab eventKey="departure" title={`${index === 0 ? "Departure" : "Return"} (${data.from}-${data.to})`}>
                            <div className="d-flex justify-content-between">
                                <div className="nonStop stopBox">
                                  <input type="checkbox" checked={slicedata.stops[index]?.onestop === true ? true : false} aria-label="option 1" id='dnonStop' onClick={(e) => {
                                        handleFlightStops(index, 1, e.target.checked)
                                      }} />
                                  <label for='dnonStop' className='stopInnerBox'>
                                    <span>Non Stop</span>
                                    <img src={NonStop} alt="nonStopImage" className='img-fluid' />
                                  </label>
                                </div>
                                <div className="oneStop stopBox">
                                  <input type="checkbox" checked={slicedata.stops[index]?.onestop === true ? true : false} aria-label="option 1" id='doneStop' onClick={(e) => {
                                        handleFlightStops(index, 2, e.target.checked)
                                      }} />
                                  <label for='doneStop' className='stopInnerBox'>
                                    <span>1 Stop</span>
                                    <img src={OneStop} alt="oneStopImage" className='img-fluid' />
                                  </label>
                                </div>
                                <div className="morethanOneStop stopBox">
                                  <input type="checkbox" checked={slicedata.stops[index]?.morethanonestop === true ? true : false} aria-label="option 1" id='dmorethanOneStop' onClick={(e) => {
                                        handleFlightStops(index, 3, e.target.checked)
                                      }} />
                                  <label for='dmorethanOneStop' className='stopInnerBox'>
                                    <span>2+ Stops</span>
                                    <img src={MorethanOneStop} alt="morethanOneStopImage" className='img-fluid' />
                                  </label>
                                </div>
                            </div>
                          </Tab></Tabs>
                          {/* <Tab eventKey="return" title="Return">
                            <div className="d-flex justify-content-between">
                                <div className="nonStop stopBox">
                                  <input type="checkbox" aria-label="option 1" id='rnonStop' />
                                  <label for='rnonStop' className='stopInnerBox'>
                                    <span>Non Stop</span>
                                    <img src={NonStop} alt="nonStopImage" className='img-fluid' />
                                  </label>
                                </div>
                                <div className="oneStop stopBox">
                                  <input type="checkbox" aria-label="option 1" id='roneStop' />
                                  <label for='roneStop' className='stopInnerBox'>
                                    <span>1 Stop</span>
                                    <img src={OneStop} alt="oneStopImage" className='img-fluid' />
                                  </label>
                                </div>
                                <div className="morethanOneStop stopBox">
                                  <input type="checkbox" aria-label="option 1" id='rmorethanOneStop' />
                                  <label for='rmorethanOneStop' className='stopInnerBox'>
                                    <span>2+ Stops</span>
                                    <img src={MorethanOneStop} alt="morethanOneStopImage" className='img-fluid' />
                                  </label>
                                </div>
                            </div>
                          </Tab> */}
                              </>
                            )
                          }
                        )
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Trip Duration <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        {
                          filterData?.destination_filters?.map((data, indexx) => {
                            return (
                              <>
                                <div className="fltab">
                                  <div className="inner">
                                    <span className="active">{data.from + " - " + data.to}</span>
                                  </div>
                                  <div className="set-price d-flex">
                                    <span>{handleTimeChange(data.stops.journeymin)}</span>
                                    <span>{handleTimeChange(data.stops.journeymax)}</span>
                                  </div>

                                  {/* <div className="setprice">
                                    <MultiRangeSlider
                                      min={data.stops.journeymin}
                                      max={data.stops.journeymax}
                                      minValue={data.stops.journeymin}
                                      maxValue={data.stops.journeymax}
                                      canMinMaxValueSame={false}
                                      label={true}
                                      ruler={false}
                                      style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                                      barLeftColor="white"
                                      barInnerColor="#033B55"
                                      barRightColor="white"
                                      thumbLeftColor="white"
                                      thumbRightColor="white"
                                      onChange={(e) => { console.log("asdfghjkl") }}
                                      onInput={(e) => { handleTripDuration(e, indexx) }}
                                    /> */}
                                    {/* <img src="assets/images/flight-result/price-line.svg" alt="" /> */}
                                  {/* </div> */}
                                </div>
                              </>
                            )
                          })
                        }
                        {/* <Tabs
                            defaultActiveKey="departure"
                            id="uncontrolled-tab-example"
                            className="filterTab"
                          >
                          <Tab eventKey="departure" title="Departure">
                          Departure
                          </Tab>
                          <Tab eventKey="return" title="Return">
                          Return
                          </Tab>
                        </Tabs> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Layover Duration <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        <Tabs
                              defaultActiveKey="departure"
                              id="uncontrolled-tab-example"
                              className="filterTab"
                            >
                          <Tab eventKey="departure" title="Departure">
                          Departure
                          </Tab>
                          <Tab eventKey="return" title="Return">
                          Return
                          </Tab>
                        </Tabs>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}

                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> On Board Time <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                        <div className="fltab">
                          <div className="inner">
                            <span className={`depborder ${departureShow ? 'active' : ''}`} onClick={() => { handleOnBoardTimings('dep') }}>Departure</span>
                            <span className={`depborder ${returnShow ? 'active' : ''}`} onClick={() => { handleOnBoardTimings('ret') }}>Return</span>
                          </div>                        
                          {departureShow && (
                            <ul className="early-root d-flex flex-wrap justify-content-between">
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[0]?.earlymorning ? 'active' : ''}`}
                                onClick={() => { handleOnboard('earlymorning', 0, !slicedata?.boardTime[0]?.earlymorning) }} >
                                <i class="icon icon-Early-Morning"></i>
                                <span>Early Morning</span>
                                <div>00:00 to 06:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[0]?.morning ? 'active' : ''}`}
                                onClick={() => { handleOnboard('morning', 0, !slicedata?.boardTime[0]?.morning) }}>
                                <i class="icon icon-Morning"></i>
                                <span>Morning</span>
                                <div>06:00 to 12:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[0]?.afternoon ? 'active' : ''}`}
                                onClick={() => { handleOnboard('afternoon', 0, !slicedata?.boardTime[0]?.afternoon) }}>
                                <i class="icon icon-Afternoon"></i>
                                <span>Afternoon</span>
                                <div>12:00 to 18:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[0]?.evening ? 'active' : ''}`}
                                onClick={() => { handleOnboard('evening', 0, !slicedata?.boardTime[0]?.evening) }}>
                                <i class="icon icon-Evening"></i>
                                <span>Evening</span>
                                <div>18:00 to 24:00</div>
                              </li>
                            </ul>
                          )}
                          {returnShow && (
                            <ul className="early-root d-flex flex-wrap justify-content-between">
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[1]?.earlymorning ? 'active' : ''}`}
                                onClick={() => { handleOnboard('earlymorning', 1, !slicedata?.boardTime[1]?.earlymorning) }}>
                                <i class="icon icon-Early-Morning"></i>
                                <span>Early Morning</span>
                                <div>00:00 to 06:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[1]?.morning ? 'active' : ''}`}
                                onClick={() => { handleOnboard('morning', 1, !slicedata?.boardTime[1]?.morning) }}>
                                <i class="icon icon-Morning"></i>
                                <span>Morning</span>
                                <div>06:00 to 12:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[1]?.afternoon ? 'active' : ''}`}
                                onClick={() => { handleOnboard('afternoon', 1, !slicedata?.boardTime[1]?.afternoon) }}>
                                <i class="icon icon-Afternoon"></i>
                                <span>Afternoon</span>
                                <div>12:00 to 18:00</div>
                              </li>
                              <li className={`colorfill mt-2 ${slicedata?.boardTime[1]?.evening ? 'active' : ''}`}
                                onClick={() => { handleOnboard('evening', 1, !slicedata?.boardTime[1]?.evening) }}>
                                <i class="icon icon-Evening"></i>
                                <span>Evening</span>
                                <div>18:00 to 24:00</div>
                              </li>
                            </ul>  
                          )}
                        </div>
                        {/* <Tabs
                              defaultActiveKey="departure"
                              id="uncontrolled-tab-example"
                              className="filterTab"
                            >
                          <Tab eventKey="departure" title="Departure">
                            <div className='depFrom'>Departure from Dubai</div>
                          
                            <div className="d-flex justify-content-between">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='ddearlyMorning' />
                                  <label for='ddearlyMorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Early-Morning"></i>
                                    <span>Early Morning</span>
                                    <div>00:00 to 06:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='ddmorning' />
                                  <label for='ddmorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Morning"></i>
                                    <span>Morning</span>
                                    <div>06:00 to 12:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-12">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='ddafternoon' />
                                  <label for='ddafternoon' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Afternoon"></i>
                                    <span>Afternoon</span>
                                    <div>12:00 to 18:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='ddevening' />
                                  <label for='ddevening' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Evening"></i>
                                    <span>Evening</span>
                                    <div>18:00 to 24:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className='arrIn'>Arrival in London</div>
                            <div className="d-flex justify-content-between">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='daearlyMorning' />
                                  <label for='daearlyMorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Early-Morning"></i>
                                    <span>Early Morning</span>
                                    <div>00:00 to 06:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='damorning' />
                                  <label for='damorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Morning"></i>
                                    <span>Morning</span>
                                    <div>06:00 to 12:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-12">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='daafternoon' />
                                  <label for='daafternoon' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Afternoon"></i>
                                    <span>Afternoon</span>
                                    <div>12:00 to 18:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='daevening' />
                                  <label for='daevening' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Evening"></i>
                                    <span>Evening</span>
                                    <div>18:00 to 24:00</div>
                                  </label>
                                </div>
                            </div>
                          </Tab>
                          <Tab eventKey="return" title="Return">
                            <div className='depFrom'>Departure from Dubai</div>
                            <div className="d-flex justify-content-between">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='rdearlyMorning' />
                                  <label for='rdearlyMorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Early-Morning"></i>
                                    <span>Early Morning</span>
                                    <div>00:00 to 06:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='rdmorning' />
                                  <label for='rdmorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Morning"></i>
                                    <span>Morning</span>
                                    <div>06:00 to 12:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-12">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='rdafternoon' />
                                  <label for='rdafternoon' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Afternoon"></i>
                                    <span>Afternoon</span>
                                    <div>12:00 to 18:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='rdevening' />
                                  <label for='rdevening' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Evening"></i>
                                    <span>Evening</span>
                                    <div>18:00 to 24:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className='arrIn'>Arrival in London</div>
                            <div className="d-flex justify-content-between">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='raearlyMorning' />
                                  <label for='raearlyMorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Early-Morning"></i>
                                    <span>Early Morning</span>
                                    <div>00:00 to 06:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='ramorning' />
                                  <label for='ramorning' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Morning"></i>
                                    <span>Morning</span>
                                    <div>06:00 to 12:00</div>
                                  </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-12">
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='raafternoon' />
                                  <label for='raafternoon' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Afternoon"></i>
                                    <span>Afternoon</span>
                                    <div>12:00 to 18:00</div>
                                  </label>
                                </div>
                                <div className="partsOfTheDayBox">
                                  <input type="checkbox" aria-label="option 1" id='raevening' />
                                  <label for='raevening' className='partsOfTheDayInnerBox'>
                                    <i class="icon icon-Evening"></i>
                                    <span>Evening</span>
                                    <div>18:00 to 24:00</div>
                                  </label>
                                </div>
                            </div>
                          </Tab>
                        </Tabs> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Stopover (Dubai to London) <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      <Form>
                      <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="CDG - Aeroport de Paris- Ch..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="AMS - Amsterdam Airport Sc..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="ATH - Athens International a..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="BAH - Bahrain International..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="CAI - Cairo International Air..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                  {/* <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Stopover (London to Dubai) <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      <Form>
                      <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="CDG - Aeroport de Paris- Ch..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="AMS - Amsterdam Airport Sc..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="ATH - Athens International a..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="BAH - Bahrain International..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="CAI - Cairo International Air..."
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                  {/* <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header><i class="icon icon-down-arrow"></i> Aircraft Types <span>Clear</span></Accordion.Header>
                      <Accordion.Body>
                      <Form>
                      <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="Airbus 332"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        <div key={`inline-checkbox`} className="inlineCheckboxWrap">
                      <Form.Check
                            inline
                            label="Airbus 388"
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                          />
                        </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                </div>
            {/* <div className="se-flight"> <img src="assets/images/flight-result/fl.svg" alt="" /> Select Flights
              Separately
            </div> */}
      {/* </aside > */}
    </>
  )
}

export default FlightFilters
