import React, { useState, useEffect, useReducer } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';
import {getNumberFormat} from '../../../utils/validators'

/**
 * @description:This function for Price Details
 * @author: Praveen Varma
 * @param {*}
 * @function Price details
 * @date : 08-08-2024
 */
const initialState = {
    no_of_rooms:"", adt_count:"", chd_count:""
};
const reducer = (state, action) => {
    switch (action.type) {
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function PriceDetails({itinerary, total_usr_price}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [hotel_details, setHotelDetais] = useState({});
    const [rooms, setRooms] = useState([]);

    useEffect(() =>{
        setHotelDetais(itinerary?.req?.htldtls || {});
        setRooms((itinerary?.req?.rooms &&  itinerary?.req?.rooms?.length > 0) ? itinerary?.req?.rooms : []);
        getRoomsCount();
    }, [itinerary]);

    const getStarRating = (star) => {
        let ary = [];
        if (star !== undefined && star !== null && star !== "") {
            for (var s = 0; s < Number(star); s++) {
                ary.push(s);
            }
        }
        return ary
    }

    const getRoomsCount = () =>{

    }

    return (
        <>
            <div className="summarySideWrap">
                <div className="reviewBookingWrap">
                    <div class="leftSemicircle"></div>
                    <div className="reviewBookingTitle">
                        Review Booking
                    </div>
                    <div className="bookingHotelImage">
                        <img src={hotel_details?.htimg} alt="hotelImage" />
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="hotelName">{hotel_details?.htnm}</div>
                        <div class="hotelRating">
                        {getStarRating(hotel_details?.rating).map((mp) => {
                            return (
                                <>
                                    <i class="icon icon-star"></i>
                                </>
                            )
                        })}
                        </div>
                    </div>
                    <div class="d-flex align-items-center locationReviewWrap">
                        <div class="hotelLocationWrap">
                            <i class="icon icon-Pointer"></i> {hotel_details?.address}
                        </div>
                    </div>
                    <div className="checkInOutNights">
                        <div className="checkIn">
                            <div className="checkInOutTitle">Check-In</div>
                            <div className="checkInOutDate">{hotel_details?.cin}</div>
                            <div className="checkFromUntil">From 15:00</div>
                        </div>
                        <div className="nightWrap">Nights : {hotel_details?.no_nights}</div>
                        <div className="checkOut">
                            <div className="checkInOutTitle">Check-Out</div>
                            <div className="checkInOutDate">{hotel_details?.cout}</div>
                            <div className="checkFromUntil">Until 12:00</div>
                        </div>
                    </div>
                    <Accordion defaultActiveKey="0" className='tripSumCollapse reviewBookCollapse'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className='tripSumHeader'>
                                <div className="d-flex justify-content-between fullWidth">
                                    <div className="roomsAdults d-flex align-items-center">
                                        <i class="icon icon-down-arrow font16"></i>
                                        <span>{hotel_details?.no_rooms} Rooms: {hotel_details?.total_no_of_adts} Adult(s) 
                                        {hotel_details?.total_no_of_chds !== undefined && hotel_details?.total_no_of_chds !== null && hotel_details?.total_no_of_chds !== "" && hotel_details?.total_no_of_chds > 0 ?  ` ${hotel_details?.total_no_of_chds} Child(s)` :"" }</span>
                                    </div>
                                    <div className="roomAmount">
                                    {itinerary?.req?.usr_cur} {getNumberFormat(itinerary?.req?.final_amt) || 0}
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                            {rooms?.length > 0 && rooms?.map((room) => {
                                return (
                                    <>
                                        <Accordion defaultActiveKey="0" className='tripSumCollapse reviewBookInnerCollapse'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='tripSumHeader'>
                                                    <i class="icon icon-down-arrow"></i>
                                                    <span className="roomCount">Room {room?.rmno} : <span className="roomClass">{room?.bed}</span></span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="adultBedSize">
                                                        <div className="adultCount">{room?.no_of_adts} Adult(s) 
                                                        {room?.no_of_chds !== undefined && room?.no_of_chds !== null && room?.no_of_chds !== "" && room?.no_of_chds > 0?  ` ${room?.no_of_chds} Child(s)` :"" }
                                                        </div>
                                                        <div className="bedSize">{room?.bed}</div>
                                                    </div>
                                                    <div className="freeCancelAmount">
                                                        <div className="freeCancel">Free Cancellation</div>
                                                        <div className="cancelAmount">{itinerary?.req?.usr_cur} {getNumberFormat((itinerary?.req?.base_to_usr_ex_rate * Number(room?.pr[0]?.tf))?.toFixed(2))}</div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                )
                            })
                            }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="totalPayableWrap">
                        <div className="totalPayableTitle">Total Payable Amount</div>
                        <div>
                            <div className="totalPayableAmount">{itinerary?.req?.usr_cur} {getNumberFormat(itinerary?.req?.final_amt) || 0}</div>
                            <div className="installments"><i class="icon icon-tick"></i> Installments</div>
                        </div>
                    </div>
                    <div class="rightSemicircle"></div>
                </div>
            </div>
        </>
    )
}
export default PriceDetails;