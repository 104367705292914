
import React from 'react';
import moment from "moment";

/**
 * @author Praveen Varma
 * @description Mytrips child component
 * @date 24-06-2024
 * @returns 
 */
function TripsTab({ trips }) {
    return (
        <>
            {trips && trips.length > 0 ?
                <div className="tripsTable">
                    <div className="tripsTableHeader">
                        <span className='routeSpan'>Route/Hotel</span>
                        <span className='bookingIDSpan'>Booking ID</span>
                        <span className='bookingStatusSpan'>Booking Status</span>
                        <span className='actionSpan'>Actions</span>
                    </div>
                    <div className='tripsTableDataWrap'>
                        {trips && trips.length > 0 &&
                            trips.map((itm) => {
                                return (
                                    <>
                                        {itm?.product && itm?.product === "Flight" &&
                                            <div className="tripsTableData">
                                                <span className='routeSpan'>
                                                    <div className="innerRouteSpan">
                                                        <i class="icon icon-Flight-Circle"></i>
                                                        <div className="innerRouteSpanCont">
                                                            <div>
                                                                <div className='tripsRoute'>{itm.src_code}</div><i class="icon icon-Arrow"></i><div className='tripsRoute'>{itm.dest_code}</div>
                                                            </div>
                                                            <div className='tripsDateTimeWrap'><span className='tripsDate'>{moment(itm.travel_date).format("ddd, DD MMM, YYYY")}</span></div>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className='bookingIDSpan'>{itm.booking_id}</span>
                                                <span className={itm.booking_status_str === "Cancelled" ? 'bookingStatusSpan Cancelled' : 'bookingStatusSpan'}><i class={itm.booking_status_str === "Cancelled" ? 'icon icon-Cross' : 'icon icon-tick'}></i> {itm.booking_status_str}</span>
                                                <span className='actionSpan'><i class="icon icon-View"></i> <i class="icon icon-Download"></i></span>
                                            </div>
                                        }
                                        {itm?.product && itm?.product === "Hotel" &&
                                            <div className="tripsTableData">
                                                <span className='routeSpan'>
                                                    <div className="innerRouteSpan">
                                                        <i class="icon icon-City"></i>
                                                        <div className="innerRouteSpanCont">
                                                            <div className='tripHotelName'>JW Marriott Marquis Hotel Dubai</div>
                                                            <div className='tripsDateTimeWrap'><span className='tripsDate'>Sat, 20 Apr, 2024</span><span className='nightTime leftSeparatorLine'>12:30 AM - 5 Nights</span></div>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className='bookingIDSpan'>KF123456</span>
                                                <span className='bookingStatusSpan'><i class="icon icon-tick"></i> Confirmed</span>
                                                <span className='actionSpan'><i class="icon icon-View"></i> <i class="icon icon-Download"></i></span>
                                            </div>
                                        }
                                    </>
                                )
                            })}
                    </div>
                </div>
                : "No trips found"
            }
        </>
    )
}
export default TripsTab
