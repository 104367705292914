import React from 'react'
import { Link } from 'react-router-dom'

function FlightReviewBar() {
  const handleScrollPage = () => {
    window.scrollTo({
      top: 1200,
      behavior: 'smooth' // Smooth scrolling
    })
  }
  return (
    <>
      <section className="review-bar">
        <div className="container">
          <ul style={{ cursor: 'pointer' }}>
            <Link to='/review'>
              <li>
                <i>1</i>
                <span style={{ color: 'white' }}>Review Flight</span>
              </li>
            </Link>
            <figure><img src="assets/images/flight-result/iconright.svg" alt="" /></figure>
            <Link to='/review'>
              <li onClick={handleScrollPage} >
                <i>2</i>
                <span style={{ color: 'white' }}>Traveller Details</span>
              </li>
            </Link>
            <figure><img src="assets/images/flight-result/iconright1.svg" alt="" /></figure>
            <Link to='/payment'>
              <li>
                <i>3</i>
                <span style={{ color: 'white' }}>Payment</span>
              </li>
            </Link>
            <figure><img src="assets/images/flight-result/iconright1.svg" alt="" /></figure>
            <li>
              <i>4</i>
              <span>Confirmation</span>
            </li>

          </ul>

        </div>
      </section>
    </>
  )
}

export default FlightReviewBar
