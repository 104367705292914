
/**
 * @description:My Profile activities
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-06-2024
 */

import React, { useEffect, useState, useReducer } from 'react';
import { Row, Col, Form, InputGroup, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useNavigate } from 'react-router-dom';
import { handlePostApi } from '../../utilities/Axios';
import Loader from '../../assets/Gifs/Double Ring-1s-200px.gif';
import { isArrayEmpty, isArrayNotEmpty, isNotNull } from "../../utilities/Validators";
import Sidebar from './Sidebar';
import MyAccFooter from '../common/MyAccFooter';
let months_json = require("../maasterdata/Months.json");
let countries_json = require("../maasterdata/CountriesList.json");
let std_codes_json = require("../maasterdata/CountrySTDCodes.json");
let airlies_json = require("../maasterdata/AirlinesMasterData.json");
let days_raw_json = require("../maasterdata/DaysJson.json");

/**
 * @description:My Profile activities
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-06-2024
 */
const initialState = {
  profile_id: "", usr_uniq_id: "", title: "", firstName: "", lastName: "", email: "", nationality: "", maritalStatus: "",
  dob_day: "", dob_month: "", dob_year: "", gender: "", ph_code: "", ph_num: "",
  annivesry_day: "", annivesry_month: "", annivesry_year: "",
  doc_id: "", doc_type: "", passport_no: "", issue_cnt: "", issue_date_day: "", issue_date_month: "",
  issue_date_year: "", exp_date_day: "", exp_date_month: "", exp_date_year: "",
  notiMessage: "", notiMessageShow: false, notiVarient: "", display_name: "", month: "",
  frequentFlyerData: [{ id: "", airline_name: "", ffp: "", ffn: "", sel_airline_name: "" }],
  sel_ph_code: "", sel_title: "", sel_dob_month: "", sel_dob_day: "", sel_dob_year: "",
  sel_gender: "", sel_marital_status: "", sel_nationality: "",
  sel_issue_date_month: "", sel_issue_date_day: "", sel_issue_date_year: "", sel_issue_cnt: "",
  sel_exp_date_month: "", sel_exp_date_day: "", sel_exp_date_year: "",
  title_array: [
    { "label": "Mr.", "value": "Mr." },
    { "label": "Mrs.", "value": "Mrs." },
    { "label": "Miss", "value": "Miss" }
  ],
  gender_arry: [
    { "label": "Male", "value": "M" },
    { "label": "Female", "value": "F" }
  ],
  marital_arry: [
    { "label": "Single", "value": "Single" },
    { "label": "Married", "value": "Married" }
  ]
}

const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    case "ADD_FFP_ROW":
      return {
        ...state,
        ...state,
        frequentFlyerData: [...state.frequentFlyerData, {
          sel_airline_name: "",
          airline_name: "",
          ffp: "",
          ffn: "",
        }],
      };
    case "DELETE_FFP_ROW_INDEX":
      return {
        ...state,
        ...state,
        frequentFlyerData: state.frequentFlyerData?.filter(
          (_, index) => index !== action.index
        ),
      };
    case "DELETE_FFP_ROW_ID":
      return {
        ...state,
        ...state,
        frequentFlyerData: state.frequentFlyerData?.filter(
          (itm) => itm.id && itm.id !== action.id
        ),
      };
    case "UPDATE_FFP_DATA":
      const frequentFlyerData = [...state.frequentFlyerData];
      if (action?.field === "airline_name") {
        let sel_air_Data = "";
        let fil = airlies_json?.filter(im => action.value && im?.value === action.value);
        if (isArrayNotEmpty(fil)) {
          sel_air_Data = fil[0];
        }
        frequentFlyerData[action.index] = {
          ...frequentFlyerData[action.index],
          [action.field]: action.value,
          sel_airline_name: sel_air_Data
        };
      } else {
        frequentFlyerData[action.index] = {
          ...frequentFlyerData[action.index],
          [action.field]: action.value,
        };
      }
      return {
        ...state,
        ...state.formData,
        frequentFlyerData,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:My Profile activities
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-06-2024
 */
function MyProfile(props) {
  const nav = useNavigate();
  const [profile, setProfile] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentYear = new Date().getFullYear();
  const [loader, setLoader] = useState(false);

  const [prof_edit, setProf_edit] = useState(false);
  const [doc_edit, setDoc_edit] = useState(false);
  const [contact_edit, setContact_edit] = useState(false);
  const [ffp_edit, setFFP_edit] = useState(false);
  const [update_profile, setUpdateProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [years_json, setYearsJson] = useState([]);
  const [expiry_years_json, setExpiryYearsJson] = useState([]);
  const [days_json, setDaysJson] = useState([]);

  const yearsRange = 80;

  const expiryYears = Array.from(
    { length: yearsRange },
    (_, index) => currentYear + index
  );

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 0);
    return date.getDate();
  };



  useEffect(() => {
    //localStorage.setItem("logged",JSON.stringify({fname:"jamal",unique_id:"24191031061043"}))
    let login = localStorage.getItem("logged");
    if (login) {
      getDaysArray();
      getYearsJson();
      getExpiryYearsJson();
      login = JSON.parse(login);
      if (login && login.unique_id) {
        fetchProfile(login.unique_id);
      }
    } else {
      nav("/")
    }
  }, []);

  useEffect(() => {
    if (update_profile && state?.usr_uniq_id) {
      setUpdateProfile(false);
      dispatch({ type: "fieldError", payload: [] });
      fetchProfile(state?.usr_uniq_id);
    }
  }, [update_profile]);

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const getYearsJson = async () => {
    const years = Array.from(
      { length: yearsRange },
      (_, index) => currentYear - index
    );
    let years_arry = years.map(itm => {
      return {
        label: itm + "",
        value: itm + ""
      }
    }
    )
    setYearsJson(years_arry)
    return years_arry;
  }

  const getExpiryYearsJson = async () => {
    const years = Array.from(
      { length: yearsRange },
      (_, index) => currentYear + index
    );
    let years_arry = years.map(itm => {
      return {
        label: itm + "",
        value: itm + ""
      }
    }
    )
    setExpiryYearsJson(years_arry)
    return years_arry;
  }

  

  /**
   * Fetch Profile info
   */
  const fetchProfile = async (usr_uniq_id) => {
    try {
      handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/user/profile/fetch`, {
        usr_uniq_id: usr_uniq_id
      }).then(async function (result) {
        if (result?.data?.suc) {
          let dumy_day_ary = days_json;
          if (isArrayEmpty(dumy_day_ary)) {
            dumy_day_ary = await getDaysArray()
          }
          let dumy_year_ary = years_json;
          if (isArrayEmpty(dumy_year_ary)) {
            dumy_year_ary = await getYearsJson();
          }
          let profile_info = result?.data?.res;
          setProfile(result?.data?.res);
          dispatch({ type: "editProfile", payload: profile_info });
          dispatch({ type: "title", payload: profile_info?.title });
          if (profile_info?.title && profile_info?.title !== null && profile_info?.title !== "") {
            let sel_title_ary = state?.title_array?.filter(itm => itm.value === profile_info?.title);
            if (sel_title_ary && sel_title_ary.length > 0) {
              dispatch({ type: "sel_title", payload: sel_title_ary[0] });
            }
          }
          dispatch({ type: "display_name", payload: `${profile_info?.title || ""} ${profile_info?.fname || ""} ${profile_info?.lname || ""}` });
          let displayName = `${profile_info?.title || ""} ${profile_info?.fname ? `${profile_info?.fname?.substring(0,1).toUpperCase()}${profile_info?.fname?.substring(1, profile_info?.fname.length).toLowerCase()}` : ""} ${profile_info?.lname ?  `${profile_info?.lname?.substring(0,1).toUpperCase()}${profile_info?.lname?.substring(1, profile_info?.lname.length).toLowerCase()}` :""}`
          let first_name = profile_info?.fname ?  `${profile_info?.fname?.substring(0,1).toUpperCase()}${profile_info?.fname?.substring(1, profile_info?.fname.length).toLowerCase()}` :""
          if (displayName && usr_uniq_id && first_name) {
            let loggedInData = localStorage.getItem("logged");
            if (loggedInData && loggedInData !== null && loggedInData !== "") {
              let loggedInfo = JSON.parse(loggedInData)
              let loginInfo = {
                fname: first_name,
                unique_id: usr_uniq_id,
                display_name : displayName,
                profile_pic : profile_info.profile_pic,
              }
              localStorage.setItem("logged", JSON.stringify(loginInfo))
            }
            // let loginInfo = {
            //   fname: first_name,
            //   unique_id: usr_uniq_id,
            //   display_name : displayName,
            // }
            // localStorage.setItem("logged", JSON.stringify(loginInfo))
          }
          dispatch({ type: "profile_id", payload: profile_info?.id });
          dispatch({ type: "usr_uniq_id", payload: profile_info?.usr_uniq_id });
          dispatch({ type: "firstName", payload: profile_info?.fname });
          dispatch({ type: "lastName", payload: profile_info?.lname });
          dispatch({ type: "email", payload: profile_info?.email });
          dispatch({ type: "gender", payload: profile_info?.gender });
          if (profile_info?.gender) {
            let sel_gener_ary = state?.gender_arry?.filter(itm => itm.value === profile_info?.gender);
            if (sel_gener_ary && sel_gener_ary.length > 0) {
              dispatch({ type: "sel_gender", payload: sel_gener_ary[0] });
            }
          }
          setProfileImage(profile_info?.profile_pic);
          dispatch({ type: "profile_pic", payload: profile_info?.profile_pic });
          if (profile_info?.dob !== null && profile_info?.dob !== undefined && profile_info?.dob !== "") {
            dispatch({ type: "dob_day", payload: profile_info?.dob?.slice(0, 2) });
            let sel_dob_day_ary = dumy_day_ary?.filter(itm => itm.value === profile_info?.dob?.slice(0, 2));
            if (sel_dob_day_ary && sel_dob_day_ary.length > 0) {
              dispatch({ type: "sel_dob_day", payload: sel_dob_day_ary[0] });
            }
            dispatch({ type: "dob_month", payload: profile_info?.dob?.slice(3, 5) });
            let sel_dob_month_ary = months_json?.filter(itm => itm.value === profile_info?.dob?.slice(3, 5));
            if (sel_dob_month_ary && sel_dob_month_ary.length > 0) {
              dispatch({ type: "sel_dob_month", payload: sel_dob_month_ary[0] });
            }
            dispatch({ type: "dob_year", payload: profile_info?.dob?.slice(6, 10) });
            let yr_data = profile_info?.dob?.slice(6, 10) + "";
            let sel_dob_year_ary = dumy_year_ary?.filter(itm => itm.value && itm.value + "" === yr_data);
            if (sel_dob_year_ary && sel_dob_year_ary.length > 0) {
              dispatch({ type: "sel_dob_year", payload: sel_dob_year_ary[0] });
            }
          } else {
            dispatch({ type: "dob_day", payload: "" });
            dispatch({ type: "dob_month", payload: "" });
            dispatch({ type: "dob_year", payload: "" });
          }
          dispatch({ type: "nationality", payload: profile_info?.nationality });
          if (profile_info?.nationality) {
            let sel_country_ary = countries_json?.filter(itm => itm.value === profile_info?.nationality);
            if (sel_country_ary && sel_country_ary.length > 0) {
              dispatch({ type: "sel_nationality", payload: sel_country_ary[0] });
            }
          }
          dispatch({ type: "maritalStatus", payload: profile_info?.marital_status });
          if (profile_info?.marital_status) {
            let sel_mar_ary = state?.marital_arry?.filter(itm => itm.value === profile_info?.marital_status);
            if (sel_mar_ary && sel_mar_ary.length > 0) {
              dispatch({ type: "sel_marital_status", payload: sel_mar_ary[0] });
            }
          }
          if (profile_info?.mrg_date !== null && profile_info?.mrg_date !== undefined && profile_info?.mrg_date !== "") {
            dispatch({ type: "annivesry_day", payload: profile_info?.mrg_date?.slice(0, 2) });
            dispatch({ type: "annivesry_month", payload: profile_info?.mrg_date?.slice(3, 5) });
            dispatch({ type: "annivesry_year", payload: profile_info?.mrg_date?.slice(6, 10) });
          } else {
            dispatch({ type: "annivesry_day", payload: "" });
            dispatch({ type: "annivesry_month", payload: "" });
            dispatch({ type: "annivesry_year", payload: "" });
          }
          dispatch({ type: "ph_code", payload: profile_info?.ph_code });
          dispatch({ type: "ph_num", payload: profile_info?.ph_num });
          if (profile_info?.ph_code && profile_info?.ph_code !== null && profile_info?.ph_code !== "") {
            let sel_ph_code_ary = std_codes_json?.filter(itm => itm.value === profile_info?.ph_code);
            if (sel_ph_code_ary && sel_ph_code_ary.length > 0) {
              dispatch({ type: "sel_ph_code", payload: sel_ph_code_ary[0] });
            }
          }
          if (profile_info?.docs?.length > 0) {
            let doc = profile_info?.docs[0];
            dispatch({ type: "doc_id", payload: doc?.id });
            dispatch({ type: "doic_type", payload: doc?.doic_type });
            dispatch({ type: "passport_no", payload: doc?.passport_no });
            dispatch({ type: "issue_cnt", payload: doc?.issue_cnt });
            if (doc?.issue_cnt) {
              let sel_country_ary = countries_json?.filter(itm => itm.value === doc?.issue_cnt);
              if (sel_country_ary && sel_country_ary.length > 0) {
                dispatch({ type: "sel_issue_cnt", payload: sel_country_ary[0] });
              }
            }
            if (doc?.issue_date) {
              dispatch({ type: "issue_date_day", payload: doc?.issue_date?.slice(0, 2) });
              let sel_iss_day_ary = dumy_day_ary?.filter(itm => itm.value === doc?.issue_date?.slice(0, 2));
              if (sel_iss_day_ary && sel_iss_day_ary.length > 0) {
                dispatch({ type: "sel_issue_date_day", payload: sel_iss_day_ary[0] });
              }
              dispatch({ type: "issue_date_month", payload: doc?.issue_date?.slice(3, 5) });
              let sel_iss_month_ary = months_json?.filter(itm => itm.value === doc?.issue_date?.slice(3, 5));
              if (sel_iss_month_ary && sel_iss_month_ary.length > 0) {
                dispatch({ type: "sel_issue_date_month", payload: sel_iss_month_ary[0] });
              }
              dispatch({ type: "issue_date_year", payload: doc?.issue_date?.slice(6, 10) });
              let yr_data = doc?.issue_date?.slice(6, 10) + "";
              let sel_iss_year_ary = dumy_year_ary?.filter(itm => itm.value && itm.value + "" === yr_data);
              if (sel_iss_year_ary && sel_iss_year_ary.length > 0) {
                dispatch({ type: "sel_issue_date_year", payload: sel_iss_year_ary[0] });
              }
            }
            if (doc?.exp_date) {
              dispatch({ type: "exp_date_day", payload: doc?.exp_date?.slice(0, 2) });
              let sel_exp_day_ary = dumy_day_ary?.filter(itm => itm.value === doc?.exp_date?.slice(0, 2));
              if (sel_exp_day_ary && sel_exp_day_ary.length > 0) {
                dispatch({ type: "sel_exp_date_day", payload: sel_exp_day_ary[0] });
              }
              dispatch({ type: "exp_date_month", payload: doc?.exp_date?.slice(3, 5) });
              let sel_exp_month_ary = months_json?.filter(itm => itm.value === doc?.exp_date?.slice(3, 5));
              if (sel_exp_month_ary && sel_exp_month_ary.length > 0) {
                dispatch({ type: "sel_exp_date_month", payload: sel_exp_month_ary[0] });
              }
              dispatch({ type: "exp_date_year", payload: doc?.exp_date?.slice(6, 10) });
              let yr_data = doc?.exp_date?.slice(6, 10) + "";
              let dumy_exp_year_ary = expiry_years_json;
              if (isArrayEmpty(dumy_exp_year_ary)) {
                dumy_exp_year_ary = await getExpiryYearsJson();
              }
              let sel_iss_year_ary = dumy_exp_year_ary?.filter(itm => itm.value && itm.value + "" === yr_data);
              if (sel_iss_year_ary && sel_iss_year_ary.length > 0) {
                dispatch({ type: "sel_exp_date_year", payload: sel_iss_year_ary[0] });
              }
            }
          }
          if (profile_info?.freq_fly?.length === 0) {
            let ffp = profile_info?.freq_fly[0];
            state.frequentFlyerData[0].airline_name = ffp?.airline_name;
            state.frequentFlyerData[0].ffp = ffp?.ffp;
            state.frequentFlyerData[0].ffn = ffp?.ffn;
          } else if (profile_info?.freq_fly?.length > 0) {
            profile_info?.freq_fly.forEach((imm) => {
              let fil = airlies_json?.filter(im => imm.airline_name && im.value === imm.airline_name);
              if (isArrayNotEmpty(fil)) {
                imm.sel_airline_name = fil[0];
              }
            })
            dispatch({
              type: "frequentFlyerData",
              payload: profile_info?.freq_fly,
            });
          }
        } else {
          setProfile(null);
        }
      }).catch(error => {
        console.log("fetch profile data", error)
        setProfile(null);
      });
    } catch (error) {
      console.log("catch fetch profile data", error)
    }
  }

  const handleChange = async (e) => {
    let errors = state?.fieldError || [];
    const { id, value } = e.target;
    if(id === "ph_num" && value !== "" && value !== null){
      const re = /^[0-9\b]+$/;
      if(!re.test(value)){
        errors = errors?.filter((itm) => itm !== "ph_num");
        errors.push("ph_only_num");
        dispatch({ type: "fieldError", payload: errors });
        return;
      }else{
        errors = errors.filter((itm) => itm !== "ph_only_num");
        dispatch({ type: "fieldError", payload: errors });
      }
    }
    dispatch({ type: id, payload: value });
    if(value === null || value === ""){
      errors = errors?.filter((itm) => itm !== "ph_only_num");
      errors.push(id);
      dispatch({ type: "fieldError", payload: errors });
    }else{
      errors = errors.filter((itm => itm !== id));
      dispatch({ type: "fieldError", payload: errors });
    }
  }

  /**
   * 
   * @param {*} type 
   */
  const updateProfieDetails = (type) => {
    setLoader(true);
    dispatch({ type: "fieldError", payload: [] });
    if (type === null || type === "") {
      return;
    }
    let errors = [];
    try {
      let updated_payload = {
        id: state.profile_id || null,
        usr_uniq_id: state.usr_uniq_id
      }
      if (type === "profile") {
        if (!isNotNull(state.title) || (state.title !== null && state.title === "Select")) errors.push("title")
        if (!isNotNull(state.firstName)) errors.push("firstName")
        if (!isNotNull(state.lastName)) errors.push("lastName")
        if (!isNotNull(state.dob_day) || (state.dob_day !== null && state.dob_day === "Day")) errors.push("dob_day")
        if (!isNotNull(state.dob_month) || (state.dob_day !== null && state.dob_day === "Month")) errors.push("dob_month")
        if (!isNotNull(state.dob_year) || (state.dob_day !== null && state.dob_day === "Year")) errors.push("dob_year")
        if (!isNotNull(state.gender) || (state.gender !== null && state.gender === "Select")) errors.push("gender")
        if (!isNotNull(state.maritalStatus) || (state.maritalStatus !== null && state.maritalStatus === "Select")) errors.push("maritalStatus")
        if (!isNotNull(state.nationality) || (state.nationality !== null && state.nationality === "Select")) errors.push("nationality");
        dispatch({ type: "fieldError", payload: errors });
        if (isArrayNotEmpty(errors)) {
          setLoader(false);
          return
        }
        updated_payload = {
          ...updated_payload,
          title: state.title,
          fname: state.firstName,
          lname: state.lastName,
          dob: `${state.dob_day}-${state.dob_month}-${state.dob_year}`,
          gender: state.gender,
          marital_status: state.maritalStatus,
          nationality: state.nationality,
          is_active: state.is_active
        }
      } else if (type === "contact") {
        if (!isNotNull(state.ph_code) || (state.ph_code !== null && state.ph_code === "Select")) errors.push("ph_code")
        if (!isNotNull(state.ph_num)) errors.push("ph_num");
        dispatch({ type: "fieldError", payload: errors });
        if (isArrayNotEmpty(errors)) {
          setLoader(false);
          return
        }
        updated_payload = {
          ...updated_payload,
          ph_code: state.ph_code,
          ph_num: state.ph_num
        }
      } else if (type === "doc") {
        if (!isNotNull(state.passport_no)) errors.push("passport_no")
        if (!isNotNull(state.issue_cnt) || (state.issue_cnt !== null && state.issue_cnt === "Select")) errors.push("issue_cnt");
        if (!isNotNull(state.issue_date_day) || (state.issue_date_day !== null && state.issue_date_day === "Day")) errors.push("issue_date_day")
        if (!isNotNull(state.issue_date_month) || (state.issue_date_month !== null && state.issue_date_month === "Month")) errors.push("issue_date_month")
        if (!isNotNull(state.issue_date_year) || (state.issue_date_year !== null && state.issue_date_year === "Year")) errors.push("issue_date_year")
        if (!isNotNull(state.exp_date_day) || (state.exp_date_day !== null && state.exp_date_day === "Day")) errors.push("exp_date_day")
        if (!isNotNull(state.exp_date_month) || (state.exp_date_month !== null && state.exp_date_month === "Month")) errors.push("exp_date_month")
        if (!isNotNull(state.exp_date_year) || (state.exp_date_year !== null && state.exp_date_year === "Year")) errors.push("exp_date_year")
        dispatch({ type: "fieldError", payload: errors });
        if (isArrayNotEmpty(errors)) {
          setLoader(false);
          return
        }
        updated_payload = {
          ...updated_payload,
          docs: [
            {
              id: state.doc_id,
              doc_type: state.doc_type || "passport",
              passport_no: state.passport_no,
              issue_cnt: state.issue_cnt,
              issue_date: `${state.issue_date_day}-${state.issue_date_month}-${state.issue_date_year}`,
              exp_date: `${state.exp_date_day}-${state.exp_date_month}-${state.exp_date_year}`,
            }
          ]
        }
      } else if (type = "ffy") {
        if (state.frequentFlyerData && state.frequentFlyerData.length > 0) {
          for (let inx in state.frequentFlyerData) {
            let ff = state.frequentFlyerData[inx];
            if (!isNotNull(ff.airline_name) || (ff.airline_name !== null && ff.airline_name === "Select")) errors.push("fre_airline_name_" + inx);
            if (!isNotNull(ff.ffp)) errors.push("fre_ffp_" + inx)
            if (!isNotNull(ff.ffn)) errors.push("fre_ffn_" + inx)
          }
          dispatch({ type: "fieldError", payload: errors });
          if (isArrayNotEmpty(errors)) {
            setLoader(false);
            return
          }
          updated_payload = {
            ...updated_payload,
            freq_fly: state.frequentFlyerData
          }
        } else {
          return
        }
      }
      if (isArrayEmpty(errors)) {
        handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/user/profile/update`, updated_payload).then(function (result) {
          if (result?.data?.suc) {
            if (type === "profile") {
              dispatch({
                type: "display_name", payload: `${state?.title || ""} 
              ${state?.firstName?.substring(0, 1).toUpperCase()}${state?.firstName?.substring(1, state?.firstName?.length).toLowerCase()} 
              ${state?.lastName?.substring(0, 1).toUpperCase()}${state?.lastName?.substring(1, state?.lastName?.length).toLowerCase()} 
             `});
              if (state.firstName && state.usr_uniq_id) {
                localStorage.setItem("logged", JSON.stringify({
                  fname: state.firstName,
                  unique_id: state.usr_uniq_id,
                  profile_pic: state.profile_pic,
                  display_name: `${state?.title || ""} 
                  ${state?.firstName?.substring(0, 1).toUpperCase()}${state?.firstName?.substring(1, state?.firstName?.length).toLowerCase()} 
                  ${state?.lastName?.substring(0, 1).toUpperCase()}${state?.lastName?.substring(1, state?.lastName?.length).toLowerCase()} 
                 `
                }))
              }
            }
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiVarient", payload: "success" });
          } else {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiVarient", payload: "error" });
          }
          dispatch({
            type: "notiMessage",
            payload: "Updated successfully"
          });
          hidemessage();
          setLoader(false);

          setProf_edit(false);
          setDoc_edit(false);
          setContact_edit(false);
          setFFP_edit(false);
          window.scrollTo(0, 0);
        }).catch(error => {
          console.log("error updateProfieDetails data", error);
          setLoader(false);
        });
      } else {
        setLoader(false);
      }
    } catch (error) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiVarient", payload: "error" });
      dispatch({
        type: "notiMessage",
        payload: "Error while updating the profile details"
      });
      hidemessage();
      console.log("catch updateProfieDetails data", error);
      setLoader(false);
    }
  }

  const getMonth = (val) => {
    if (val) {
      let fil = months_json.filter(itm => itm.value === val);
      if (fil && fil.length > 0) {
        return fil[0].label;
      }
      return val
    }
    return val;
  }


  const getCountryName = (val) => {
    if (val) {
      let fil = countries_json.filter(itm => itm.value === val);
      if (fil && fil.length > 0) {
        return fil[0].label;
      }
      return val
    }
    return val;
  }

  const handleFFPAddRow = () => {
    dispatch({ type: "ADD_FFP_ROW" });
  };

  const handleFFPDeleteRow = (index, id) => {
    if (id !== undefined && id !== null && id !== "") {
      setLoader(true);
      handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/user/profile/delete`, {
        type: "ffp",
        id: id
      }).then(function (result) {
        if (result?.data?.suc) {
          dispatch({ type: "DELETE_FFP_ROW_ID", id });
        }
        setLoader(false);
      }).catch(error => {
        setLoader(false);
        console.log("handleFFPDeleteRow profile data", error)
      });
    } else {
      dispatch({ type: "DELETE_FFP_ROW_INDEX", index });
    }
  }

  const getDaysArray_1 = async () => {
    let array_data = Array.from(
      {
        length: getDaysInMonth(
          state.issue_date_day,
          state.issue_date_year || "1934"
        ),
      },
      (_, i) => i + 1
    );

    let day_array = array_data.map((day) => {
      return {
        value: day.toString().length === 1 ? "0" + day.toString() : day.toString(),
        label: day.toString().length === 1 ? "0" + day.toString() : day.toString()
      }
    })
    setDaysJson(day_array);
    return day_array;
  }

  const getDaysArray = async () => {
    let day_array = days_raw_json;
    setDaysJson(day_array);
    return day_array;
  }
  return (
    <>
      <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
        <img src={Loader} alt='' />
      </div>
      {state.notiMessageShow &&
        <Alert className="notification" style={{ position: "fixed", width: "100%" }} variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <div className="fixedHeader">
        <Header page="fixedHeader" flow="RESULT" />
      </div>
      <div className="myaccountStatus"></div>
      <div className="myprofilePage">
        <section>
          <div className="mainContainer">
            <Row>
              <Col>
                <div className="d-flex topPage">
                  <Sidebar active="Profile" />
                  <div className="myprofileSideWrap">
                    <div className="myaccCardWrap">
                      <div className="myaccHeaderWrap"><span>Personal Information</span> {!prof_edit ? <span className='editBtn' onClick={() => setProf_edit((pre) => !pre)}> Edit <i className="icon icon-Edit1"></i></span> : ""}</div>
                      <div className="myaccBodyWrap">
                        {prof_edit ?
                          <div className="cardItemWrap">
                            <div className="cardLeftItem">
                              <div className="cardLeftItemTitle">Title</div>
                            </div>
                            <div className="cardRightItem">
                              <>
                                <Select
                                // defaultMenuIsOpen="true"
                                  value={state?.sel_title}
                                  onChange={(data) => {
                                    dispatch({ type: "title", payload: data?.value })
                                    dispatch({ type: "sel_title", payload: data })
                                  }}
                                  options={state?.title_array}
                                  placeholder="Select"
                                  className='formSelect mediumSelect'
                                />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("title") && (
                                  <div className="error">Title must be provided</div>
                                )}
                              </>
                            </div>
                          </div> : ""
                        }
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">{prof_edit ? "First Name" : "Name"}</div>
                          </div>
                          <div className="cardRightItem">
                            {prof_edit ?
                              <>
                                <Form.Control type="text" id="firstName" value={state.firstName} placeholder="" onChange={(e) => handleChange(e)} className='redBorder_1' />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("firstName") && (
                                  <div className="error">First name must be provided</div>
                                )}
                              </>
                              : `${state.title || ""} ${state?.firstName?.substring(0, 1).toUpperCase()}${state?.firstName?.substring(1, state?.firstName?.length).toLowerCase()} ${state?.lastName?.substring(0, 1).toUpperCase()}${state?.lastName?.substring(1, state?.lastName?.length).toLowerCase()} 
             `
                            }
                          </div>
                        </div>
                        {prof_edit &&
                          <div className="cardItemWrap">
                            <div className="cardLeftItem">
                              <div className="cardLeftItemTitle">Last Name</div>
                            </div>
                            <div className="cardRightItem">
                              <>
                                <Form.Control type="text" id="lastName" value={state.lastName} placeholder="" onChange={(e) => handleChange(e)} />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("lastName") && (
                                  <div className="error">Last name must be provided</div>
                                )}
                              </>
                            </div>
                          </div>
                        }
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Date of birth</div>
                          </div>
                          <div className="cardRightItem">
                            {prof_edit ?
                              <>
                                <InputGroup>
                                  <Select
                                    value={state?.sel_dob_month}
                                    onChange={(data) => {
                                      if(data?.value && state?.dob_day && (
                                      (data?.value === "02" && (state?.dob_day === "30" || state?.dob_day === "31"))
                                      || (["04","06","09","11"].includes(data?.value) && state?.dob_day === "31")
                                      )){
                                        dispatch({ type: "dob_day", payload: "" })
                                        dispatch({ type: "sel_dob_day", payload: "" })
                                      }
                                      dispatch({ type: "dob_month", payload: data?.value })
                                      dispatch({ type: "sel_dob_month", payload: data })
                                    }}
                                    options={months_json}
                                    placeholder="Month"
                                    className='formSelect monthSelect'
                                  />
                                  <Select
                                    value={state?.sel_dob_day}
                                    onChange={(data) => {
                                      dispatch({ type: "dob_day", payload: data?.value })
                                      dispatch({ type: "sel_dob_day", payload: data })
                                    }}
                                    options={["04", "06", "09", " 11"].includes(state?.dob_month) ? days_raw_json?.filter(itm => itm.value !== "31") :
                                      ["02"].includes(state?.dob_month) ? days_raw_json.filter(itm => (itm.value !== "31" && itm.value !== "30")) :days_json}
                                    placeholder="Day"
                                    className='formSelect daySelect'
                                  />
                                  <Select
                                    value={state?.sel_dob_year}
                                    onChange={(data) => {
                                      dispatch({ type: "dob_year", payload: data?.value })
                                      dispatch({ type: "sel_dob_year", payload: data })
                                    }}
                                    options={years_json}
                                    placeholder="Year"
                                    className='formSelect yearSelect'
                                  />
                                </InputGroup>
                                {isArrayNotEmpty(state.fieldError) && (state.fieldError?.includes("dob_day") ||
                                  state.fieldError?.includes("dob_month") || state.fieldError?.includes("dob_year")) && (
                                    <div className="error">Date of birth must be provided</div>
                                  )}
                              </> :
                              state.dob_month ?
                                `${getMonth(state.dob_month)}-${state.dob_day}-${state.dob_year}` : <div className="addMore" onClick={() => setProf_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Gender</div>
                          </div>
                          <div className="cardRightItem">
                            {prof_edit ?
                              <>
                                <Select
                                // defaultMenuIsOpen="true"
                                  value={state?.sel_gender}
                                  onChange={(data) => {
                                    dispatch({ type: "gender", payload: data?.value })
                                    dispatch({ type: "sel_gender", payload: data })
                                  }}
                                  options={state?.gender_arry}
                                  placeholder="Select"
                                  className='formSelect mediumSelect'
                                />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("gender") && (
                                  <div className="error">Gender must be provided</div>
                                )}
                              </> :
                              state.gender ? (state.gender === "M" ? "Male" : "Female") : <div className="addMore" onClick={() => setProf_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Marital Status</div>
                          </div>
                          <div className="cardRightItem">
                            {prof_edit ?
                              <>
                                <Select
                                  value={state?.sel_marital_status}
                                  id="maritalStatus"
                                  onChange={(data) => {
                                    dispatch({ type: "maritalStatus", payload: data?.value })
                                    dispatch({ type: "sel_marital_status", payload: data })
                                  }}
                                  options={state?.marital_arry}
                                  placeholder="Select"
                                  className='formSelect mediumSelect'
                                />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("maritalStatus") && (
                                  <div className="error">Marital status must be provided</div>
                                )}
                              </> :
                              state.maritalStatus ? state.maritalStatus : <div className="addMore" onClick={() => setProf_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        <div className="cardItemWrap lastChild">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Nationality</div>
                          </div>
                          <div className="cardRightItem">
                            {prof_edit ?
                              <>
                                <Select
                                  value={state?.sel_nationality}
                                  id="nationality"
                                  onChange={(data) => {
                                    dispatch({ type: "nationality", payload: data?.value })
                                    dispatch({ type: "sel_nationality", payload: data })
                                  }}
                                  options={countries_json}
                                  placeholder="Select"
                                  className='formSelect mediumSelect'
                                />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("nationality") && (
                                  <div className="error">Nationality must be provided</div>
                                )}
                              </> :
                              state.nationality ? `${getCountryName(state.nationality)}` : <div className="addMore" onClick={() => setProf_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        {prof_edit &&
                          <div className="cardBottomWrap">
                            <div className="btngrpWrap">
                              <Button variant="outline-primary" className="cardCancelBtn" onClick={() => {
                                setProf_edit((pre) => !pre);
                                setUpdateProfile(true);
                              }}>Cancel</Button>
                              <Button variant="primary" className="cardSaveBtn" onClick={() => updateProfieDetails("profile")}>Update</Button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="myaccCardWrap">
                      <div className="myaccHeaderWrap"><span>Contact Details</span> {!contact_edit ? <span className="editBtn" onClick={() => setContact_edit((pre) => !pre)}> Edit <i className="icon icon-Edit1"></i></span> : ""}</div>
                      <div className="myaccBodyWrap">
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Email address</div>
                          </div>
                          <div className="cardRightItem">
                            <div className="cardRightItemTitle" >{state.email} <span className='verifyWrap'><i class="icon icon-tick"></i>Verified</span></div>
                          </div>
                        </div>
                        <div className="cardItemWrap lastChild">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Mobile Number</div>
                          </div>
                          <div className="cardRightItem mobileNumWrap">
                            {contact_edit ?
                              <div className="cardRightItemTitle position-relative">
                                <InputGroup>
                                  <>
                                    <Select
                                      // defaultMenuIsOpen="true"
                                      options={std_codes_json}
                                      value={state.sel_ph_code}
                                      onChange={(data) => {
                                        dispatch({ type: "ph_code", payload: data?.value })
                                        dispatch({ type: "sel_ph_code", payload: data })
                                      }}
                                      placeholder="Select country"
                                      className='countrySelect'
                                    />

                                  </>
                                  <>
                                    <Form.Control type="text" id="ph_num" value={state.ph_num} placeholder="Mobile number" onChange={(e) => handleChange(e)} />

                                    
                                  </>
                                </InputGroup>
                                <div className="d-flex">
                                    {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("ph_code") && (
                                      <div className="error coutryError">Country code must be provided</div>
                                    )}
                                    {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("ph_num") && (
                                      <div className="error mobileError">Mobile number must be provided</div>
                                    )}
                                    {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("ph_only_num") && (
                                      <div className="error mobileError">Mobile number must be only numbers</div>
                                    )}
                                </div>
                                
                              </div>
                              :
                              <>
                                {((state?.ph_code !== "" && state.ph_code !== null) || (state?.ph_num !== "" && state.ph_num !== null)) ?
                                  <div className="cardRightItemTitle">+{state.ph_code || ""}-{state.ph_num || ""}</div> :
                                  <div className="addMore" onClick={() => setContact_edit((pre) => !pre)}>+ Add</div>
                                }
                              </>
                            }
                          </div>
                        </div>
                        {contact_edit &&
                          <div className="cardBottomWrap">
                            <div className="btngrpWrap">
                              <Button variant="outline-primary" className="cardCancelBtn" onClick={() => {
                                setContact_edit((pre) => !pre);
                                setUpdateProfile(true);
                              }}>Cancel</Button>
                              <Button variant="primary" className="cardSaveBtn" onClick={() => updateProfieDetails("contact")}>Update</Button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="myaccCardWrap">
                      <div className="myaccHeaderWrap"><span>Passport Details</span> {!doc_edit ? <span className="editBtn" onClick={() => setDoc_edit((pre) => !pre)}> Edit <i className="icon icon-Edit1"></i></span> : ""}</div>
                      <div className="myaccBodyWrap">
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Passport Number</div>
                          </div>
                          <div className="cardRightItem">
                            {doc_edit ?
                              <>
                                <Form.Control type="text" id="passport_no" value={state?.passport_no} onChange={(e) => handleChange(e)} />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("passport_no") && (
                                  <div className="error">Passport number must be provided</div>
                                )}
                              </>
                              : state.passport_no ? state.passport_no : <div className="addMore" onClick={() => setDoc_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Issuing Country</div>
                          </div>
                          <div className="cardRightItem">
                            {doc_edit ?
                              <>
                                <Select
                                  value={state?.sel_issue_cnt}
                                  onChange={(data) => {
                                    dispatch({ type: "issue_cnt", payload: data?.value })
                                    dispatch({ type: "sel_issue_cnt", payload: data })
                                  }}
                                  options={countries_json}
                                  placeholder="Select"
                                  className='formSelect mediumSelect'
                                />
                                {isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("issue_cnt") && (
                                  <div className="error">Issuing country must be provided</div>
                                )}
                              </>
                              : state.issue_cnt ? `${getCountryName(state.issue_cnt)}` : <div className="addMore" onClick={() => setDoc_edit((pre) => !pre)}>+ Add</div>
                            }
                          </div>
                        </div>
                        <div className="cardItemWrap">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Issuing Date</div>
                          </div>
                          <div className="cardRightItem">
                            {doc_edit ?
                              <>
                                <InputGroup>
                                  <Select
                                    value={state?.sel_issue_date_month}
                                    onChange={(data) => {
                                      if (data?.value && state?.issue_date_day && (
                                        (data?.value === "02" && (state?.issue_date_day === "30" || state?.issue_date_day === "31"))
                                        || (["04", "06", "09", "11"].includes(data?.value) && state?.issue_date_day === "31")
                                      )) {
                                        dispatch({ type: "issue_date_day", payload: "" })
                                        dispatch({ type: "sel_issue_date_day", payload: "" })
                                      }
                                      dispatch({ type: "issue_date_month", payload: data?.value })
                                      dispatch({ type: "sel_issue_date_month", payload: data })
                                    }}
                                    options={months_json}
                                    placeholder="Month"
                                    className='formSelect monthSelect'
                                  />
                                  <Select
                                    value={state?.sel_issue_date_day}
                                    onChange={(data) => {
                                      dispatch({ type: "issue_date_day", payload: data?.value })
                                      dispatch({ type: "sel_issue_date_day", payload: data })
                                    }}
                                    options={["04", "06", "09", " 11"].includes(state?.issue_date_month) ? days_raw_json?.filter(itm => itm.value !== "31") :
                                      ["02"].includes(state?.issue_date_month) ? days_raw_json.filter(itm => (itm.value !== "31" && itm.value !== "30")) : days_json}
                                    placeholder="Day"
                                    className='formSelect daySelect'
                                  />
                                  <Select
                                    value={state?.sel_issue_date_year}
                                    onChange={(data) => {
                                      dispatch({ type: "issue_date_year", payload: data?.value })
                                      dispatch({ type: "sel_issue_date_year", payload: data })
                                    }}
                                    options={years_json}
                                    placeholder="Year"
                                    className='formSelect yearSelect'
                                  />
                                </InputGroup>
                                {isArrayNotEmpty(state.fieldError) && (state.fieldError?.includes("issue_date_day") ||
                                  state.fieldError?.includes("issue_date_month") || state.fieldError?.includes("issue_date_year")) && (
                                    <div className="error">Issuing date must be provided</div>
                                  )}
                              </>
                              : state.issue_date_month ?
                                `${getMonth(state.issue_date_month)}-${state.issue_date_day}-${state.issue_date_year}` : <div className="addMore" onClick={() => setDoc_edit((pre) => !pre)}>+ Add</div>

                            }
                          </div>
                        </div>
                        <div className="cardItemWrap lastChild">
                          <div className="cardLeftItem">
                            <div className="cardLeftItemTitle">Expiry Date</div>
                          </div>
                          <div className="cardRightItem">
                            {doc_edit ?
                              <>
                                <InputGroup>
                                  <Select
                                    value={state?.sel_exp_date_month}
                                    onChange={(data) => {
                                      if (data?.value && state?.exp_date_day && (
                                        (data?.value === "02" && (state?.exp_date_day === "30" || state?.exp_date_day === "31"))
                                        || (["04", "06", "09", "11"].includes(data?.value) && state?.exp_date_day === "31")
                                      )) {
                                        dispatch({ type: "exp_date_day", payload: "" })
                                        dispatch({ type: "sel_exp_date_day", payload: data })
                                      }
                                      dispatch({ type: "exp_date_month", payload: data?.value })
                                      dispatch({ type: "sel_exp_date_month", payload: data })
                                    }}
                                    options={months_json}
                                    placeholder="Month"
                                    className='formSelect monthSelect'
                                  />
                                  <Select
                                    value={state?.sel_exp_date_day}
                                    onChange={(data) => {
                                      dispatch({ type: "exp_date_day", payload: data?.value })
                                      dispatch({ type: "sel_exp_date_day", payload: data })
                                    }}
                                    options={["04", "06", "09", " 11"].includes(state?.exp_date_month) ? days_raw_json?.filter(itm => itm.value !== "31") :
                                      ["02"].includes(state?.exp_date_month) ? days_raw_json.filter(itm => (itm.value !== "31" && itm.value !== "30")) : days_json}
                                    placeholder="Day"
                                    className='formSelect daySelect'
                                  />
                                  <Select
                                    value={state?.sel_exp_date_year}
                                    onChange={(data) => {
                                      dispatch({ type: "exp_date_year", payload: data?.value })
                                      dispatch({ type: "sel_exp_date_year", payload: data })
                                    }}
                                    options={expiry_years_json}
                                    placeholder="Year"
                                    className='formSelect yearSelect'
                                  />
                                </InputGroup>
                                {isArrayNotEmpty(state.fieldError) && (state.fieldError?.includes("exp_date_day") ||
                                  state.fieldError?.includes("exp_date_month") || state.fieldError?.includes("exp_date_year")) && (
                                    <div className="error">Expiry date must be provided</div>
                                  )}
                              </>
                              : state.exp_date_month ?
                                `${getMonth(state.exp_date_month)}-${state.exp_date_day}-${state.exp_date_year}` : <div className="addMore" onClick={() => setDoc_edit((pre) => !pre)}>+ Add</div>

                            }
                          </div>
                        </div>
                        {doc_edit &&
                          <div className="cardBottomWrap">
                            <div className="btngrpWrap">
                              <Button variant="outline-primary" className="cardCancelBtn" onClick={() => {
                                setDoc_edit((pre) => !pre);
                                setUpdateProfile(true);
                              }}>Cancel</Button>
                              <Button variant="primary" className="cardSaveBtn" onClick={() => updateProfieDetails("doc")}>Update</Button>
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                    <div className="myaccCardWrap frequentFlyerWrap">
                      <div className="myaccHeaderWrap"><span>Frequent Flyer</span> {!ffp_edit ? <span className="editBtn" onClick={() => setFFP_edit((pre) => !pre)}> Edit <i className="icon icon-Edit1"></i></span> : ""}</div>
                      <div className="myaccBodyWrap">
                        {state.frequentFlyerData && state.frequentFlyerData.length > 0 &&
                          state.frequentFlyerData.map((itm, index) => {
                            return (
                              <div className="cardItemWrap">
                                <div className='cardRightItem'>
                                  <div className="cardLeftItemTitle">Airline Name</div>
                                  <Select
                                    value={itm.sel_airline_name}
                                    isDisabled={!ffp_edit}
                                    onChange={(data) => {
                                      dispatch({
                                        type: "UPDATE_FFP_DATA",
                                        index,
                                        field: "airline_name",
                                        value: data.value,
                                      })
                                    }}
                                    options={airlies_json}
                                    placeholder="Select"
                                    className=''
                                  />
                                  {ffp_edit && isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("fre_airline_name_" + index) && (
                                    <div className="error">Airline name must be provided</div>
                                  )}
                                </div>
                                <div className='cardRightItem'>
                                  <div className="cardLeftItemTitle">Frequent Flyer Programme</div>
                                  <Form.Control type="text" value={itm.ffp} placeholder="" disabled={!ffp_edit} onChange={(e) =>
                                    dispatch({
                                      type: "UPDATE_FFP_DATA",
                                      index,
                                      field: "ffp",
                                      value: e.target.value,
                                    })
                                  } />
                                  {ffp_edit && isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("fre_ffp_" + index) && (
                                    <div className="error">FFP must be provided</div>
                                  )}
                                </div>
                                <div className='cardRightItem'>
                                  <div className="cardLeftItemTitle">Frequent Flyer Number</div>
                                  <Form.Control type="text" value={itm.ffn} placeholder="" disabled={!ffp_edit} onChange={(e) =>
                                    dispatch({
                                      type: "UPDATE_FFP_DATA",
                                      index,
                                      field: "ffn",
                                      value: e.target.value,
                                    })
                                  } />
                                  {ffp_edit && isArrayNotEmpty(state.fieldError) && state.fieldError?.includes("fre_ffn_" + index) && (
                                    <div className="error">FFN must be provided</div>
                                  )}
                                </div>
                                <div className='closeMoreWrap'>
                                  {ffp_edit && state.frequentFlyerData.length > 1 && (
                                    <i className="icon icon-Close" onClick={() => handleFFPDeleteRow(index, itm.id)}></i>
                                  )}
                                  {ffp_edit && state.frequentFlyerData.length < 20 && state.frequentFlyerData.length - 1 === index &&
                                    <Button variant="primary" className="addMoreBtn" onClick={handleFFPAddRow} disabled={!ffp_edit}>+</Button>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                        {ffp_edit &&
                          <div className="cardBottomWrap">
                            <div className="btngrpWrap">
                              <Button variant="outline-primary" className="cardCancelBtn" onClick={() => {
                                setFFP_edit((pre) => !pre);
                                setUpdateProfile(true);
                              }}>Cancel</Button>
                              <Button variant="primary" className="cardSaveBtn" onClick={() => updateProfieDetails("ffy")}>Update</Button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <MyAccFooter />
    </>
  )
}

export default MyProfile
