import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PayMethod1 from "../../assets/images/home/footer/card.png";
import PayMethod2 from "../../assets/images/home/footer/visa.png";
import PayMethod3 from "../../assets/images/home/footer/express.png";
import PayMethod4 from "../../assets/images/home/footer/discover.png";
import PayMethod5 from "../../assets/images/home/footer/jcb.png";
import Khamisani from "../../assets/images/home/footer/khamisani.svg";
import Iata from "../../assets/images/home/footer/iata.svg";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
      <section className='footerWrap'>
        <div className="mainContainer">
          <Row>
            <Col>
                <div className="contactUsWrap">
                  <h6 className='footerTitle mb-20'>CONTACT US</h6>
                  <ul>
                    <li><i className="icon icon-call"></i>+971 585678610</li>
                    <li><i className="icon icon-email"></i>cs@kwickfly.com</li>
                  </ul>
                  <div className="followWrap mt-50">
                  <h6 className='footerTitle mb-20'>Follow us</h6>
                  <ul className='d-flex'>
                    <li className='mb-0'><i className="icon icon-twitter"></i></li>
                    <li className='mb-0'><i className="icon icon-facebook"></i></li>
                    <li className='mb-0'><i className="icon icon-linkedin"></i></li>
                  </ul>
                </div>
                  <h6 className='footerTitle mb-20 mt-30'>Address</h6>
                  <ul>
                    <li>Business Center, Sharjah Publishing City Freezone, Sharjah, UAE</li>
                  </ul>
                </div>
                
                
            </Col>
            <Col>
                <h6 className='footerTitle mb-20'>QUICK LINKS</h6>
                <ul className='underline-effect'> 
                  <li><a href="">Home</a></li>
                  <li><Link to="/about" target="_blank">About Us</Link></li>
                  <li><a href="">Terms & Conditions</a></li>
                  <li><a href="">FAQ</a></li>
                  <li><a href="">Kwickfly Services</a></li>
                </ul>
                <div className="payMethodWrap mt-50">
                  <h6 className='footerTitle mb-20'>Payment Methods</h6>
                  <ul className='d-flex'>
                    <li className='mb-0'><img src={PayMethod1} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod2} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod3} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod4} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                    <li className='mb-0'><img src={PayMethod5} alt="pay-method-image" className='img-fluid payMethodImage' /></li>
                  </ul>
                </div>
            </Col>
            <Col>
                <h6 className='footerTitle mb-20'>FLIGHTS</h6>
                <ul className='underline-effect'>
                  <li><a href="">Emirates Airlines</a></li>
                  <li><a href="">Etihad Airlines</a></li>
                  <li><a href="">FlyDubai</a></li>
                  <li><a href="">Qatar Airways</a></li>
                  <li><a href="">Turkish Airines</a></li>
                </ul>
                
            </Col>
            <Col>
                <h6 className='footerTitle mb-20'>POPULAR ROUTES</h6>
                <ul className='underline-effect'>
                  <li><a href="">Book Hotels To Muscat</a></li>
                  <li><a href="">Book Flights To Beirut</a></li>
                  <li><a href="">Book Flights To Muscat</a></li>
                  <li><a href="">Book Flights to London</a></li>
                </ul>
            </Col>
          </Row>
        </div>
      </section>
      <section className='bottomFooterWrap'>
        <div className="mainContainer">
          <Row>
            <Col md={2}>
                <img src={Khamisani} alt="khamisani-logo" className='img-fluid khamisaniLogoImage' />
            </Col>
            <Col md={8} className='d-flex justify-content-center align-items-center'>
                <span className='allRightsTitle'>2024 Kwickfly. All Rights Reserved</span>
            </Col>
            <Col md={2} className='d-flex align-items-center'>
                <span><img src={Iata} alt="pay-method-image" className='img-fluid' /></span>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default Footer
