import axios from "axios"
/**
 * 
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const handlePostApi = async (url, data) => {
    try {
        const response = await axios.post(`${url}`, data);
        return response;
    } catch (err) {
        console.log(`${url} Error`, err)
    }
}

/**
 * 
 * @param {*} url 
 * @param {*} extention 
 * @returns 
 */
export const handleGetApi = async (url, config) => {
    let response=null;
    try {
        if(config){
            response = await axios.get(url, config);
        }else{
            response = await axios.get(url);
        }
    } catch (err) {
        console.log(`${url} Error`, err)
    }
    return response;
}