import React, { useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Provider } from 'react-redux';
import { store } from "./toolkit/Store";
import Routing from "./router/Routing";
export const Context = React.createContext();

function App() {
  const [context, setContext] = useState({ex_rates:"", userCur:""})
  return (  
    <>
      <Provider store={store}>
        <Context.Provider value={[context, setContext]}>
          <Routing />
        </Context.Provider>
      </Provider>
    </>
  );
}

export default App;
