import React from 'react';
import Error from "../../assets/images/home/other/error.svg";

function ErrorPage() {
  return (
    <>
        <section className='errorPage'>
          <div className="mainContainer">
            <div className='d-flex justify-content-center align-items-center'>
              <div className='text-center'>
                <img src={Error} alt="errorIcon" />
                <div className='wentWrong'>Oops! Something Went Wrong!</div>
                <div className='refreshPage'>Don't worry, Refresh the page to get back on track!</div>
                <div className='refereshBtn'>Refresh Page <i class="icon icon-Refresh"></i></div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default ErrorPage
