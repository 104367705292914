import React, { useState, useEffect } from 'react';
import {
    useLocation,
} from "react-router-dom";
import { handlePostApi } from '../../../../utilities/Axios';
import Loader from '../../../../assets/Gifs/Double Ring-1s-200px.gif';
import GetoNWhatshapp from '../../../../assets/images/review/Watsapp-update.png';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from 'react-bootstrap/Accordion';
const HOTEL_CONTENT_URL = process.env.REACT_APP_HOTEL_CONTENT_URL;

/**
 * @description:Rooms header info
 * @author: Praveen Varma
 * @param {*}
 * @function Confirmation page
 * @date : 15-05-2024
 */

function ConfirmationPage(props) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [loader, setLoader] = useState(false);
    const [hotel_details, setHotelDetails] = useState();

    useEffect(() => {
        sessionStorage.removeItem("h_kft_id");
        sessionStorage.removeItem("h_itinerary");
        sessionStorage.removeItem("H_S_C");
        loadItineraryDetails();
    }, [])


    const loadItineraryDetails = async () => {
        setLoader(true)
        let response = await handlePostApi(`${HOTEL_CONTENT_URL}/htl/common/price/fetch`, {
            itinerary_id: params.get("itinerary_id")
        });
        if (response?.data?.suc) {
            setHotelDetails(response?.data?.res);
        } else {
            setHotelDetails("");
        }
        setLoader(false)
    }

    return (
        <>
            <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
                <img src={Loader} alt='' />
            </div>
            <div className="fixedHeader">
                <Header page="fixedHeader" />
            </div>
            <div className="completedStatus"></div>
            <div className="confirmationPage">
                <section>
                    <div className="mainContainer">
                        <Row>
                            <Col>
                                <div className="d-flex topPage">
                                    <div className="confirmationSideWrap">
                                        <div className="bookingSuccessfulWrap">
                                            <div className="bookingSuccessfulTitle">Booking Successfull <i class="icon icon-tick"></i></div>
                                            <div className="bookingSuccessfulInfo">Confirmation and E-Tickets sent to <span>khamisanidaniyal193@gmail.com</span> and <span>+971-507000826</span></div>
                                        </div>
                                        <div className='guestTableWrap'>
                                            <div className="guestTableTitle">
                                                <span className='guestBookingDetails'><i class="icon icon-hotel"></i> Booking Details</span>
                                                <span className='guestBookingId'>Booking Id : KWF123456</span>
                                            </div>
                                            <div className="guestTable p-15">
                                                <div className="guestTableHeader">
                                                    <span className='roomTypeSpan'>Room Type</span>
                                                    <span className='leadPaxSpan'>Lead Pax</span>
                                                    <span className='occupancySpan'>Occupancy</span>
                                                    <span className='mealTypeSpan'>Meal Type</span>
                                                    <span className='confirmSpan'>Confirmation No.</span>
                                                    <span className='statusSpan'>Status</span>
                                                </div>
                                                <div className="guestTableDataWrap">
                                                    <div className="guestTableData">
                                                        <span className='roomTypeSpan'>Room 1 : Deluxe King Room</span>
                                                        <span className='leadPaxSpan'>Mr John Doe</span>
                                                        <span className='occupancySpan'>2 Adults</span>
                                                        <span className='mealTypeSpan'>Room Only</span>
                                                        <span className='confirmSpan'>#123456</span>
                                                        <span className='statusSpan green'>Confirmed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="travellersTableWrap">
                                <div className="travellersTableTitle">Travellers Details</div>
                                <div className="travellersTable">
                                    <div className="travellersTableHeader">
                                        <span className='travellerNameSpan'>Traveller Name</span>
                                        <span className='sectorSpan'>Sector</span>
                                        <span className='pnrSpan'>PNR No</span>
                                        <span className='ticketSpan'>Ticket No</span>
                                        <span className='specialSpan'>Special Request</span>
                                        <span className='statusSpan'>Status</span>
                                    </div>
                                    <div className='travellersTableDataWrap'>
                                        <div className="travellersTableData">
                                            <span className='travellerNameSpan'>Mr John Doe (ADT)</span>
                                            <span className='sectorSpan'>DXB - LHR</span>
                                            <span className='pnrSpan'>K8P4Y</span>
                                            <span className='ticketSpan'>#45678905</span>
                                            <span className='specialSpan'>Islamic Meal</span>
                                            <span className='statusSpan'>Confirmed</span>   
                                        </div>
                                        <div className="travellersTableData">
                                            <span className='travellerNameSpan'>Mr John Doe (ADT)</span>
                                            <span className='sectorSpan'>DXB - LHR</span>
                                            <span className='pnrSpan'>K8P4Y</span>
                                            <span className='ticketSpan'>#45678905</span>
                                            <span className='specialSpan'>Islamic Meal</span>
                                            <span className='statusSpan'>Confirmed</span>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Inclusions
                                            </div>
                                            <div className="roomOneText">
                                                <span className='textOne'>Room 1 :</span>
                                                <span className='textValue'><i class="icon icon-Breakfast"></i> Free Breakfast</span>
                                                <span className='textValue'><i class="icon icon-wifi"></i> Free Wifi</span>
                                            </div>
                                            <div className="roomTwoText">
                                                <span className='textOne'>Room 2 :</span>
                                                <span className='textValue'><i class="icon icon-Breakfast"></i> Free Breakfast</span>
                                                <span className='textValue'><i class="icon icon-wifi"></i> Free Wifi</span>
                                            </div>
                                        </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Supplements
                                            </div>
                                            <div className="textOne">Room 1 : Supplements of mandatory tax AED 50 is payable at hotel.</div>
                                            <div className="textTwo">Room 2 : Supplements of mandatory tax AED 50 is payable at hotel.</div>
                                        </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Special Request
                                            </div>
                                            <div className="textOne">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                        </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Promotions
                                            </div>
                                            <div className="textOne">Room 1 : 20% food and beverage discount</div>
                                            <div className="textTwo">Room 2 : 20% food and beverage discount</div>
                                        </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Cancellation Policy
                                            </div>
                                            <div className="textOne">From 17-Sep-2022 12:00 AM To. 01-Oct-2022 12:00 AM cost will be of USD 0.02</div>
                                            <div className="textTwo">From 01-Oct-2022 12:00 AM To. 04-Oct-2022 02:00 PM cost will be of USD 100</div>
                                        </div>
                                        <div className="contactTableWrap">
                                            <div className="contactTableTitle">Contact Details</div>
                                            <div className="contactTable">
                                                <div className="contactTableHeader">
                                                    <span>Traveller Name</span>
                                                    <span>Phone Number</span>
                                                    <span>Email Id</span>
                                                </div>
                                                <div className="contactTableData">
                                                    <span>Mr John Doe (ADT)</span>
                                                    <span>+91 9876543210</span>
                                                    <span>john@gmail.com</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='confirmationInnerSideWrap'>
                                            <div className='confirmationInnerSideTitle'>
                                                Booking Policy
                                            </div>
                                            <ol>
                                                <li className='textOne'>W.e.f 31.03.2014, Government of Dubai is applying “Tourism Dirham” a fee ranging from AED 7-20 per room per night, which the guests availing the stay will have to pay to the hotel directly as applied before check-out.</li>
                                                <li className='textOne'>Early check out will attract full cancellation charge unless otherwise specified.</li>
                                                <li className='textOne'>Please note that the cancellation policy is based on the hotel’s time.</li>
                                            </ol>
                                            <div class="moreWrap">+ More</div>
                                        </div>
                                    </div>
                                    <div className="ticketSideWrap">
                                        <div className="eticketsWrap">
                                            <div className="ticketTitle">Ticket (s)</div>
                                            <div className="downloadETicket"><i class="icon icon-d-e-ticket"></i> <span>Download E-ticket(s)</span></div>
                                            <div className="emailETicket"><i class="icon icon-e-w-ticket"></i> <span>Email/Watsapp E-Ticket(S)</span></div>
                                        </div>
                                        <div className="reviewBookingWrap">
                                        <div class="leftSemicircle"></div>
                                        <div className="reviewBookingTitle">
                                            Review Booking
                                        </div>
                                        <div className="bookingHotelImage">
                                            <img src="https://plus.unsplash.com/premium_photo-1672097247893-4f8660247b1f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG90ZWwlMjBidWlsZGluZ3xlbnwwfHwwfHx8MA%3D%3D" alt="hotelImage" />
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="hotelName">JW Marriott Marquis Hotel Dubai</div>
                                            <div class="hotelRating">
                                                <i class="icon icon-star"></i>
                                                <i class="icon icon-star"></i>
                                                <i class="icon icon-star"></i>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center locationReviewWrap">
                                            <div class="hotelLocationWrap">
                                                <i class="icon icon-Pointer"></i> Near Burj Khalifa
                                            </div>
                                        </div>
                                        <div className="checkInOutNights">
                                            <div className="checkIn">
                                                <div className="checkInOutTitle">Check-In</div>
                                                <div className="checkInOutDate">15-May-2024</div>
                                                <div className="checkFromUntil">From 15:00</div>
                                            </div>
                                            <div className="nightWrap">Nights : 05</div>
                                            <div className="checkOut">
                                                <div className="checkInOutTitle">Check-Out</div>
                                                <div className="checkInOutDate">20-May-2024</div>
                                                <div className="checkFromUntil">Until 12:00</div>
                                            </div>
                                        </div>
                                        <Accordion defaultActiveKey="0" className='tripSumCollapse reviewBookCollapse'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='tripSumHeader'>
                                                    <div className="d-flex justify-content-between fullWidth">
                                                        <div className="roomsAdults d-flex">
                                                            <i class="icon icon-down-arrow"></i>
                                                            <span>2 Rooms: 4 Adults</span>
                                                        </div>
                                                        <div className="roomAmount">
                                                            AED 6,038.00
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                <Accordion defaultActiveKey="0" className='tripSumCollapse reviewBookInnerCollapse'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='tripSumHeader'>
                                                    <i class="icon icon-down-arrow"></i>
                                                    <span className="roomCount">Room 1 : <span className="roomClass">Deluxe King Room</span></span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="adultBedSize">
                                                        <div className="adultCount"><i class="icon icon-person"></i><i class="icon icon-person"></i>2 Adults</div>
                                                        <div className="bedSize">1 King Bed</div>
                                                    </div>
                                                    <div className="freeCancelAmount">
                                                        <div className="freeCancel">Free Cancellation <i class="icon icon-Info"></i></div>
                                                        <div className="cancelAmount">AED 3,019.00</div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                            <Accordion defaultActiveKey="0" className='tripSumCollapse reviewBookInnerCollapse'>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className='tripSumHeader'>
                                                    <i class="icon icon-down-arrow"></i>
                                                    <span className="roomCount">Room 2 : <span className="roomClass">Standard Double Room</span></span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="adultBedSize">
                                                        <div className="adultCount"><i class="icon icon-person"></i><i class="icon icon-person"></i>2 Adults</div>
                                                        <div className="bedSize">1 King Bed</div>
                                                    </div>
                                                    <div className="freeCancelAmount">
                                                        <div className="freeCancel">Free Cancellation  <i class="icon icon-Info"></i></div>
                                                        <div className="cancelAmount">AED 3,019.00</div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                            <div className="hotelPaymentDetailWrap">
                                                <div className="hotelPaymentTitle">Payment Details</div>
                                                <div className="hotelPaymentBoxWrap">
                                                    <div className="hotelRoomNightWrap">
                                                        <div className="hotelRoomNight">2 Rooms X 5 Nights</div>
                                                        <div className="hotelRoomNightValue">AED 3,500.00</div>
                                                    </div>
                                                    <div className="serviceFeeWrap">
                                                        <div className="serviceFeeTitle">Service Fee</div>
                                                        <div className="serviceFeeTitleValue">AED 10.00</div>
                                                    </div>
                                                </div>
                                                <div className="hotelDiscountWrap">
                                                    <div className="hotelDiscountTitle">Discount</div>
                                                    <div className="hotelDiscountValue">- AED 100.00</div>
                                                </div>
                                                <div className="promocodeWrap">
                                                    <div className="promocodeTitle">Promo Code (KWF10)</div>
                                                    <div className="promocodeValue">- AED 10.00</div>
                                                </div>
                                            </div>
                                            <div className="totalPayableWrap">
                                                <div className="totalPayableTitle">Total Payable Amount</div>
                                                <div>
                                                <div className="totalPayableAmount">AED 6,038.00</div>
                                                <div className="installments"><i class="icon icon-tick"></i> Installments</div>
                                                </div>
                                            </div>
                                        <div class="rightSemicircle"></div>
                                    </div>
                                <div className="manageYourTripWrap">
                                <div className="manageYourTripTitle">Manage Your Trip</div>
                                <div className="yourListWrap">
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Web-Checkin"></i> Web Check In</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Seat"></i> Add Seat or Meal</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-calendar"></i> Modify Dates</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'><i class="icon icon-Cancel-ticket"></i> Cancel All Tickets</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='yourListTitle'>Go to my Trips</div>
                                        <div><i class="icon icon-down-arrow"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="congrulationsWrap">
                                <div className="congrulationsTitle"><i class="icon icon-tick"></i> Congratulations!</div>
                                <div className="congrulationsInfo">
                                 Cashback of AED 20.0 has been processed to your Wallet and shall reflect in a few days.
                                </div>
                            </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
            <Footer />
            <p>Hotel Confirmation</p>
            {hotel_details && hotel_details?.req && hotel_details?.req?.htldtls && hotel_details !== "" && Object.keys(hotel_details?.req?.htldtls).length > 0 &&
                <p>
                    Booking Status: {hotel_details?.req?.booking_status}<br />
                    Check-In: {hotel_details?.req?.htldtls?.cin}<br />
                    Check-In": {hotel_details?.req?.htldtls?.cout}<br />
                    Hotel Name: {hotel_details?.req?.htldtls?.htnm} <br />
                    Address : {hotel_details?.req?.htldtls?.address}<br />
                    Hotel ID: {hotel_details?.req?.htldtls?.pid}<br />
                    CityName: {hotel_details?.req?.htldtls?.city}<br />
                    CountryName: {hotel_details?.req?.htldtls?.country}<br />
                    StarCategoryId: {hotel_details?.req?.htldtls?.rating}<br />
                    {hotel_details?.req?.htldtls?.phone ? "Telephone:" + hotel_details?.req?.htldtls?.phone : ""}
                </p>
            }

            <p> Rooms </p>
            {hotel_details && hotel_details?.req && hotel_details?.req?.rooms && hotel_details?.req?.rooms.length > 0 &&
                hotel_details?.req?.rooms.map((room) => {
                    return (
                        <>
                            <p> Room No : {room.rmno}</p><br />
                            <p> Total Amount : {room.pr[0].tf}</p><br />
                            <p> Bed : {room.bed}</p><br />
                            <p> Room Status : {room.room_status}</p><br />
                            <p> Room Status Code : {room.room_status_code}</p><br />
                            <p> Supplier Confirm Number : {room.sup_confirm_number}</p><br />
                            <p> Amenties : {room.room_ameneties && room.room_ameneties.length > 0 ? JSON.stringify(room.room_ameneties) : ""}</p><br />
                        </>
                    )
                })
            }
        </>
    )
}
export default ConfirmationPage