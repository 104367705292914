import React, { Component, useState, useReducer, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { handlePostApi } from "../../utilities/Axios";
import { isNotNull } from "../../utilities/Validators";
import { useNavigate } from 'react-router-dom';
import Loader from '../../assets/Gifs/Double Ring-1s-200px.gif';

/**
 * @description:My Profile activities
 * @author: Praveen Varma
 * @param {*}
 * @date : 15-06-2024
 */
const initialState = {
  profile_id: "", usr_uniq_id: "",
  notiMessage: "", notiMessageShow: false, notiVarient: "", display_name: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function Sidebar(props) {
    const nav = useNavigate();
    const [profile, setProfile] = useState("");
    const [state, dispatch] = useReducer(reducer, initialState);
    const { navigation } = props || {}
    const [profileImage, setProfileImage] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      var loggedInData = JSON.parse(localStorage.getItem("logged"));
      if(loggedInData !== null) {
        dispatch({ type: "usr_uniq_id", payload: loggedInData?.unique_id }); 
        dispatch({ type: "display_name", payload: loggedInData?.display_name });   
        dispatch({ type: "profile_pic", payload: loggedInData?.profile_pic });       
      }
    },[localStorage.getItem("logged")])

    
    

    /**
     * @author Praveen Varma
     * Profile pics upload
     * @param {*} type 
     */
    const handleImageUpload = (event) => {
      setLoader(true);
      if (isNotNull(profileImage)) {
        const payload = {
          url: profileImage,
        };
        handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/file/delete`, payload)
          .then((response) => {
            if (response?.data?.suc) {
              console.log("::::: S3 bucket Profile pic deleted successfully", profileImage);
            } else {
              console.log("::::: Error ::: S3 bucket Profile pic not deleted successfully", profileImage);
            }
          }).catch(function (error) {
            console.error("Error S3 Delete pic:" + error);
            setLoader(false);
          });
      }
      const file = event?.target?.files[0];
      const formData = new FormData();
      formData.append("image", file);
      formData.append("type", "profile");
      formData.append("usr_uniq_id", state?.usr_uniq_id);
  
      handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/file/upload`, formData)
        .then((response) => {
          if (response?.data?.suc) {
            console.log("::::: URL for image db", response?.data?.res?.url)
            setProfileImage(response?.data?.res?.url);
            if (response?.data?.res?.url) {
              let loggedInData = localStorage.getItem("logged");
              dispatch({ type: "profile_pic", payload: response?.data?.res?.url });
              if (loggedInData && loggedInData !== null && loggedInData !== "") {
                let loggedInfo = JSON.parse(loggedInData)
                let loginInfo = {
                  fname: loggedInfo.fname,
                  unique_id: loggedInfo.unique_id,
                  display_name : loggedInfo.display_name,
                  profile_pic : response?.data?.res?.url,
                }
                localStorage.setItem("logged", JSON.stringify(loginInfo))
              }
            }
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiVarient", payload: "success" });
            dispatch({ type: "notiMessage", payload: "Profile pic Updated successfully" });
            hidemessage();
            setLoader(false);
          } else {
            console.log("::::: Error URL for image db");
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiVarient", payload: "error" });
            dispatch({ type: "notiMessage", payload: "Error while updating profile pic" });
            hidemessage();
            setLoader(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error uploading image:", error);
        });
    };
    
    const hidemessage = () => {
      setTimeout(function () {
        dispatch({ type: "notiMessageShow", payload: false });
        dispatch({ type: "notiMessage", payload: "" });
        dispatch({ type: "notiVarient", payload: "" });
      }, 5000);
    };

    return (

        <div className="myaccountSideWrap">
        <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
          <img src={Loader} alt='' />
        </div>
            <div className="myaccountWrap">
                <div class="leftSemicircle"></div>
                <div className="myaccDetailsWrap text-center">
                    <div className="myPicWrap position-relative d-inline">
                        <label htmlFor="profilepic-upload">
                        <img
                            src={state?.profile_pic ? state?.profile_pic : "https://static.vecteezy.com/system/resources/thumbnails/002/002/403/small/man-with-beard-avatar-character-isolated-icon-free-vector.jpg"}
                        />
                        <i className="icon icon-Edit1"></i>
                        </label>
                        <input
                            id="profilepic-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className="profileName">{state.display_name || ""}</div>
                    <div className="uid">UID : {state.usr_uniq_id}</div>
                </div>
                <div className="myaccountListWrap">
                    <ul>
                        <li><Link to="/myprofile" className={navigation.active === "Profile" ? 'active' : ""}><i class="icon icon-Profile1"></i> My Profile</Link></li>
                        <li><a href="#"><i class="icon icon-star"></i> Add Preferences</a></li>
                        <li><Link to="/mytrips" className={navigation.active === "Mytrips" ? 'active' : ""}><i class="icon icon-trip"></i> My Trips</Link></li>
                        <li><a href="#"><i class="icon icon-Save-Travellers"></i> Save Travellers</a></li>
                        <li><a href="#"><i class="icon icon-Change-Password"></i> Change Password</a></li>
                        <li><a onClick={() => {
                            localStorage.removeItem("logged");
                            // localStorage.removeItem("display_name");                            
                            nav("/");
                          }}><i className="icon icon-Sign-Out"></i> Sign Out</a></li>
                    </ul>
                </div>
                <div class="rightSemicircle"></div>
            </div>
        </div>
    )
}

class SideMenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active
        };
    }

    render() {
        return <Sidebar navigation={this.state} />
    }
}
export default SideMenuBar;
