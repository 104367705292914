import React, {useEffect} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Dropdown from 'react-bootstrap/Dropdown';
 
const Search = () => {
    useEffect(() => {
        let tabs = document.querySelector(".searchPanelTabs");
          let tabHeader = tabs.querySelector(".tabHeader");
          let tabBody = tabs.querySelector(".tabBody");
          let tabIndicator = tabs.querySelector(".tabIndicator");
          let tabHeaderNodes = tabs.querySelectorAll(".tabHeader > div");
          let tabBodyNodes = tabs.querySelectorAll(".tabBody > div");
      
          for(let i=0;i<tabHeaderNodes.length;i++){
              tabHeaderNodes[i].addEventListener("click",function(){
                  tabHeader.querySelector(".active").classList.remove("active");
                  tabHeaderNodes[i].classList.add("active");
                  tabBody.querySelector(".active").classList.remove("active");
                  tabBodyNodes[i].classList.add("active");
                  tabIndicator.style.left = `calc(calc(calc(50% - 0px) * ${i}) + 0px)`;
              });
          }
      }, [])

    return (
        <section className='bannerWrap'>
      <div className="bgBanner"></div>
      <div className="msg">Let us show you the world!</div>
      <div className="mainContainer">
        
        <Row>
          <Col>
          <div className="searchPanelWrap">
              
              <div className="searchPanelTabs">
              
                <div className="tabHeaderWrap">
                <div className="tabHeader">
                    <div className='active'><i class="icon icon-flight"></i>Flights</div>
                    <div><i class="icon icon-hotel"></i>Hotels</div>
                </div>
                <div className="tabIndicator"></div>
                </div>
                <div className="tabBody">
                    <div className='active'>
                    <ul className='tripTabWrap mb-15'>
                  <li className='active'>Roundtrip</li>
                  <li>One-Way</li>
                  <li>Multi-City</li>
                </ul>
                <div className="inputFieldsWrap">
                  <Form.Group className="destFrom position-relative">
                    <Form.Label><i class="icon icon-flight"></i>From</Form.Label>
                    <Form.Control type="text" placeholder="Origin" />
                  </Form.Group>
                  <div className="interchangeWrap position-relative"><i class="icon icon-flip-arrow"></i></div>
                  <Form.Group className="destTo position-relative">
                    <Form.Label><i class="icon icon-flight"></i>To</Form.Label>
                    <Form.Control type="text" placeholder="Destination" />
                  </Form.Group>

                  <DateRangePicker>
                    <input type="text" className="form-control dateRangePicker" defaultValue="" />
                  </DateRangePicker>
                  
                  <Dropdown className="travellerWrap">
                        <Dropdown.Toggle>
                            <div className="travellerTitleWrap">
                                <div className="travellerLabel"><i class="icon icon-signin"></i> Travellers & Class</div>
                                <div className="travellerPlaceholder">1 Traveller, Economy</div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="travellerDropdown">
                                <div className="cabinClassWrap">
                                    <div className="cabinClassTitle"><i class="icon icon-signin"></i> Cabin Class</div>
                                    <div className="classOptionWrap">
                                        <div className="classOption active">Economy</div>
                                        <div className="classOption">Premium Economy</div>
                                        <div className="classOption">Business Class</div>
                                        <div className="classOption">First Class</div>
                                    </div>
                                </div>
                                <div className="selectTravellersWrap">
                                    <div className="selectTravellersTitle"><i class="icon icon-signin"></i> Select Travellers</div>
                                    <ul>
                                        <li>
                                            <div className="label">
                                                <span className="travellersType">Adult</span>
                                                <span className="age">(12+ years)</span>
                                            </div>
                                            <div className="count">
                                                <span className="minus">-</span>
                                                <span className="countValue">1</span>
                                                <span className="plus">+</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="label">
                                                <span className="travellersType">Child</span>
                                                <span className="age">(2 -11 years)</span>
                                            </div>
                                            <div className="count">
                                                <span className="minus">-</span>
                                                <span className="countValue">1</span>
                                                <span className="plus">+</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="label">
                                                <span className="travellersType">Infant </span>
                                                <span className="age">(Under 2 years)</span>
                                            </div>
                                            <div className="count">
                                                <span className="minus">-</span>
                                                <span className="countValue">1</span>
                                                <span className="plus">+</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="applyBtnWrap">
                                    <div className="applyBtn">Apply</div>
                                </div>
                        </Dropdown.Menu>
                  </Dropdown>
                  <Button variant="primary" className='searchBtn'><i class="icon icon-k-logo"></i></Button>
                </div>
                <div className="d-flex align-items-center mt-15">
                    <div className='switchBoxWrap'>
                    <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                    </label>
                    <span className='switchTitle'>Direct Flights</span>
                    </div>
                    <div className="moreOptionSlide">
                    <div className="moreOptionsWrap">
                        More Options <i class="icon icon-down-arrow"></i>
                    </div>
                    <div className='switchBoxWrap moreOptionItem'>
                    <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                    </label>
                    <span className='switchTitle'>Refundable</span>
                    </div>
                    <div className='switchBoxWrap moreOptionItem'>
                    <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                    </label>
                    <span className='switchTitle'>Baggage Only</span>
                    </div>
                    <div className="prefferedAirlines">Preffered Airlines <i class="icon icon-down-arrow"></i></div>
                    </div>
                </div>
               
                    </div>
                    <div>
                        <h1>Hotels</h1>
                        <p>Hotel content goes here ...</p>
                    </div>
                </div>
            </div>

        
            {/* <Tabs
              defaultActiveKey="flights"
              id="uncontrolled-tab-example"
              className="d-flex justify-content-center border-bottom-0"
            >
              <Tab eventKey="flights" title="Flights">
                <ul className='tripTabWrap mb-15'>
                  <li className='active'>Roundtrip</li>
                  <li>One-Way</li>
                  <li>Multi-City</li>
                </ul>
                <div className="inputFieldsWrap">
                  <Form.Group className="destFrom position-relative">
                    <Form.Label><i class="icon icon-flight"></i>From</Form.Label>
                    <Form.Control type="text" placeholder="Origin" />
                  </Form.Group>
                  <div className="interchangeWrap position-relative"><i class="icon icon-arrow"></i></div>
                  <Form.Group className="destTo position-relative">
                    <Form.Label><i class="icon icon-flight"></i>To</Form.Label>
                    <Form.Control type="text" placeholder="Destination" />
                  </Form.Group>

                  <DateRangePicker>
                  <input type="text" className="form-control dateRangePicker" defaultValue="" />
</DateRangePicker>
<Button variant="primary" className='searchBtn'><i class="icon icon-k-logo"></i></Button>
                </div>
                <div>
                  <label class="switch">
  <input type="checkbox" />Direct Flights
  <span class="slider round"></span>
</label>
                </div>
                <div className="moreOptionsWrap">
                  More Options
                </div>
              </Tab>
              <Tab eventKey="hotels" title="Hotels">
                Hotel contents goes here ...
              </Tab>
            </Tabs> */}
            </div>    
          </Col>
        </Row>
      </div>

    </section>
        
    );
}
 
export default Search;