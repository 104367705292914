import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { characterValidation } from '../../../../utilities/FormValidations';

function PromocodesList(props) {
  const [promoCodesData, setPromoCodesData] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [showMand, setShowMand] = useState("");

  useEffect(() => {
    if(props?.promo_codes !== undefined && props?.promo_codes?.length !== 0) {
      setPromoCodesData(props?.promo_codes);
    } else {
      setPromoCodesData([]);
    }

  }, [props?.promo_codes])

  const updatePromo=(val)=>{
    setPromoCode(val.target.value)
    if(val.target.value===""){
      props.removeData();
    }     
  }

  const applyPromoCode=()=>{
      setShowMand(false);
      if(promoCode!==""){
        if(characterValidation(promoCode)){
          props.sendPromo(promoCode);
        }else{
          setShowMand("Enter valid promo code");
        }
      }else{
          setShowMand("Enter promo code");
      }
  }
  const removeCode=()=>{
    setPromoCode("")
      for(let val of promoCodesData){
        val.active=false;
        val.selected=false;
      }
      props.removeData();
}

const updateSelPromo=(code, index)=>{
  for(let val of promoCodesData){
    val.active=false;
    if(val.pro_code===code){
      val.selected=true;
    }
  }
  promoCodesData[index].active = true;
    setPromoCode(code)
}

  return (
    <>
    {promoCodesData && promoCodesData.length !== 0 && (
      <div className="promoCodeWrap mt-20">
      <div className="promoCodeTitle">Promo Code</div>
      <div className="inputListWrap">
        <div className="promoCodeInputWrap">
          <Form className='d-flex formWrap'>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control 
                type="text" 
                placeholder='Select or Enter Promo Code'
                value={promoCode}
                onChange={(value) => updatePromo(value)}
              />
              {showMand !== "" && (
                <div className='error'>
                  <span>{showMand}</span>
                </div>
              )}
              {promoCode!=="" && (
                <i className='' onClick={()=>removeCode()}></i>
              )}
            </Form.Group>
            <Button variant="primary" className='applyBtn' onClick={applyPromoCode}>
              {props?.promo_disc===0 ? "Apply" : "Applied"}
            </Button>
          </Form>
        </div>
        <div className="promoCodeOptionList">
          <Form>
              {promoCodesData.map((promo, idx) => (              
              <div key={idx} className={promo.active!==undefined && promo.active?"promoCodeOption active":"promoCodeOption"}>
                <Form.Check // prettier-ignore
                  type='radio'
                  id={idx}
                  label={promo.offer_name}
                  onClick={() => updateSelPromo(promo.offer_name, idx)}
                />
                <span>{promo.promo_code}</span>
              </div>
              ))}
          </Form>
        </div>
        {/* <div className="promoCodeOptionList">
          <Form>
            <div key={`default-radio`} className='promoCodeOption'>
              <Form.Check // prettier-ignore
                type='radio'
                id={`default-radio1`}
                label={`FLY100`}
              />
              <span>Use this promo code & save SAR 50.00</span>
            </div>
            <div key={`default-radio2`} className='promoCodeOption'>
              <Form.Check // prettier-ignore
                type='radio'
                id={`default-radio2`}
                label={`FLY100`}
              />
              <span>Use this promo code & save SAR 50.00</span>
            </div>
            <div key={`default-radio3`} className='promoCodeOption'>
              <Form.Check // prettier-ignore
                type='radio'
                id={`default-radio3`}
                label={`FLY100`}
              />
              <span>Use this promo code & save SAR 50.00</span>
            </div>
          </Form>
        </div> */}
      </div>
      </div>
    )}
    </>
  )
}

export default PromocodesList
