import React from 'react'

function ReviewBooking() {
  return (
    <>
      <div className="toggle-box">
        <div className="bookingtitle d-flex">Review Booking
          <i><img src="assets/images/flight-result/group.svg" alt="" /></i>
        </div>

        <div className="booking-review-info">
          <div className="flight-to">
            <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
            <div className="info-text">
              <span>Dubai</span>
              <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
              <span>London</span>
              |
              <span>Sat, 20 Apr,2024</span>
            </div>
          </div>
          <div className="flt-eco d-flex">

            <div className="egypt-air">
              <strong>Egypt Air</strong>
              <div className="d-flex eco">
                <span>MS-911</span> | <span>Economy - K</span>
              </div>
              <p>Operated by : Emirates</p>

            </div>

            <div className="flt-roots">
              <div className="left">
                <strong>23:00</strong>
                <span>Dubai</span>
                <p>Dubai International Airport, Dubai</p>
              </div>
              <div className="flt-middle-info">
                <p>1 Stop, Layover : 2h 20m (CAI)</p>
                <div className="bordericons">
                  <img src="assets/images/flight-result/fly-right.svg" alt="" />
                  <img src="assets/images/flight-result/pin.svg" alt="" />
                </div>
                <p>Duration : 11h 15m</p>
              </div>
              <div className="right">
                <strong>05:40</strong>
                <span>London</span>
                <p>Heathrow Airport, London</p>
              </div>
            </div>

          </div>
          <div className="flight-to">
            <div className="flt-dep"><img src="assets/images/flight-result/fly.svg" alt="" /> Departure</div>
            <div className="info-text">
              <span>Dubai</span>
              <i><img src="assets/images/flight-result/rightarow.svg" alt="" /></i>
              <span>London</span>
              |
              <span>Sat, 20 Apr,2024</span>
            </div>
          </div>
          <div className="flt-eco d-flex">

            <div className="egypt-air">
              <strong>Egypt Air</strong>
              <div className="d-flex eco">
                <span>MS-911</span> | <span>Economy - K</span>
              </div>
              <p>Operated by : Emirates</p>

            </div>

            <div className="flt-roots">
              <div className="left">
                <strong>23:00</strong>
                <span>Dubai</span>
                <p>Dubai International Airport, Dubai</p>
              </div>
              <div className="flt-middle-info">
                <p>1 Stop, Layover : 2h 20m (CAI)</p>
                <div className="bordericons">
                  <img src="assets/images/flight-result/fly-right.svg" alt="" />
                  <img src="assets/images/flight-result/pin.svg" alt="" />
                </div>
                <p>Duration : 11h 15m</p>
              </div>
              <div className="right">
                <strong>05:40</strong>
                <span>London</span>
                <p>Heathrow Airport, London</p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default ReviewBooking
