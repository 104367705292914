import React,{useEffect, useState} from "react";

function PassengersDetails(props) {
  const [contactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    if(props.pax_info && props.pax_info?.length !== 0) {
      const contactData = props.pax_info.filter((pax) => pax.is_lead_pax === 1);
      setContactInfo(contactData)
    }
  }, [props.pax_info])

  return (
    <>
    
    <div className="travellersTableWrap">
      <div className="travellersTableTitle">Travellers Details</div>
      <div className="travellersTable">
          <div className="travellersTableHeader">
              <span className='travellerNameSpan'>Traveller Name</span>
              <span className='sectorSpan'>Sector</span>
              <span className='pnrSpan'>PNR No</span>
              <span className='ticketSpan'>Ticket No</span>
              <span className='specialSpan'>Special Request</span>
              <span className='statusSpan'>Status</span>
          </div>
          <div className='travellersTableDataWrap'>            
          {props.pax_info && props.pax_info.map((pax, index) => (
          <React.Fragment key={index}>
           
            <div className="travellersTableData">
                <span className='travellerNameSpan'>{pax.pax_name} ({pax.pax_type === "adult" ? "ADT" : pax.pax_type === "child" ? "CHD" : "INF"})</span>
                <span className='sectorSpan'>{pax.sec!==undefined && pax.sec!=="" ? pax.sec : (pax.sec===undefined || pax.sec==="") && ""}</span>
                <span className='pnrSpan'>{pax.pnr!==undefined && pax.pnr!=="" ? pax.pnr : (pax.pnr===undefined || pax.pnr==="") && "N/A"}</span>
                <span className='ticketSpan'>{pax.tkt_no && pax.tkt_no!==undefined && pax.tkt_no!=="" ? pax.tkt_no : "N/A"}</span>
                <span className='specialSpan'>{pax.sa || pax.sp || pax.mp ?
              <>
                {pax.sa && pax.sp ==="" && pax.mp ==="" && (
                  <>
                    {pax.sa}
                  </>
                )}
                {pax.sp && pax.sa === "" && pax.mp === "" && (
                  <>
                    {pax.sp}
                  </>
                )}
                {pax.mp && pax.sp === "" && pax.sa === "" && (
                  <>
                    {pax.mp}
                  </>
                )}
                {pax.sa && pax.sp && pax.mp === "" && (
                  <>
                    {pax.sa + " / " + pax.sp}
                  </>
                )}
                {pax.sa && pax.mp && pax.sp === "" && (
                  <>
                    {pax.sa + " / " + pax.mp}
                  </>
                )}
                {pax.sp && pax.mp && pax.sa === "" && (
                  <>
                    {pax.sp + " / " + pax.mp}
                  </>
                )}
                {pax.sa && pax.sp && pax.mp && (
                  <>
                    {pax.sa + " / " + pax.sp + " / " + pax.mp}
                  </>
                )}
              </> : "N/A"
            }</span>
                <span className='statusSpan'>{pax.sts}</span>   
            </div>
          </React.Fragment>
          ))}
          </div>
      </div>

      
      <div className="optionalTableWrap">
        <div className="optionalTableTitle">Optional Preferences</div>
        <div className="optionalTable">
            <div className="optionalTableHeader">
                <span className='travellerSpan'>Traveller Name</span>
                <span className='frequentSpan'>Frequent Flyer</span>
                <span className='seatSpan'>Seat</span>
                <span className='mealSpan'>Meal</span>
                <span className='specialSpan'>Baggage</span>
                {/* <span className='specialSpan'>Special Assistance</span> */}
            </div>
            <div className='optionalTableDataWrap'>                        
              {props.pax_info && props.pax_info.map((pax, index) => (
              <React.Fragment key={index}>
                <div className="optionalTableData">
                    <span className='travellerSpan'>{pax.pax_name} ({pax.pax_type === "adult" ? "ADT" : pax.pax_type === "child" ? "CHD" : "INF"})</span>
                    <span className='frequentSpan'>{pax?.ffnum && pax?.ffnum!=="" ? pax?.ffnum : "N/A"}</span>
                    <span className='seatSpan'>{pax.seg_seat_no!==undefined && pax.seg_seat_no ? pax.seg_seat_no?.split(",").filter((s) => s !== "-").join(","):"N/A"}</span>
                    <span className='mealSpan'>{pax.meal_lable!=undefined && pax.meal_lable ? pax.meal_lable:"N/A"}</span>
                    <span className='specialSpan'>{pax.bag_lable!=undefined && pax.bag_lable ? pax.bag_lablel:"N/A"}</span>
                </div>   
              </React.Fragment>
              ))}
            </div>
        </div>
      </div>

    {contactInfo && contactInfo.length !==0 && (
    <div className="contactTableWrap">
      <div className="contactTableTitle">Contact Details</div>
      <div className="contactTable">
          <div className="contactTableHeader">
              <span>Traveller Name</span>
              <span>Phone Number</span>
              <span>Email Id</span>
          </div>
          {contactInfo.map((pax, index) => (
          <div className="contactTableData" key={index}>
              <span>{pax.pax_name} ({pax.pax_type === "adult" ? "ADT" : pax.pax_type === "child" ? "CHD" : "INF"})</span>
              <span>{props.isd_code + " " + props.phone_no}</span>
              <span>{props.email}</span>
          </div>
          ))}
      </div>
    </div>
    )}
  </div>
  </>
  );
}

export default PassengersDetails;
