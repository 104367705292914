import React from 'react'

function ContactDetails() {
  return (
    <>
      <div className="insure-row d-flex">
        <figure><img src="assets/images/flight-result/insure.svg" alt="" /></figure>
        <div>
          <span>Insure your trip</span>
          <p>Protect you from unexpected disruptions.</p>
        </div>
      </div>


      <div className="contact-details-row">
        <div className="title">Contact Details</div>
        <div className="inner-box">
          <div className="inputroots-box">
            <div className="box">
              <label>Email</label>
              <input type="text" placeholder="Enter Last Name" />
            </div>
            <div className="box">
              <label>Mobile Number</label>
              <div className="inputselect">
                <select>
                  <option>+91</option>
                </select>
                <input type="text" placeholder="" />
              </div>
            </div>
            <div className="box">
              <label for=""></label>
              <div className="eticket">
                <i><img src="assets/images/flight-result/icon.svg
                                        " alt="" /></i>
                <small>Get your E-ticket on WhatsApp</small>
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <p>*E-ticket will be sent to this email address and phone number.</p>
        </div>
      </div>
    </>
  )
}

export default ContactDetails
