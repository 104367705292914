import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {
    destination: [],
    nationality: '',
    checkin: '',
    checkout: '',
    rooms: [{
      adult: 1,
      children: 0,
      infant: 0,
      childAges: [],
    }],
    classType: '',
  }
}
export const SearchHotelSlice = createSlice({
  name: 'searchhotel',
  initialState,
  reducers: {
    addRooms: (state, action) => {
      const newRoomId = state.value.rooms.length + 1;
      state.value.rooms = [
        ...state.value.rooms, {
          adult: 1,
          children: 0,
          infant: 0,
          childAges: [],
        }
      ]
    },
    removeRooms: (state, action) => {
      console.log(action.payload)
      const indexToRemove = action.payload
      if (indexToRemove !== -1) {
        state.value.rooms.splice(indexToRemove, 1);
      }
      const values = state.value.rooms
      console.log("values", values)
    },
    setDestination: (state, action) => {

    },
    setNationality: (state, action) => {
      const nation = action.payload;
      state.value.nationality = nation;
    },
    setCheckin: (state, action) => {
      const chkin = action.payload;
      state.value.checkin = chkin;
    },
    setCheckout: (state, action) => {
      const chkout = action.payload;
      state.value.checkout = chkout;
    },
    increaseAdult: (state, action) => {
      if (state.value.rooms[action.payload].adult <= 5) {
        state.value.rooms[action.payload].adult += 1;
      } else {
        alert("you reached the maximum limit...")
      }

    },
    decreaseAdult: (state, action) => {
      if (state.value.rooms[action.payload].adult >= 2) {
        state.value.rooms[action.payload].adult -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    increaseChildren: (state, action) => {
      if (state.value.rooms[action.payload].children <= 2) {
        state.value.rooms[action.payload].children += 1
      } else {
        alert("you reached the maximum limit...")
      }
    },
    decreaseChildren: (state, action) => {
      if (state.value.rooms[action.payload].children >= 0 && state.value.rooms[action.payload].children !== 0) {
        state.value.rooms[action.payload].children -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    increaseInfant: (state, action) => {
      if (state.value.rooms[action.payload].infant <= 1) {
        state.value.rooms[action.payload].infant += 1
      } else {
        alert("you reached the maximum limit...")
      }
    },
    decreaseInfant: (state, action) => {
      if (state.value.rooms[action.payload].infant >= 1) {
        state.value.rooms[action.payload].infant -= 1
      } else {
        alert("You are at the minimum value...")
      }
    },
    setChildAges: (state, action) => {
      const roomidd = action.payload.roomid;
      const idd = action.payload.id;
      const value = action.payload.aages;
      state.value.rooms[roomidd].childAges[idd] = value
    },
    setClassType: (state, action) => {
      const classT = action.payload;
      state.value.classType = classT;
    },
    totalPassengers: (state) => {
      const totalAdults = 0, totalChildren = 0, totalInfant = 0;
      for (let i = 1; i <= state.value.rooms.length; i++) {
        totalAdults = state.value.rooms[i].adult + totalAdults;
        totalChildren = state.value.rooms[i].children + totalChildren;
        totalInfant = state.value.rooms[i].infant + totalInfant;
      }
      console.log(totalAdults, totalChildren, totalInfant)
    }
  }
})
export const {
  setDestination,
  setNationality,
  setCheckin,
  setCheckout,
  increaseAdult,
  decreaseAdult,
  increaseChildren,
  decreaseChildren,
  increaseInfant,
  decreaseInfant,
  setChildAges,
  setClassType,
  addRooms,
  removeRooms,
  totalPassengers
} = SearchHotelSlice.actions
export default SearchHotelSlice.reducer;