import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import loaderImg from '../../../../assets/Gifs/Double Ring-1s-200px.gif'

function FlightReviewLoader() {
    return (
        <>
            <div className='loadersdiv'>
                <img src={loaderImg} alt='' />
            </div>
            {/* <section className="statusBarWrap">
                <div className="mainContainer">
                    <Row>
                        <Col>
                            <div className="d-flex align-items-center">
                                <div className="statusBarItem">
                                    <span className='done smallCircle'><i class="icon icon-tick"></i></span>
                                    <span></span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='atPresent smallCircle'></span>
                                    <span></span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='pending smallCircle'></span>
                                    <span></span>
                                </div>
                                <div className="rightArrBar">
                                    <i class="icon icon-down-arrow"></i>
                                </div>
                                <div className="statusBarItem">
                                    <span className='pending smallCircle'></span>
                                    <span></span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <div className="reviewPage">
                <section>
                    <div className="mainContainer">
                        <Row>
                            <Col>
                                <div className="d-flex mt-20">
                                    <div className="reviewSideWrap">
                                        <Accordion defaultActiveKey="0" className='bookingReviewWrap'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><span className='bookingReviewTitle'>Review Booking</span> <i class="icon icon-Plus"></i> <i class="icon icon-Minus"></i></Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div className="flight-to">
                                                            <div className="flyinner d-flex">

                                                                <div className="flightDepWrap">
                                                                    <div className="flightDep">
                                                                        <i class="icon icon-Flight-Circle"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="routeTimeWrap">
                                                                    <div className="routeTime">
                                                                        <span className='route'></span>
                                                                        <i class="icon icon-Arrow"></i>
                                                                        <span className='route'></span>
                                                                        <span className='time leftSeparatorLine'></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="brBot">
                                                                <div className='planeInfo'>
                                                                    <div className="planeName"><img src="" alt="AirlineLogo" className='img-fluid planeImage' />
                                                                    </div>
                                                                    <div className="planeCodeClassWrap"><span className='planeCode'></span><span className='planeClass leftSeparatorLine'></span></div>
                                                                    <div className="operatedBy"></div>
                                                                </div>
                                                                <div className='airlineInfo'>
                                                                    <div className='deparrInfo'>
                                                                        <div className='deparrTime'></div>
                                                                        <div className='deparrLocation'></div>
                                                                        <div className='airportName'></div>
                                                                    </div>
                                                                    <div className='layoverInfo text-center'>
                                                                        <div className='layoverDuration'></div>
                                                                        <div className='layoverSeparator'><span></span></div>
                                                                        <div className='totalDuration'></div>
                                                                    </div>
                                                                    <div className='deparrInfo'>
                                                                        <div className='deparrTime'></div>
                                                                        <div className='deparrLocation'></div>
                                                                        <div className='airportName'></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <section>
                                            <div className="mainContainer">
                                                <Row>
                                                    <Col>
                                                        <div className="signInWrap">
                                                            <i class="icon icon-Login2"></i>
                                                            <span></span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </section>
                                        <section>
                                            <div className="mainContainer">
                                                <Row>
                                                    <Col>
                                                        <Accordion defaultActiveKey="0" className='bookingReviewWrap mt-20'>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header className='baggageTitleWrap'><span><img src="" alt="baggageImage" className='img-fluid' /> </span> <span className='d-flex align-items-center'><span className='addNow'></span> <i class="icon icon-Plus"></i> <i class="icon icon-Minus"></i></span></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="baggageProtectionWrap">
                                                                        <div className="protectionTitle"></div>
                                                                        <div className="protectionBoxWrap">
                                                                            <div className="protectionBox">
                                                                                <div className="protectionBoxTitle"><i class="icon icon-Tracking"></i> </div>
                                                                                <div className="protectionBoxCont"></div>
                                                                            </div>
                                                                            <div className="protectionBox">
                                                                                <div className="protectionBoxTitle"><i class="icon icon-Doc"></i> </div>
                                                                                <div className="protectionBoxCont"></div>
                                                                            </div>
                                                                            <div className="protectionBox">
                                                                                <div className="protectionBoxTitle"><i class="icon icon-Pay"></i></div>
                                                                                <div className="protectionBoxCont"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="protectionBottomLine"></div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </section>
                                        <section>
                                            <div className="mainContainer">
                                                <Row>
                                                    <Col>
                                                        <div className="insureTripWrap">
                                                            <div className='insureWrap'>
                                                                <i class="icon icon-Insure"></i>
                                                                <div className='insureTitle'></div>
                                                                <div className="insureCont"></div>
                                                            </div>
                                                            <div className='chooseWrap'> <i class="icon icon-down-arrow"></i></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div >
                </section >
            </div > */}
        </>
    )
}

export default FlightReviewLoader
