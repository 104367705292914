import React from 'react';
import FormateCurrency from '../../../utils/FormateCurrency';
import master_card_url from "../../../../assets/images/home/footer/card.png";
import visa_card_url from "../../../../assets/images/home/footer/visa.png";
import express_card_url from "../../../../assets/images/home/footer/express.png";

function PaymentSummary( { payment }) {

  const getCardIconUrl = (type) =>{
    if(type && type !== null && type !== ""){
        if(type.toUpperCase() === "MASTERCARD"){
          return master_card_url;
        }else if(type.toUpperCase() === "VISA"){
          return visa_card_url;
        }else if(type.toUpperCase() === "AMERICAN EXPRESS"){
          return express_card_url;
        }
    }
    return "";
  }
  
  return (
    <div className="optionalTableWrap">
    <div className="optionalTableTitle">Payment Info</div>
    <div className="optionalTable">
        <div className="optionalTableHeader">
            <span className='travellerSpan'>Mode of payment</span>
            <span className='frequentSpan'>Card type</span>
            <span className='seatSpan'>Card details</span>
            <span className='mealSpan'>Amount paid</span>
        </div>
        <div className='optionalTableDataWrap'>                        
          {payment && payment.map((pay, index) => (
          <React.Fragment key={index}>
            <div className="optionalTableData">
                <span className='travellerSpan'>Card</span>
                <span className='frequentSpan'>{pay?.card_type ? <img src={getCardIconUrl(pay?.card_type)} alt="pay-method-image" title={pay?.card_type} className='img-fluid payMethodImage' />  : "N/A"}</span>
                <span className='seatSpan'>{pay.card_num}</span>
                <span className='mealSpan'>{pay.usr_cur} <FormateCurrency value={pay.total_amt} /></span>
            </div>   
          </React.Fragment>
          ))}
        </div>
    </div>
  </div>
  )
}

export default PaymentSummary
