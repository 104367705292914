import React, { useState, useEffect, useReducer } from 'react';
import { Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import GetoNWhatshapp from '../../../../assets/images/review/Watsapp-update.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Month from '../../../common/Month';
import Days from '../../../common/Days';
import Years from '../../../common/Years';
import AddBaggage from './extras/AddBaggage';
import { isArray } from "lodash";
import AddMeal from './extras/AddMeal';

let countrieslist = require("../../../../components/maasterdata/CountriesList.json")
let stdCodes = require("../../../bookingflow/Flights/common/masterData/CountrySTDCodes.json");
let seatPrefs = require("../../../bookingflow/Flights/common/masterData/SeatPreference.json");
let airportsData = require("../../../maasterdata/AirlinesMasterData.json");

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * Initial State Declaration
 */
const initialState = {
  passengers: [],
  docMandatory: "",
  extras: false,
  onwardBaggageOptions: [],
  onwardMealsOptions: [],
  returnBaggageOptions: [],
  returnMealsOptions: [],
  onwardTripSector: "",
  returnTripSector: "",
  airlines: [],
};

function TravellerDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [adts, setAdt] = useState(props.srequest.adults);
  const [chds, setChd] = useState(props.srequest.children);
  const [infs, setInf] = useState(props.srequest.infants);

  const [adt_titles, setAdtTitles] = useState([
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ]);
  const [chd_titles, setChdTitles] = useState([
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
    { value: "Ms", label: "Ms" },
  ]);
  const [inf_titles, setInfTitles] = useState([
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
  ]);

  useEffect(() => {
    createPaxList();
    createAirlines();
  }, [])

  useEffect(() => {
    if (props.fareresp !== undefined && props.fareresp.data != undefined) {
      dispatch({ type: 'docMandatory', payload: props.fareresp?.data?.doc })
      if(props.fareresp?.data?.baggage_details?.length !== 0 || props.fareresp?.data?.meals_details?.length !== 0) {
        dispatch({ type: 'extras', payload: true })
        if(props?.srequest?.tt === 2) {
          if(props.fareresp?.data?.baggage_details?.length !== 0) {
            let onwardSector = props?.srequest?.dep + "_" + props?.srequest?.arr;
            let returnSector = props?.srequest?.arr + "_" + props?.srequest?.dep;
            let onwardBaggage = props.fareresp?.data?.baggage_details?.filter((bag) => bag.sector === onwardSector);
            let returnBaggage = props.fareresp?.data?.baggage_details?.filter((bag) => bag.sector === returnSector);
            dispatch({ type: 'onwardBaggageOptions', payload: onwardBaggage.length !==0 ? convertLabelUsrPrice(onwardBaggage) : [] })
            dispatch({ type: 'returnBaggageOptions', payload: returnBaggage.length !==0 ? convertLabelUsrPrice(returnBaggage) : [] })
            dispatch({ type: 'onwardTripSector', payload: onwardSector });
            dispatch({ type: 'returnTripSector', payload: returnSector });
          }
          if(props.fareresp?.data?.meals_details?.length !== 0) {
            let onwardSector = props?.srequest?.dep + "_" + props?.srequest?.arr;
            let returnSector = props?.srequest?.arr + "_" + props?.srequest?.dep;
            let onwardMeals = props.fareresp?.data?.meals_details?.filter((meal) => meal.sector === onwardSector);
            let returnMeals = props.fareresp?.data?.meals_details?.filter((meal) => meal.sector === returnSector);
            dispatch({ type: 'onwardMealsOptions', payload: onwardMeals.length !==0 ? convertLabelUsrPrice(onwardMeals) : [] })
            dispatch({ type: 'returnMealsOptions', payload: returnMeals.length !==0 ? convertLabelUsrPrice(returnMeals) : [] })        
          }
        } else {
          if(props.fareresp?.data?.baggage_details?.length !== 0) {
            let onwardSector = props?.srequest?.dep + "_" + props?.srequest?.arr;
            let onwardBaggage = props.fareresp?.data?.baggage_details?.filter((bag) => bag.sector === onwardSector);
            dispatch({ type: 'onwardBaggageOptions', payload: onwardBaggage.length !==0 ? convertLabelUsrPrice(onwardBaggage) : [] })
            dispatch({ type: 'onwardTripSector', payload: onwardSector }); 
          }
          if(props.fareresp?.data?.meals_details?.length !== 0) {
            let onwardSector = props?.srequest?.dep + "_" + props?.srequest?.arr;
            let onwardMeals = props.fareresp?.data?.meals_details?.filter((meal) => meal.sector === onwardSector);
            dispatch({ type: 'onwardMealsOptions', payload: onwardMeals.length !==0 ? convertLabelUsrPrice(onwardMeals) : [] })      
            dispatch({ type: 'onwardTripSector', payload: onwardSector }); 
          }
        }
      } else {
        dispatch({ type: 'extras', payload: false })
      }
    }
  }, []);

  const createAirlines=()=>{
    let airlinesList = [];
    let finailAirlines = [];
    if (props.srequest.Airlinelists !== undefined) {
      let finalList = props.srequest.Airlinelists;
      finailAirlines.push(finalList);
      if (finalList) {
        for (let val of finalList) {
          if (airportsData.filter((obj) => obj.value === val)) {
            var name = airportsData.filter((obj) => obj.value === val);
            if(airlinesList.length===0){
              airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
            }else{
              let list = airlinesList.filter((obj) => obj.value === val);
              if(list.length==0){
                airlinesList.push({ label: name[0]?.label, value: name[0]?.value });
              }
            }
          }
        }
      }
      dispatch({ type: 'airlines', payload: airlinesList })
    }
  }

  const convertLabelUsrPrice=(list)=>{
    for(let val of list){
      if(isArray(val)) {
        for(let value of val){
          if(value.price!==0 && value.price > 0){
            value.label=value.label +" - "+(value.price * props.base_usr_exrate).toFixed(2)+" "+props.srequest.cur;
          }
        }
      } else {
        if(val.price!==0 && val.price > 0){
          val.label=val.label +" - "+(val.price * props.base_usr_exrate).toFixed(2)+" "+props.srequest.cur;
        }
      }
    }
    return list
  }

  //=====================================
  // This function will create the Pax List based on selection
  // which will update the state
  //=====================================
  const createPaxList = () => {
    let paxList = [];
    var adtcount = 1;
    var infCount = infs;
    if (adts) {
      for (var i = 0; i < adts; i++) {
        let passKey = [];
        if (props.fareresp!=="" && props.fareresp?.data?.passengerUniqueKey!==undefined && props.fareresp?.data?.passengerUniqueKey?.length > 0) {
          passKey = props.fareresp?.data?.passengerUniqueKey.filter((s) => s.type == "ADT");
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : "";
        const payload = createPaxPayload(
          "ADT",
          adtcount,
          "Adult",
          infCount,
          adtcount,
          uniqueKey
        );
        paxList.push(payload);
        adtcount = adtcount + 1;
        infCount = infCount - 1;
      }
    }
    if (chds && chds > 0) {
      var chdcount = adtcount;
      var fesno = 1;
      for (var i = 0; i < chds; i++) {
        let passKey = [];
        if (props.fareresp!=="" && props.fareresp?.data?.passengerUniqueKey!==undefined && props.fareresp?.data?.passengerUniqueKey?.length > 0) {
          passKey = props.fareresp?.data?.passengerUniqueKey.filter((s) => s.type == "CHD");
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : "";
        const payload = createPaxPayload(
          "CHD",
          chdcount,
          "Child",
          0,
          fesno,
          uniqueKey
        );
        paxList.push(payload);
        chdcount = chdcount + 1;
        fesno = fesno + 1;
      }
    }
    dispatch({ type: "passengers", payload: paxList });
  };

  //=====================================
  // This is common function to create passengers
  //=====================================
  const createPaxPayload = (type, count, lable, infs, sno, pUkey) => {
    var lead = false;
    var docPayload = "";
    var prefPayload = "";
    var ffPayload = "";
    var infPayload = "";
    type === "ADT" && count === 1 ? (lead = true) : (lead = false);
    docPayload = {
      ty: "P",
      num: "",
      na: "",
      isc: "",
      isd: null,
      exd: null,
      isd_month: "",
      isd_day: "",
      isd_year: "",
      exp_month: "",
      exp_day: "",
      exp_year: "",
    };
    dispatch({ type: "passportEnable", payload: true });
    if (type !== "INF") {
      prefPayload = { sa: "", sp: "", mp: "" };
      ffPayload = { ffnum: "", air: "" };
    }
    if (infs > 0) {
      infPayload = {
        sno: count,
        tl: "",
        ty: "INF",
        plbl: "Infant",
        fn: "",
        ln: "",
        dob: null,
        doc: {
          ty: "P",
          num: "",
          na: "",
          isc: "",
          isd: null,
          exd: null,
          isd_month: "",
          isd_day: "",
          isd_year: "",
          exp_month: "",
          exp_day: "",
          exp_year: "",
        },
        trave_id: 0,
        dob_month: "",
        dob_day: "",
        dob_year: "",
      };
    }
    return {
      sno: count,
      passengerKey: pUkey ? pUkey : "",
      fesno: sno,
      tl: "",
      ty: type,
      plbl: lable,
      lead: lead,
      fn: "",
      ln: "",
      em: "",
      phc: "",
      phn: "",
      dob: null,
      inf: infPayload,
      doc: docPayload,
      opref: prefPayload,
      ffinfo: ffPayload,
      baggage: [],
      meals: [],
      seats: [],
      trave_id: 0,
      dob_month: "",
      dob_day: "",
      dob_year: "",
    };
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is the '+' symbol
    if (e.key === "+") {
      e.preventDefault(); // Prevent the '+' symbol from being entered
    }
    // Check if the pressed key is the '-' symbol
    if (e.key === "-") {
      e.preventDefault(); // Prevent the '-' symbol from being entered
    }
    // Check if the pressed key is the 'e' symbol
    if (e.key === "e") {
      e.preventDefault(); // Prevent the 'e' alphabet from being entered
    }
  };

  const handleChangeText = (idx, type, paxType) => (e) => {
    const { id, value } = e.target;
    try {
      let paxList = state.passengers;
      for (let pax of paxList) {
        if (pax.sno === idx) {
          if (paxType !== "INF" && paxType !== "infant") {
            if (type === "fn") {
              pax.fn = value;
            } else if (type === "ln") {
              pax.ln = value;
            } else if (pax.lead && type === "em") {
              pax.em = value;
            } else if (pax.lead && type === "phn") {
              pax.phn = value;
            } else if (type === "pnum") {
              pax.doc.num = value;
            } else if (type === "ffnum") {
              pax.ffinfo.ffnum = value;
            }
          } else {
            if (type === "fn") {
              pax.inf.fn = value;
            } else if (type === "ln") {
              pax.inf.ln = value;
            } else if (type === "pnum") {
              pax.inf.doc.num = value;
            }
          }
        }
      }
      dispatch({ type: "passengers", payload: paxList });
      props.sendpsngersdata(paxList);
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  // OnChange event we will call this and update the state
  const handleChangeSelect = (idx, type, field, paxType) => (e) => {
    let value = ""
    if(e.value) {
      value = e.value
    } else {
      value = e.target.value
    }
    try {
      if (e !== "") {
        let paxList = state.passengers;
        for (let pax of paxList) {
          if (pax.sno === idx) {
            if (paxType !== "INF" && paxType !== "infant") {
              if (type === "tl") {
                pax.tl = value;
              } else if (pax.lead && type === "phc") {
                pax.phc = value;
              } else if (field === "dob") {
                createDobInfo(pax, type, value, false);
              } else if (field === "isudate") {
                createISDateInfo(pax, type, value, false);
              } else if (field === "expiry") {
                createExpiryInfo(pax, type, value, false);
              } else if (type === "na") {
                pax.doc.na = value;
              } else if (type === "isc") {
                pax.doc.isc = value;
              } else if (type === "spe") {
                pax.opref.sa = value;
              } else if (type === "meal") {
                pax.opref.mp = value;
              } else if (type === "seat") {
                pax.opref.sp = value;
              } else if (type === "freq") {
                pax.ffinfo.air = value;
              }
            } else {
              if (field === "dob") {
                createDobInfo(pax, type, value, true);
              } else if (field === "isudate") {
                createISDateInfo(pax, type, value, true);
              } else if (field === "expiry") {
                createExpiryInfo(pax, type, value, true);
              } else if (type === "nationality") {
                pax.inf.doc.na = value;
              } else if (type === "issuedcountry") {
                pax.inf.doc.isc = value;
              } else if (type === "tl") {
                pax.inf.tl = value;
              }
            }
          }
        }
        dispatch({ type: "passengers", payload: paxList });
          props.sendpsngersdata(paxList);
      }
    } catch (err) {
      console.error("Eroor+++++++++++" + err);
    }
  };

  const createDobInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.dob_month = e;
      } else {
        pax.inf.dob_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.dob_day = e;
      } else {
        pax.inf.dob_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.dob_year = e;
      } else {
        pax.inf.dob_year = e;
      }
    }
    return pax;
  };
  const createISDateInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.doc.isd_month = e;
      } else {
        pax.inf.doc.isd_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.doc.isd_day = e;
      } else {
        pax.inf.doc.isd_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.doc.isd_year = e;
      } else {
        pax.inf.doc.isd_year = e;
      }
    }
    return pax;
  };

  const createExpiryInfo = (pax, type, e, isInf) => {
    if (type === "month") {
      if (!isInf) {
        pax.doc.exp_month = e;
      } else {
        pax.inf.doc.exp_month = e;
      }
    } else if (type === "day") {
      if (!isInf) {
        pax.doc.exp_day = e;
      } else {
        pax.inf.doc.exp_day = e;
      }
    } else if (type === "year") {
      if (!isInf) {
        pax.doc.exp_year = e;
      } else {
        pax.inf.doc.exp_year = e;
      }
    }
  };

  const convertDobMonth = (pax, dob, type) => {
    if (dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_month = dobAry[0];
      } else if (type === "isd") {
        pax.doc.isd_month = dobAry[0];
      } else {
        pax.doc.exp_month = dobAry[0];
      }
    } else {
      if (type === "dob") {
        pax.dob_month = "";
      } else if (type === "isd") {
        pax.doc.isd_month = "";
      } else {
        pax.doc.exp_month = "";
      }
    }
  };
  const convertDobDate = (pax, dob, type) => {
    if (dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      if (type === "dob") {
        pax.dob_day = dobAry[1];
      } else if (type === "isd") {
        pax.doc.isd_day = dobAry[1];
      } else {
        pax.doc.exp_day = dobAry[1];
      }
    } else {
      if (type === "dob") {
        pax.dob_day = "";
      } else if (type === "isd") {
        pax.doc.isd_day = "";
      } else {
        pax.doc.exp_day = "";
      }
    }
  };
  const convertDobYear = (pax, dob, type) => {
    if (dob !== null && dob !== undefined && dob !== "") {
      let dobAry = dob.split("-");
      pax.year = dobAry[2];
      if (type === "dob") {
        pax.dob_year = dobAry[2];
      } else if (type === "isd") {
        pax.doc.isd_year = dobAry[2];
      } else {
        pax.doc.exp_year = dobAry[2];
      }
    } else {
      if (type === "dob") {
        pax.dob_year = "";
      } else if (type === "isd") {
        pax.doc.isd_year = "";
      } else {
        pax.doc.exp_year = "";
      }
    }
  };
  const updatePax = (e, obj, paxType, idx) => {
    let paxList = state.passengers;
    for (let pax of paxList) {
      if (pax.sno === idx) {
        if (paxType !== "INF") {
          pax.tl = obj.title;
          pax.fn = obj.fname;
          pax.ln = obj.lname;
          pax.em = obj.email;
          pax.phc = obj.phn_code;
          pax.phn = obj.ph_num;
          pax.trave_id = e;
          pax.doc.num = obj.passport_no;
          pax.doc.na = obj.na;
          pax.doc.isc = obj.issue_cnt;
          //DOB
          convertDobMonth(pax, obj.dob, "dob");
          convertDobDate(pax, obj.dob, "dob");
          convertDobYear(pax, obj.dob, "dob");
          //ISSUE DATE
          convertDobMonth(pax, obj.issue_date, "isd");
          convertDobDate(pax, obj.issue_date, "isd");
          convertDobYear(pax, obj.issue_date, "isd");
          //EXP DATE
          convertDobMonth(pax, obj.exp_date, "exp");
          convertDobDate(pax, obj.exp_date, "exp");
          convertDobYear(pax, obj.exp_date, "exp");
          break;
        } else {
          pax.inf.tl = obj.title;
          pax.inf.fn = obj.fname;
          pax.inf.ln = obj.lname;
          pax.inf.doc.num = obj.passport_no;
          pax.inf.doc.na = obj.na;
          pax.inf.doc.isc = obj.issue_cnt;
          pax.inf.trave_id = e;
          //DOB
          convertDobMonth(pax.inf, obj.dob, "dob");
          convertDobDate(pax.inf, obj.dob, "dob");
          convertDobYear(pax.inf, obj.dob, "dob");
          //ISSUE DATE
          convertDobMonth(pax.inf, obj.issue_date, "isd");
          convertDobDate(pax.inf, obj.issue_date, "isd");
          convertDobYear(pax.inf, obj.issue_date, "isd");
          //EXP DATE
          convertDobMonth(pax.inf, obj.exp_date, "exp");
          convertDobDate(pax.inf, obj.exp_date, "exp");
          convertDobYear(pax.inf, obj.exp_date, "exp");
          break;
        }
      }
    }
    dispatch({ type: "passengers", payload: paxList });
      props.sendpsngersdata(paxList);
  };

  const handleClick = (e) => {

    let cn = e.currentTarget.classList;

    if (cn.contains('active')) {
      cn.remove('active')
    } else {
      cn.add('active')
    }
  };
  
  //Creating Selected Baggage List for Normal and Split flow
  const updateBagPrice = (price, no, idx, flow) => (obj) => {
    props.selbagprice([obj, price, no, flow]);
    let baggage = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = obj;
          if (pax.baggage === undefined) {
            baggage.push(onward);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.code != onward.code; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.code === onward.code && el.tid !== onward.tid; });
            }
            baggage.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: baggage };
          }
        } else {
          const retun = obj;
          if (pax.baggage === undefined) {
            baggage.push(retun);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.code != retun.code; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.code === retun.code && el.tid !== retun.tid; });
            }
            baggage.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: [...pax.baggage, ...baggage] };
          }
        }
      }
    });
    state.passengers=newpax
    props.sendpsngersdata(newpax);
  };
  
  //This is common for Baggage and Meal
  // const createBaggageReq = (obj, name, tid,route,flow) => {
  //   if(obj!==undefined){
  //   const payload = {
  //     "tid": tid,
  //     "nm": name,
  //     "val": obj.value,
  //     "label": obj.label,
  //     "price": obj.price,
  //     "sup_price": obj.sup_price,
  //     "sup_cur_price": obj.sup_cur_price,
  //     "mkp": obj.mkp,
  //     "jtype": flow
  //   }
  //   if(route !== undefined) {
  //     payload.route = route
  //   }
  //   if(obj.ssrKey) {
  //     payload.ssrKey = obj.ssrKey
  //   }
  //   if(obj.hashcode) {
  //     payload.hashcode = obj.hashcode
  //     payload.routeIds = obj.routeIds
  //     payload.code = obj.code
  //     payload.offerId = obj.offerId
  //   }
  //   return payload;
  // }
  // };    

  //Creating Selected Baggage List for Normal and Split flow
  const updateMealprice = (price, no, idx, flow,route) => (obj) => {
    props.selmealprice([obj, price, no, flow,route]);
    let meals = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = obj;
          if (pax.meals === undefined) {
            meals.push(onward);
            return { ...pax, meals: meals };
          } else {
            var filtered = pax.meals.filter(function (el) { return  el.route && onward.route ? el.route != onward.route : el.nm != onward.nm; });
            meals.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            return { ...pax, meals: meals };
          }
        } else {
          const retun = obj;
          if (pax.meals === undefined && retun!==undefined) {
            meals.push(retun);
            return { ...pax, meals: meals };
          } else {
            if(retun!==undefined){
            var filtered = pax.meals.filter(function (el) { return  el.route && retun.route!==undefined && retun.route ? el.route != retun.route : el.nm != retun.nm; });
            meals.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            return { ...pax, meals: meals };
          }
        }
        }
      }
    });
    state.passengers=newpax
    props.sendpsngersdata(newpax);
  };

  // const createMealsObj=(pax,obj)=>{
  //   let matched=false;
  //   let meals=pax.meals;
  //  for(let val of meals){
  //     if(val.route===obj.route){
  //       val.label=obj.label;
  //       val.price=obj.price;
  //       val.tid=obj.tid;
  //       val.sup_price=obj.sup_price;
  //       val.sup_cur_price=obj.sup_cur_price;
  //       val.val=obj.val;
  //       if(obj.ssrKey) {
  //         val.ssrKey = obj.ssrKey
  //       }
  //       if(obj.hashcode) {
  //         val.hashcode = obj.hashcode 
  //         val.routeIds = obj.routeIds 
  //         val.code = obj.code 
  //         val.offerId = obj.offerId
  //       }
  //       matched=true;
  //       break;
  //     }
  //  }
  //  if(!matched){
  //   meals=[];
  //   meals=pax.meals;
  //   meals.push(obj);
  //  }
  //  return meals;
  // };

  return (
    <div>
      
      <section>
        <div className="mainContainer">
          <Row>
            <Col>
              <div className="contactWrap">
                <div className="contactTitle">Contact Details</div>                
                {state.passengers.length > 0 && (
                  <div className="p-15">
                    {state.passengers.map((pasenger, idx) => (
                      <React.Fragment key={idx}>                          
                          {pasenger.lead && (pasenger.ty.toUpperCase() === "ADT" || pasenger.ty.toUpperCase() === "ADULT") && (
                            <>
                            <div className="contactDetailsWrap">
                                <div>
                                  <Form>
                                    <Form.Group className="">
                                      <Form.Label>Email<span>*</span></Form.Label>
                                      <Form.Control 
                                        type="email" 
                                        placeholder="Enter Email"                                  
                                        name="em"
                                        value={pasenger.em}
                                        onChange={handleChangeText(
                                          idx + 1,
                                          "em",
                                          pasenger.ty
                                        )} 
                                      />
                                    </Form.Group>
                                  </Form>
                                </div>
                                <div>
                                  <Form.Label>Mobile Number<span>*</span></Form.Label>                            
                                  <InputGroup className="">
                                    {/* <Form.Select 
                                      aria-label="Default select example"
                                      name="phc"
                                      value={pasenger.phc}
                                      onChange={handleChangeSelect(
                                        idx + 1,
                                        "phc",
                                        "code",
                                        pasenger.ty
                                      )}                                
                                      className='mobileSelect'
                                    >
                                      <option value="">Select</option>
                                      {stdCodes.map((option, index) => (
                                        <option key={index} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </Form.Select> */}
                                              
                                    <Select 
                                        options={stdCodes}
                                        name="phc"
                                        values={stdCodes.find(item => item.value == pasenger.phc)}
                                        placeholder="Select"
                                        onChange={handleChangeSelect(
                                          idx + 1,
                                          "phc",
                                          "code",
                                          pasenger.ty
                                        )}  
                                        className='codeSelect'  
                                        > 
                                        {stdCodes.map((option, index) => {
                                          return (
                                            <option key={index} value={option.value}>
                                              {option.label}
                                            </option>
                                          )
                                          }
                                        )}                 
                                    </Select>
                                    <Form.Control 
                                      aria-label="Text input with dropdown button" 
                                      name="phn"
                                      max={10}
                                      value={pasenger.phn}
                                      placeholder='Enter phone number'
                                      onChange={handleChangeText(
                                        idx + 1,
                                        "phn",
                                        pasenger.ty
                                      )}
                                    />
                                  </InputGroup>
                                </div>
                              <div><img src={GetoNWhatshapp} alt="" /> Get your E-ticket on WhatsApp <i class="icon icon-tick"></i></div>
                            </div>
                           <div className='contactNote'>*E-ticket will be sent to this email address and phone number.</div>
                            </>
                          )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <div className="mainContainer">
          <Row>
            <Col>
              <div className="travellersDetailsWrap">
                <div className="travellersDetailTitle">
                  Enter Travellers Details
                </div>
                <div className="p-15">
                  <div className="enterDetailTitle"><i class="icon icon-Info"></i> Enter your details as they appear on your passport. Use English only.</div>



                  {state.passengers.length > 0 && (
                    <div className="paxList">
                      {state.passengers.map((pasenger, idx) => (
                        <div className="paxDetailsWrapper" key={idx}>
                          <div
                            className={"travellerDetailHeader dFlex " + (idx === 0 && 'active')}
                            onClick={handleClick}
                          >
                            <div>
                            </div>
                          </div>

                          <div className="travDetailsSec">
                            <div className="travellerDetailBody">
                              <Row gutter={20}>
                                <Col span={24}>

                                </Col>
                                {/* {(pasenger.ty.toUpperCase() === "ADT" && state.adtTraList.length !== 0 || pasenger.ty.toUpperCase() === "CHD" && state.chdTraList.length !== 0) && (
                      <Col span={8}>
                        <Form.Item label="">
                          {pasenger.ty.toUpperCase() === "ADT" &&
                            state.adtTraList.length !== 0 && (
                            <>
                                <Form.Select
                                  name="title"
                                  defaultValue="Select Traveller"
                                  value={
                                    pasenger.trave_id
                                      ? pasenger.trave_id
                                      : undefined
                                  }
                                  onSelect={(value, event) =>
                                    updatePax(value, event, "ADT", idx + 1)
                                  }
                                  options={state.adtTraList}
                                  className="selectBoxShadow"
                                />
                            </>
                            )}
                          {pasenger.ty.toUpperCase() === "CHD" &&
                            state.chdTraList.length !== 0 && (
                              <>
                              {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                                <Form.Select
                                name="title"
                                defaultValue="Select Traveller"
                                value={
                                  pasenger.trave_id
                                    ? pasenger.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                  updatePax(value, event, "CHD", idx + 1)
                                }
                                options={state.chdTraList}
                                className="selectBoxShadow"
                              />
                              }
                              {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                                <Form.Select
                                name="title"
                                defaultValue="إختر المسافر"
                                value={
                                  pasenger.trave_id
                                    ? pasenger.trave_id
                                    : undefined
                                }
                                onSelect={(value, event) =>
                                  updatePax(value, event, "CHD", idx + 1)
                                }
                                options={state.chdTraList}
                                className="selectBoxShadow"
                              />
                              }
                            </>
                            )}
                        </Form.Item>
                      </Col>
                      )} */}
                              </Row>
                              <Accordion defaultActiveKey="0" className='enterDetailCollapse'>
                                <Accordion.Item eventKey="0">
                                  {(pasenger.ty.toUpperCase() === "ADT" || pasenger.ty.toUpperCase() === "ADULT" || pasenger.ty === "adult") && (
                                    <Accordion.Header><span>Adult {pasenger.fesno} {pasenger.lead ? ("Lead") : ""}</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i></Accordion.Header>
                                  )}
                                  {(pasenger.ty.toUpperCase() === "CHD" || pasenger.ty.toUpperCase() === "CHILD") && (
                                    <Accordion.Header><span>Child {pasenger.fesno}</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i></Accordion.Header>
                                  )}
                                  <Accordion.Body>
                                    <div className="enterDetailWrap">
                                      <div>
                                        <Form.Label>Title<span>*</span></Form.Label>
                                        {(pasenger.ty.toUpperCase() === "ADT" || pasenger.ty.toUpperCase() === "ADULT" || pasenger.ty === "adult") && (
                                          <>
                                            <InputGroup className="">
                                              {/* <Form.Select aria-label="Default select example"
                                                name="tl"
                                                className='titleSelect'
                                                value={pasenger.tl}
                                                onChange={handleChangeSelect(
                                                  idx + 1,
                                                  "tl",
                                                  "title",
                                                  pasenger.ty
                                                )}
                                              >
                                                <option value="">Select</option>
                                                {adt_titles.map((option, index) => (
                                                  <option key={index} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </Form.Select> */}
                                              
                                              <Select 
                                                  options={adt_titles}
                                                  name="tl"
                                                  values={adt_titles.find(item => item.value == pasenger.tl)}
                                                  placeholder="Select"
                                                  onChange={handleChangeSelect(
                                                    idx + 1,
                                                    "tl",
                                                    "title",
                                                    pasenger.ty
                                                  )}
                                                  > 
                                                  {adt_titles.map((option, index) => {
                                                    return (
                                                      <option key={index} value={option.value}>
                                                        {option.label}
                                                      </option>
                                                    )
                                                    }
                                                  )}                 
                                              </Select>
                                              <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name'
                                                name="fn"
                                                value={pasenger.fn}
                                                onChange={handleChangeText(
                                                  idx + 1,
                                                  "fn",
                                                  pasenger.ty
                                                )}
                                              />
                                            </InputGroup>
                                          </>
                                        )}
                                        {(pasenger.ty.toUpperCase() === "CHD" || pasenger.ty.toUpperCase() === "CHILD") && (
                                          <>
                                            <InputGroup className="">
                                              {/* <Form.Select aria-label="Default select example"
                                                name="title"
                                                className='titleSelect'
                                                value={pasenger.tl}
                                                onChange={handleChangeSelect(
                                                  idx + 1,
                                                  "tl",
                                                  "title",
                                                  pasenger.ty
                                                )}
                                              >
                                                <option value="">Select</option>
                                                {chd_titles.map((option, index) => (
                                                  <option key={index} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </Form.Select> */}
                                              
                                              <Select 
                                                  options={chd_titles}
                                                  name="title"
                                                  values={chd_titles.find(item => item.value == pasenger.tl)}
                                                  placeholder="Select"
                                                  onChange={handleChangeSelect(
                                                    idx + 1,
                                                    "tl",
                                                    "title",
                                                    pasenger.ty
                                                  )}
                                                  > 
                                                  {chd_titles.map((option, index) => {
                                                    return (
                                                      <option key={index} value={option.value}>
                                                        {option.label}
                                                      </option>
                                                    )
                                                    }
                                                  )}                 
                                              </Select>
                                              <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name'
                                                name="fn"
                                                value={pasenger.fn}
                                                onChange={handleChangeText(
                                                  idx + 1,
                                                  "fn",
                                                  pasenger.ty
                                                )}
                                              />
                                            </InputGroup>
                                          </>
                                        )}
                                      </div>
                                      <div>
                                        <Form.Label>Last Name<span>*</span></Form.Label>
                                        <Form.Control aria-label="Text input with dropdown button" placeholder='Enter Last Name'
                                          name="ln"
                                          value={pasenger.ln}
                                          onChange={handleChangeText(
                                            idx + 1,
                                            "ln",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div>
                                        <Form.Label>Date of Birth<span>*</span></Form.Label>
                                        <InputGroup className="">
                                          <Days
                                            updateDay={pasenger.dob_day}
                                            sendDay={handleChangeSelect(
                                              idx + 1,
                                              "day",
                                              "dob",
                                              pasenger.ty
                                            )}
                                            className='formSelect daySelect'
                                          />
                                          <Month
                                            updateMonth={pasenger.dob_month}
                                            sendMonth={handleChangeSelect(
                                              idx + 1,
                                              "month",
                                              "dob",
                                              pasenger.ty
                                            )}
                                            className='formSelect monthSelect'
                                          />
                                          <Years
                                            updateYear={pasenger.dob_year}
                                            type={pasenger.ty}
                                            sendYear={handleChangeSelect(
                                              idx + 1,
                                              "year",
                                              "dob",
                                              pasenger.ty
                                            )}
                                            className='formSelect yearSelect'
                                          />
                                        </InputGroup>
                                      </div>
                                    </div>
                                    <div className='passInfoTitle'>Passport Information</div>
                                    <div className="enterDetailWrap">
                                      <div>
                                        <Form.Label>Passport Number<span>{state.docMandatory && "*"}</span></Form.Label>
                                        <Form.Control 
                                          aria-label="Text input with dropdown button" 
                                          type="text"
                                          name="num"
                                          value={pasenger.doc.num}
                                          onChange={handleChangeText(
                                            idx + 1,
                                            "pnum",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div>
                                        <Form.Label>Nationality<span>{state.docMandatory && "*"}</span></Form.Label> 
                                        
                                        {/* <Form.Select aria-label="Default select example"
                                          name="na"
                                          value={pasenger.doc.na}
                                          onChange={handleChangeSelect(
                                          idx + 1,
                                          "na",
                                          "isc",
                                          pasenger.ty
                                          )}
                                        >
                                          <option value="">Select</option>
                                          {countrieslist.map((option, index) => (
                                            <option key={index} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </Form.Select> */}
                                        <Select 
                                            options={countrieslist}
                                            name="na"
                                            values={countrieslist.find(item => item.value == pasenger.doc.na)}
                                            placeholder="Select"
                                            onChange={handleChangeSelect(
                                            idx + 1,
                                            "na",
                                            "isc",
                                            pasenger.ty
                                            )}
                                            > 
                                            {countrieslist.map((option) => {
                                              return (
                                                <option key={option.label} value={option.label}>
                                                  {option.label}
                                                </option>
                                              )
                                              }
                                            )}                 
                                        </Select>
                                      </div>
                                      <div>
                                        <Form.Label>Issuing Country<span>{state.docMandatory && "*"}</span></Form.Label>
                                        
                                        {/* <Form.Select aria-label="Default select example"
                                          name="isc"
                                          value={pasenger.doc.isc}
                                          onChange={handleChangeSelect(
                                            idx + 1,
                                            "isc",
                                            "isd",
                                            pasenger.ty
                                          )}
                                        >
                                          <option value="">Select</option>
                                          {countrieslist.map((option, index) => (
                                            <option key={index} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </Form.Select> */}
                                        <Select 
                                            options={countrieslist}
                                            name="isc"
                                            values={countrieslist.find(item => item.value == pasenger.doc.isc)}
                                            placeholder="Select"
                                            onChange={handleChangeSelect(
                                              idx + 1,
                                              "isc",
                                              "isd",
                                              pasenger.ty
                                            )}
                                            > 
                                            {countrieslist.map((option) => {
                                              return (
                                                <option key={option.label} value={option.label}>
                                                  {option.label}
                                                </option>
                                              )
                                              }
                                            )}                 
                                        </Select>
                                      </div>
                                    </div>
                                    <div className="enterDetailWrap">
                                      <div>
                                        <Form.Label>Passport Issue Date<span>{state.docMandatory && "*"}</span></Form.Label>
                                        <InputGroup className="">
                                          <Days
                                              updateDay={pasenger.doc.isd_day}
                                              sendDay={handleChangeSelect(
                                                idx + 1,
                                                "day",
                                                "isudate",
                                                pasenger.ty
                                              )}
                                            />
                                          <Month
                                            updateMonth={pasenger.doc.isd_month}
                                            sendMonth={handleChangeSelect(
                                              idx + 1,
                                              "month",
                                              "isudate",
                                              pasenger.ty
                                            )}
                                          />
                                          <Years
                                            updateYear={pasenger.doc.isd_year}
                                            type="isuDate"
                                            sendYear={handleChangeSelect(
                                              idx + 1,
                                              "year",
                                              "isudate",
                                              pasenger.ty
                                            )}
                                          />
                                        </InputGroup>
                                      </div>
                                      <div>
                                        <Form.Label>Passport Expiry Date<span>{state.docMandatory && "*"}</span></Form.Label>
                                        <InputGroup className="">
                                        <Days
                                          updateDay={pasenger.doc.exp_day}
                                          sendDay={handleChangeSelect(
                                            idx + 1,
                                            "day",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                          />
                                          <Month
                                            updateMonth={pasenger.doc.exp_month}
                                            sendMonth={handleChangeSelect(
                                              idx + 1,
                                              "month",
                                              "expiry",
                                              pasenger.ty
                                            )}
                                          />
                                        <Years
                                            updateYear={pasenger.doc.exp_year}
                                            type="expDate"
                                            sendYear={handleChangeSelect(
                                              idx + 1,
                                              "year",
                                              "expiry",
                                              pasenger.ty
                                            )}
                                          />
                                        </InputGroup>
                                      </div>
                                    </div>
                                    {!state.extras ?
                                    <Accordion defaultActiveKey="0" className='optionalPrefCollapse'>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header><span className='d-flex align-items-center optionalPrefTitle'>Optinal Preferences <i class="icon icon-down-arrow"></i></span></Accordion.Header>
                                        <Accordion.Body>
                                          <div className="enterDetailWrap">
                                            <div>
                                              <Form.Label>Seat preference</Form.Label>
                                                {/* <Form.Select 
                                                  aria-label="Default select example"
                                                  name="isc"
                                                  value={pasenger.opref.sp}
                                                  onChange={handleChangeSelect(
                                                    idx + 1,
                                                    "seat",
                                                    "sp",
                                                    pasenger.ty
                                                  )}
                                                >
                                                  <option value="">Select</option>
                                                  {seatPrefs.map((option, index) => (
                                                    <option key={index} value={option.value}>
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </Form.Select> */}
                                              
                                              <Select 
                                                  options={seatPrefs}
                                                  name="isc"
                                                  values={seatPrefs.find(item => item.value == pasenger.opref.sp)}
                                                  placeholder="Select"
                                                  onChange={handleChangeSelect(
                                                    idx + 1,
                                                    "seat",
                                                    "sp",
                                                    pasenger.ty
                                                  )}
                                                  > 
                                                  {seatPrefs.map((option, index) => {
                                                    return (
                                                      <option key={index} value={option.value}>
                                                        {option.label}
                                                      </option>
                                                    )
                                                    }
                                                  )}                 
                                              </Select>
                                            </div>
                                            <div>
                                              <Form.Label>Airline</Form.Label>
                                              {/* <Form.Select 
                                                aria-label="Default select example"
                                                name="ffinfo.air"
                                                value={pasenger.ffinfo.air}
                                                onChange={handleChangeSelect(
                                                  idx + 1,
                                                  "freq",
                                                  "spair",
                                                  pasenger.ty
                                                )}
                                              >
                                                <option value="">Select</option>
                                                {/* {seatPrefs.map((option, index) => (
                                                  <option key={index} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </Form.Select> */}
                                              
                                              <Select 
                                                  options={state.airlines}
                                                  name="ffinfo.air"
                                                  values={state.airlines.find(item => item.value == pasenger.ffinfo.air)}
                                                  placeholder="Select"
                                                  onChange={handleChangeSelect(
                                                    idx + 1,
                                                    "freq",
                                                    "spair",
                                                    pasenger.ty
                                                  )}
                                                  > 
                                                  {state.airlines.map((option, index) => {
                                                    return (
                                                      <option key={index} value={option.value}>
                                                        {option.label}
                                                      </option>
                                                    )
                                                    }
                                                  )}                 
                                              </Select>
                                              
                                            </div>
                                            <div>
                                              <Form.Label>Frequent flyer number</Form.Label>
                                              <Form.Control 
                                                aria-label="Text input with dropdown button" 
                                                type="text"
                                                name="ffinfo.ffnum"
                                                value={pasenger.ffinfo.ffnum}
                                                placeholder=""
                                                onChange={handleChangeText(
                                                  idx + 1,
                                                  "ffnum",
                                                  pasenger.ty
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                    :                                    
                                    <Accordion defaultActiveKey="0" className='optionalPrefCollapse'>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header><span className='d-flex align-items-center optionalPrefTitle'>Extras <i class="icon icon-down-arrow"></i></span></Accordion.Header>
                                        <Accordion.Body>      
                                          <h5>{state.onwardTripSector?.split("_")[0]} - {state.onwardTripSector?.split("_")[1]}</h5>                                    
                                          {state.onwardBaggageOptions && state.onwardBaggageOptions.length !== 0 ? (
                                            <>
                                            {/* <p>Onward Baggage</p> */}
                                            <AddBaggage 
                                            sendBagprice={updateBagPrice(pasenger.ty, pasenger.fesno, idx, "O")} 
                                            bagList={state.onwardBaggageOptions}
                                            />
                                            </>
                                          ) :<p>Baggage options are not available</p>}         
                                          
                                          <h5>{state.returnTripSector?.split("_")[0]} - {state.returnTripSector?.split("_")[1]}</h5>                                   
                                          {state.returnBaggageOptions && state.returnBaggageOptions.length !== 0 ? (
                                            <>
                                            {/* <p>Return Baggage</p> */}
                                            <AddBaggage 
                                            sendBagprice={updateBagPrice(pasenger.ty, pasenger.fesno, idx, "R")} 
                                            bagList={state.returnBaggageOptions}
                                            />
                                            </>
                                          ) :<p>Baggage options are available</p>}
                                          <h5>{state.onwardTripSector?.split("_")[0]} - {state.onwardTripSector?.split("_")[1]}</h5> 
                                          {state.onwardMealsOptions && state.onwardMealsOptions.length !== 0 ? (
                                            <>
                                            {/* <p>Onward Meals</p> */}
                                            <AddMeal 
                                            sendMealprice={updateMealprice(pasenger.ty, pasenger.fesno, idx, "O")} 
                                            mealList={state.onwardMealsOptions}
                                            />
                                            </>
                                          ) : <p>Meals are not available</p>}
                                          <h5>{state.returnTripSector?.split("_")[0]} - {state.returnTripSector?.split("_")[1]}</h5> 
                                          {state.returnMealsOptions && state.returnMealsOptions.length !== 0 ? (
                                            <>
                                            {/* <p>Return Meals</p> */}
                                            <AddMeal 
                                            sendMealprice={updateMealprice(pasenger.ty, pasenger.fesno, idx, "R")} 
                                            mealList={state.returnMealsOptions}
                                            />
                                            </>
                                          ) : <p>Meals are not available</p>}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                    }
                                  </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="1">
                          <Accordion.Header><span>Infant</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i></Accordion.Header>
                          <Accordion.Body>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Title<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='titleSelect'>
                                              <option>Select</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name' />
                                      </InputGroup>
                                  </div>
                                  <div>
                                      <Form.Label>Last Name<span>*</span></Form.Label>
                                      <Form.Control aria-label="Text input with dropdown button" placeholder='Enter Last Name' />
                                  </div>
                                  <div>
                                      <Form.Label>Date of Birth<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                              </div>
                              <div className='passInfoTitle'>Passport Information</div>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Passport Number<span>*</span></Form.Label>
                                      <Form.Control aria-label="Text input with dropdown button" />
                                  </div>
                                  <div>
                                      <Form.Label>Nationality<span>*</span></Form.Label>
                                      <Form.Select aria-label="Default select example">
                                          <option>Select</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                      </Form.Select>
                                  </div>
                                  <div>
                                      <Form.Label>Issuing Country<span>*</span></Form.Label>
                                      <Form.Select aria-label="Default select example">
                                          <option>Select</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                      </Form.Select>
                                  </div>
                              </div>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Passport Issue Date<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                                  <div>
                                      <Form.Label>Passport Expiry Date<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                              </div>
                              <Accordion defaultActiveKey="0" className='optionalPrefCollapse'>
                                  <Accordion.Item eventKey="0">
                                      <Accordion.Header><span className='d-flex align-items-center optionalPrefTitle'>Optinal Preferences <i class="icon icon-down-arrow"></i></span></Accordion.Header>
                                      <Accordion.Body>
                                          <div className="enterDetailWrap">
                                              <div>
                                                  <Form.Label>Seat preference</Form.Label>
                                                  <Form.Select aria-label="Default select example">
                                                      <option>Select</option>
                                                      <option value="1">One</option>
                                                      <option value="2">Two</option>
                                                      <option value="3">Three</option>
                                                  </Form.Select>
                                              </div>
                                              <div>
                                                  <Form.Label>Airline</Form.Label>
                                                  <Form.Select aria-label="Default select example">
                                                      <option>Select</option>
                                                      <option value="1">One</option>
                                                      <option value="2">Two</option>
                                                      <option value="3">Three</option>
                                                  </Form.Select>
                                              </div>
                                              <div>
                                                  <Form.Label>Frequent flyer number</Form.Label>
                                                  <Form.Control aria-label="Text input with dropdown button" />
                                              </div>
                                          </div>
                                      </Accordion.Body>
                                  </Accordion.Item>
                              </Accordion>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                          <Accordion.Header><span>Adult 2</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i></Accordion.Header>
                          <Accordion.Body>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Title<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='titleSelect'>
                                              <option>Select</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name' />
                                      </InputGroup>
                                  </div>
                                  <div>
                                      <Form.Label>Last Name<span>*</span></Form.Label>
                                      <Form.Control aria-label="Text input with dropdown button" placeholder='Enter Last Name' />
                                  </div>
                                  <div>
                                      <Form.Label>Date of Birth<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                              </div>
                              <div className='passInfoTitle'>Passport Information</div>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Passport Number<span>*</span></Form.Label>
                                      <Form.Control aria-label="Text input with dropdown button" />
                                  </div>
                                  <div>
                                      <Form.Label>Nationality<span>*</span></Form.Label>
                                      <Form.Select aria-label="Default select example">
                                          <option>Select</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                      </Form.Select>
                                  </div>
                                  <div>
                                      <Form.Label>Issuing Country<span>*</span></Form.Label>
                                      <Form.Select aria-label="Default select example">
                                          <option>Select</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                      </Form.Select>
                                  </div>
                              </div>
                              <div className="enterDetailWrap">
                                  <div>
                                      <Form.Label>Passport Issue Date<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                                  <div>
                                      <Form.Label>Passport Expiry Date<span>*</span></Form.Label>
                                      <InputGroup className="">
                                          <Form.Select aria-label="Default select example" className='daySelect'>
                                              <option>Day</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='monthSelect'>
                                              <option>Month</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                          <Form.Select aria-label="Default select example" className='yearSelect'>
                                              <option>Year</option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                          </Form.Select>
                                      </InputGroup>
                                  </div>
                              </div>
                              <Accordion defaultActiveKey="0" className='optionalPrefCollapse'>
                                  <Accordion.Item eventKey="0">
                                      <Accordion.Header><span className='d-flex align-items-center optionalPrefTitle'>Optinal Preferences <i class="icon icon-down-arrow"></i></span></Accordion.Header>
                                      <Accordion.Body>
                                          <div className="enterDetailWrap">
                                              <div>
                                                  <Form.Label>Seat preference</Form.Label>
                                                  <Form.Select aria-label="Default select example">
                                                      <option>Select</option>
                                                      <option value="1">One</option>
                                                      <option value="2">Two</option>
                                                      <option value="3">Three</option>
                                                  </Form.Select>
                                              </div>
                                              <div>
                                                  <Form.Label>Airline</Form.Label>
                                                  <Form.Select aria-label="Default select example">
                                                      <option>Select</option>
                                                      <option value="1">One</option>
                                                      <option value="2">Two</option>
                                                      <option value="3">Three</option>
                                                  </Form.Select>
                                              </div>
                                              <div>
                                                  <Form.Label>Frequent flyer number</Form.Label>
                                                  <Form.Control aria-label="Text input with dropdown button" />
                                              </div>
                                          </div>
                                      </Accordion.Body>
                                  </Accordion.Item>
                              </Accordion>
                          </Accordion.Body>
                      </Accordion.Item> */}
                              </Accordion>
                              {/* <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item autoComplete="off" className="pRelative">
                          <label className="requiredField"></label>
                          {pasenger.ty.toUpperCase() === "ADT" && (
                            <>
                            
                              <input
                                addonBefore={
                                  <Form.Select
                                    name="title"
                                    defaultValue="Select"
                                    value={pasenger.tl ? pasenger.tl : undefined}
                                    onChange={handleChangeSelect(
                                      idx + 1,
                                      "tl",
                                      "title",
                                      pasenger.ty
                                    )}
                                    options={adt_titles}
                                  />
                                }
                                className="selectBoxShadow"
                                type="text"
                                name="fn"
                                value={pasenger.fn}
                                onChange={handleChangeText(
                                  idx + 1,
                                  "fn",
                                  pasenger.ty
                                )}
                              />
                              </>
                          )}
                          {pasenger.ty.toUpperCase() === "CHD" && (
                            <input
                              addonBefore={
                                <>
                                <Form.Select
                                name="tl"
                                defaultValue= "Select"
                                value={pasenger.tl ? pasenger.tl : undefined}
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "tl",
                                  "title",
                                  pasenger.ty
                                )}
                                options={chd_titles}
                              />
                            </>
                              }
                              className="selectBoxShadow"
                              type="text"
                              name="fn"
                              value={pasenger.fn}
                              onChange={handleChangeText(
                                idx + 1,
                                "fn",
                                pasenger.ty
                              )}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label className="requiredField"></label>
                          <input
                            type="text"
                            className="innerBoxShadow"
                            name="ln"
                            value={pasenger.ln}
                            onChange={handleChangeText(
                              idx + 1,
                              "ln",
                              pasenger.ty
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          {pasenger.ty === "ADT" && (
                            <label
                              className={
                                state.docMandatory === "yes" ||
                                props.oonwardsupp === "3" ||
                                props.returnsupp === "3" ||
                                props.oonwardsupp === "4" ||
                                props.returnsupp === "4" ||
                                props.oonwardsupp === "5" ||
                                props.returnsupp === "5" ||
                                props.oonwardsupp === "6" ||
                                props.returnsupp === "6" ||
                                props.oonwardsupp === "7" ||
                                props.returnsupp === "7"
                                  ? "requiredField"
                                  : "notRequiredField"
                              }
                            >
                            </label>
                          )}
                          {pasenger.ty === "CHD" && (
                            <label className="requiredField">
                            </label>
                          )}
                          <div className="onlyFlex">
                            <div className="dateMY month">
                              {/* <Month
                                updateMonth={pasenger.dob_month}
                                sendMonth={handleChangeSelect(
                                  idx + 1,
                                  "month",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                            <div className="dateMY day">
                              {/* <Days
                                updateDay={pasenger.dob_day}
                                sendDay={handleChangeSelect(
                                  idx + 1,
                                  "day",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                            <div className="dateMY year">
                              {/* <Years
                                updateYear={pasenger.dob_year}
                                type={pasenger.ty}
                                sendYear={handleChangeSelect(
                                  idx + 1,
                                  "year",
                                  "dob",
                                  pasenger.ty
                                )}
                              />
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row> */}
                              {/* {pasenger.lead && pasenger.ty.toUpperCase() === "ADT" && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item className="pRelative">
                            <label className="requiredField"></label>
                            <input
                              type="text"
                              className="innerBoxShadow"
                              name="em"
                              value={pasenger.em}
                              onChange={handleChangeText(
                                idx + 1,
                                "em",
                                pasenger.ty
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item autoComplete="off" className="pRelative">
                            <label className="requiredField">
                            </label>
                            <input
                              addonBefore={
                                <>
                                <Form.Select
                                optionLabelProp="value"
                                popupClassName="contactDpDown"
                                name="phc"
                                showSearch
                                defaultValue="Select"
                                value={
                                  pasenger.phc ? pasenger.phc : undefined
                                }
                                onChange={handleChangeSelect(
                                  idx + 1,
                                  "phc",
                                  "code",
                                  pasenger.ty
                                )}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                // options={stdCodes}
                              />
                            </>
                              }
                              className="selectBoxShadow enterContactNumber"
                              type="number"
                              name="phn"
                              max={10}
                              value={pasenger.phn ? pasenger.phn : undefined}
                              onKeyPress={handleKeyPress} {...props}
                              onChange={handleChangeText(
                                idx + 1,
                                "phn",
                                pasenger.ty
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )} */}
                              <div className="emailDivider"></div>
                              {/* <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indpassportenable === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                          </label>
                          <input
                            type="text"
                            className="innerBoxShadow"
                            name="num"
                            value={pasenger.doc.num}
                            onChange={handleChangeText(
                              idx + 1,
                              "pnum",
                              pasenger.ty
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item className="pRelative">
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.checkmandatoryflagnat === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                          </label>
                              <Form.Select
                              defaultValue= "Select"
                              showSearch
                              optionFilterProp="children"
                              name="na"
                              value={
                                pasenger.doc.na ? pasenger.doc.na : undefined
                              }
                              className="selectBoxShadow"
                              onChange={handleChangeSelect(
                                idx + 1,
                                "na",
                                "isc",
                                pasenger.ty
                              )}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            //   options={countrieslist}
                            />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                          </label>
                           
                              <Form.Select
                            defaultValue= "Select"
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={
                              pasenger.doc.isc ? pasenger.doc.isc : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                              idx + 1,
                              "isc",
                              "isd",
                              pasenger.ty
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // options={countrieslist}
                          />
                          
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20} className="issueExpiryDateWrap">
                      <Col span={8}>
                        <Form.Item>
                          <label className="notRequiredField">
                          </label>
                          <div className="onlyFlex">
                                  <div className="dateMY month">
                                    {/* <Month
                                      updateMonth={pasenger.doc.isd_month}
                                      sendMonth={handleChangeSelect(
                                        idx + 1,
                                        "month",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY day">
                                    {/* <Days
                                      updateDay={pasenger.doc.isd_day}
                                      sendDay={handleChangeSelect(
                                        idx + 1,
                                        "day",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                  <div className="dateMY year">
                                    {/* <Years
                                      updateYear={pasenger.doc.isd_year}
                                      type="isuDate"
                                      sendYear={handleChangeSelect(
                                        idx + 1,
                                        "year",
                                        "isudate",
                                        pasenger.ty
                                      )}
                                    />
                                  </div>
                                </div>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item>
                          <label
                            className={
                              state.docMandatory === "yes" ||
                              props.indpassportenable === "true"
                                ? "requiredField"
                                : "notRequiredField"
                            }
                          >
                          </label>
                          <div className="onlyFlex">
                                      <div className="dateMY month">
                                        {/* <Month
                                          updateMonth={pasenger.doc.exp_month}
                                          sendMonth={handleChangeSelect(
                                            idx + 1,
                                            "month",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY day">
                                        {/* <Days
                                          updateDay={pasenger.doc.exp_day}
                                          sendDay={handleChangeSelect(
                                            idx + 1,
                                            "day",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                      <div className="dateMY year">
                                        {/* <Years
                                          updateYear={pasenger.doc.exp_year}
                                          type="expDate"
                                          sendYear={handleChangeSelect(
                                            idx + 1,
                                            "year",
                                            "expiry",
                                            pasenger.ty
                                          )}
                                        />
                                      </div>
                                    </div>
                        </Form.Item>
                      </Col>
                    </Row> */}
                            </div>

                            {pasenger.inf !== "" && (                              
                              <>            
                                {/* <Accordion.Header> */}
                                  <span>Infant {pasenger.inf.fesno}
                                    {pasenger.sno}</span> <i class="icon icon-Plus"></i><i class="icon icon-Minus"></i>
                                {/* </Accordion.Header> */}
                                {/* <Accordion.Body> */}
                                    <InputGroup className="">
                                      {/* <Form.Select aria-label="Default select example"
                                        name="title"
                                        className='titleSelect'
                                        value={pasenger.inf.tl}
                                        onChange={handleChangeSelect(
                                            idx + 1,
                                            "tl",
                                            "title",
                                            "INF"
                                        )}
                                      >
                                        <option value="">Select</option>
                                        {inf_titles.map((option, index) => (
                                          <option key={index} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </Form.Select> */}
                                              
                                      <Select 
                                          options={inf_titles}
                                          name="title"
                                          values={inf_titles.find(item => item.value == pasenger.inf.tl)}
                                          placeholder="Select"
                                          onChange={handleChangeSelect(
                                              idx + 1,
                                              "tl",
                                              "title",
                                              "INF"
                                          )}
                                          > 
                                          {inf_titles.map((option, index) => {
                                            return (
                                              <option key={index} value={option.value}>
                                                {option.label}
                                              </option>
                                            )
                                            }
                                          )}                 
                                      </Select>
                                      <Form.Control aria-label="Text input with dropdown button" placeholder='Enter First Name'
                                        name="fn"
                                        value={pasenger.inf.fn}
                                        onChange={handleChangeText(
                                            idx + 1,
                                            "fn",
                                            "INF"
                                        )}
                                      />
                                    </InputGroup>
                                    
                                    <div>
                                      <Form.Label>Last Name<span>*</span></Form.Label>
                                      <Form.Control aria-label="Text input with dropdown button" placeholder='Enter Last Name'
                                        name="ln"
                                        value={pasenger.inf.ln}
                                        onChange={handleChangeText(
                                            idx + 1,
                                            "ln",
                                            "INF"
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <Form.Label>Date of Birth<span>*</span></Form.Label>
                                      <InputGroup className="">
                                        <Days
                                          updateDay={pasenger.inf.dob_day}
                                          sendDay={handleChangeSelect(
                                              idx + 1,
                                              "day",
                                              "dob",
                                              "INF"
                                          )}
                                        />
                                        <Month
                                          updateMonth={pasenger.inf.dob_month}
                                          sendMonth={handleChangeSelect(
                                              idx + 1,
                                              "month",
                                              "dob",
                                              "INF"
                                          )}
                                        />
                                        <Years
                                          updateYear={pasenger.inf.dob_year}
                                          type="Inf"
                                          sendYear={handleChangeSelect(
                                              idx + 1,
                                              "year",
                                              "dob",
                                              "INF"
                                          )}
                                        />
                                      </InputGroup>
                                    </div>
                                  <div className='passInfoTitle'>Passport Information</div>
                                  <div className="enterDetailWrap">
                                    <div>
                                      <Form.Label>Passport Number<span>{state.docMandatory && "*"}</span></Form.Label>
                                      <Form.Control 
                                        aria-label="Text input with dropdown button" 
                                        type="text"
                                        name="num"
                                        value={pasenger.inf.doc.num}
                                        onChange={handleChangeText(
                                            idx + 1,
                                            "pnum",
                                            "INF"
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <Form.Label>Nationality<span>{state.docMandatory && "*"}</span></Form.Label> 
                                      
                                      {/* <Form.Select aria-label="Default select example"
                                          name="na"
                                          value={pasenger.inf.doc.na}
                                          onChange={handleChangeSelect(
                                          idx + 1,
                                          "nationality",
                                          "isc",
                                          "INF"
                                          )}
                                      >
                                        <option value="">Select</option>
                                        {countrieslist.map((option, index) => (
                                          <option key={index} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </Form.Select> */}

                                      <Select 
                                          options={countrieslist}
                                          name="na"
                                          values={countrieslist.find(item => item.value == pasenger.inf.doc.na)}
                                          placeholder="Select"
                                          onChange={handleChangeSelect(
                                          idx + 1,
                                          "nationality",
                                          "isc",
                                          "INF"
                                          )}
                                          > 
                                          {countrieslist.map((option, index) => {
                                            return (
                                              <option key={index} value={option.value}>
                                                {option.label}
                                              </option>
                                            )
                                            }
                                          )}                 
                                      </Select>
                                    </div>
                                    <div>
                                      <Form.Label>Issuing Country<span>{state.docMandatory && "*"}</span></Form.Label>
                                      
                                      {/* <Form.Select aria-label="Default select example"
                                          name="isc"
                                          value={pasenger.inf.doc.isc}
                                          onChange={handleChangeSelect(
                                              idx + 1,
                                              "issuedcountry",
                                              "isd",
                                              "INF"
                                          )}
                                      >
                                        <option value="">Select</option>
                                        {countrieslist.map((option, index) => (
                                          <option key={index} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </Form.Select> */}

                                      <Select 
                                          options={countrieslist}
                                          name="isc"
                                          values={countrieslist.find(item => item.value == pasenger.inf.doc.isc)}
                                          placeholder="Select"
                                          onChange={handleChangeSelect(
                                              idx + 1,
                                              "issuedcountry",
                                              "isd",
                                              "INF"
                                          )}
                                          > 
                                          {countrieslist.map((option, index) => {
                                            return (
                                              <option key={index} value={option.value}>
                                                {option.label}
                                              </option>
                                            )
                                            }
                                          )}                 
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="enterDetailWrap">
                                    <div>
                                      <Form.Label>Passport Issue Date<span>{state.docMandatory && "*"}</span></Form.Label>
                                      <InputGroup className="">
                                        <Days
                                          updateDay={pasenger.inf.doc.isd_day}
                                          sendDay={handleChangeSelect(
                                          idx + 1,
                                          "day",
                                          "isudate",
                                          "INF"
                                          )}
                                          />
                                        <Month
                                          updateMonth={pasenger.inf.doc.isd_month}
                                          sendMonth={handleChangeSelect(
                                          idx + 1,
                                          "month",
                                          "isudate",
                                          "INF"
                                          )}
                                        />
                                        <Years
                                          updateYear={pasenger.inf.doc.isd_year}
                                          type="isuDate"
                                          sendYear={handleChangeSelect(
                                          idx + 1,
                                          "year",
                                          "isudate",
                                          "INF"
                                          )}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div>
                                      <Form.Label>Passport Expiry Date<span>{state.docMandatory && "*"}</span></Form.Label>
                                      <InputGroup className="">
                                      <Days
                                        updateDay={pasenger.inf.doc.exp_day}
                                        sendDay={handleChangeSelect(
                                        idx + 1,
                                        "day",
                                        "expiry",
                                        "INF"
                                        )}
                                        />
                                        <Month
                                          updateMonth={pasenger.inf.doc.exp_month}
                                          sendMonth={handleChangeSelect(
                                          idx + 1,
                                          "month",
                                          "expiry",
                                          "INF"
                                          )}
                                        />
                                      <Years
                                        updateYear={pasenger.inf.doc.exp_year}
                                        type="expDate"
                                        sendYear={handleChangeSelect(
                                        idx + 1,
                                        "year",
                                        "expiry",
                                        "INF"
                                        )}
                                        />
                                      </InputGroup>
                                    </div>
                                  </div>
                                {/* </Accordion.Body> */}
                              </>
                            )}

                            {/* {pasenger.inf !== "" && (
                    <div className="travellerDetailsInfant">
                        <div className="travellerDetailHeader dFlex">
                        <div>
                            <h3>
                            <span className="traveller">
                                Traveller {pasenger.inf.fesno}
                                {pasenger.sno}
                            </span>&nbsp;
                            <span className="stages"></span>
                            </h3>
                        </div>
                        {/* <div>
                            <span>
                            <i className="icon icon-Plus"></i>
                            </span>
                        </div>
                        </div>
                        <div className="travellerDetailBody">
                        <Row gutter={20}>
                            <Col span={24}>
                                
                            </Col>
                            {state.infTraList.length !== 0 && (
                            <Col span={8}>
                                <Form.Item label="">
                                    <>
                            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                            <Form.Select
                                    name="title"
                                    defaultValue="Select Traveller"
                                    value={
                                    pasenger.inf.trave_id
                                        ? pasenger.inf.trave_id
                                        : undefined
                                    }
                                    onSelect={(value, event) =>
                                    updatePax(value, event, "INF", idx + 1)
                                    }
                                    options={state.infTraList}
                                    className="selectBoxShadow"
                                />
                            }
                            {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                            <Form.Select
                                    name="title"
                                    defaultValue="إختر المسافر"
                                    value={
                                    pasenger.inf.trave_id
                                        ? pasenger.inf.trave_id
                                        : undefined
                                    }
                                    onSelect={(value, event) =>
                                    updatePax(value, event, "INF", idx + 1)
                                    }
                                    options={state.infTraList}
                                    className="selectBoxShadow"
                                />
                            }
                        </>
                                </Form.Item>
                            </Col>
                            )}
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>
                            <Form.Item
                                autoComplete="off"
                                className="pRelative"
                            >
                                <label className="requiredField">
                                </label>
                                <input
                                addonBefore={
                                    <>
                            <Form.Select
                            name="tl"
                            defaultValue="Select"
                            value={
                                pasenger.inf.tl
                                ? pasenger.inf.tl
                                : undefined
                            }
                            onChange={handleChangeSelect(
                                idx + 1,
                                "tl",
                                "title",
                                "INF"
                            )}
                            options={inf_titles}
                            />
                        </>
                                }
                                className="selectBoxShadow"
                                type="text"
                                name="fn"
                                value={pasenger.inf.fn}
                                onChange={handleChangeText(
                                    idx + 1,
                                    "fn",
                                    "INF"
                                )}
                                />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item className="pRelative">
                                <label className="requiredField">
                                </label>
                                <input
                                type="text"
                                className="innerBoxShadow"
                                name="ln"
                                value={pasenger.inf.ln}
                                onChange={handleChangeText(
                                    idx + 1,
                                    "ln",
                                    "INF"
                                )}
                                />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item>
                                <label className="requiredField">
                                </label>
                                <div className="onlyFlex">
                                <div className="dateMY month">
                                    {/* <Month
                                    updateMonth={pasenger.inf.dob_month}
                                    sendMonth={handleChangeSelect(
                                        idx + 1,
                                        "month",
                                        "dob",
                                        "INF"
                                    )}
                                    />
                                </div>
                                <div className="dateMY day">
                                    {/* <Days
                                    updateDay={pasenger.inf.dob_day}
                                    sendDay={handleChangeSelect(
                                        idx + 1,
                                        "day",
                                        "dob",
                                        "INF"
                                    )}
                                    />
                                </div>
                                <div className="dateMY year">
                                    {/* <Years
                                    updateYear={pasenger.inf.dob_year}
                                    type="Inf"
                                    sendYear={handleChangeSelect(
                                        idx + 1,
                                        "year",
                                        "dob",
                                        "INF"
                                    )}
                                    />
                                </div>
                                </div>
                            </Form.Item>
                            </Col>
                        </Row>
                        <div className="emailDivider"></div>
                        <Row gutter={20}>
                            <Col span={8}>
                            <Form.Item className="pRelative">
                                <label
                                className={
                                    state.docMandatory === "yes" ||
                                    props.indpassportenable === "true"
                                    ? "requiredField"
                                    : "notRequiredField"
                                }
                                >
                                </label>
                                <input
                                type="text"
                                className="innerBoxShadow"
                                name="num"
                                value={pasenger.inf.doc.num}
                                onChange={handleChangeText(
                                    idx + 1,
                                    "pnum",
                                    "INF"
                                )}
                                />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item className="pRelative">
                                <label
                                className={
                                    state.docMandatory === "yes" ||
                                    props.checkmandatoryflagnat === "true"
                                    ? "requiredField"
                                    : "notRequiredField"
                                }
                                >
                                </label>
                                <>
                            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                            <Form.Select
                            defaultValue="Select"
                            showSearch
                            optionFilterProp="children"
                            name="na"
                            value={
                            pasenger.inf.doc.na
                                ? pasenger.inf.doc.na
                                : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                            idx + 1,
                            "nationality",
                            "isc",
                            "INF"
                            )}
                            filterOption={(input, option) =>
                            (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // options={countrieslist}
                        />
                            }
                            {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                            <Form.Select
                            defaultValue="اختار"
                            showSearch
                            optionFilterProp="children"
                            name="na"
                            value={
                            pasenger.inf.doc.na
                                ? pasenger.inf.doc.na
                                : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                            idx + 1,
                            "nationality",
                            "isc",
                            "INF"
                            )}
                            filterOption={(input, option) =>
                            (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // options={countrieslist}
                        />
                            }
                        </>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item>
                                <label
                                className={
                                    state.docMandatory === "yes"
                                    ? "requiredField"
                                    : "notRequiredField"
                                }
                                >
                                </label>
                                <>
                            {(sessionStorage.getItem("SEL_LAG") === null || sessionStorage.getItem("SEL_LAG") === "English") &&
                            <Form.Select
                            defaultValue="Select"
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={
                                pasenger.inf.doc.isc
                                ? pasenger.inf.doc.isc
                                : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                                idx + 1,
                                "issuedcountry",
                                "isd",
                                "INF"
                            )}
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // options={countrieslist}
                            />
                            }
                            {(sessionStorage.getItem("SEL_LAG") === "Arabic") &&
                            <Form.Select
                            defaultValue="اختار"
                            showSearch
                            optionFilterProp="children"
                            name="isc"
                            value={
                                pasenger.inf.doc.isc
                                ? pasenger.inf.doc.isc
                                : undefined
                            }
                            className="selectBoxShadow"
                            onChange={handleChangeSelect(
                                idx + 1,
                                "issuedcountry",
                                "isd",
                                "INF"
                            )}
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // options={countrieslist}
                            />
                            }
                        </>
                            </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20} className="issueExpiryDateWrap">
                            <Col span={8}>
                            <Form.Item>
                                <label className="notRequiredField">
                                </label>
                                <div className="onlyFlex">
                                        <div className="dateMY month">
                                        {/* <Month
                                            updateMonth={pasenger.inf.doc.isd_month}
                                            sendMonth={handleChangeSelect(
                                            idx + 1,
                                            "month",
                                            "isudate",
                                            "INF"
                                            )}
                                        />
                                        </div>
                                        <div className="dateMY day">
                                        {/* <Days
                                            updateDay={pasenger.inf.doc.isd_day}
                                            sendDay={handleChangeSelect(
                                            idx + 1,
                                            "day",
                                            "isudate",
                                            "INF"
                                            )}
                                        /> 
                                        </div>
                                        <div className="dateMY year">
                                        {/* <Years
                                            updateYear={pasenger.inf.doc.isd_year}
                                            type="isuDate"
                                            sendYear={handleChangeSelect(
                                            idx + 1,
                                            "year",
                                            "isudate",
                                            "INF"
                                            )}
                                        />
                                        </div>
                                    </div>
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item>
                                <label
                                className={
                                    state.docMandatory === "yes" ||
                                    props.indpassportenable === "true"
                                    ? "requiredField"
                                    : "notRequiredField"
                                }
                                >
                                </label>
                                <div className="onlyFlex">
                                            <div className="dateMY month">
                                            {/* <Month
                                                updateMonth={pasenger.inf.doc.exp_month}
                                                sendMonth={handleChangeSelect(
                                                idx + 1,
                                                "month",
                                                "expiry",
                                                "INF"
                                                )}
                                            />
                                            </div>
                                            <div className="dateMY day">
                                            {/* <Days
                                                updateDay={pasenger.inf.doc.exp_day}
                                                sendDay={handleChangeSelect(
                                                idx + 1,
                                                "day",
                                                "expiry",
                                                "INF"
                                                )}
                                            />
                                            </div>
                                            <div className="dateMY year">
                                            {/* <Years
                                                updateYear={pasenger.inf.doc.exp_year}
                                                type="expDate"
                                                sendYear={handleChangeSelect(
                                                idx + 1,
                                                "year",
                                                "expiry",
                                                "INF"
                                                )}
                                            /> 
                                            </div>
                                        </div>
                            </Form.Item>
                            </Col>
                        </Row>
                        </div>
                    </div>
                    )} */}
                          </div>

                        </div>
                      ))}
                    </div>
                  )}





                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  )
}

export default TravellerDetails
