/**
 * @ Author: Aman Goyal
 * @ Description: Result Details
 */

import React, { useContext, useEffect, useState } from 'react';
import ModifySearch from './ChildComp/ModifySearch';
import { Context } from '../../../../App';
import SortBy from './ChildComp/SortBy';
import FlightFilters from './ChildComp/FlightFilters';
import FlightDetails from './ChildComp/FlightDetails';
import { useDispatch, useSelector } from 'react-redux';
import { handleMulticity, setArrivalDatas, setChildAges, setClassType, setDepartureDatas, setDeparturedate, setMultiArrival, setMultiDeparture, setMultiDeparturedate, setReturnDate, setTotalAdults, setTotalChildrens, setTotalInfants, setTripWays } from '../../../../toolkit/Slices/SearchFlightSlice';
import { handleDateFormat } from '../../../common/services/DateValidations';
import { handlePostApi } from '../../../../utilities/Utils';
import Static from './ChildComp/Static';
import loaderImg from '../../../../assets/Gifs/Double Ring-1s-200px.gif'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';
import AirlineLogo from "../../../../assets/images/result/QR.png";
import NonStop from "../../../../assets/images/result/nonStop.png";
import OneStop from "../../../../assets/images/result/oneStop.png";
import MorethanOneStop from "../../../../assets/images/result/morethanOneStops.png";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';
import FlightSearchLoader from './FlightSearchLoader';
import NoFlights from "../../../../assets/images/home/other/noFlight.png";

const resultsResponse = require("../../../../JSON/response-Roundtrip.json")
const BASE_CURRENCY = process.env.REACT_APP_BASE_CUR;

function FlightResultDetails(props) {
  const [context, setContext] = useContext(Context);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);

  const parseQueryString = () => {
    const payload = {};
    for (const [key, value] of searchParams.entries()) {
      payload[key] = value;
    }
    return payload;
  }

  const [reviewLoader, setReviewLoader] = useState(false);
  const [request, setRequest] = useState(location ? parseQueryString() : "");
  const dispatch = useDispatch();
  const slicedata = useSelector((state) => state.searchflight.value);
  const [base_usr_exrate, setBaseToUserExrate] = useState(1);
  const [finalData, setFinalData] = useState({
    adults: '',
    children: '',
    infants: '',
    tt: '',
    cabin: '',
    stops: false,
    pref_airlines: '',
    ages: [],
    segments: [
      {
        depfrom: '',
        arrto: '',
        depdate: ''
      }
    ]
  })
  const [raw_srp_data, setRawSRPData] = useState("");
  const [loader, setLoader] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [flightData, setFlightData] = useState([])
  const [width, setWidth] = useState(false);

  useEffect(() => {
    const storedData = sessionStorage.getItem('flightSlice');
    const urlChanges = request;

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("parsedData", parsedData);

      // Dispatch actions for setting various data
      dispatch(setTotalAdults(urlChanges.adt ? Number(urlChanges.adt) : parsedData?.adult));
      dispatch(setTotalChildrens(urlChanges.adt ? Number(urlChanges.chd) : parsedData?.children));
      dispatch(setTotalInfants(urlChanges.adt ? Number(urlChanges.inf) : parsedData?.infant));
      dispatch(setTripWays(urlChanges.tt ? urlChanges.tt === "2" ? 'round' : urlChanges.tt === "3" ? 'multi' : 'one' : parsedData?.tripWay));
      dispatch(setClassType(urlChanges.cls ? urlChanges.cls : parsedData?.ClassType));

      // If child ages are present, dispatch actions for each child age
      if (parsedData.childAges?.length) {
        parsedData.childAges.forEach((newData, index) => {
          dispatch(setChildAges({ index, newData }));
        });
      }

      let updateSegment = [];

      if (parsedData.tripWay === 'multi' && parsedData.multicity) {

        // If trip way is multi and multicity data exists, dispatch actions for multi-city segments
        parsedData?.multicity?.forEach((data, index) => {
          dispatch(setMultiDeparture({ index, value: data?.departure }));
          dispatch(setMultiArrival({ index, value: data?.arrival }));
          dispatch(setMultiDeparturedate({ index, date: data.departureDate }));

          // Add segment data to updateSegment array
          updateSegment.push({
            depfrom: data?.departure?.iata,
            arrto: data?.arrival?.iata,
            // depdate: handleDateFormat(data?.departureDate),
            depdate: data?.departureDate,
          });
          console.log("runnn 76")
        });
        console.log("runnn 78")
      } else {
        // Dispatch actions for departure and arrival data
        dispatch(setDepartureDatas(parsedData?.departure));
        dispatch(setArrivalDatas(parsedData?.arrival));
        dispatch(setDeparturedate(urlChanges.dd ? urlChanges.dd : parsedData?.departureDate));
        dispatch(setReturnDate(urlChanges.ad ? urlChanges.ad : parsedData?.returnDate));

        // Add segment data for one-way or round-trip flights
        updateSegment.push({
          depfrom: urlChanges.srcCode ? urlChanges.srcCode : parsedData?.departure?.iata,
          arrto: urlChanges.destCode ? urlChanges.destCode : parsedData?.arrival?.iata,
          // depdate: handleDateFormat(parsedData.departureDate),
          depdate: parsedData?.departureDate,
        });
        console.log("runnn 91")
        if (parsedData.returnDate && parsedData.tripWay === 'round') {
          // If return date exists, add return segment data
          updateSegment.push({
            depfrom: urlChanges.destCode ? urlChanges.destCode : parsedData?.arrival?.iata,
            arrto: urlChanges.srcCode ? urlChanges.srcCode : parsedData?.departure?.iata,
            // depdate: handleDateFormat(parsedData.returnDate),
            depdate: parsedData.returnDate,
          });
        }
      }
      // Set final data with all the collected information
      var triptype = 2;
      if(parsedData.tripWay === 'multi') {
        triptype = 3
      } else if(parsedData.tripWay === 'round') {
        triptype = 2
      } else {
        triptype = 1
      }
      setFinalData({
        ...finalData,
        adults: urlChanges.adt ? Number(urlChanges.adt) : parsedData.adult,
        children: urlChanges.chd ? Number(urlChanges.chd) : parsedData.children,
        infants: urlChanges.inf ? Number(urlChanges.inf) : parsedData.infant,
        ages: parsedData.childAges,
        cabin: urlChanges.cls ? urlChanges.cls : parsedData.ClassType,
        tt: urlChanges.tt ? Number(urlChanges.tt) : triptype,
        segments: updateSegment,
        stops: urlChanges.ns ? urlChanges.ns === "false" ? false: true : parsedData.ns,
        refundable: parsedData.refundable,
        baggage: parsedData.baggage,
        pref_airlines: parsedData.prefAirlines,
      });
    }
    console.log(finalData)
  }, []);

  // const [flightData, setFlightData] = useState(resultsResponse.data.data);
  useEffect(() => {
    setRawSRPData({...flightData})
  }, [])
  const handleFlightData = () => {
    setLoader(true);
    setNotFound(false);
    if (finalData.adults !== null && finalData.adults !== undefined && finalData.adults !== '' && flightData?.length === 0) {
      const response = handlePostApi('/searchFlight', finalData);
      response.then(function (result) {
        console.log(result)
        if (result?.data?.success) { 
          if(result?.data?.data?.data !== undefined) {
            setFlightData(result?.data?.data?.data)
          } else {
            setFlightData(result?.data?.data)
            console.log("dataaaaaaaaa", result?.data?.data)
          }
          setLoader(false);
        } else {
          setNotFound(true);
          setLoader(false);
          // hardcoded response
          // setFlightData(resultsResponse.data.data);
        }
      }).catch(error => {
        console.log("Error occurred in Flight search", error)
      });
    }
  }
  // console.log(flightData)
  useEffect(() => {
    handleFlightData();
  }, [finalData])
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    // if (flightData.length === 0) {
    //   const timer = setTimeout(() => {
    //     setShowMessage(true);
    //   }, 1000);
    //   return () => clearTimeout(timer);
    // }
  }, []);
  const clearAllFilter = (data) => {
    setFlightData(data);
  }

  useEffect(() => {
    var userCur = context.userCur!==undefined && context.userCur!==""?context.userCur:sessionStorage.getItem("SEL_CUR")
    var exRates = JSON.parse(localStorage.getItem("KF_EX_RATES"))
    if(context.ex_rates === undefined || context.ex_rates === "") {
      setContext({...context, ex_rates: exRates})
    }
    if((context.ex_rates !==undefined && context.ex_rates!=="") || (exRates !== undefined && exRates !== "") && userCur!==undefined && userCur!==""){
      if(BASE_CURRENCY!==userCur){
          finalData.cur=userCur;
          let currecny=BASE_CURRENCY+"-"+userCur;
          getExchangeRate(currecny)
      }else if(BASE_CURRENCY===userCur){
        setBaseToUserExrate(1)
        finalData.cur=userCur;
      }
    }else{
      setBaseToUserExrate(1)
    }
  }, [context.userCur])
  
  useEffect(() => {
  if(context.ex_rates!=="" && context.ex_rates!==undefined){
    let currecny=BASE_CURRENCY+"-"+finalData.cur;
    getExchangeRate(currecny)
  } 
  }, [context.ex_rates])

  const getExchangeRate=(usrCur)=>{
    if(context.ex_rates!=="" && context.ex_rates!==undefined && context.ex_rates!==null){
      if (context.ex_rates?.hasOwnProperty(usrCur)) {
        var sup = context.ex_rates[usrCur];
        setBaseToUserExrate(Number(sup))
      }
    }
  }

  const FilteredResponseCount = (count) => {
    setResultsCount(count);
  }

  const handleBlockWidth = (data) => {
    if(data === "full") {
      setWidth(false);
    } else {
      setWidth(true);
    }
  }

  const makeHeaderActive = (value) => {
    props.activeHeader(value)
  }

  const handleReviewLoader = (data) => {
    setReviewLoader(data);
  }

  const priceFilter = (data) => {
    if(data.length !== 0) {
      setFlightData(data);
    }
  }

  
    return (
      <>
        {loader ? (
          <>
            <ModifySearch headerActive={makeHeaderActive} />
            <FlightSearchLoader />
          </>
        ) : (
          <>        
          <div className="resultPage">  
          {!reviewLoader && <ModifySearch headerActive={makeHeaderActive} />}          
          {flightData?.length !== 0 && flightData !== undefined && flightData !== null && (
            <>
            {!reviewLoader && (
              <>
              <section className='showingWrap'>
                <div class="mainContainer">
                  <Row>
                    <Col>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="showingFlights">
                            Showing {resultsCount} of {flightData?.totalflight} flights
                          </div>
                          <div className="showPricingCalendarWrap">
                          <i class="icon icon-calendar"></i> Show Pricing Calendar <i class="icon icon-down-arrow"></i>
                          </div>
                        </div>
                    </Col>
                  </Row>
                </div>
              </section>
              <SortBy
                totalflights={flightData?.totalflight}
                currency={flightData?.Currency}
                data={flightData?.Filter?.sortby}            
                base_usr_exrate={base_usr_exrate}
                sendWidth={handleBlockWidth}
              />  
              </>
            )}
                <section>
                <div className="mainContainer">               
                <div className='d-flex'>
                  <FlightFilters
                    data={flightData?.Filter}
                    currency={flightData?.Currency}
                    clearFilter={clearAllFilter}
                    totalData={flightData?.Data}
                    clear={flightData}
                    base_usr_exrate={base_usr_exrate}
                    sendPriceFilterData={priceFilter}
                  />
                  <FlightDetails
                    totalflights={flightData?.totalflight}
                    currency={flightData?.Currency}
                    cur={finalData?.cur}
                    data={flightData?.Data}
                    request={finalData}
                    base_usr_exrate={base_usr_exrate}
                    sendResultsCount={FilteredResponseCount}
                    width={width}
                    skeletonloader={handleReviewLoader}
                  />
                </div>
              </div>
            </section>
            </>
          )}
          {notFound && 
          <div className='d-flex justify-content-center align-items-center noFlightsWrap'>
          <div className='text-center'>
            <img src={NoFlights} alt="errorIcon" />
            <div className='noflight'>No flights found</div>
            <div className='anyflights'>We couldn't find any flights for the dates your are searching for.</div>
            <div className='changeDates'><i class="icon icon-Reset"></i> Change dates or airports</div>
          </div>
        </div>
          
          }
          </div> 
          </>
        )}
      </>
    )
  // } else {
  //   return (
  //     <>
  //       <div className='loadersdiv'>
  //         <img src={loader} alt='' />
  //       </div>

  //       {/* <ModifySearch /> */}
  //       <section class="flight-info-roots">
  //         <div class="container d-flex">
  //           <Static />
  //         </div>
  //       </section>

  //     </>
  //   )
  // }
}

export default FlightResultDetails
