import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Td1 from "../../assets/images/home/trending-destinations/td1.png";
import Td2 from "../../assets/images/home/trending-destinations/td2.png";
import Td3 from "../../assets/images/home/trending-destinations/td3.png";
import Td4 from "../../assets/images/home/trending-destinations/td4.png";
import Td5 from "../../assets/images/home/trending-destinations/td5.png";
import Td6 from "../../assets/images/home/trending-destinations/td6.png";

function TrendingDestination() {
  return (
    <section className='mt-50'>
        <div className="mainContainer">
        <h3 className='headingTitle mb-5'>Trending Destinations</h3>
          <Row>
            <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td1} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
            <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td2} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
            <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td3} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
          </Row>
          <Row>
          <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td4} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
            <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td5} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
            <Col className='p-0 m-10'>
              <div className='tdWrap position-relative'>
                <figure><img src={Td6} alt='trending-destination-image' className='img-fluid trendingImage' /></figure>
                <div className='destinationName'>Bangkok</div>
                <div className='destinationAmout'><span>Starts from</span> AED 385.00</div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
  )
}

export default TrendingDestination
