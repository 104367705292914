import React from 'react';
import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Bo1 from "../../assets/images/home/best-offer/bo1.png";
import Bo2 from "../../assets/images/home/best-offer/bo2.png";
import Bo3 from "../../assets/images/home/best-offer/bo3.png";

function BestOffers() {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 3px 6px #00000029" }}
            onClick={onClick}
          />
        );
      }
    
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "#fff", boxShadow: "0px 3px 6px #00000029" }}
            onClick={onClick}
          />
        );
      }
      var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

      // const options = {
      //   items: 3,
      //   loop: true
      // }
  return (
    <section className='bestOfferWrap'>
    <div className="mainContainer">
    <h3 className='headingTitle mb-15'>Best Flight & Hotel Deals</h3>
      <Row>
        <Col>
            <Slider {...settings}>
              <div>
                <img src={Bo1} alt='best-offer-image' className='img-fluid offerImage' />
              </div>
              <div>
                <img src={Bo2} alt='best-offer-image' className='img-fluid offerImage' />
              </div>
              <div>
                <img src={Bo3} alt='best-offer-image' className='img-fluid offerImage' />
              </div>
            </Slider>

            {/* <OwlCarousel className='owl-theme' {...options}>
                <div class='item'>
                  <img src={Bo1} alt='best-offer-image' className='img-fluid offerImage' />
                </div>
                <div class='item'>
                  <img src={Bo2} alt='best-offer-image' className='img-fluid offerImage' />
                </div>
                <div class='item'>
                  <img src={Bo3} alt='best-offer-image' className='img-fluid offerImage' />
                </div>
            </OwlCarousel>; */}
        </Col>
      </Row>
    </div>
  </section>
  )
}

export default BestOffers
