// handle post api.....

import axios from "axios"
const Api_Url = process.env.REACT_APP_FLIGHT_CONNECTER;

export const handlePostApi= async (url, data)=>{
  try {
    const response = await axios.post(`${Api_Url}${url}`, data);
    return response;
  } catch(err){
    console.log(`${url} Error`, err)
  }
}

export const handleGetApi = async (url, data) => {
  try {
    const response = await axios.post(`${url}`, data);
    return response;
  } catch (err) {
    console.log(`${url} Error`, err)
  }
}