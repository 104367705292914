import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import { handlePostApi } from '../../utilities/Axios';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Sidebar from './Sidebar';
import TripsTab from './TripsTab';
import Loader from '../../assets/Gifs/Double Ring-1s-200px.gif';
import MyAccFooter from '../common/MyAccFooter';

/**
 * @author Praveen Varma
 * @description Mytrips
 * @date 24-06-2024
 * @returns 
 */
function MyTrips(props) {
    const nav = useNavigate();
    const [active_tab, setActiveTab] = useState("Upcoming");
    const [trips, setTrips] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selProduct, setselProduct] = useState({value:"Flight",label:"Flight"});    
    const [product, setProduct] = useState(
        [
            {value:"Flight",label:"Flight"},
            // {value:"Hotel",label:"Hotel"}
        ]);

    useEffect(() => {
        let login = localStorage.getItem("logged");
        if (login) {
            login = JSON.parse(login);
            if (login && login.unique_id) {
                fetch_mytrips(login.unique_id, active_tab);
            }
        } else {
            nav("/")
        }
    }, [active_tab]);

    /**
     * Fetch my trips
     * @param {*} key 
     */
    const fetch_mytrips = async (unique_id, key) => {
        try {
            setLoader(true);
            setTrips([]);
            handlePostApi(`${process.env.REACT_APP_COMMON_URL}/b2c/user/mytrips`, {
                unique_id: unique_id,
                header_st: key
            }).then(async function (result) {
                if (result?.data?.suc && result?.data?.res && result?.data?.res?.length > 0) {
                    setTrips(result?.data?.res);
                }
                setLoader(false);
            }).catch(error => {
                console.log("fetch trips data", error);
                setLoader(false);
                setTrips(null);
            });
        } catch (error) {
            console.log("catch fetch trips data", error);
            setTrips(null);
        }
    }

    return (
        <>
            <div className="fixedHeader">
                <Header page="fixedHeader" flow="RESULT" />
            </div>
            <div className='loadersdiv' style={{ display: loader ? 'flex' : 'none' }}>
                <img src={Loader} alt='' />
            </div>
            <div className="myaccountStatus"></div>
            <div className="mytripsPage">
                <section>
                    <div className="mainContainer">
                        <Row>
                            <Col>
                                <div className="d-flex topPage">
                                    <Sidebar active="Mytrips" />
                                    <div className="mytripsSideWrap">
                                        <div className="myaccCardWrap">
                                            <div className="myaccHeaderWrap">
                                                <span>My Trips</span>
                                                <div className="bookingWrap">
                                                    <div className='allBookingSelect'>
                                                        <Select 
                                                          options={product}
                                                          values={selProduct}
                                                          defaultValue={selProduct}
                                                          placeholder="All Bookings"
                                                        >                  
                                                        </Select>
                                                    </div>
                                                    {/* <div className='position-relative'><Form.Control type="text" placeholder="Search Bookings..." /><i class="icon icon-Search"></i></div> */}
                                                </div>
                                            </div>
                                            <div className="myaccBodyWrap">
                                                <Tabs defaultActiveKey={active_tab} id="uncontrolled-tab-example" className='tripsTab' onSelect={(e) => {
                                                    setActiveTab(e);
                                                }}>
                                                    <Tab eventKey={"Upcoming"} title="Upcoming">
                                                        {trips && trips.length > 0 ? <TripsTab trips={trips} /> : loader ? "" : "No upcoming trips found"}
                                                    </Tab>
                                                    <Tab eventKey={"Cancelled"} title="Cancelled">
                                                        {trips && trips.length > 0 ? <TripsTab trips={trips} /> : loader ? "" :  "No cancelled trips found"}
                                                    </Tab>
                                                    <Tab eventKey={"Completed"} title="Completed">
                                                        {trips && trips.length > 0 ? <TripsTab trips={trips} /> : loader ? "" :  "No completed trips found"}
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
             <MyAccFooter />                                   
        </>
    )
}

export default MyTrips
