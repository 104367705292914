import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';

function Newsletter() {
  return (
    <section className='newsletterWrap'>
        <div className="mainContainer">
          <Row>
            <Col>
             <div className="paddWrap">
              <div>
                <h3>Sign up & Enjoy Exclusive Benefits!</h3>
                <p className='mb-30'>Get cashback, exclusive deals, heads up on upcoming offers and many more</p>
                <Form className='d-flex formWrap'>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control type="email" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className='subscribeBtn'>
                    Subscribe
                  </Button>
                </Form>
              </div>
              <div>
              <i class="icon icon-send"></i>
              </div>
             </div>
            </Col>
          </Row>
        </div>
      </section>
  )
}

export default Newsletter
