import axios from 'axios';
const Api_Url = process.env.REACT_APP_COMMON_URL;
// returns the airport name...
// const airportList = require('../../../utilities/trawexairportlist.json')
export const handleAirportSearch = async (value) => {
  const data = {
    searchCriteria: value
  }
  console.log(data)
  try {
    const response = await axios.post(`${Api_Url}/b2c/prsearch/predectivesearch`, data);
    return response;
  } catch (err) {
    console.log(err);
  }
}